import React, { useState } from 'react';
import { Box, SpeedDial, SpeedDialIcon, SpeedDialAction, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import useStyles from './styles';

export interface Iactions {
  name: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  handleClick: () => void;
  hidden?: boolean;
}

interface Props {
  actions: Iactions[];
}

const CustomSpeedDial = ({ actions }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <Box className={classes.speedDial}>
      <SpeedDial
        ariaLabel="SpeedDial custom component"
        open={open}
        icon={<SpeedDialIcon />}
        onOpen={handleClose}
        onClose={handleClose}
      >
        {/* eslint-disable-next-line operator-linebreak */}
        {actions.map(({ name, icon: Icon, handleClick, hidden }) => {
          return (
            !hidden && (
              <SpeedDialAction key={name} icon={<Icon color="primary" />} onClick={handleClick} tooltipTitle={name} />
            )
          );
        })}
      </SpeedDial>
    </Box>
  );
};

export default CustomSpeedDial;
