import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import Image from 'components/Image';
import Audio from 'components/Audio';
import { FormikProps } from 'formik';
import { newProposal } from 'interfaces/Proposal';

interface Props {
  formik: FormikProps<newProposal>;
  activeNextButton: (active: boolean) => void;
}

const maxPhotosSizeMB = 1.6;
const maxAudioSizeMB = 1.6;

const spacing = 2;

const Documents = ({ formik, activeNextButton }: Props) => {
  useEffect(() => {
    if (
      formik.values.documentos[0].frenteBase64 === 'data:image/png;base64,undefined' ||
      formik.values.documentos[0].frenteBase64 === '' ||
      formik.values.documentos[1].versoBase64 === '' ||
      formik.values.documentos[1].versoBase64 === 'data:image/png;base64,undefined'
    ) {
      activeNextButton(true);
    } else {
      activeNextButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.documentos]);

  const handleBase64Front = (base64: string, file: File) => {
    formik.setFieldValue('documentos[0].frenteBase64', `${base64}`);
    formik.values.formDataNewProposal.append('frente', file);
    // formik.values.documentosArquivo.push(file);
  };

  const handleBase64Verse = (base64: string, file: File) => {
    formik.setFieldValue('documentos[1].versoBase64', `${base64}`);
    formik.values.formDataNewProposal.append('verso', file);
    // formik.values.documentosArquivo.push(file);
  };

  const handleBase64Address = (base64: string, file: File) => {
    formik.setFieldValue('documentos[2].frenteBase64', `${base64}`);
    formik.values.formDataNewProposal.append('endereco', file);
    // formik.values.documentosArquivo.push(file);
  };
  const handleBase64Audio = (base64: string, file: File) => {
    formik.values.formDataNewProposal.append('audio', file);
  };
  return (
    <Grid container direction="column" spacing={spacing} sx={{ overflow: 'hidden' }}>
      <Grid item>
        <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '120%' }}>
          Documentos
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={spacing}>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '100%' }}>
                  Documento de Identificação - Frente *
                </Typography>
              </Grid>
              <Grid item>
                <Image
                  name="frente"
                  sizeLimitMB={maxPhotosSizeMB}
                  getBase64={handleBase64Front}
                  base64={formik.values.documentos[0].frenteBase64}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '100%' }}>
                  Documento de Identificação - Verso *
                </Typography>
              </Grid>
              <Grid item>
                <Image sizeLimitMB={maxPhotosSizeMB} name="verso" getBase64={handleBase64Verse} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '100%' }}>
          Comprovante de Endereço
        </Typography>
      </Grid>
      <Grid item>
        <Image sizeLimitMB={maxPhotosSizeMB} name="endereco" getBase64={handleBase64Address} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '100%' }}>
          Áudio
        </Typography>
      </Grid>
      <Grid item>
        <Audio name="audio" sizeLimitMB={maxAudioSizeMB} getBase64={handleBase64Audio} />
      </Grid>
    </Grid>
  );
};

export default Documents;
