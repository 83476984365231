import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    bodyContent: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '350px',
      boxShadow: '24',
      p: 4,
    },
    paperContent: { padding: '20px', maxHeight: '80vh', overflow: 'auto' },
    circularIcon: {
      // border: `2px solid ${theme.palette.primary.main}`,
      padding: '3px',
      marginLeft: '10px',
    },
    colorLabelRadioButton: { color: theme.palette.primary.main },
    colorIconButton: { color: 'white' },
  };
});

export default useStyles;
