/* eslint-disable object-curly-newline */
import React from 'react';
import {
  Box,
  Typography,
  Modal,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  FormHelperText,
  IconButton,
  Fab,
  CircularProgress,
  Grow,
} from '@mui/material';
import { Send, Check } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks/useAppStore';
import { resendContractType } from 'store/ducks/Proposal';
import useStyles from './styles';
import validationSchema from './validationSchema';

interface Props {
  open: boolean;
  handleModal: () => void;
  proposal: number;
  idProduct: number;
}

const ResendContract = ({ open, proposal, idProduct, handleModal }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const {
    groups: {
      groupsAndPermission: { data: dataPermissions },
    },
  } = useAppSelector((state) => {
    return state;
  });

  const permissions = { canSendEmail: false, canSendSms: false, canSendWhatsapp: false };
  dataPermissions.forEach(({ funcionalidade, type: tipo, visualizar }) => {
    if (tipo === 'FUNCTIONALITY' && funcionalidade === 'Send Email') {
      permissions.canSendEmail = visualizar;
    } else if (tipo === 'FUNCTIONALITY' && funcionalidade === 'Send SMS') {
      permissions.canSendSms = visualizar;
    } else if (tipo === 'FUNCTIONALITY' && funcionalidade === 'Send WhatsApp') {
      permissions.canSendWhatsapp = visualizar;
    }
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: { shippingType: '' },
    onSubmit: async (values) => {
      const {
        meta: { requestStatus },
      } = await dispatch(
        resendContractType({
          idProposal: proposal,
          idProduct,
          shippingType: values.shippingType,
        }),
      );
      setSuccess(false);
      setLoading(true);
      setTimeout(() => {
        if (requestStatus === 'fulfilled' || requestStatus === 'rejected') {
          setSuccess(true);
          setLoading(false);
        }
      }, 2000);
    },
  });

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': { bgcolor: green[700] },
    }),
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-history-proposal"
        aria-describedby="modal-history-description"
      >
        <Box className={classes.bodyContent}>
          <Paper className={classes.paperContent}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container direction="column">
                <Grid item>
                  <FormControl component="fieldset" error={!formik.errors.shippingType}>
                    <RadioGroup name="shippingType" onChange={formik.handleChange}>
                      <FormLabel component="legend">
                        <Typography color="primary" variant="button">
                          Tipo de Reenvio
                        </Typography>
                      </FormLabel>
                      {permissions.canSendEmail && (
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label="Email"
                          className={classes.colorLabelRadioButton}
                        />
                      )}
                      {permissions.canSendSms && (
                        <FormControlLabel
                          value="sms"
                          control={<Radio />}
                          color="primary"
                          label="SMS"
                          className={classes.colorLabelRadioButton}
                        />
                      )}
                      {permissions.canSendWhatsapp && (
                        <FormControlLabel
                          value="whatsapp"
                          control={<Radio />}
                          label="WhatsApp"
                          className={classes.colorLabelRadioButton}
                        />
                      )}
                    </RadioGroup>
                    <FormHelperText error>{formik.errors.shippingType}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ m: 1, position: 'relative' }}>
                          <Grow in={success}>
                            <div
                              style={{
                                backgroundColor: green[500],
                                color: 'white',
                                padding: '8px',
                                borderRadius: '5px',
                              }}
                            >
                              {/* eslint-disable-next-line react/jsx-one-expression-per-line  */}
                              {formik.values.shippingType} enviado com sucesso
                            </div>
                          </Grow>
                        </Box>
                        <Box sx={{ m: 1, position: 'relative' }}>
                          <Fab aria-label="save" color="primary" sx={buttonSx}>
                            <IconButton
                              aria-label="filter-list"
                              size="large"
                              aria-controls={open ? 'sendBtn' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              type="submit"
                              disabled={success}
                            >
                              {success ? (
                                <Check className={classes.colorIconButton} />
                              ) : (
                                <Send className={classes.colorIconButton} />
                              )}
                            </IconButton>
                          </Fab>
                          {loading && (
                            <CircularProgress
                              size={68}
                              sx={{
                                color: green[500],
                                position: 'absolute',
                                top: -6,
                                left: -6,
                                zIndex: 1,
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </Modal>
    </div>
  );
};

export default ResendContract;
