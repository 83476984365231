import FormProposalStatus from 'pages/Proposal/FormView';
import Layout from 'components/Layout';
import ListView from 'pages/Proposal/ListView';
import NewProposal from 'pages/Proposal/NewProposal';
import Home from 'pages/Home';
import Signin from 'pages/Signin';
import ProductSelection from 'pages/Proposal/ProductSelection';

interface IRoutes {
  path: string;
  title: string;
  component: () => JSX.Element;
  isPrivate: boolean;
}

const routes: IRoutes[] = [
  {
    path: '/',
    title: 'Home',
    component: Home,
    isPrivate: true,
  },
  {
    path: '/signin',
    title: 'Signin',
    component: Signin,
    isPrivate: false,
  },

  {
    path: '/*',
    title: 'Layout',
    component: Layout,
    isPrivate: false,
  },
  {
    path: '/proposal/list',
    title: 'Proposal List',
    component: ListView,
    isPrivate: true,
  },
  {
    path: '/proposal/:id',
    title: 'Proposal By Id',
    component: FormProposalStatus,
    isPrivate: true,
  },
  {
    path: '/proposal/new/:productid',
    title: 'dashboard',
    component: NewProposal,
    isPrivate: true,
  },
  {
    path: '/proposal/productselection',
    title: 'New Proposal',
    component: ProductSelection,
    isPrivate: true,
  },
];

export default routes;
