/* eslint-disable object-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  Container,
  Typography,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  FormHelperText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/';
import { FormikProps } from 'formik';
import { newProposal } from 'interfaces/Proposal';
import { maskCep, maskCpf, maskTellPhone, maskCel } from 'utils/string/masks';
import { getCep } from 'services/external/correios';
import { useAppDispatch } from 'hooks/useAppStore';
import cities from 'utils/data/cities';
import {
  vileveAssistenciaEstadosCivis,
  vileveAssistenciaNacionalidade,
  vileveAssistenciaSexo,
  vileveAssistenciaProfissoes,
} from 'interfaces/externalApi';
import { getNacionalidades, getEstadosCivis, getSexo, getProfissoes } from 'services/external/vileveAssistencia';
import CustomBackDrop from 'components/CustomBackDrop';
import { validateCelular, validadorCpf } from 'store/ducks/Proposal';
import { useParams } from 'react-router-dom';
import { ParamsRouteProduct } from 'interfaces/RouterParams';
import { Product } from '..';

interface Props {
  formik: FormikProps<newProposal>;
  activeNextButton: (active: boolean) => void;
}

const spacing = 2;
const PersonalData = ({ formik, activeNextButton }: Props) => {
  const dispatch = useAppDispatch();
  const { productid: productId } = useParams<ParamsRouteProduct>();

  const [citiesSelect, setCitiesSelect] = useState<{ cidade: string }[]>([{ cidade: '' }]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const [widthProfessions, setWidthProfessions] = useState<number | string>('100%');
  const [sexos, setSexos] = useState<vileveAssistenciaSexo[]>([]);
  const [nacionalidades, setSetNacionalidades] = useState<vileveAssistenciaNacionalidade[]>([]);
  const [estadosCivis, setEstadoCivis] = useState<vileveAssistenciaEstadosCivis[]>([]);
  const [professions, setProfessions] = useState<vileveAssistenciaProfissoes[]>([]);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [product] = useState<Product>({
    isCobap: Number(productId as string) === 46,
    isVileve:
      Number(productId as string) === 1 ||
      Number(productId as string) === 2 ||
      Number(productId as string) === 3 ||
      Number(productId as string) === 28,
    isZurich: Number(productId as string) === 44 || Number(productId as string) === 45,
  });

  useEffect(() => {
    if (!formik.errors?.cliente && !formik.errors?.clienteCobapEZurich?.idProfissao && !formik.errors?.enderecos) {
      activeNextButton(false);
    } else {
      activeNextButton(true);
    }
  }, [formik.errors?.cliente, formik.errors?.clienteCobapEZurich?.idProfissao, formik.errors?.enderecos]);

  const [stateSelect] = useState(
    cities
      .filter((value, index, self) => {
        return (
          // eslint-disable-next-line operator-linebreak
          index ===
          self.findIndex((t) => {
            return t.estado === value.estado;
          })
        );
      })
      .map(({ estado }) => {
        return { estado };
      })
      .sort((a, b) => {
        // eslint-disable-next-line no-nested-ternary
        return a.estado < b.estado ? -1 : a.estado > b.estado ? 1 : 0;
      }),
  );

  useEffect(() => {
    const getData = async () => {
      setOpenBackDrop(true);
      setSetNacionalidades(await getNacionalidades());
      setEstadoCivis(await getEstadosCivis());
      setSexos(await getSexo());
      setProfessions(await getProfissoes());
      setOpenBackDrop(false);
    };
    const professionsInput = document.getElementById('clienteCobapEZurich.idProfissao') as HTMLInputElement;
    if (isMobile) {
      setWidthProfessions(professionsInput.clientWidth || '100%');
    }
    getData();
  }, []);

  useEffect(() => {
    if (product.isCobap) {
      formik.setFieldValue('cliente.inssNumeroBeneficio', 'yup');
      formik.setFieldValue('cliente.outrosDiaPagamento', 1);
      formik.setFieldTouched('cliente.inssNumeroBeneficio', true);
    } else if (product.isVileve) {
      formik.setFieldValue('cliente.codigoEstadoCivil', 55);
      formik.setFieldValue('cliente.codigoNacionalidade', 113);
      formik.setFieldValue('cliente.codigoSexo', 113);
      formik.setFieldValue('clienteCobapEZurich.idProfissao', 11);
      formik.setFieldValue('cliente.inssNumeroBeneficio', 'yup');
      formik.setFieldValue('cliente.outrosDiaPagamento', 1);
    } else if (product.isZurich) {
      formik.setFieldValue('cliente.inssNumeroBeneficio', 'yup');
      formik.setFieldValue('cliente.outrosDiaPagamento', 1);
    }
  }, []);

  useEffect(() => {
    const fillCep = async () => {
      if (formik.values.enderecos.cep.length === 9) {
        setOpenBackDrop(true);
        const res = await getCep(formik.values.enderecos.cep);
        formik.setFieldValue('enderecos.logradouro', res.logradouro);
        formik.setFieldValue('enderecos.complemento', res.complemento);
        formik.setFieldValue('enderecos.bairro', res.bairro);
        formik.setFieldValue('enderecos.cidade', res.localidade.toUpperCase().trim());
        formik.setFieldValue('enderecos.estado', res.uf);
        setOpenBackDrop(false);
      } else if (formik.values.enderecos.cep.length === 0) {
        formik.setFieldValue('enderecos.logradouro', '');
        formik.setFieldValue('enderecos.complemento', '');
        formik.setFieldValue('enderecos.bairro', '');
        formik.setFieldValue('enderecos.cidade', '');
        formik.setFieldValue('enderecos.estado', '');
      }
    };
    fillCep();
  }, [formik.values.enderecos.cep]);

  useEffect(() => {
    if (formik.values.enderecos.estado.length === 2) {
      setCitiesSelect(
        cities
          .filter((value) => {
            return value.estado === formik.values.enderecos.estado;
          })
          .map(({ cidade }) => {
            return { cidade };
          }),
      );
      if (formik.values.enderecos.cidade !== '') {
        formik.setFieldValue('enderecos.cidade', formik.values.enderecos.cidade);
      }
    }
  }, [formik.values.enderecos.estado]);

  return (
    <Container>
      <CustomBackDrop open={openBackDrop} />
      <Grid container direction="column" spacing={spacing}>
        <Grid item>
          <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '120%' }}>
            Dados Pessoais
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={spacing}>
            <Grid item xs={12} md={4}>
              <TextField
                id="cliente.cpf"
                name="cliente.cpf"
                label="CPF"
                size="small"
                fullWidth
                required
                inputProps={{ maxLength: 14 }}
                value={formik.values.cliente.cpf}
                onChange={(event) => {
                  formik.setFieldValue('cliente.cpf', maskCpf(event.target.value));
                }}
                onBlur={async (event) => {
                  setOpenBackDrop(true);
                  await dispatch(validadorCpf(formik.values.cliente.cpf.replace(/[.-]/g, '')));
                  setOpenBackDrop(false);
                  formik.handleBlur(event);
                }}
                error={formik.touched.cliente?.cpf && Boolean(formik.errors.cliente?.cpf)}
                helperText={formik.touched.cliente?.cpf && formik.errors.cliente?.cpf}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="cliente.nomeCompleto"
                name="cliente.nomeCompleto"
                label="Nome Completo"
                size="small"
                fullWidth
                required
                inputProps={{ maxLength: 50 }}
                value={formik.values.cliente.nomeCompleto}
                onChange={(event) => {
                  formik.setFieldValue(
                    'cliente.nomeCompleto',
                    event.target.value.replace(/[^A-Z a-zzáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/g, ''),
                  );
                }}
                onBlur={(event) => {
                  localStorage.setItem('name', formik.values.cliente.nomeCompleto.split(' ')[0]);
                  formik.handleBlur(event);
                }}
                error={formik.touched.cliente?.nomeCompleto && Boolean(formik.errors.cliente?.nomeCompleto)}
                helperText={formik.touched.cliente?.nomeCompleto && formik.errors.cliente?.nomeCompleto}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Data de Nascimento"
                value={formik.values.cliente.dataNascimento}
                onChange={(event) => {
                  formik.setFieldValue('cliente.dataNascimento', event);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      id="personalData"
                      name="cliente.dataNascimento"
                      size="small"
                      variant="outlined"
                      fullWidth
                      required
                      // eslint-disable-next-line
                      {...params}
                      onBlur={formik.handleBlur}
                      error={formik.touched.cliente?.dataNascimento && Boolean(formik.errors.cliente?.dataNascimento)}
                      helperText={formik.touched.cliente?.dataNascimento && formik.errors?.cliente?.dataNascimento}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {(product.isCobap || product.isZurich) && (
          <Grid item xs={12}>
            <Grid container spacing={spacing}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="cliente.codigoSexo"
                  name="cliente.codigoSexo"
                  label="Sexo"
                  size="small"
                  fullWidth
                  required
                  value={formik.values.cliente.codigoSexo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.cliente?.codigoSexo && Boolean(formik.errors.cliente?.codigoSexo)}
                  helperText={formik.touched.cliente?.codigoSexo && formik.errors.cliente?.codigoSexo}
                  select
                >
                  {sexos.map(({ codigoSexo, nome }) => {
                    return (
                      <MenuItem value={codigoSexo} key={`${nome}-${codigoSexo}`}>
                        {nome}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="cliente.codigoEstadoCivil"
                  name="cliente.codigoEstadoCivil"
                  label="Estado Civil"
                  size="small"
                  fullWidth
                  required
                  value={formik.values.cliente.codigoEstadoCivil}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.cliente?.codigoEstadoCivil && Boolean(formik.errors.cliente?.codigoEstadoCivil)}
                  helperText={formik.touched.cliente?.codigoEstadoCivil && formik.errors.cliente?.codigoEstadoCivil}
                  select
                >
                  {estadosCivis.map(({ codigoEstadoCivil, nome }) => {
                    return (
                      <MenuItem key={codigoEstadoCivil} value={codigoEstadoCivil}>
                        {nome}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="cliente.codigoNacionalidade"
                  name="cliente.codigoNacionalidade"
                  label="Nacionalidade"
                  size="small"
                  fullWidth
                  required
                  inputProps={{ maxLength: 14 }}
                  value={formik.values.cliente.codigoNacionalidade}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.cliente?.codigoNacionalidade && Boolean(formik.errors.cliente?.codigoNacionalidade)
                  }
                  helperText={formik.touched.cliente?.codigoNacionalidade && formik.errors.cliente?.codigoNacionalidade}
                  select
                >
                  {nacionalidades.map(({ codigoNacionalidade, nome }) => {
                    return (
                      <MenuItem key={`${codigoNacionalidade}-${nome}`} value={codigoNacionalidade}>
                        {nome}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={spacing}>
            <Grid item xs={12} md={4}>
              <TextField
                id="cliente.telefoneCelular"
                name="cliente.telefoneCelular"
                label="Celular"
                size="small"
                fullWidth
                required
                inputProps={{ maxLength: 15 }}
                value={formik.values.cliente.telefoneCelular}
                onChange={(event) => {
                  formik.setFieldValue('cliente.telefoneCelular', maskCel(event.target.value));
                  // if (proposalRedux.validateCelular?.document[0].status) {
                  //   localStorage.setItem('validateCelular', proposalRedux.validateCelular?.document[0].status);
                  // } else {
                  //   localStorage.setItem('validateCelular', 'false');
                  // }
                }}
                onBlur={async (event) => {
                  setOpenBackDrop(true);
                  await dispatch(validateCelular(`0${formik.values.cliente.telefoneCelular.replace(/[- ()]/g, '')}`));
                  setOpenBackDrop(false);
                  formik.handleBlur(event);
                }}
                error={formik.touched.cliente?.telefoneCelular && Boolean(formik.errors.cliente?.telefoneCelular)}
                helperText={formik.touched.cliente?.telefoneCelular && formik.errors.cliente?.telefoneCelular}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="cliente.telefoneFixo"
                name="cliente.telefoneFixo"
                label="Telefone Fixo"
                size="small"
                fullWidth
                inputProps={{ maxLength: 14 }}
                value={formik.values.cliente.telefoneFixo}
                onChange={(event) => {
                  formik.setFieldValue('cliente.telefoneFixo', maskTellPhone(event.target.value));
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.cliente?.telefoneFixo && Boolean(formik.errors.cliente?.telefoneFixo)}
                helperText={formik.touched.cliente?.telefoneFixo && formik.errors.cliente?.telefoneFixo}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="cliente.email"
                name="cliente.email"
                label="Email"
                size="small"
                fullWidth
                required
                value={formik.values.cliente?.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.cliente?.email && Boolean(formik.errors.cliente?.email)}
                helperText={formik.touched.cliente?.email && formik.errors.cliente?.email}
              />
            </Grid>
            {(product.isCobap || product.isZurich) && (
              <Grid item xs={12} lg={12}>
                <TextField
                  id="clienteCobapEZurich.idProfissao"
                  name="clienteCobapEZurich.idProfissao"
                  label="Profissão"
                  size="small"
                  fullWidth
                  required
                  value={formik.values.clienteCobapEZurich.idProfissao}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.clienteCobapEZurich?.idProfissao &&
                    Boolean(formik.errors.clienteCobapEZurich?.idProfissao)
                  }
                  helperText={
                    formik.touched.clienteCobapEZurich?.idProfissao && formik.errors.clienteCobapEZurich?.idProfissao
                  }
                  sx={{ maxWidth: widthProfessions }}
                  select
                >
                  {professions.map(({ idProfissao, descricao }) => {
                    return (
                      <MenuItem key={`${idProfissao}-${descricao}`} value={idProfissao}>
                        {descricao}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            )}
          </Grid>
        </Grid>
        {product.isZurich && (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormControlLabel
                      // prettier-ignore
                      control={
                    (
                      <Switch
                        id="clienteCobapEZurich.servidorPublico"
                        name="clienteCobapEZurich.servidorPublico"
                        value={formik.values.clienteCobapEZurich.servidorPublico}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )
                  }
                      label="É servidor público?"
                    />
                    <FormHelperText
                      error={
                        formik.touched.clienteCobapEZurich?.servidorPublico &&
                        Boolean(formik.errors.clienteCobapEZurich?.servidorPublico)
                      }
                    >
                      {formik.touched.clienteCobapEZurich?.servidorPublico &&
                        formik.errors.clienteCobapEZurich?.servidorPublico}
                    </FormHelperText>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={5}>
                  <FormGroup>
                    <FormControlLabel
                      // prettier-ignore
                      control={
                    (
                      <Switch
                        id="clienteCobapEZurich.publicamenteExposto"
                        name="clienteCobapEZurich.publicamenteExposto"
                        value={formik.values.clienteCobapEZurich.publicamenteExposto}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )
                  }
                      // eslint-disable-next-line max-len
                      label="É politicamente exposto PEP?"
                    />
                    <FormHelperText
                      error={
                        formik.touched.clienteCobapEZurich?.publicamenteExposto &&
                        Boolean(formik.errors.clienteCobapEZurich?.publicamenteExposto)
                      }
                    >
                      {formik.touched.clienteCobapEZurich?.publicamenteExposto &&
                        formik.errors.clienteCobapEZurich?.publicamenteExposto}
                    </FormHelperText>
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormControlLabel
                      // prettier-ignore
                      control={
                    (
                      <Switch
                        id="clienteCobapEZurich.estaTrabalhando"
                        name="clienteCobapEZurich.estaTrabalhando"
                        value={formik.values.clienteCobapEZurich.estaTrabalhando}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )
                  }
                      label="No momento, você está ativo trabalhando?"
                    />
                    <FormHelperText
                      error={
                        formik.touched.clienteCobapEZurich?.estaTrabalhando &&
                        Boolean(formik.errors.clienteCobapEZurich?.estaTrabalhando)
                      }
                    >
                      {formik.touched.clienteCobapEZurich?.estaTrabalhando &&
                        formik.errors.clienteCobapEZurich?.estaTrabalhando}
                    </FormHelperText>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={5}>
                  <FormGroup>
                    <FormControlLabel
                      // prettier-ignore
                      control={
                    (
                      <Switch
                        id="clienteCobapEZurich.afastadoNosUltimosDoisAnos"
                        name="clienteCobapEZurich.afastadoNosUltimosDoisAnos"
                        value={formik.values.clienteCobapEZurich.afastadoNosUltimosDoisAnos}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )
                  }
                      // eslint-disable-next-line max-len
                      label="Se afastou mais de 21 dias consecutivos do trabalho, nos últimos 2 anos por doença ou lesão?"
                    />
                    <FormHelperText
                      error={
                        formik.touched.clienteCobapEZurich?.afastadoNosUltimosDoisAnos &&
                        Boolean(formik.errors.clienteCobapEZurich?.afastadoNosUltimosDoisAnos)
                      }
                    >
                      {formik.touched.clienteCobapEZurich?.afastadoNosUltimosDoisAnos &&
                        formik.errors.clienteCobapEZurich?.afastadoNosUltimosDoisAnos}
                    </FormHelperText>
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormControlLabel
                      // prettier-ignore
                      control={
                    (
                      <Switch
                        id="clienteCobapEZurich.medicamentoPorPeriodoMaiorQueQuatroSemanas"
                        name="clienteCobapEZurich.medicamentoPorPeriodoMaiorQueQuatroSemanas"
                        value={formik.values.clienteCobapEZurich.medicamentoPorPeriodoMaiorQueQuatroSemanas}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )
                  }
                      // eslint-disable-next-line max-len
                      label="Nos últimos 2 anos, você recebeu medicamentos que foram aconselhados a tomar por um período superior a 4 semanas?"
                    />
                    <FormHelperText
                      error={
                        formik.touched.clienteCobapEZurich?.medicamentoPorPeriodoMaiorQueQuatroSemanas &&
                        Boolean(formik.errors.clienteCobapEZurich?.medicamentoPorPeriodoMaiorQueQuatroSemanas)
                      }
                    >
                      {formik.touched.clienteCobapEZurich?.medicamentoPorPeriodoMaiorQueQuatroSemanas &&
                        formik.errors.clienteCobapEZurich?.medicamentoPorPeriodoMaiorQueQuatroSemanas}
                    </FormHelperText>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="clienteCobapEZurich.qualMedicamento"
                    name="clienteCobapEZurich.qualMedicamento"
                    label="Qual Medicamento"
                    size="small"
                    fullWidth
                    required={formik.values.clienteCobapEZurich.medicamentoPorPeriodoMaiorQueQuatroSemanas}
                    disabled={!formik.values.clienteCobapEZurich.medicamentoPorPeriodoMaiorQueQuatroSemanas}
                    value={formik.values.clienteCobapEZurich.qualMedicamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.clienteCobapEZurich?.qualMedicamento &&
                      Boolean(formik.errors.clienteCobapEZurich?.qualMedicamento)
                    }
                    helperText={
                      formik.touched.clienteCobapEZurich?.qualMedicamento &&
                      formik.errors.clienteCobapEZurich?.qualMedicamento
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid item>
          <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '120%' }}>
            Endereço
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={spacing}>
            <Grid item xs={12} md={3}>
              <TextField
                id="enderecos.cep"
                name="enderecos.cep"
                label="CEP"
                size="small"
                fullWidth
                required
                inputProps={{ maxLength: 9 }}
                value={formik.values.enderecos.cep}
                onChange={async (event) => {
                  formik.setFieldValue('enderecos.cep', maskCep(event.target.value));
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.enderecos?.cep && Boolean(formik.errors.enderecos?.cep)}
                helperText={formik.touched.enderecos?.cep && formik.errors.enderecos?.cep}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="enderecos.logradouro"
                name="enderecos.logradouro"
                label="Endereço"
                size="small"
                fullWidth
                required
                value={formik.values.enderecos.logradouro}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.enderecos?.logradouro && Boolean(formik.errors.enderecos?.logradouro)}
                helperText={formik.touched.enderecos?.logradouro && formik.errors.enderecos?.logradouro}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="enderecos.numero"
                name="enderecos.numero"
                label="Número"
                size="small"
                fullWidth
                required
                inputProps={{ maxLength: 8 }}
                value={formik.values.enderecos.numero}
                onChange={(event) => {
                  formik.setFieldValue('enderecos.numero', event.target.value.replace(/\D/g, ''));
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.enderecos?.numero && Boolean(formik.errors.enderecos?.numero)}
                helperText={formik.touched.enderecos?.numero && formik.errors.enderecos?.numero}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="enderecos.complemento"
                name="enderecos.complemento"
                label="Complemento"
                size="small"
                fullWidth
                value={formik.values.enderecos.complemento}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.enderecos?.complemento && Boolean(formik.errors.enderecos?.complemento)}
                helperText={formik.touched.enderecos?.complemento && formik.errors.enderecos?.complemento}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={spacing}>
            <Grid item xs={12} md={4}>
              <TextField
                id="enderecos.bairro"
                name="enderecos.bairro"
                label="Bairro"
                size="small"
                fullWidth
                required
                value={formik.values.enderecos.bairro}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.enderecos?.bairro && Boolean(formik.errors.enderecos?.bairro)}
                helperText={formik.touched.enderecos?.bairro && formik.errors.enderecos?.bairro}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="enderecos.estado"
                name="enderecos.estado"
                label="Estado"
                size="small"
                fullWidth
                required
                inputProps={{ maxLength: 2 }}
                value={formik.values.enderecos.estado}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.enderecos?.estado && formik.errors.enderecos?.estado}
                select
              >
                {stateSelect.map(({ estado }) => {
                  return (
                    <MenuItem key={estado} value={estado}>
                      {estado}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="enderecos.cidade"
                name="enderecos.cidade"
                label="Cidade"
                size="small"
                fullWidth
                required
                value={formik.values.enderecos.cidade}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.enderecos?.cidade && Boolean(formik.errors.enderecos?.cidade)}
                helperText={formik.touched.enderecos?.cidade && formik.errors.enderecos?.cidade}
                select
              >
                {citiesSelect.map(({ cidade }, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={index} value={cidade}>
                      {cidade}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PersonalData;
