import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import useStyles from './styles';

interface Props {
  open: boolean;
}

const CustomBackDrop = ({ open = false }: Props) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CustomBackDrop;
