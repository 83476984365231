/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  useTheme,
  Container,
  Grid,
  Modal,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Radio,
  Typography,
  Paper,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useFormik, FormikProps } from 'formik';
import { newProposal, newProposalBody } from 'interfaces/Proposal';
import { ParamsRouteProduct } from 'interfaces/RouterParams';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/useAppStore';
import { newProposal as newProposalDispath } from 'store/ducks/Proposal';
import SwipeableViews from 'react-swipeable-views';
import { useSnackbar } from 'notistack';
import successContrateSvg from 'assets/images/successContract.svg';
import PersonalData from './PersonalData';
import validationSchema from './validationSchema';
import Payment from './Payment';
import Documents from './Documents';
import useStyles from './styles';
import ContractReview from './ContractReview';

export interface Product {
  isCobap: boolean;
  isVileve: boolean;
  isZurich: boolean;
}

const NewProposal = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { productid: productId } = useParams<ParamsRouteProduct>();
  const [activeStep, setActiveStep] = useState(0);
  const [product] = useState<Product>({
    isCobap: Number(productId as string) === 46,
    isVileve:
      Number(productId as string) === 1 ||
      Number(productId as string) === 2 ||
      Number(productId as string) === 3 ||
      Number(productId as string) === 28,
    isZurich: Number(productId as string) === 44 || Number(productId as string) === 45,
  });
  const [nextButton, setNextButton] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [successContract, setsuccessContract] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  let productValue = 0;
  switch (productId) {
    case '1':
      productValue = 39.9;
      break;
    case '2':
      productValue = 49.9;
      break;
    case '3':
      productValue = 69.9;
      break;
    default:
      productValue = 0;
      break;
  }

  useEffect(() => {
    const validProducts = ['1', '2', '3', '28', '44', '45', '46'];
    if (validProducts.indexOf(String(productId)) === -1) {
      navigate('/proposal/productselection');
    }
  }, [navigate, productId]);

  let productType = 5;
  if (product.isCobap) {
    productType = 1;
  } else if (product.isVileve) {
    productType = 0;
  } else if (product.isZurich) {
    productType = 2;
  }

  const handleOpen = () => {
    setsuccessContract(false);
    setOpenModal(true);
  };
  const handleClose = () => {
    return setOpenModal(false);
  };

  const { access_token } = useAppSelector((state) => {
    return state.signer.signin?.data[0];
  });

  const {
    proposal: { newProposal: newProposalResponse, type, status },
    groups: {
      groupsAndPermission: { data },
      // eslint-disable-next-line object-curly-newline
    },
  } = useAppSelector((state) => {
    return state;
  });

  const permissions = { canSendEmail: false, canSendSms: false, canSendWhatsapp: false };
  data.forEach(({ funcionalidade, type: tipo, visualizar }) => {
    if (tipo === 'FUNCTIONALITY' && funcionalidade === 'Send Email') {
      permissions.canSendEmail = visualizar;
    } else if (tipo === 'FUNCTIONALITY' && funcionalidade === 'Send SMS') {
      permissions.canSendSms = visualizar;
    } else if (tipo === 'FUNCTIONALITY' && funcionalidade === 'Send WhatsApp') {
      permissions.canSendWhatsapp = visualizar;
    }
  });

  const handleChangeIndex = (index: number) => {
    setActiveStep(index);
  };

  const handleNextButton = (isReadOnly: boolean) => {
    setNextButton(isReadOnly);
  };

  const handleNext = () => {
    if (activeStep !== 3) {
      setActiveStep((prevActiveStep) => {
        return prevActiveStep + 1;
      });
      setNextButton(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep - 1;
    });
    if (formik.isValid) {
      setNextButton(false);
    } else {
      setNextButton(true);
    }
  };

  const initialValues: newProposal = {
    token: access_token,
    cliente: {
      cpf: '',
      nomeCompleto: '',
      dataNascimento: null,
      email: '',
      telefoneFixo: '',
      telefoneCelular: '',
      codigoSexo: 0,
      codigoEstadoCivil: 0,
      codigoNacionalidade: 31,
      inssNumeroBeneficio: '',
      inssSalario: '',
      inssEspecie: 0,
      outrosDiaPagamento: 0,
      enviarTokenSms: false,
    },
    enderecos: {
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
    },
    debitoConta: {
      codigoBanco: '',
      agencia: '',
      conta: '',
      codigoOperacao: '',
      nomeFavorecido: '',
      cpfCnpjFavorecido: '',
    },
    documentos: [
      {
        frenteBase64: '',
        versoBase64: '',
        tipoDocumento: 1,
      },
      {
        frenteBase64: '',
        versoBase64: '',
        tipoDocumento: 1,
      },
      {
        frenteBase64: '',
        versoBase64: '',
        tipoDocumento: 0,
      },
    ],
    clienteCobapEZurich: {
      servidorPublico: false,
      publicamenteExposto: false,
      estaTrabalhando: false,
      afastadoNosUltimosDoisAnos: false,
      medicamentoPorPeriodoMaiorQueQuatroSemanas: false,
      qualMedicamento: '',
      idProfissao: 0,
    },
    cartaoCredito: {
      nomeCartao: '',
      numeroCartao: '',
      valorTotal: productValue,
      codigoProposta: null,
      cpf: '',
      cvv: null,
      dataValidade: null,
    },
    produto: { codigoProdutoItem: productId as string },
    documentosArquivo: [],
    formDataNewProposal: new FormData(),
    hasInss: product.isCobap,
    tipoEnvio: 'sms',
    tipoProduto: productType,
  };

  const formik: FormikProps<newProposal> = useFormik<newProposal>({
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      let newProposalBodyEdit: newProposalBody;
      if (product.isCobap) {
        newProposalBodyEdit = {
          ...values,
          cliente: {
            ...values.cliente,
            cpf: values.cliente.cpf.replace(/\D/g, ''),
            dataNascimento: values.cliente.dataNascimento ? values.cliente.dataNascimento : '',
            telefoneCelular: `55${values.cliente.telefoneCelular.replace(/\D/g, '')}`,
            telefoneFixo: values.cliente.telefoneFixo.replace(/\D/g, ''),
            outrosDiaPagamento: null,
            inssSalario:
              typeof values.cliente.inssSalario === 'string'
                ? Number(parseFloat(values.cliente.inssSalario.replace(/[R$ .]/g, '').replace(',', '.')).toFixed(2))
                : null,
            inssNumeroBeneficio:
              typeof values.cliente.inssNumeroBeneficio === 'string'
                ? values.cliente.inssNumeroBeneficio.replace('-', '')
                : null,
          },
          clienteCobapEZurich: {
            servidorPublico: false,
            publicamenteExposto: false,
            estaTrabalhando: false,
            afastadoNosUltimosDoisAnos: false,
            medicamentoPorPeriodoMaiorQueQuatroSemanas: false,
            qualMedicamento: '',
            idProfissao: '',
          },
          enderecos: [{ ...values.enderecos, numero: Number(values.enderecos.numero) }],
        };

        delete newProposalBodyEdit.debitoConta;
        delete newProposalBodyEdit.cartaoCredito;
        dispatch(
          newProposalDispath({ dataProposal: newProposalBodyEdit, formDataNewProposal: values.formDataNewProposal }),
        );
      } else if (product.isZurich) {
        newProposalBodyEdit = {
          ...values,
          cliente: {
            ...values.cliente,
            cpf: values.cliente.cpf.replace(/\D/g, ''),
            dataNascimento: values.cliente.dataNascimento ? values.cliente.dataNascimento : '',
            telefoneCelular: `55${values.cliente.telefoneCelular.replace(/\D/g, '')}`,
            telefoneFixo: values.cliente.telefoneFixo.replace(/\D/g, ''),
            inssNumeroBeneficio: null,
            inssSalario: null,
            inssEspecie: null,
          },
          debitoConta: {
            ...values.debitoConta,
            agencia: values.debitoConta.agencia.replace(/\D/g, ''),
            conta: values.debitoConta.conta.replace(/\D/g, ''),
            cpfCnpjFavorecido: values.debitoConta.cpfCnpjFavorecido.replace(/\D/g, ''),
          },
          enderecos: [{ ...values.enderecos, numero: Number(values.enderecos.numero) }],
          tipoProduto: 2,
        };
        delete newProposalBodyEdit.cartaoCredito;
        dispatch(
          newProposalDispath({ dataProposal: newProposalBodyEdit, formDataNewProposal: values.formDataNewProposal }),
        );
      } else if (product.isVileve) {
        newProposalBodyEdit = {
          ...values,
          cliente: {
            ...values.cliente,
            cpf: values.cliente.cpf.replace(/\D/g, ''),
            dataNascimento: values.cliente.dataNascimento ? values.cliente.dataNascimento : '',
            telefoneCelular: `55${values.cliente.telefoneCelular.replace(/\D/g, '')}`,
            telefoneFixo: values.cliente.telefoneFixo.replace(/\D/g, ''),
            inssNumeroBeneficio: null,
            inssSalario: null,
            inssEspecie: null,
          },
          debitoConta: {
            ...values.debitoConta,
            agencia: values.debitoConta.agencia.replace(/\D/g, ''),
            conta: values.debitoConta.conta.replace(/\D/g, ''),
            cpfCnpjFavorecido: values.debitoConta.cpfCnpjFavorecido.replace(/\D/g, ''),
          },
          cartaoCredito: {
            ...values.cartaoCredito,
            nomeCartao: values.cartaoCredito.nomeCartao.trim(),
            numeroCartao: values.cartaoCredito.numeroCartao.replace(/\D/g, '').trim(),
            cpf: values.cliente.cpf.replace(/\D/g, ''),
          },
          enderecos: [{ ...values.enderecos, numero: Number(values.enderecos.numero) }],
          tipoProduto: 0,
        };

        delete newProposalBodyEdit.clienteCobapEZurich;
        delete newProposalBodyEdit.cliente.codigoSexo;
        delete newProposalBodyEdit.cliente.codigoEstadoCivil;
        delete newProposalBodyEdit.cliente.codigoNacionalidade;

        values.debitoConta.codigoBanco === '900'
          ? delete newProposalBodyEdit.debitoConta
          : delete newProposalBodyEdit.cartaoCredito;
        dispatch(
          newProposalDispath({ dataProposal: newProposalBodyEdit, formDataNewProposal: values.formDataNewProposal }),
        );
      }
    },
  });

  useEffect(() => {
    if (formik.isValid) {
      setNextButton(false);
    } else {
      setNextButton(true);
    }
  }, [formik.isValid]);

  useEffect(() => {
    if (status === 'loading' && type === '/proposal/onboarding/pending') {
      setOpenBackdrop(true);
    } else if (status === 'completed' && type === '/proposal/onboarding/fulfilled') {
      setOpenBackdrop(false);
      setsuccessContract(true);
    } else if (status === 'failed' && type === '/proposal/onboarding/rejected') {
      setOpenBackdrop(false);
      enqueueSnackbar('Contrato não enviado, tente novamente!', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }

    // eslint-disable-next-line
  }, [newProposalResponse, status, type]);

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Container maxWidth="lg">
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Card variant="outlined">
            <CardContent>
              <Grid>
                <Modal
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="modal-history-proposal"
                  aria-describedby="modal-history-description"
                >
                  <form onSubmit={formik.handleSubmit}>
                    <Box className={classes.bodyContent}>
                      <Paper className={classes.paperContent}>
                        <Grid container direction="column">
                          <Grid item>
                            <FormControl component="fieldset" error={!formik.errors.tipoEnvio}>
                              <RadioGroup name="tipoEnvio" onChange={formik.handleChange}>
                                <FormLabel component="legend">
                                  <Typography color="primary" variant="button">
                                    Tipo de Envio
                                  </Typography>
                                </FormLabel>
                                {permissions.canSendEmail && (
                                  <FormControlLabel
                                    value="email"
                                    control={<Radio />}
                                    label="Email"
                                    className={classes.colorLabelRadioButton}
                                  />
                                )}
                                {permissions.canSendSms && (
                                  <FormControlLabel
                                    value="sms"
                                    control={<Radio />}
                                    color="primary"
                                    label="SMS"
                                    className={classes.colorLabelRadioButton}
                                  />
                                )}
                                {permissions.canSendWhatsapp && (
                                  <FormControlLabel
                                    value="whatsapp"
                                    control={<Radio />}
                                    label="WhatsApp"
                                    className={classes.colorLabelRadioButton}
                                  />
                                )}
                              </RadioGroup>
                              <FormHelperText error>{formik.errors.tipoEnvio}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <Grid container justifyContent="flex-end">
                              <Grid item>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                      type="submit"
                                      onClick={() => {
                                        setsuccessContract(false);
                                      }}
                                      variant="contained"
                                    >
                                      Enviar Contrato
                                    </Button>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </form>
                </Modal>
                {successContract && (
                  <Modal
                    open={openModal}
                    aria-labelledby="modal-history-proposal"
                    aria-describedby="modal-history-description"
                  >
                    <Box className={classes.bodyContent}>
                      <Paper className={classes.paperContent}>
                        <Grid container direction="column" alignItems="center">
                          <Grid item xs={12} md={2}>
                            <img src={successContrateSvg} />
                          </Grid>
                          <Typography color="primary">Contrato enviado com sucesso</Typography>
                        </Grid>
                        <Grid container direction="column" alignItems="center" margin="20px 0 10px 0">
                          <Grid item xs={12} md={6}>
                            <Button
                              onClick={() => {
                                navigate('/proposal/productselection', { replace: true });
                                setsuccessContract(false);
                              }}
                              variant="contained"
                            >
                              Nova proposta
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </Modal>
                )}
              </Grid>

              <Box>
                <Stepper activeStep={activeStep} orientation={isMobile ? 'vertical' : 'horizontal'}>
                  <Step>
                    <StepLabel>Informações Cadastrais</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Pagamento</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Documentos</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Revisão do Contrato</StepLabel>
                  </Step>
                </Stepper>

                <Box sx={{ mt: 2, mb: 1 }}>
                  <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleChangeIndex}
                    disabled
                  >
                    {activeStep === 0 && (
                      <div dir={theme.direction}>
                        <PersonalData formik={formik} activeNextButton={handleNextButton} />
                      </div>
                    )}
                    {activeStep === 1 && (
                      <div dir={theme.direction}>
                        <Payment formik={formik} activeNextButton={handleNextButton} />
                      </div>
                    )}
                    {activeStep === 2 && (
                      <div dir={theme.direction}>
                        <Documents formik={formik} activeNextButton={handleNextButton} />
                      </div>
                    )}
                    {activeStep === 3 && (
                      <div dir={theme.direction}>
                        <ContractReview formik={formik} />
                      </div>
                    )}
                  </SwipeableViews>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                    Voltar
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep !== 3 && (
                    <Button onClick={handleNext} disabled={nextButton}>
                      Próximo
                    </Button>
                  )}
                  {activeStep === 3 && <Button onClick={handleOpen}>Finalizar</Button>}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </Grid>
  );
};

export default NewProposal;
