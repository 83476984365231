import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const width = '231px';
const height = '160px';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      width,
      height,
    },
    audio: {
      width,
      height,
    },
    audioUpload: {
      maxHeight: height,
      maxWidth: width,
    },
    input: { display: 'none' },
    button: { margin: 10 },
    positionBtnUpload: {
      bottom: theme.spacing(10),
      right: theme.spacing(-21),
    },
    player: { width: '100%' },
    playerPosition: { marginTop: '-60px' },
  };
});

export default useStyles;
