import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiCardContent: { styleOverrides: { root: { border: '1px solid rgba(0, 88, 130, 0.10)' } } },
    MuiIconButton: { styleOverrides: { root: { color: '#005882' } } },
    MuiIcon: { styleOverrides: { colorPrimary: '#005882' } },
  },
  palette: {
    primary: { main: '#005882' },
    secondary: { main: '#C8DC50' },
    mode: 'light',
  },
});

export default theme;
