/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@mui/material';
import { History, ContactPage, ReadMore } from '@mui/icons-material';
import { useFormik } from 'formik';
import { proposal as IProposal } from 'interfaces/Proposal';
import CustomSpeedDial, { Iactions } from 'components/CustomSpeedDial';
import { maskDateTimeStamp, maskDate, maskCel, maskCpf, maskRealMoney } from 'utils/string/masks';
import CustomBackDrop from 'components/CustomBackDrop';
import TimelineProposal from 'components/TimelineProposal';
import FileListLinks from 'components/FileListLinks';
import { ParamsRoutes } from 'interfaces/RouterParams';
import { useParams } from 'react-router-dom';
import Label, { Icolor } from 'components/Label';
import { useAppSelector } from 'hooks/useAppStore';
import ResendContract from 'components/ResendContract';
import validationSchema from './validationSchema';
import useStyle from './styles';

interface Props {
  proposalParams?: IProposal['document'][0] | undefined;
  create?: boolean;
}

interface valuesInfo {
  label: string;
  value: unknown;
}

interface IProposalInfo {
  proposal: valuesInfo[];
  client: valuesInfo[];
  payment: valuesInfo[];
  signature: valuesInfo[];
  partner: valuesInfo[];
}

const Form = ({ proposalParams = undefined, create = true }: Props) => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [openModalResendContract, setOpenModalResendContract] = useState(false);
  const [proposal] = useState<Partial<IProposal['document'][0] | undefined>>(proposalParams);
  const [idProduct, setIdProduct] = useState(0);
  const [proposalInfo, setProposalInfo] = useState<IProposalInfo[]>();
  const [openBackDrop] = useState(false);
  const [isZurich, setIsZurich] = useState(false);
  const [permission, setPermission] = useState(false);
  const [actions, setActions] = useState<Iactions[]>([]);

  const {
    groups: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      groupsAndPermission: { document, data },
    },
    // eslint-disable-next-line object-curly-newline
  } = useAppSelector((state) => {
    return state;
  });

  useEffect(() => {
    const emailBlackList = ['karensantos.rodrigues36@gmail.com', 'fcristina968@gmail.com'];
    const email = proposal?.email ? proposal?.email.toLocaleLowerCase() : '';
    setIsZurich(
      !!document.find(({ descricao }) => {
        return descricao === 'Zurich';
      }),
    );

    setPermission(
      !!data.find(({ funcionalidade, visualizar }) => {
        if (funcionalidade === 'Reenvio de Contrato') {
          return visualizar;
        }
        return false;
      }),
    );

    setActions([
      {
        icon: History,
        name: 'Histórico',
        handleClick: () => {
          handleModal();
        },
      },
      {
        icon: ContactPage,
        name: 'Arquivos',
        handleClick: () => {
          handleModalFiles();
        },
      },
      {
        icon: ReadMore,
        name: 'Reenviar Contrato',
        handleClick: () => {
          handleModalResendContract();
        },
        hidden:
          (proposal?.Status_Proposta !== 'Pendente' &&
            proposal?.Motivo_Proposta !== 'PENDENTE ASSINATURA' &&
            emailBlackList.indexOf(email) === -1) ||
          !permission,
      },
    ]);
    const proposalInfoConstruction: IProposalInfo[] = [
      {
        proposal: [
          {
            label: 'Número da Proposta',
            value: formik.values.Id_da_Proposta,
          },
          {
            label: 'Produto',
            value: formik.values.Produto_Pai_Nome,
          },
          {
            label: 'Data de Criação',
            value: formik.values.Data_Criacao,
          },
          {
            label: 'Criação',
            value: maskDateTimeStamp(formik.values.Criacao || ''),
          },
          {
            label: 'Status Interno',
            value: formik.values.Status_da_Proposta,
          },
          {
            label: 'Status da Proposa',
            value: formik.values.Status_Proposta,
          },
          {
            label: 'Motivo da Proposta',
            value: formik.values.Motivo_Proposta,
          },
        ],
        client: [
          {
            label: 'Nome do Cliente',
            value: formik.values.Nome_do_Cliente,
          },
          {
            label: 'Código do Cliente',
            value: formik.values.Usuario_da_Proposta,
          },
          {
            label: 'Data de Nascimento',
            value: maskDate(formik.values.Data_Nascimento_Cliente || ''),
          },
          {
            label: 'Telefone Residêncial',
            value: maskCel(String(formik.values.Telefone_Residencial)),
          },
          {
            label: 'Telefone Celular',
            value: maskCel(String(formik.values.Telefone_Celular)),
          },
          {
            label: 'Email',
            value: formik.values.Email_Cliente,
          },
          {
            label: 'Rua',
            value: formik.values.Rua_do_Cliente,
          },
          {
            label: 'Número',
            value: formik.values.Numero_do_Cliente,
          },
          {
            label: 'Bairro',
            value: formik.values.Bairro_do_Cliente,
          },
          {
            label: 'Cidade',
            value: formik.values.Cidade_do_Venda,
          },
          {
            label: 'Estado',
            value: formik.values.Estado_do_Cliente,
          },
          {
            label: 'CEP',
            value: formik.values.Cep_do_Cliente,
          },
          {
            label: 'Dependente',
            value: formik.values.Dependente,
          },
          {
            label: 'Data de Nascimento Dependente',
            value: formik.values.depnascimento,
          },
          {
            label: 'CPF Dependente',
            value: formik.values.depcpf,
          },
          {
            label: 'Valor Dependente',
            value: formik.values.depvalor,
          },
        ],
        payment: [
          {
            label: 'Código do Banco',
            value: formik.values.Codigo_Banco,
          },
          {
            label: 'Agência',
            value: formik.values.Agencia,
          },
          {
            label: 'Conta',
            value: formik.values.Conta,
          },
          {
            label: 'Valor da Venda',
            value: formik.values.Valor_da_Venda,
          },
          {
            label: 'Nome do Cartão',
            value: formik.values.Nome_Cartao,
          },
          {
            label: 'Número do Cartão',
            value: formik.values.Numero_Cartao,
          },
          {
            label: 'Dia da Cobrança',
            value: formik.values.Dia_Cobranca,
          },
          {
            label: 'Número do Benefício',
            value: formik.values.Numero_Beneficio,
          },
          {
            label: 'Espécie',
            value: formik.values.especie,
          },
          {
            label: 'Salário do Cliente',
            value: formik.values.Salario_do_Cliente,
          },
        ],
        signature: [
          {
            label: 'Status Assinatura do Cliente',
            value: formik.values.Status_Assinatura,
          },
          {
            label: 'Status Assinatura Associaçao',
            value: formik.values.Status_Assinatura_Associacao,
          },
          {
            label: 'Associação',
            value: formik.values.Entidade_Associacao,
          },
          {
            label: 'Status Assinatura Federação',
            value: formik.values.Status_Assinatura_Federacao,
          },
          {
            label: 'Federação',
            value: formik.values.Entidade_Federacao,
          },
          {
            label: 'Status Assinatura Cobap',
            value: formik.values.Status_Assinatura_Cobap,
          },
          {
            label: 'Cobap',
            value: formik.values.Entidade_Cobap,
          },
        ],
        partner: [
          {
            label: 'Representante Comercial',
            value: formik.values.Nome_Empresa_RC,
          },
          {
            label: 'CPF Digitador',
            value: maskCpf(String(formik.values.Cpf_Digitador) || ''),
          },
          {
            label: 'Nome do Parceiro',
            value: formik.values.Nome_RC,
          },
          {
            label: 'Telefone do Parceiro',
            value: maskCel(formik.values.Telefone_RC || ''),
          },
        ],
      },
    ];

    if (proposal?.id_produto) {
      setIdProduct(proposal.id_produto);
    }

    setProposalInfo(proposalInfoConstruction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalParams, permission]);

  const { id } = useParams<ParamsRoutes>();
  let idCasting: number = 0;

  if (id) {
    idCasting = parseInt(id, 10);
  }

  const handleModal = () => {
    setOpen(!open);
  };

  const handleModalFiles = () => {
    setOpenFiles(!openFiles);
  };

  const handleModalResendContract = () => {
    setOpenModalResendContract(!openModalResendContract);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: proposal || {},
    validationSchema,
    onSubmit: async (values) => {},
  });

  const getColor = (value: string | undefined): Icolor => {
    let color: Icolor;
    switch (value) {
      case 'Cancelado':
        color = 'error';
        break;
      case 'Conferência Dados Bancários':
        color = 'info';
        break;
      case 'Em Análise':
        color = 'warning';
        break;
      case 'Em Cobrança':
        color = 'info';
        break;
      case 'Expirado':
        color = 'draft';
        break;
      case 'Pendente':
        color = 'warning';
        break;
      case 'Recusado':
        color = 'error';
        break;
      case 'Assinado':
        color = 'success';
        break;
      default:
        color = 'info';
        break;
    }
    return color;
  };

  const status = [
    'Cancelado',
    'Conferência Dados Bancários',
    'Em Análise',
    'Em Cobrança',
    'Expirado',
    'Pendente',
    'Recusado',
    'Assinado',
  ];

  const zurichPayment = ['Nome do Cartão', 'Número do Cartão', 'Numero do Benefício', 'Espécie', 'Salario Cliente'];
  const zurichSignature = [
    'Status Assinatura Associaçao',
    'Associação',
    'Status Assinatura Federação',
    'Federação',
    'Status Assinatura Cobap',
    'Cobap',
  ];

  return (
    <>
      <CustomBackDrop open={openBackDrop} />
      <TimelineProposal open={open} handleModal={handleModal} proposal={idCasting} />
      <FileListLinks open={openFiles} handleModalFiles={handleModalFiles} proposal={idCasting} />
      <ResendContract
        open={openModalResendContract}
        handleModal={handleModalResendContract}
        proposal={idCasting}
        idProduct={idProduct}
      />

      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column">
          <Grid item>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>Dados da Proposta</TableCell>
                  </TableRow>
                </TableHead>
                {
                  // eslint-disable-next-line operator-linebreak
                  proposalInfo &&
                    proposalInfo[0].proposal.map((proposalArray) => {
                      let color: Icolor | undefined;
                      if (String(proposalArray.value) === 'Cobap 2.3%' && String(proposalArray.label) === 'Produto') {
                        color = 'error';
                      } else if (
                        // eslint-disable-next-line operator-linebreak
                        String(proposalArray.value) === 'Vileve' &&
                        String(proposalArray.label) === 'Produto'
                      ) {
                        color = 'primary';
                      } else if (
                        ['Zurich', 'Zurich(DebC) - Plano AP 01', 'Zurich(DebC) - Plano AP 02'].indexOf(
                          String(proposalArray.value),
                          // eslint-disable-next-line operator-linebreak
                        ) !== -1 &&
                        String(proposalArray.label) === 'Produto'
                      ) {
                        color = 'info';
                      } else if (String(proposalArray.label) === 'Produto') {
                        color = 'draft';
                      }

                      if (status.indexOf(String(proposalArray.value)) !== -1) {
                        color = getColor(String(proposalArray.value));
                      }

                      return (
                        <TableBody key={proposalArray.label}>
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>{proposalArray.label}</TableCell>
                            <TableCell className={classes.tableCell}>
                              {color ? (
                                <Label color={color}>{String(proposalArray.value)}</Label>
                              ) : (
                                String(proposalArray.value)
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                }
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table aria-label="client table">
                <TableHead>
                  <TableRow>
                    <TableCell>{isZurich ? 'Associado' : 'Cliente / Associado'}</TableCell>
                  </TableRow>
                </TableHead>
                {
                  // eslint-disable-next-line operator-linebreak
                  proposalInfo &&
                    proposalInfo[0].client.map((proposalArray) => {
                      const isEmail = proposalArray.label;

                      return (
                        <TableBody key={proposalArray.label}>
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>{proposalArray.label}</TableCell>
                            <TableCell className={classes.tableCell}>
                              {isEmail === 'Email' ? (
                                <Label color="success">{String(proposalArray.value)}</Label>
                              ) : (
                                String(proposalArray.value)
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                }
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table aria-label="payment table">
                <TableHead>
                  <TableRow>
                    <TableCell>Pagamento e Cobrança</TableCell>
                  </TableRow>
                </TableHead>
                {
                  // eslint-disable-next-line operator-linebreak
                  proposalInfo &&
                    proposalInfo[0].payment.map((proposalArray) => {
                      if (isZurich && zurichPayment.indexOf(String(proposalArray.label)) === -1) {
                        return (
                          <TableBody key={proposalArray.label}>
                            <TableRow className={classes.tableRow}>
                              <TableCell className={classes.tableCell}>
                                {proposalArray.label === 'Valor da Venda' ? 'Preço do Produto' : proposalArray.label}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {proposalArray.label === 'Valor da Venda'
                                  ? maskRealMoney(String(proposalArray.value))
                                  : String(proposalArray.value)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      }
                      if (!isZurich) {
                        return (
                          <TableBody key={proposalArray.label}>
                            <TableRow className={classes.tableRow}>
                              <TableCell className={classes.tableCell}>{proposalArray.label}</TableCell>
                              <TableCell className={classes.tableCell}>
                                {proposalArray.label === 'Valor da Venda'
                                  ? maskRealMoney(String(proposalArray.value))
                                  : String(proposalArray.value)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      }

                      return <span />;
                    })
                }
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table aria-label="payment table">
                <TableHead>
                  <TableRow>
                    <TableCell>Assinatura</TableCell>
                  </TableRow>
                </TableHead>
                {
                  // eslint-disable-next-line operator-linebreak
                  proposalInfo &&
                    proposalInfo[0].signature.map((proposalArray) => {
                      let color: Icolor | undefined;

                      if (status.indexOf(String(proposalArray.value)) !== -1) {
                        color = getColor(String(proposalArray.value));
                      }

                      if (isZurich && zurichSignature.indexOf(String(proposalArray.label)) === -1) {
                        return (
                          <TableBody key={proposalArray.label}>
                            <TableRow className={classes.tableRow}>
                              <TableCell className={classes.tableCell}>{proposalArray.label}</TableCell>
                              <TableCell className={classes.tableCell}>
                                {color ? (
                                  <Label color={color}>{String(proposalArray.value)}</Label>
                                ) : (
                                  String(proposalArray.value)
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      }
                      if (!isZurich) {
                        return (
                          <TableBody key={proposalArray.label}>
                            <TableRow className={classes.tableRow}>
                              <TableCell className={classes.tableCell}>{proposalArray.label}</TableCell>
                              <TableCell className={classes.tableCell}>
                                {color ? (
                                  <Label color={color}>{String(proposalArray.value)}</Label>
                                ) : (
                                  String(proposalArray.value)
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      }

                      return false;
                    })
                }
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <br />
            <TableContainer component={Paper}>
              <Table aria-label="partner table">
                <TableHead>
                  <TableRow>
                    <TableCell>Parceiro</TableCell>
                  </TableRow>
                </TableHead>
                {
                  // eslint-disable-next-line operator-linebreak
                  proposalInfo &&
                    proposalInfo[0].partner.map((proposalArray) => {
                      return (
                        <TableBody key={proposalArray.label}>
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>{proposalArray.label}</TableCell>
                            <TableCell className={classes.tableCell}>{String(proposalArray.value)}</TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                }
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </form>
      <CustomSpeedDial actions={actions} />
      {/* {showResend ? <CustomSpeedDial actions={actions} /> : <CustomSpeedDial actions={actionsWithoutSending} />} */}
    </>
  );
};

Form.defaultProps = { create: true, proposalParams: undefined };

export default Form;
