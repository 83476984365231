import React, { useEffect, useState } from 'react';
import { Box, Chip, Typography, Modal, Paper, SvgIconTypeMap } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';
import { ErrorOutlineOutlined, CheckCircleOutlineOutlined, HourglassEmptyOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'hooks/useAppStore';
import Label, { Icolor } from 'components/Label';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { proposalHistory } from 'store/ducks/Proposal';
import PaperBackDrop from 'components/PaperBackDrop';
import useStyles from './styles';

interface Props {
  open: boolean;
  handleModal: () => void;
  proposal: number | undefined;
}

const TimelineProposal = ({ open, handleModal, proposal }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isLoadingTimeLine, setIsLoadingTimeLine] = useState(true);

  const {
    proposalHistory: proposalHistoryRedux,
    status,
    type,
  } = useAppSelector((state) => {
    return state.proposal;
  });

  useEffect(() => {
    if (status === 'loading' && type === '/proposal/adm/status/id/pending') {
      setIsLoadingTimeLine(true);
    } else if (status === 'completed' && type === '/proposal/adm/status/id/fulfilled') {
      setIsLoadingTimeLine(false);
    } else if (status === 'failed' && type === '/proposal/adm/status/id/rejected') {
      setIsLoadingTimeLine(false);
    }
  }, [status, type]);

  useEffect(() => {
    if (proposal && open) {
      dispatch(proposalHistory(proposal));
    }
  }, [open, dispatch, proposal]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-history-proposal"
        aria-describedby="modal-history-description"
      >
        {
          <Box className={classes.bodyContent}>
            {isLoadingTimeLine ? (
              <Box sx={{ minWidth: '375px' }}>
                <PaperBackDrop />
              </Box>
            ) : (
              <Paper className={classes.paperContent}>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <Chip
                    label={
                      // eslint-disable-next-line
                      <Typography variant="h5" sx={{ color: '#c62d1d' }}>
                        {/* eslint-disable-next-line */}
                        Proposta: {proposal}
                      </Typography>
                    }
                  />
                </Box>
                <Timeline
                  position="alternate"
                  style={{ minWidth: '375px' }}
                >
                  {proposalHistoryRedux?.map(({ Data: data, tipolog, descricao }) => {
                    let color: Icolor;
                    let Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
                      muiName: string;
                    };

                    if (tipolog === 'Sucesso') {
                      color = 'success';
                      Icon = CheckCircleOutlineOutlined;
                    } else if (tipolog === 'Erro') {
                      color = 'error';
                      Icon = ErrorOutlineOutlined;
                    } else {
                      color = 'warning';
                      Icon = HourglassEmptyOutlined;
                    }
                    return (
                      <TimelineItem key={`${data}`}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          align="right"
                          variant="body2"
                          color="text.secondary"
                        >
                          {data}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color={color}>
                            <Icon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Typography variant="h6" component="span">
                            <Label color={color}>{tipolog}</Label>
                          </Typography>
                          <Typography>{descricao}</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              </Paper>
            )}
          </Box>
        }
      </Modal>
    </div>
  );
};

export default TimelineProposal;
