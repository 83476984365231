/* eslint-disable react/react-in-jsx-scope  */
import { MUIDataTableColumn } from 'mui-datatables';
import { proposal } from 'interfaces/Proposal';
import ArrowNavigate from 'components/ArrowNavigate';
import { maskTellPhone } from 'utils/string/masks';
import Label, { Icolor } from 'components/Label';
import HistoryButton from 'components/HistoryButton';

const getColumns = (proposalParams?: proposal): MUIDataTableColumn[] => {
  const colunsData: MUIDataTableColumn[] = [
    {
      name: 'Id_da_Proposta',
      label: 'Proposta',
      options: {
        filter: true,
        sort: true,
        sortDirection: 'desc',
      },
    },
    {
      name: 'Cpf_Cliente',
      label: 'CPF',
    },
    {
      name: 'Nome_do_Cliente',
      label: 'Nome Cliente',
    },
    {
      name: 'Telefone_Celular',
      label: 'Celular',
      options: {
        customBodyRender: (value) => {
          return maskTellPhone(value);
        },
      },
    },
    {
      name: 'Data_Criacao',
      label: 'Data Contratação',
    },
    {
      name: 'Status_Proposta',
      label: 'Status',
      options: {
        customBodyRender: (value) => {
          let color: Icolor;

          switch (value) {
            case 'Cancelado':
              color = 'error';
              break;
            case 'Conferência Dados Bancários':
              color = 'info';
              break;
            case 'Em Análise':
              color = 'warning';
              break;
            case 'Em Cobrança':
              color = 'info';
              break;
            case 'Expirado':
              color = 'draft';
              break;
            case 'Pendente':
              color = 'warning';
              break;
            case 'Recusado':
              color = 'error';
              break;
            default:
              color = 'info';
              break;
          }
          return <Label color={color}>{value}</Label>;
        },
      },
    },
    {
      name: 'Motivo_Proposta',
      label: 'Motivo',
    },
    {
      name: 'Ações',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataindex) => {
          const dataRow = proposalParams && proposalParams.document[dataindex].Id_da_Proposta;
          return (
            <div>
              <HistoryButton proposal={dataRow} />
              <ArrowNavigate linkTo={`/proposal/${dataRow}`} label="Ir para propostas" />
            </div>
          );
        },
      },
    },
  ];
  return colunsData;
};

export default getColumns;
