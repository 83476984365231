/* eslint-disable object-curly-newline */
import * as Yup from 'yup';
import { resendContract } from 'interfaces/Proposal';

const validationSchema: Yup.SchemaOf<Partial<resendContract>> = Yup.object({
  shippingType: Yup.string().required('É necessário selecionar um tipo de envio').trim(),
  idProposal: Yup.number(),
  idProduct: Yup.number(),
});

export default validationSchema;
