/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Typography, CardActionArea, Grid, Button } from '@mui/material';
import useStyle from '../styles';

const CobapPackages = () => {
  const classes = useStyle();

  return (
    <Grid container justifyContent="space-around" spacing={3} direction="row">
      <Grid item>
        <Button component={RouterLink} to="/proposal/new/46">
          <Card className={classes.cardLayout}>
            <CardActionArea>
              <CardContent>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      Cobap Individual
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      <b>2,3% do Salário Benefício</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography variant="body2" color="text.secondary">
                    <Grid>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'start', color: '#005882' }}>Benefício</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Assistência Saúde <br />
                              <i>Sem limite de utilização</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>
                                Titular, Cônjuge, <br /> Filhos e Netos.
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Assistência farmácia <br />
                              <i>Sem limite de utilização</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>
                                Titular, Cônjuge, <br /> Filhos e Netos.
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Funeral <br />
                              <i>Sem limite de utilização</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>
                                Titular, Cônjuge e <br /> Filhos, até 21 anos
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Auxílio Funeral <br />
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Cesta Básica - <i>R$720,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Residencial - <i>Conforme Manual</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>
                              Clube Vida Longa - <i>Sem Limite</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>
                                Titular, Cônjuge e <br /> Filhos, até 21 anos
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item className={classes.contractButton}>
                  <Button variant="outlined">
                    <Typography color="primary">Contratar</Typography>
                  </Button>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Button>
      </Grid>
    </Grid>
  );
};
export default CobapPackages;
