/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { signaturesStatus, clientReportsResponse, clientReportsId, FilterDate } from 'interfaces/SignaturesStatus';
import { reduxTemplate } from 'interfaces/redux';
import { clientReports, getSignaturesStatus } from './service';

export const signaturesStatusList = createAsyncThunk<signaturesStatus, FilterDate, { rejectValue: reduxTemplate }>(
  'signatures/entities/list',
  async (querySignatureDate, { rejectWithValue }) => {
    try {
      const data = await getSignaturesStatus(querySignatureDate);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const clientReportsCSV = createAsyncThunk<
  clientReportsResponse,
  clientReportsId,
  { rejectValue: reduxTemplate }
>('proposal/entities/proposalpendings', async (idEntidade, { rejectWithValue }) => {
  try {
    const data = await clientReports(idEntidade);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

interface ISignatures extends reduxTemplate {
  signaturesStatusList: signaturesStatus;
  clientReportsCSV: clientReportsResponse;
}

const initialState: ISignatures = {
  status: 'idle',
  message: '',
  type: '',
  signaturesStatusList: {
    status: '',
    message: '',
    document: [],
  },
  clientReportsCSV: {
    status: '',
    message: '',
    document: [],
  },
};

export const SignaturesStatusReducer = createSlice({
  name: 'SignaturesStatus',
  initialState,
  reducers: {
    clearSignaturesStatus: (state) => {
      state.status = 'idle';
      state.message = '';
      state.type = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signaturesStatusList.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(signaturesStatusList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.type = action.type;
        state.message = action.payload.message;
        state.signaturesStatusList = action.payload;
      })
      .addCase(signaturesStatusList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(clientReportsCSV.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(clientReportsCSV.fulfilled, (state, action) => {
        state.status = 'completed';
        state.type = action.type;
        state.message = action.payload.message;
        state.clientReportsCSV = action.payload;
      })
      .addCase(clientReportsCSV.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearSignaturesStatus } = SignaturesStatusReducer.actions;
export default SignaturesStatusReducer.reducer;
