/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import api from 'services';
import axios from 'axios';
import store from 'store';

import {
  proposal,
  statusProposal,
  filesProposal,
  responseResendContract,
  resendContract,
  newProposalResponse,
  newProposalParams,
  responseValidation,
  responseValidationCelular,
} from 'interfaces/Proposal';

import { vileveAssitenciaValidadorCpf } from 'interfaces/externalApi';

export const getProposalStatusList = async (searchAdvanced?: string): Promise<proposal> => {
  const response = await api.get<proposal>(`/proposal/list?searchParams=${searchAdvanced}`);
  return response.data;
};

export const getProposalHistory = async (id: number): Promise<statusProposal> => {
  const response = await api.get<statusProposal>(`/proposal/status/${id}`);
  return response.data;
};

export const getProposalFiles = async (id: number): Promise<filesProposal> => {
  const response = await api.get<filesProposal>(`/proposal/files/${id}`);
  return response.data;
};

export const postProposalResendContract = async (
  resendContractParams: resendContract,
): Promise<responseResendContract> => {
  const response = await api.post<responseResendContract>('/proposal/resendContract', resendContractParams);
  return response.data;
};

export const postnewProposal = async ({
  dataProposal,
  formDataNewProposal,
}: newProposalParams): Promise<newProposalResponse> => {
  const newProposalBodyApiEdit = dataProposal;
  delete newProposalBodyApiEdit.documentosArquivo;
  delete newProposalBodyApiEdit.documentos;
  delete newProposalBodyApiEdit.formDataNewProposal;
  delete newProposalBodyApiEdit.hasInss;
  formDataNewProposal.delete('info');
  formDataNewProposal.append('info', JSON.stringify(newProposalBodyApiEdit));
  const response = await api.post<newProposalResponse>('/proposal/onboarding', formDataNewProposal, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const getValidateCpf = async (cpf: string): Promise<responseValidation> => {
  const response = await api.get<responseValidation>(`proposal/cpfvalidate/${cpf}`);
  return response.data;
};

export const getValidateCelular = async (celular: string): Promise<responseValidationCelular> => {
  const response = await api.get<responseValidationCelular>(`proposal/celularvalidate/${celular}`);
  return response.data;
};

export const getValidadorCpf = async (cpf: string): Promise<vileveAssitenciaValidadorCpf> => {
  const { access_token: acessToken } = store.getState().signer.signin.data[0];

  await axios.get<string[] | undefined>(`${process.env.REACT_APP_VILEVE_ASSISTENCIA}/clientes/cpf-cnpj/validar`, {
    headers: {
      cpfCnpj: cpf,
      Authorization: `bearer ${acessToken}`,
    },
  });
  return { isValidCpf: true };
};
