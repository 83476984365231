/* eslint-disable object-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { TextField, Grid, Typography, MenuItem } from '@mui/material';
// import { DatePicker } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { FormikProps } from 'formik';
import { newProposal } from 'interfaces/Proposal';
import { ParamsRouteProduct } from 'interfaces/RouterParams';
import { maskCpf, maskRealMoney, maskCardNumber, maskAgence, maskAccount } from 'utils/string/masks';
import { useParams } from 'react-router-dom';
import { vileveAssistenciaOperacoesBancarias, vileveAssistenciaProductById } from 'interfaces/externalApi';
import { getOperacoesBancarias, getProductsById } from 'services/external/vileveAssistencia';
import { billingDate, inssEspecieOptions } from 'utils/data/payment';

interface Props {
  formik: FormikProps<newProposal>;
  activeNextButton: (active: boolean) => void;
}

interface Product {
  isCobap: boolean;
  isVileve: boolean;
  isZurich: boolean;
}

const spacing = 2;

const Payment = ({ formik, activeNextButton }: Props) => {
  const { productid: productId } = useParams<ParamsRouteProduct>();
  const [product] = useState<Product>({
    isCobap: Number(productId as string) === 46,
    isVileve:
      Number(productId as string) === 1 ||
      Number(productId as string) === 2 ||
      Number(productId as string) === 3 ||
      Number(productId as string) === 28,
    isZurich: Number(productId as string) === 44 || Number(productId as string) === 45,
  });
  const [typePayment, setTypePayment] = useState<vileveAssistenciaProductById>();
  const [operacoeBancarias, setOperacoeBancarias] = useState<vileveAssistenciaOperacoesBancarias[]>();

  useEffect(() => {
    const getData = async () => {
      if (product.isCobap) {
        setTypePayment(await getProductsById('Cobap'));
      } else if (product.isVileve) {
        setTypePayment(await getProductsById('VILEVE'));
      } else if (product.isZurich) {
        setTypePayment(await getProductsById('Zurich'));
      }
      setOperacoeBancarias(await getOperacoesBancarias());
    };
    getData();
  }, []);

  useEffect(() => {
    if (product.isCobap) {
      formik.setFieldValue('cliente.inssNumeroBeneficio', '');
      // eslint-disable-next-line no-octal
      formik.setFieldValue('cliente.outrosDiaPagamento', 10);
      formik.setFieldValue('debitoConta.agencia', '0000');
      formik.setFieldValue('debitoConta.codigoBanco', '0');
      formik.setFieldValue('debitoConta.codigoOperacao', '0');
      formik.setFieldValue('debitoConta.conta', '0000');
      formik.setFieldValue('debitoConta.cpfCnpjFavorecido', '0');
      formik.setFieldValue('debitoConta.nomeFavorecido', '0');
      formik.setFieldTouched('cliente.inssNumeroBeneficio', true);
    } else if (product.isVileve) {
      formik.setFieldValue('cliente.codigoEstadoCivil', 55);
      formik.setFieldValue('cliente.codigoNacionalidade', 113);
      formik.setFieldValue('cliente.codigoSexo', 113);
      formik.setFieldValue('cliente.outrosDiaPagamento', 0);
      formik.setFieldValue('clienteCobapEZurich.idProfissao', 11);
      formik.setFieldTouched('cliente.outrosDiaPagamento', false);
    } else if (product.isZurich) {
      formik.setFieldValue('cliente.outrosDiaPagamento', 0);
      formik.setFieldTouched('cliente.outrosDiaPagamento', false);
    }
  }, []);

  useEffect(() => {
    if (formik.values.debitoConta.codigoBanco === '900') {
      formik.setFieldValue('cartaoCredito.dataValidade', null);
      formik.setFieldValue('cartaoCredito.numeroCartao', null);
      formik.setFieldValue('cartaoCredito.nomeCartao', null);
    } else {
      formik.setFieldValue('cartaoCredito.dataValidade', new Date());
      formik.setFieldValue('cartaoCredito.numeroCartao', '0000-0000-0000-0000');
      formik.setFieldValue('cartaoCredito.nomeCartao', ' ');
      formik.setFieldValue('cliente.outrosDiaPagamento', 0);
      formik.setFieldTouched('cliente.outrosDiaPagamento', false);
      formik.setFieldTouched('cartaoCredito.dataValidade', false);
      formik.setFieldTouched('cartaoCredito.numeroCartao', false);
      formik.setFieldTouched('cartaoCredito.nomeCartao', false);
      if (product.isCobap) {
        formik.setFieldValue('cliente.outrosDiaPagamento', 10);
      }
    }
  }, [formik.values.debitoConta.codigoBanco]);

  useEffect(() => {
    formik.setFieldValue('debitoConta.cpfCnpjFavorecido', maskCpf(formik.values.cliente.cpf));
    formik.setFieldValue('debitoConta.nomeFavorecido', formik.values.cliente.nomeCompleto);
  }, [formik.values.cliente.cpf, formik.values.cliente.nomeCompleto]);

  useEffect(() => {
    if (!formik.errors?.debitoConta && !formik.errors.cliente && !formik.errors.cartaoCredito) {
      activeNextButton(false);
    } else {
      activeNextButton(true);
    }
  }, [formik.errors]);

  return (
    <Grid container direction="column" spacing={spacing}>
      <Grid item xs={5}>
        <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '120%' }}>
          Informações do Pagamento
        </Typography>
      </Grid>
      {/*
        ----------------> NÃO APAGAR <----------------
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormControlLabel
              // prettier-ignore
              control={
                    (
                      <Switch
                        id="hasInss"
                        name="hasInss"
                        value={formik.values.hasInss}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )
                  }
              label="Possui INSS?"
            />
            <FormHelperText error={formik.touched.hasInss && Boolean(formik.errors.hasInss)}>
              {formik.touched.hasInss && formik.errors.hasInss}
            </FormHelperText>
          </FormGroup>
        </Grid> */}
      {(product.isCobap || formik.values.hasInss) && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={spacing}>
              {(product.isVileve || product.isCobap) && (
                <Grid item xs={12} md={8} lg={8}>
                  <TextField
                    id="cliente.inssNumeroBeneficio"
                    name="cliente.inssNumeroBeneficio"
                    label="Número do Benefício"
                    size="small"
                    fullWidth
                    required={formik.values.hasInss}
                    value={formik.values.cliente.inssNumeroBeneficio}
                    onChange={(event) => {
                      formik.setFieldValue('cliente.inssNumeroBeneficio', event.currentTarget.value.replace(/\D/g, ''));
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.cliente?.inssNumeroBeneficio && Boolean(formik.errors.cliente?.inssNumeroBeneficio)
                    }
                    helperText={
                      formik.touched.cliente?.inssNumeroBeneficio && formik.errors.cliente?.inssNumeroBeneficio
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={spacing}>
              {(product.isVileve || product.isCobap) && (
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    id="cliente.inssEspecie"
                    name="cliente.inssEspecie"
                    label="Espécie"
                    size="small"
                    fullWidth
                    required={product.isCobap || product.isVileve}
                    value={formik.values.cliente.inssEspecie}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cliente?.inssEspecie && Boolean(formik.errors.cliente?.inssEspecie)}
                    helperText={formik.touched.cliente?.inssEspecie && formik.errors.cliente?.inssEspecie}
                    select
                  >
                    {inssEspecieOptions.map(({ codigoInssEspecie }) => {
                      return (
                        <MenuItem key={codigoInssEspecie} value={codigoInssEspecie}>
                          {codigoInssEspecie}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              )}
              {(product.isVileve || product.isCobap) && (
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    id="cliente.inssSalario"
                    name="cliente.inssSalario"
                    label="Salário Bruto"
                    size="small"
                    fullWidth
                    required={product.isCobap || product.isVileve}
                    value={formik.values.cliente.inssSalario}
                    onChange={(event) => {
                      formik.setFieldValue('cliente.inssSalario', maskRealMoney(event.target.value));
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cliente?.inssSalario && Boolean(formik.errors.cliente?.inssSalario)}
                    helperText={formik.touched.cliente?.inssSalario && formik.errors.cliente?.inssSalario}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}

      {(product.isVileve || product.isZurich) && (
        <Grid item xs={12}>
          <Grid container spacing={spacing}>
            <Grid item xs={12} md={4}>
              <TextField
                id="debitoConta.nomeFavorecido"
                name="debitoConta.nomeFavorecido"
                label="Nome do Favorecido"
                size="small"
                fullWidth
                required
                inputProps={{ maxLength: 50 }}
                value={formik.values.debitoConta.nomeFavorecido}
                onChange={(event) => {
                  formik.setFieldValue(
                    'debitoConta.nomeFavorecido',
                    event.target.value.replace(/[^A-Z a-zzáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/g, ''),
                  );
                }}
                onBlur={(event) => {
                  const nomeCompleto = formik.values.cliente.nomeCompleto.split(' ')[0];
                  const nomeFavorecido = formik.values.debitoConta.nomeFavorecido.split(' ')[0];
                  if (nomeCompleto !== nomeFavorecido) {
                    formik.setFieldValue('debitoConta.nomeFavorecido', formik.values.debitoConta.nomeFavorecido);
                  }

                  formik.handleBlur(event);
                }}
                error={formik.touched.debitoConta?.nomeFavorecido && Boolean(formik.errors.debitoConta?.nomeFavorecido)}
                helperText={formik.touched.debitoConta?.nomeFavorecido && formik.errors.debitoConta?.nomeFavorecido}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="debitoConta.cpfCnpjFavorecido"
                name="debitoConta.cpfCnpjFavorecido"
                label="CPF"
                size="small"
                fullWidth
                required
                disabled
                inputProps={{ maxLength: 14 }}
                value={formik.values.debitoConta.cpfCnpjFavorecido}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.debitoConta?.cpfCnpjFavorecido && Boolean(formik.errors.debitoConta?.cpfCnpjFavorecido)
                }
                helperText={
                  formik.touched.debitoConta?.cpfCnpjFavorecido && formik.errors.debitoConta?.cpfCnpjFavorecido
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {(product.isVileve || product.isZurich) && (
        <>
          {!formik.values.hasInss && (
            <Grid item>
              <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '120%' }}>
                Dados de Pagamento
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={spacing}>
              {!formik.values.hasInss && (
                <Grid item xs={12} md={8}>
                  <TextField
                    id="debitoConta.codigoBanco"
                    name="debitoConta.codigoBanco"
                    label="Tipo de Pagamento"
                    size="small"
                    fullWidth
                    required={!formik.values.hasInss}
                    value={formik.values.debitoConta.codigoBanco}
                    onChange={(event) => {
                      formik.handleChange(event);
                      if (event.target.value === '900') {
                        formik.setFieldValue('debitoConta.agencia', '0000');
                        formik.setFieldValue('debitoConta.conta', '0000');
                      } else {
                        formik.setFieldValue('debitoConta.agencia', '');
                        formik.setFieldValue('debitoConta.conta', '');
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.debitoConta?.codigoBanco && Boolean(formik.errors.debitoConta?.codigoBanco)}
                    helperText={formik.touched.debitoConta?.codigoBanco && formik.errors.debitoConta?.codigoBanco}
                    select
                  >
                    {typePayment?.meiosPagamentos.map(({ codigoIdBanco, nome }) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <MenuItem key={codigoIdBanco} value={codigoIdBanco}>
                          {nome}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              )}
            </Grid>
          </Grid>
          {formik.values.debitoConta.codigoBanco === '900' && (
            <>
              <Grid item xs={12}>
                <Grid container spacing={spacing}>
                  {!formik.values.hasInss && (
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="cartaoCredito.nomeCartao"
                        name="cartaoCredito.nomeCartao"
                        label="Nome Impresso no Cartão"
                        size="small"
                        fullWidth
                        required
                        value={formik.values.cartaoCredito.nomeCartao}
                        onChange={(event) => {
                          formik.setFieldValue('cartaoCredito.nomeCartao', event.target.value.replace(/[0-9]/g, ''));
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.cartaoCredito?.nomeCartao && Boolean(formik.errors.cartaoCredito?.nomeCartao)
                        }
                        helperText={formik.touched.cartaoCredito?.nomeCartao && formik.errors.cartaoCredito?.nomeCartao}
                      />
                    </Grid>
                  )}
                  {!formik.values.hasInss && (
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="cartaoCredito.numeroCartao"
                        name="cartaoCredito.numeroCartao"
                        label="Número do Cartão"
                        size="small"
                        fullWidth
                        required
                        value={formik.values.cartaoCredito.numeroCartao}
                        inputProps={{ maxLength: 19 }}
                        onChange={(event) => {
                          formik.setFieldValue('cartaoCredito.numeroCartao', maskCardNumber(event.target.value));
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.cartaoCredito?.numeroCartao &&
                          Boolean(formik.errors.cartaoCredito?.numeroCartao)
                        }
                        helperText={
                          formik.touched.cartaoCredito?.numeroCartao && formik.errors.cartaoCredito?.numeroCartao
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={spacing}>
                  {!formik.values.hasInss && (
                    <Grid item xs={12} md={4}>
                      <DatePicker
                        label="Data de Validade"
                        inputFormat="MM/yyyy"
                        value={formik.values.cartaoCredito.dataValidade}
                        onChange={(event) => {
                          formik.setFieldValue('cartaoCredito.dataValidade', event);
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              id="personalData"
                              name="cartaoCredito.dataValidade"
                              size="small"
                              variant="outlined"
                              fullWidth
                              required
                              // eslint-disable-next-line
                              {...params}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.cartaoCredito?.dataValidade &&
                                Boolean(formik.errors.cartaoCredito?.dataValidade)
                              }
                              helperText={
                                formik.touched.cartaoCredito?.dataValidade && formik.errors?.cartaoCredito?.dataValidade
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="cliente.outrosDiaPagamento"
                      name="cliente.outrosDiaPagamento"
                      label="Dia da Cobrança"
                      size="small"
                      fullWidth
                      required={product.isZurich || product.isVileve}
                      value={formik.values.cliente.outrosDiaPagamento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.cliente?.outrosDiaPagamento && Boolean(formik.errors.cliente?.outrosDiaPagamento)
                      }
                      helperText={
                        formik.touched.cliente?.outrosDiaPagamento && formik.errors.cliente?.outrosDiaPagamento
                      }
                      select
                    >
                      {billingDate.map((day) => {
                        return (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {formik.values.debitoConta.codigoBanco !== '900' && (
            <Grid item xs={12}>
              <Grid container spacing={spacing}>
                {!formik.values.hasInss && (
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="debitoConta.agencia"
                      name="debitoConta.agencia"
                      label="Agência"
                      size="small"
                      fullWidth
                      required
                      inputProps={{ maxLength: 5 }}
                      value={maskAgence(formik.values.debitoConta.agencia)}
                      onChange={(event) => {
                        formik.setFieldValue('debitoConta.agencia', event.target.value.replace(/\D/g, ''));
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.debitoConta?.agencia && Boolean(formik.errors.debitoConta?.agencia)}
                      helperText={formik.touched.debitoConta?.agencia && formik.errors.debitoConta?.agencia}
                    />
                  </Grid>
                )}
                {!formik.values.hasInss && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="debitoConta.conta"
                      name="debitoConta.conta"
                      label="Conta"
                      size="small"
                      fullWidth
                      required
                      inputProps={{ maxLength: 11 }}
                      value={formik.values.debitoConta.conta}
                      onChange={(event) => {
                        formik.setFieldValue('debitoConta.conta', maskAccount(event.target.value));
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.debitoConta?.conta && Boolean(formik.errors.debitoConta?.conta)}
                      helperText={formik.touched.debitoConta?.conta && formik.errors.debitoConta?.conta}
                    />
                  </Grid>
                )}
                {!formik.values.hasInss && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="cliente.outrosDiaPagamento"
                      name="cliente.outrosDiaPagamento"
                      label="Dia da Cobrança"
                      size="small"
                      fullWidth
                      required={product.isCobap || product.isZurich}
                      value={formik.values.cliente.outrosDiaPagamento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.cliente?.outrosDiaPagamento && Boolean(formik.errors.cliente?.outrosDiaPagamento)
                      }
                      helperText={
                        formik.touched.cliente?.outrosDiaPagamento && formik.errors.cliente?.outrosDiaPagamento
                      }
                      select
                    >
                      {billingDate.map((day) => {
                        return (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {formik.values.debitoConta.codigoBanco === '104' && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="debitoConta.codigoOperacao"
                    name="debitoConta.codigoOperacao"
                    label="Operações Bancárias"
                    size="small"
                    fullWidth
                    required={formik.values.debitoConta.codigoBanco === '104'}
                    value={formik.values.debitoConta.codigoOperacao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.debitoConta?.codigoOperacao && Boolean(formik.errors.debitoConta?.codigoOperacao)
                    }
                    helperText={formik.touched.debitoConta?.codigoOperacao && formik.errors.debitoConta?.codigoOperacao}
                    select
                  >
                    {operacoeBancarias?.map(({ codigoOperacao, nome }) => {
                      return (
                        <MenuItem key={codigoOperacao} value={codigoOperacao}>
                          {nome}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default Payment;
