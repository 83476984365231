/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { AudioFile } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { toBase64 } from 'utils/string/toBase64';
import { isValidateMaxSizeFile } from 'utils/files';

import noAudio from 'assets/images/no-audio.png';
import successPng from 'assets/images/success-audio.png';
import { useSnackbar } from 'notistack';
import useStyles from './styles';

interface Props {
  name: string;
  sizeLimitMB: number;
  getBase64?: (base64: string, file: File) => void;
  base64?: string;
}

const Audio = ({ name, getBase64, base64 = '', sizeLimitMB }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [base64Content, setBase64Content] = useState<string>(base64);
  const [dataAudio, setDataAudio] = useState<string>();
  const handleBase64 = (urlBase64: string, file: File) => {
    if (getBase64) {
      getBase64(urlBase64, file);
    }
  };
  const handleUploadClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget) {
      if (event.currentTarget.files) {
        const file = event.currentTarget.files[0];
        const urlBase64 = await toBase64(file);
        if (!isValidateMaxSizeFile(file, sizeLimitMB)) {
          enqueueSnackbar(`O limite para esse campo é: ${sizeLimitMB}MB`, { variant: 'warning' });
          return;
        }
        if (typeof urlBase64 === 'string') {
          setBase64Content(urlBase64);
          setDataAudio(urlBase64);
          handleBase64(urlBase64, file);
        }
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.audio}>
        <img
          id={`${name}/img`}
          src={!base64Content ? noAudio : successPng}
          alt="Audio carregado"
          className={classes.audioUpload}
        />
        <input
          name={name}
          accept="audio/*"
          className={classes.input}
          id="soundFile"
          type="file"
          onChange={handleUploadClick}
        />
      </div>

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="soundFile">
        <Fab
          component="span"
          sx={{
            backgroundColor: (theme) => {
              return theme.palette.primary.main;
            },
            color: 'white',
            width: '50px',
            height: '50px',
          }}
          className={(classes.button, classes.positionBtnUpload)}
        >
          <AudioFile />
        </Fab>
      </label>
      {dataAudio && (
        <div className={classes.playerPosition}>
          <audio controls className={classes.player} src={dataAudio} />
        </div>
      )}
    </div>
  );
};

export default Audio;

Audio.defaultProps = { getBase64: undefined, base64: '' };
