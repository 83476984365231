import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    tableRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    tableCell: { width: '100%' },
    colorLabelRadioButton: { color: theme.palette.primary.main },
    bodyContent: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '350px',
      boxShadow: '24',
      p: 4,
    },
    paperContent: { padding: '20px', maxHeight: '80vh', overflow: 'auto' },
  };
});

export default useStyles;
