import * as React from 'react';
import { AppBar, Box, Drawer, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { Menu } from '@mui/icons-material';
import { Routes, Route } from 'react-router-dom';
import routes from 'routes';
import logo from 'assets/images/logo_colorida_escura.png';
import FakePage from 'pages/FakePage';
import MenuOptions from './MenuOptions';
import NavItem from './NavItem';

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  // eslint-disable-next-line
  window?: () => Window;
}

const ResponsiveDrawer = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // prettier-ignore
  // eslint-disable-next-line
  const container = window !== undefined ? () => { return window().document.body } : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{
          boxShadow: 'none',
          backdropFilter: 'blur(6px)',
          WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
          backgroundColor: alpha('#FFFFFF', 0.7),
          height: '80px',
          [theme.breakpoints.up('lg')]: { width: `calc(100% - ${280 + 1}px)` },
        }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <Menu color="primary" />
          </IconButton>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div>
              <img alt="Vileve" src={logo} style={{ width: '100px', marginLeft: '15px' }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', width: isMobile ? '' : '200px' }}>
              <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '120%' }}>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                {isMobile ? '| Pp' : '| Portal do Parceiro'}
              </Typography>
            </div>
          </div>
          <Box sx={{ flexGrow: 1 }} />
          <MenuOptions />
          {/* <Box sx={{ display: { md: 'flex' } }}>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <Mail color="primary" />
              </Badge>
            </IconButton>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="error">
                <Notifications color="primary" />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              // aria-controls={menuId}
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle color="primary" fontSize="large" />
            </IconButton>
          </Box> */}
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <NavItem />
          {/* {drawer} */}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              // borderRight: alpha(theme.palette.primary.main, 0.1),
              backgroundColor: 'transparent',
            },
          }}
          open
        >
          <NavItem />
          {/* {drawer} */}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        <Toolbar />
        <Routes>
          {routes.map(({ isPrivate, path, component: Component }) => {
            if (isPrivate) {
              return <Route path={path} element={<Component />} key={path} />;
            }
            return false;
          })}
          <Route path="*" element={<FakePage />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default ResponsiveDrawer;
