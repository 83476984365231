import React, { useEffect } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import theme from 'styles/theme/light';
import FakePage from 'pages/FakePage';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { useAppSelector } from 'hooks/useAppStore';
import Layout from 'components/Layout';

const App = () => {
  const navigate = useNavigate();
  const signedRedux = useAppSelector((state) => {
    return state.signer.signed;
  });

  useEffect(() => {
    if (!signedRedux) {
      navigate('/signin');
    }
  }, [signedRedux, navigate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <ThemeProvider theme={theme}>
        <Routes>
          {routes.map(({ isPrivate, component: Component, path }) => {
            if (!isPrivate) {
              return <Route path={path} element={<Component />} key={path} />;
            }
            return false;
          })}
          <Route path="*" element={<FakePage />} />
          {signedRedux ? <Route path="/*" element={<Layout />} /> : <Route element={<Navigate to="/signin" />} />}
        </Routes>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
