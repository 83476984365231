import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { SnackbarOrigin } from '@mui/material/Snackbar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import logo from 'assets/images/logo_vileve_clara.png';
import { useAppDispatch, useAppSelector } from 'hooks/useAppStore';
import { useSnackbar } from 'notistack';
import { groupsAndPermission, clearGroupAndPermissions } from 'store/ducks/Groups';
import { signin, clearSignin } from 'store/ducks/Signer';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import useStyles from './styles';
import validationSchema from './validationSchema';

const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://vileve.com.br/">
        Vileve
      </Link>
      &#160;
      {new Date().getFullYear()}
    </Typography>
  );
};

export interface State extends SnackbarOrigin {
  open: boolean;
}

const Signin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const {
    signer: { status: menssageStatus },
    // eslint-disable-next-line object-curly-newline
    groups: {
      groupsAndPermission: { document: currentGroups },
      // eslint-disable-next-line object-curly-newline
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useAppSelector((state) => {
    return state;
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: async (values) => {
      dispatch(signin({ ...values, password: window.btoa(unescape(encodeURIComponent(values.password))) }));
    },
  });

  useEffect(() => {
    dispatch(clearSignin());
    dispatch(clearGroupAndPermissions());
  }, [dispatch]);

  useEffect(() => {
    const validationLogin = async () => {
      if (menssageStatus === 'loading') {
        setOpenBackdrop(true);
      } else if (menssageStatus === 'completed') {
        dispatch(groupsAndPermission());
      } else if (menssageStatus === 'failed') {
        setOpenBackdrop(false);
        enqueueSnackbar('Usuario ou senha inválida!', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };

    validationLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menssageStatus]);

  useEffect(() => {
    if (currentGroups.length > 0) {
      const groupsToDashboard = ['Administrador'];
      const groupsToProposal = ['Comercial', 'Zurich', 'Parceiros'];

      const routerToDashboard = currentGroups.find((currentGroup) => {
        return groupsToDashboard.find((groupToDashboard) => {
          return groupToDashboard === currentGroup.descricao;
        });
      });

      const routerToProposal = currentGroups.find((currentGroup) => {
        return groupsToProposal.find((groupToProposal) => {
          return groupToProposal === currentGroup.descricao;
        });
      });

      setOpenBackdrop(false);
      // enqueueSnackbar('Logado com sucesso!', {
      //   variant: 'success',
      //   autoHideDuration: 2000,
      // });
      if (routerToDashboard) {
        navigate('/dashboard', { replace: true });
      } else if (routerToProposal) {
        navigate('/proposal/list', { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroups]);

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={false}
        md={false}
        lg={7}
        sx={{
          backgroundImage: `url(${logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '25% 8%',
          backgroundColor: (theme) => {
            return theme.palette.primary.main;
          },
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={12} md={12} lg={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box sx={{ mt: 1 }}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
              {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />}
              label="Lembra minha senha" /> */}
              <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                ENTRAR
              </Button>

              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    Dont have an account? Sign Up
                  </Link>
                </Grid>
              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </form>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Signin;
