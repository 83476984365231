/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { FormikProps } from 'formik';
import { newProposal } from 'interfaces/Proposal';
import { maskDate } from 'utils/string/masks';
import useStyles from './styles';

interface Props {
  formik: FormikProps<newProposal>;
}

interface valuesInfo {
  label: string;
  value: unknown;
}

interface IReviewContract {
  clientInfo: valuesInfo[];
  adressInfo: valuesInfo[];
  paymentInfo: valuesInfo[];
  // imgInfo: valuesInfo[];
}

const spacing = 2;

const ContractReview = ({ formik }: Props) => {
  const classes = useStyles();
  const [reviewContract, setReviewContract] = useState<IReviewContract[]>();
  useEffect(() => {
    const contractInfoConstruction: IReviewContract[] = [
      {
        clientInfo: [
          { label: 'Nome', value: formik.values.cliente.nomeCompleto },
          { label: 'CPF', value: formik.values.cliente.cpf },
          {
            label: 'Data de Nascimento',
            value: formik.values.cliente.dataNascimento && maskDate(formik.values.cliente.dataNascimento),
          },
          // { label: 'Sexo', value: formik.values.cliente.codigoSexo },
          // { label: 'Estado Civil', value: formik.values.cliente.codigoEstadoCivil },
          // { label: 'Nacionalidade', value: formik.values.cliente.codigoNacionalidade },
          { label: 'Celular', value: formik.values.cliente.telefoneCelular },
          { label: 'Telefone', value: formik.values.cliente.telefoneFixo },
          { label: 'Email', value: formik.values.cliente.email },
        ],
        adressInfo: [
          { label: 'CEP', value: formik.values.enderecos.cep },
          { label: 'Logradouro', value: formik.values.enderecos.logradouro },
          { label: 'Número', value: formik.values.enderecos.numero },
          {
            label: 'Complemento',
            value: formik.values.enderecos.complemento === '' ? 'N/A' : formik.values.enderecos.complemento,
          },
          { label: 'Bairro', value: formik.values.enderecos.bairro },
          { label: 'Estado', value: formik.values.enderecos.estado },
          { label: 'Cidade', value: formik.values.enderecos.cidade },
        ],
        paymentInfo: [
          { label: 'Nome do Favorecido', value: formik.values.debitoConta.nomeFavorecido },
          { label: 'CPF', value: formik.values.debitoConta.cpfCnpjFavorecido },
          { label: 'Agência', value: formik.values.debitoConta.agencia },
          { label: 'Conta', value: formik.values.debitoConta.conta },
          { label: 'Dia do Pagamento', value: formik.values.cliente.outrosDiaPagamento },
        ],
      },
    ];
    setReviewContract(contractInfoConstruction);
  }, [formik]);

  // useEffect(() => {
  //   const formikCopyError = formik.errors;
  //   delete formik.errors.debitoConta;
  //   formik.setErrors(formikCopyError);
  //   // formik.isSubmitting = true;
  //   formik.setSubmitting(true);
  // }, [formik.errors?.debitoConta]);

  return (
    <Container>
      <Grid container direction="column" spacing={spacing}>
        <Grid item>
          <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600, fontSize: '120%' }}>
            Revisão de Dados
          </Typography>
        </Grid>
        <Grid item>
          <TableContainer component={Paper}>
            <Table aria-label="partner table">
              <TableHead>
                <TableRow>
                  <TableCell>Dados Pessoais</TableCell>
                </TableRow>
              </TableHead>
              {reviewContract &&
                reviewContract[0].clientInfo.map((reviewContractClient) => {
                  return (
                    <TableBody key={reviewContractClient.label}>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>{reviewContractClient.label}</TableCell>
                        <TableCell className={classes.tableCell}>{String(reviewContractClient.value)}</TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
            </Table>
          </TableContainer>
        </Grid>
        <Grid item>
          <TableContainer component={Paper}>
            <Table aria-label="partner table">
              <TableHead>
                <TableRow>
                  <TableCell>Endereço</TableCell>
                </TableRow>
              </TableHead>
              {reviewContract &&
                reviewContract[0].adressInfo.map((reviewContractAddress) => {
                  return (
                    <TableBody key={reviewContractAddress.label}>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>{reviewContractAddress.label}</TableCell>
                        <TableCell className={classes.tableCell}>{String(reviewContractAddress.value)}</TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
            </Table>
          </TableContainer>
        </Grid>
        <Grid item>
          <TableContainer component={Paper}>
            <Table aria-label="partner table">
              <TableHead>
                <TableRow>
                  <TableCell>Dados de Pagamento</TableCell>
                </TableRow>
              </TableHead>
              {reviewContract &&
                reviewContract[0].paymentInfo.map((reviewContractPayment) => {
                  return (
                    <TableBody key={reviewContractPayment.label}>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>{reviewContractPayment.label}</TableCell>
                        <TableCell className={classes.tableCell}>{String(reviewContractPayment.value)}</TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContractReview;
