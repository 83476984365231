import { combineReducers } from '@reduxjs/toolkit';
import signerReducer from './ducks/Signer';
import groupsReducer from './ducks/Groups';
import ProposalStatusReducer from './ducks/Proposal';
import SignaturesStatusReducer from './ducks/Signatures';

const rootReducer = combineReducers({
  groups: groupsReducer,
  proposal: ProposalStatusReducer,
  signatures: SignaturesStatusReducer,
  signer: signerReducer,
});

export default rootReducer;
