import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    circularProgess: { position: 'relative', top: 'calc(50% - 35px)' },
    paper: { padding: '15px', height: '325px', display: 'flex', justifyContent: 'center' },
  };
});

export default useStyles;
