/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { reduxTemplate } from 'interfaces/redux';
import { signer, signerResponse } from 'interfaces/Signer';
import { signinPost } from './service';

export const signin = createAsyncThunk<signerResponse, signer, { rejectValue: reduxTemplate }>(
  'signer/signin',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await signinPost(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface ISignerReducer extends reduxTemplate {
  signed: boolean;
  signin: signerResponse;
}

const initialState: ISignerReducer = {
  status: 'idle',
  signed: false,
  type: '',
  message: '',
  signin: {
    status: '',
    message: '',
    user: '',
    token: '',
    currentRefreshToken: '',
    data: [
      {
        access_token: '',
        token_type: 'bearer',
        expires_in: new Date(),
        codigoConvite: '',
        numeroCelular: '',
        statusOnboardingDescricao: '',
        statusOnboarding: 0,
        consultorId: 0,
        representanteNomeCompleto: '',
        valido: false,
      },
    ],
  },
};

export const signerReducer = createSlice({
  name: 'signer',
  initialState,
  reducers: {
    clearSignin: (state) => {
      state.status = 'idle';
      state.signed = false;
      state.signin = { status: '', message: '', token: '', currentRefreshToken: '', user: '', data: [] };
    },
    setRefreshToken: (state, action: PayloadAction<{ token: string }>) => {
      state.status = 'idle';
      state.signin.token = action.payload.token;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.status = 'completed';
        state.signed = true;
        state.signin = action.payload;
      })
      .addCase(signin.rejected, (state) => {
        state.status = 'failed';
        state.signed = false;
      });
  },
});

export const { clearSignin, setRefreshToken } = signerReducer.actions;
export default signerReducer.reducer;
