import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    bodyContent: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '24',
      p: 4,
    },
    paperContent: {
      padding: '20px',
      maxHeight: '80vh',
      overflow: 'auto',
      [theme.breakpoints.up('lg')]: { width: '800px' },
    },
    tableRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    tableCell: { width: '100%' },
  };
});

export default useStyles;
