import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    tableRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    tableCell: { width: '100%' },
  };
});

export default useStyles;
