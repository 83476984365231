import React from 'react';
import { CircularProgress, Paper } from '@mui/material';
import useStyles from './styles';

const PaperBackDrop = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <CircularProgress className={classes.circularProgess} />
    </Paper>
  );
};

export default PaperBackDrop;
