import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import { IconButton, Tooltip } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

interface ArrowNavigateProps {
  linkTo: string;
  label: string;
}

const ArrowNavigate = ({ linkTo, label }: ArrowNavigateProps) => {
  return (
    <Tooltip title={label}>
      <IconButton component={RouterLink} to={linkTo} area-label={label}>
        <ArrowForward />
      </IconButton>
    </Tooltip>
  );
};

export default ArrowNavigate;
