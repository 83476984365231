/* eslint-disable import/prefer-default-export */
import api from 'services';
import { signer, signerResponse } from 'interfaces/Signer';

export const signinPost = async ({ email, password }: signer): Promise<signerResponse> => {
  const response = await api.post<signerResponse>(
    '/signin',
    {},
    {
      headers: {
        email,
        password,
        isbackoffice: 'false',
      },
    },
  );
  api.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
  return response.data;
};
