import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    colorLabelRadioButton: { color: theme.palette.primary.main },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1500,
      color: theme.palette.primary.main,
    },
    bodyContent: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '350px',
      boxShadow: '24',
      p: 4,
    },
    paperContent: { padding: '20px', maxHeight: '80vh', overflow: 'auto' },
  };
});

export default useStyles;
