import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Sections from './Sections';

const NavItem = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        boxShadow: 'none',
        backdropFilter: 'blur(6px)',
        WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
        backgroundColor: alpha('#FFFFFF', 0.1),
        [theme.breakpoints.up('lg')]: { width: `calc(100% - ${280 + 1}px)` },
      }}
    >
      <Sections />
    </div>
  );
};

export default NavItem;
