/* eslint-disable object-curly-newline */
/* eslint-disable newline-per-chained-call */
import * as Yup from 'yup';
import store from 'store';
import validateCpf from 'utils/string/validateCpf';
import validateBenefitNumber from 'utils/string/validateBenefitNumber';

const dateMin18 = new Date();
dateMin18.setFullYear(dateMin18.getFullYear() - 18);

const requiredFieldMessage = 'Campo obrigatório';
const validationSchema = Yup.object({
  cliente: Yup.object({
    cpf: Yup.string()
      .trim()
      .max(14, 'CPF inválido')
      .min(14, 'CPF inválido')
      .required(requiredFieldMessage)
      .test('teste-validade-cpf', 'CPF inválido ou já cadastrado', async (value, SchemaOf) => {
        const cpf = String(value);
        if (cpf.length === 14) {
          if (validateCpf(cpf) && !SchemaOf.parent.isValidateCpf) {
            const {
              proposal: { validadorCpf },
            } = store.getState();

            if (validadorCpf) {
              if (validadorCpf.isValidCpf) {
                return true;
              }
            }
          }
          return false;
        }
        return true;
      }),
    nomeCompleto: Yup.string()
      .trim()
      .required(requiredFieldMessage)
      .matches(
        /[a-zzáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]\s+/g,
        'Por favor, insira um nome válido e com letras maiúsculas e minúsculas',
      )
      .min(10, 'Nome completo deve conter no minimo 10 caractéries')
      .max(50, 'Máximo de 40 caractéries'),
    dataNascimento: Yup.date()
      .required(requiredFieldMessage)
      .typeError('Data inválida')
      .max(new Date(Date.now() - 567648000000), 'Cliente precisa ser maior de idade')
      .nullable(),
    email: Yup.string()
      .trim()
      .required(requiredFieldMessage)
      .email('Por favor, insira um email válido')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u, 'Insira um e-mail válido sem acentos'),
    telefoneFixo: Yup.string()
      .trim()
      .min(14, 'Telefone precisa conter 10 dígitos')
      .min(14, 'Telefone precisa conter 10 dígitos'),
    telefoneCelular: Yup.string()
      .trim()
      .required(requiredFieldMessage)
      .min(15, 'Celular precisa conter 11 dígitos')
      .test('teste-validade-celular', 'Celular já cadastrado', async (value, SchemaOf) => {
        const cellPhone = String(value);
        if (cellPhone.length === 15) {
          const {
            proposal: { validateCelular: validateCelularRedux },
          } = store.getState();
          if (validateCelularRedux?.document[0]) {
            if (validateCelularRedux?.document[0].status === 'true') {
              return true;
            }
          }
          return false;
        }
        return true;
      }),
    codigoSexo: Yup.number()
      .required(requiredFieldMessage)
      .transform((value: string) => {
        return Number(value);
      })
      .test('test-sexo', requiredFieldMessage, (value) => {
        if (value === 0) {
          return false;
        }
        return true;
      }),
    codigoEstadoCivil: Yup.number()
      .required(requiredFieldMessage)
      .transform((value: string) => {
        return Number(value);
      })
      .test('test-sexo', requiredFieldMessage, (value) => {
        if (value === 0) {
          return false;
        }
        return true;
      }),
    codigoNacionalidade: Yup.number()
      .required(requiredFieldMessage)
      .transform((value: string) => {
        return Number(value);
      })
      .test('test-nacionalidade', requiredFieldMessage, (value) => {
        if (value === 0) {
          return false;
        }
        return true;
      }),
    inssNumeroBeneficio: Yup.string()
      .nullable()
      .test('test-inssNumeroBeneficio', requiredFieldMessage, (value) => {
        if (value === 'yup') {
          return true;
        }
        if (value === '' || !value || value.length !== 10) {
          return false;
        }
        return true;
      })
      .test('test-inssDigit', 'Número de benefício inválido', (value) => {
        if (value === 'yup') {
          return true;
        }
        if (value === '' || !value || !validateBenefitNumber(value) || value.length !== 10) {
          return false;
        }
        return true;
      })
      .transform((value: string) => {
        if (!value) {
          return '';
        }
        return value;
      }),
    hasInss: Yup.boolean(),
    inssSalario: Yup.string()
      .test('test-inssSalario', requiredFieldMessage, (value, SchemaOf) => {
        if (SchemaOf.parent?.inssNumeroBeneficio && SchemaOf.parent?.inssNumeroBeneficio !== 'yup') {
          if (!value) {
            return false;
          }
          if (value === 'R$ 0,00') {
            return false;
          }
          return true;
        }
        return true;
      })
      .when('inssNumeroBeneficio', (value, SchemaOf) => {
        if (value === 'yup') {
          return SchemaOf;
        }
        if (!value && value !== 'yup') {
          return SchemaOf.required(requiredFieldMessage);
        }
        return SchemaOf;
      }),
    inssEspecie: Yup.number()
      .transform((value: string) => {
        return Number(value);
      })
      .test('test-inssEspecie', requiredFieldMessage, (value, SchemaOf) => {
        if (SchemaOf.parent?.inssNumeroBeneficio && SchemaOf.parent?.inssNumeroBeneficio !== 'yup') {
          if (!value) {
            return false;
          }
          if (value === 0) {
            return false;
          }
          return true;
        }
        return true;
      })
      .when('inssNumeroBeneficio', (value, SchemaOf) => {
        if (value === 'yup') {
          return SchemaOf;
        }
        if (!value && value !== 'yup') {
          return SchemaOf.required(requiredFieldMessage);
        }
        return SchemaOf;
      }),
    outrosDiaPagamento: Yup.number()
      .required(requiredFieldMessage)
      .transform((value: string) => {
        return Number(value);
      })
      .test('test-outrosDiaPagamento', requiredFieldMessage, (value) => {
        if (value === 0) {
          return false;
        }
        return true;
      }),
    enviarTokenSms: Yup.boolean(),
  }),
  clienteCobapEZurich: Yup.object({
    idProfissao: Yup.number()
      .required(requiredFieldMessage)
      .transform((value: string) => {
        return Number(value);
      })
      .test('test-profissao', requiredFieldMessage, (value) => {
        if (value === 0) {
          return false;
        }
        return true;
      }),
  }),
  produto: Yup.object({ codigoProdutoItem: Yup.string() }),
  cartaoCredito: Yup.object({
    numeroCartao: Yup.string()
      .nullable()
      .min(19, 'Cartão de Crédito Precisa de 16 dígitos')
      .test('test-numeroCartao', requiredFieldMessage, (value) => {
        if (value === null) {
          return false;
        }
        return true;
      })
      .trim(),
    nomeCartao: Yup.string()
      .nullable()
      .test('test-nomeCartao', requiredFieldMessage, (value) => {
        if (value === null) {
          return false;
        }
        return true;
      }),
    dataValidade: Yup.date()
      .nullable()
      .test('test-cartaoCredito', requiredFieldMessage, (value) => {
        if (value === null) {
          return false;
        }
        return true;
      })
      .typeError('Data inválida')
      .min(new Date(), 'Cartão expirado'),
  }),
  debitoConta: Yup.object({
    codigoBanco: Yup.string().required(requiredFieldMessage),
    agencia: Yup.string().required(requiredFieldMessage).min(4, 'Agência deve ter mais de 3 dígitos'),
    conta: Yup.string().required(requiredFieldMessage),
    codigoOperacao: Yup.string(),
    nomeFavorecido: Yup.string()
      .required(requiredFieldMessage)
      .test('test-nomeFavorecido', 'Primeiro nome deve ser igual ao do contratante', (value) => {
        if (value?.split(' ')[0] === localStorage.getItem('name')) {
          return true;
        }
        return false;
      }),
    cpfCnpjFavorecido: Yup.string().required(requiredFieldMessage),
  }),
  enderecos: Yup.object().shape({
    cep: Yup.string().required(requiredFieldMessage),
    logradouro: Yup.string().required(requiredFieldMessage),
    numero: Yup.string().required(requiredFieldMessage),
    complemento: Yup.string().notRequired(),
    bairro: Yup.string().required(requiredFieldMessage),
    cidade: Yup.string().required(requiredFieldMessage),
    estado: Yup.string().required(requiredFieldMessage),
  }),
  hasInss: Yup.boolean(),
  // numeroBeneficio: Yup.string().when('hasInss', {
  //   is: true,
  //   then: Yup.string().required('Campo Obrigatório'),
  // }),
  // salarioBruto: Yup.string().when('hasInss', {
  //   is: true,
  //   then: Yup.string().required('Campo Obrigatório'),
  // }),
  // especie: Yup.string().when('hasInss', {
  //   is: true,
  //   then: Yup.string().required('Campo Obrigatório'),
  // }),
});

export default validationSchema;
