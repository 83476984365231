/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  vileveAssistenciaEstadosCivis,
  vileveAssistenciaNacionalidade,
  vileveAssistenciaSexo,
  vileveAssistenciaProfissoes,
  vileveAssistenciaTipoDeProduto,
  vileveAssistenciaProductById,
  vileveAssistenciaOperacoesBancarias,
} from 'interfaces/externalApi';
import store from 'store';

const apiAssistencia = axios.create({ baseURL: process.env.REACT_APP_VILEVE_ASSISTENCIA, timeout: 20000 });

export const getEstadosCivis = async (): Promise<vileveAssistenciaEstadosCivis[]> => {
  try {
    const res = await apiAssistencia.get<vileveAssistenciaEstadosCivis[]>('/parametrizacoes/estados-civis');
    return res.data;
  } catch (error) {
    return [
      {
        codigoEstadoCivil: -1,
        nome: '',
      },
    ];
  }
};

export const getNacionalidades = async (): Promise<vileveAssistenciaNacionalidade[]> => {
  try {
    const res = await apiAssistencia.get<vileveAssistenciaNacionalidade[]>('/parametrizacoes/nacionalidades');
    return res.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return [
      {
        codigoNacionalidade: 0,
        prefixoPais: '',
        nome: '',
      },
    ];
  }
};

export const getSexo = async (): Promise<vileveAssistenciaSexo[]> => {
  try {
    const res = await apiAssistencia.get<vileveAssistenciaSexo[]>('/parametrizacoes/sexos');
    return res.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return [
      {
        codigoSexo: 0,
        nome: 'string',
      },
    ];
  }
};

export const getProfissoes = async (): Promise<vileveAssistenciaProfissoes[]> => {
  try {
    const res = await apiAssistencia.get<vileveAssistenciaProfissoes[]>('/parametrizacoes/profissoes');
    return res.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return [
      {
        idProfissao: 1,
        codigo: '',
        descricao: '',
      },
    ];
  }
};

export const getProductType = async (): Promise<vileveAssistenciaTipoDeProduto[]> => {
  try {
    const { access_token: acessToken } = store.getState().signer.signin.data[0];
    const res = await apiAssistencia.get<vileveAssistenciaTipoDeProduto[]>('/clientes/seguros/produtos', {
      headers: { Authorization: `bearer ${acessToken}` },
    });
    return res.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return [
      {
        codigoProduto: 0,
        nome: '',
        itens: [],
        meiosPagamentos: null,
      },
    ];
  }
};

export const getProductsById = async (product: string): Promise<vileveAssistenciaProductById> => {
  try {
    const { access_token: acessToken } = store.getState().signer.signin.data[0];
    const productsApi = await getProductType();
    const currentProduct = productsApi.filter(({ nome }) => {
      return nome === product;
    });

    const res = await apiAssistencia.get<vileveAssistenciaProductById>(
      `/clientes/seguros/produtos/${currentProduct[0].codigoProduto}`,
      {
        headers: { Authorization: `bearer ${acessToken}` },
      },
    );
    return res.data;
  } catch (error) {
    return {
      codigoProduto: 0,
      nome: '',
      meiosPagamentos: [
        {
          codigoIdBanco: '',
          nome: '',
          meioPagamento: '',
          prefixo: '',
        },
      ],
      itens: [
        {
          codigoProdutoItem: 0,
          codigoProduto: 0,
          nome: '',
          valor: 0,
          descricao: '',
          quantidadeMaximaDependente: 0,
          parentescosAdicionais: [],
        },
      ],
    };
  }
};

export const getOperacoesBancarias = async (): Promise<vileveAssistenciaOperacoesBancarias[]> => {
  try {
    const res = await apiAssistencia.get<vileveAssistenciaOperacoesBancarias[]>('/parametrizacoes/operacoes-bancarias');
    return res.data;
  } catch (error) {
    return [
      {
        codigoOperacao: 0,
        nome: '',
        tipo: 0,
      },
    ];
  }
};

export const getValidadorCpf = async (cpf: string): Promise<{ isValidCpf: boolean }> => {
  try {
    const { access_token: acessToken } = store.getState().signer.signin.data[0];

    await apiAssistencia.get<string[] | undefined>('/clientes/cpf-cnpj/validar', {
      headers: {
        cpfCnpj: cpf,
        Authorization: `bearer ${acessToken}`,
      },
    });
    return { isValidCpf: true };
  } catch (error) {
    return { isValidCpf: false };
  }
};
