import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Typography, CardActionArea, Grid, Button } from '@mui/material';
import useStyle from '../styles';

const ZurichPackages = () => {
  const classes = useStyle();

  return (
    <Grid container justifyContent="space-around" spacing={3} direction="row">
      <Grid item>
        <Button component={RouterLink} to="/proposal/new/44">
          <Card className={classes.cardLayout}>
            <CardActionArea>
              <CardContent>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      Apólice 01
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      <b>R$48,37</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography variant="body2" color="text.secondary">
                    <Grid>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'start', color: '#005882' }}>Benefício</th>
                            <th style={{ textAlign: 'start', color: '#005882' }}>Seguro Premium</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Seguro de Acidentes Pessoais</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Assitência Funeral</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Auxílio Funeral</td>
                            <td style={{ textAlign: 'center', color: '#cc0000' }}>&#10005;</td>
                          </tr>
                          <tr>
                            <td>Cesta Basica</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Assistência Farmácia</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Assitência Domiciliar</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Clube de benefícios</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Sorteio</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>R$10.000,00</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item className={classes.contractButton}>
                  <Button variant="outlined">
                    <Typography color="primary">Contratar</Typography>
                  </Button>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Button>
      </Grid>
      <Grid item>
        <Button component={RouterLink} to="/proposal/new/45">
          <Card className={classes.cardLayout}>
            <CardActionArea>
              <CardContent>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      Apólice 02
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      <b>R$35,00</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography variant="body2" color="text.secondary">
                    <Grid>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'start', color: '#005882' }}>Benefício</th>
                            <th style={{ textAlign: 'start', color: '#005882' }}> Seguro Light </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Seguro de Acidentes Pessoais</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Assitência Funeral</td>
                            <td style={{ textAlign: 'center', color: '#cc0000' }}>&#10005;</td>
                          </tr>
                          <tr>
                            <td>Auxílio Funeral</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Cesta Basica</td>
                            <td style={{ textAlign: 'center', color: '#cc0000' }}>&#10005;</td>
                          </tr>
                          <tr>
                            <td>Assistência Farmácia</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Assitência Domiciliar</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Clube de benefícios</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Sorteio</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>R$10.000,00</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item className={classes.contractButton}>
                  <Button variant="outlined">
                    <Typography color="primary">Contratar</Typography>
                  </Button>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Button>
      </Grid>
    </Grid>
  );
};
export default ZurichPackages;
