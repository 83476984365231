/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Typography, CardActionArea, Grid, Button } from '@mui/material';
import useStyle from '../styles';

const VilevePackages = () => {
  const classes = useStyle();

  return (
    <Grid container justifyContent="space-around" spacing={3} direction="row">
      <Grid item>
        <Button component={RouterLink} to="/proposal/new/1">
          <Card className={classes.cardLayout}>
            <CardActionArea>
              <CardContent>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      Assistência Individual
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      <b>R$39,90</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography variant="body2" color="text.secondary">
                    <Grid>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'start', color: '#005882' }}>Benefício</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Assistência Funeral <br />
                              <i>R$3.000,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Auxílio Funeral - <i>R$2.000,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#cc0000' }}>&#10005;</td>
                          </tr>
                          <tr>
                            <td>
                              Telemedicina - <i>Sem Limite</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Saúde <br />
                              <i>Sem Limite</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Cesta Básica - <i>$720,00</i>R
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Residencial - <i>Conforme Manual</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Farmácia - <i>Sem Limite</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>
                              Medicamentos <br />
                              <i>Genéricos ou similares a preço de custo</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Sorteio - <i>R$10.000,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#cc0000' }}>&#10005;</td>
                          </tr>
                          <tr>
                            <td>Seguro por morte acidental ou invalidez perm.</td>
                            <td style={{ textAlign: 'center', color: '#cc0000' }}>&#10005;</td>
                          </tr>
                          <tr>
                            <td>
                              Carência - 90 dias do 1º pag. - <i>falecimento</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Limite de idade 80 anos</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item className={classes.contractButton}>
                  <Button variant="outlined">
                    <Typography color="primary">Contratar</Typography>
                  </Button>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Button>
      </Grid>
      <Grid item>
        <Button component={RouterLink} to="/proposal/new/2">
          <Card className={classes.cardLayout}>
            <CardActionArea>
              <CardContent>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      Assistência Familiar
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      <b>R$49,90</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography variant="body2" color="text.secondary">
                    <Grid>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'start', color: '#005882' }}>Benefício</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Assistência Funeral <br />
                              <i>R$3.000,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>
                                Titular, Cônjuge <br />e Filhos
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Auxílio Funeral - <i>R$2.000,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Telemedicina - <i>Sem Limite</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Saúde <br />
                              <i>Sem Limite</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>
                                Titular, Cônjuge,
                                <br /> Filhos e Netos
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Cesta Básica - <i>R$720,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Residencial - <i>Conforme Manual</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Farmácia - <i>Sem Limite</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>
                              Medicamentos <br />
                              <i>Genéricos ou similares a preço de custo</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Sorteio - <i>R$10.000,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#cc0000' }}>&#10005;</td>
                          </tr>
                          <tr>
                            <td>Seguro por morte acidental ou invalidez perm.</td>
                            <td style={{ textAlign: 'center', color: '#cc0000' }}>&#10005;</td>
                          </tr>
                          <tr>
                            <td>
                              Carência - 90 dias do 1º pag. - <i>falecimento</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Limite de idade 80 anos</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item className={classes.contractButton}>
                  <Button variant="outlined">
                    <Typography color="primary">Contratar</Typography>
                  </Button>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Button>
      </Grid>
      <Grid item>
        <Button component={RouterLink} to="/proposal/new/3">
          <Card className={classes.cardLayout}>
            <CardActionArea>
              <CardContent>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      Assistência Familiar Ampliada
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6" color="primary" component="div">
                      <b>R$69,90</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography variant="body2" color="text.secondary">
                    <Grid>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'start', color: '#005882' }}>Benefício</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Assistência Funeral <br />
                              <i>R$3.000,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>
                                Titular, Cônjuge, <br />
                                Filhos, Pais e Sogros
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Auxílio Funeral - <i>R$2.000,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular e Cônjuge</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Telemedicina - <i>Sem Limite</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Saúde <br />
                              <i>Sem Limite</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>
                                Titular, Cônjuge, Filhos, <br /> Netos, Pais e Sogros
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Cesta Básica - <i>R$720,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Residencial - <i>Conforme Manual</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>
                              Assistência Farmácia - <i>Sem Limite</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>
                              Medicamentos <br />
                              <i>Genéricos ou similares a preço de custo</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>
                              <b>Titular</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Sorteio - <i>R$10.000,00</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Seguro por morte acidental ou invalidez perm.</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>
                              Carência - 90 dias do 1º pag. - <i>falecimento</i>
                            </td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                          <tr>
                            <td>Limite de idade 80 anos</td>
                            <td style={{ textAlign: 'center', color: '#005882' }}>&#10003;</td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item className={classes.contractButton}>
                  <Button variant="outlined">
                    <Typography color="primary">Contratar</Typography>
                  </Button>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Button>
      </Grid>
    </Grid>
  );
};
export default VilevePackages;
