// eslint-disable-next-line no-use-before-define
import React from 'react';
import { PostAdd, ViewList } from '@mui/icons-material';
import Page from 'components/Page';
import List from './List';

const ListProposalReports = () => {
  const breadcrumb = [
    {
      href: '/proposal/list',
      label: 'Lista de Propostas',
      icon: ViewList,
    },
  ];
  return (
    <Page Icon={PostAdd} title="Nova Proposta" buttonRouter="/proposal/productselection" breadCrumb={breadcrumb}>
      <List />
    </Page>
  );
};

export default ListProposalReports;
