import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { History } from '@mui/icons-material';
import TimelineProposal from 'components/TimelineProposal';

interface Props {
  proposal: number | undefined;
}

const HistoryButton = ({ proposal }: Props) => {
  const [open, setOpen] = useState(false);
  const handleModal = () => {
    setOpen(!open);
  };
  return (
    <IconButton onClick={handleModal}>
      <History />
      <TimelineProposal open={open} handleModal={handleModal} proposal={proposal} />
    </IconButton>
  );
};

export default HistoryButton;
