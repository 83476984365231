const cities = [
  { estado: 'GO', cidade: 'ABADIA DE GOIAS' },
  { estado: 'MG', cidade: 'ABADIA DOS DOURADOS' },
  { estado: 'GO', cidade: 'ABADIANIA' },
  { estado: 'MG', cidade: 'ABAETE' },
  { estado: 'PA', cidade: 'ABAETETUBA' },
  { estado: 'CE', cidade: 'ABAIARA' },
  { estado: 'BA', cidade: 'ABAIRA' },
  { estado: 'BA', cidade: 'ABARE' },
  { estado: 'PR', cidade: 'ABATIA' },
  { estado: 'SC', cidade: 'ABDON BATISTA' },
  { estado: 'PA', cidade: 'ABEL FIGUEIREDO' },
  { estado: 'SC', cidade: 'ABELARDO LUZ' },
  { estado: 'MG', cidade: 'ABRE CAMPO' },
  { estado: 'PE', cidade: 'ABREU E LIMA' },
  { estado: 'TO', cidade: 'ABREULANDIA' },
  { estado: 'MG', cidade: 'ACAIACA' },
  { estado: 'MA', cidade: 'ACAILANDIA' },
  { estado: 'BA', cidade: 'ACAJUTIBA' },
  { estado: 'PA', cidade: 'ACARA' },
  { estado: 'CE', cidade: 'ACARAPE' },
  { estado: 'CE', cidade: 'ACARAU' },
  { estado: 'RN', cidade: 'ACARI' },
  { estado: 'PI', cidade: 'ACAUA' },
  { estado: 'RS', cidade: 'ACEGUA' },
  { estado: 'CE', cidade: 'ACOPIARA' },
  { estado: 'MT', cidade: 'ACORIZAL' },
  { estado: 'AC', cidade: 'ACRELANDIA' },
  { estado: 'GO', cidade: 'ACREUNA' },
  { estado: 'RN', cidade: 'ACU' },
  { estado: 'MG', cidade: 'ACUCENA' },
  { estado: 'SP', cidade: 'ADAMANTINA' },
  { estado: 'GO', cidade: 'ADELANDIA' },
  { estado: 'SP', cidade: 'ADOLFO' },
  { estado: 'PR', cidade: 'ADRIANOPOLIS' },
  { estado: 'BA', cidade: 'ADUSTINA' },
  { estado: 'PE', cidade: 'AFOGADOS DA INGAZEIRA' },
  { estado: 'RN', cidade: 'AFONSO BEZERRA' },
  { estado: 'ES', cidade: 'AFONSO CLAUDIO' },
  { estado: 'MA', cidade: 'AFONSO CUNHA' },
  { estado: 'PE', cidade: 'AFRANIO' },
  { estado: 'PA', cidade: 'AFUA' },
  { estado: 'PE', cidade: 'AGRESTINA' },
  { estado: 'PI', cidade: 'AGRICOLANDIA' },
  { estado: 'SC', cidade: 'AGROLANDIA' },
  { estado: 'SC', cidade: 'AGRONOMICA' },
  { estado: 'PA', cidade: 'AGUA AZUL DO NORTE' },
  { estado: 'MG', cidade: 'AGUA BOA' },
  { estado: 'MT', cidade: 'AGUA BOA' },
  { estado: 'Al', cidade: 'AGUA BRANCA' },
  { estado: 'PB', cidade: 'AGUA BRANCA' },
  { estado: 'PI', cidade: 'AGUA BRANCA' },
  { estado: 'MS', cidade: 'AGUA CLARA' },
  { estado: 'MG', cidade: 'AGUA COMPRIDA' },
  { estado: 'SC', cidade: 'AGUA DOCE' },
  { estado: 'MA', cidade: 'AGUA DOCE DO MARANHAO' },
  { estado: 'ES', cidade: 'AGUA DOCE DO NORTE' },
  { estado: 'BA', cidade: 'AGUA FRIA' },
  { estado: 'GO', cidade: 'AGUA FRIA DE GOIAS' },
  { estado: 'GO', cidade: 'AGUA LIMPA' },
  { estado: 'RN', cidade: 'AGUA NOVA' },
  { estado: 'PE', cidade: 'AGUA PRETA' },
  { estado: 'RS', cidade: 'AGUA SANTA' },
  { estado: 'SP', cidade: 'AGUAI' },
  { estado: 'MG', cidade: 'AGUANIL' },
  { estado: 'PE', cidade: 'AGUAS BELAS' },
  { estado: 'SP', cidade: 'AGUAS DA PRATA' },
  { estado: 'SC', cidade: 'AGUAS DE CHAPECO' },
  { estado: 'SP', cidade: 'AGUAS DE LINDOIA' },
  { estado: 'SP', cidade: 'AGUAS DE SANTA BARBARA' },
  { estado: 'SP', cidade: 'AGUAS DE SAO PEDRO' },
  { estado: 'MG', cidade: 'AGUAS FORMOSAS' },
  { estado: 'SC', cidade: 'AGUAS FRIAS' },
  { estado: 'GO', cidade: 'AGUAS LINDAS DE GOIAS' },
  { estado: 'SC', cidade: 'AGUAS MORNAS' },
  { estado: 'MG', cidade: 'AGUAS VERMELHAS' },
  { estado: 'RS', cidade: 'AGUDO' },
  { estado: 'SP', cidade: 'AGUDOS' },
  { estado: 'PR', cidade: 'AGUDOS DO SUL' },
  { estado: 'ES', cidade: 'AGUIA BRANCA' },
  { estado: 'PB', cidade: 'AGUIAR' },
  { estado: 'TO', cidade: 'AGUIARNOPOLIS' },
  { estado: 'MG', cidade: 'AIMORES' },
  { estado: 'BA', cidade: 'AIQUARA' },
  { estado: 'CE', cidade: 'AIUABA' },
  { estado: 'MG', cidade: 'AIURUOCA' },
  { estado: 'RS', cidade: 'AJURICABA' },
  { estado: 'MG', cidade: 'ALAGOA' },
  { estado: 'PB', cidade: 'ALAGOA GRANDE' },
  { estado: 'PB', cidade: 'ALAGOA NOVA' },
  { estado: 'PE', cidade: 'ALAGOINHA' },
  { estado: 'PB', cidade: 'ALAGOINHA' },
  { estado: 'PI', cidade: 'ALAGOINHA DO PIAUI' },
  { estado: 'BA', cidade: 'ALAGOINHAS' },
  { estado: 'SP', cidade: 'ALAMBARI' },
  { estado: 'MG', cidade: 'ALBERTINA' },
  { estado: 'MA', cidade: 'ALCANTARA' },
  { estado: 'CE', cidade: 'ALCANTARAS' },
  { estado: 'PB', cidade: 'ALCANTIL' },
  { estado: 'MS', cidade: 'ALCINOPOLIS' },
  { estado: 'BA', cidade: 'ALCOBACA' },
  { estado: 'MA', cidade: 'ALDEIAS ALTAS' },
  { estado: 'RS', cidade: 'ALECRIM' },
  { estado: 'ES', cidade: 'ALEGRE' },
  { estado: 'RS', cidade: 'ALEGRETE' },
  { estado: 'PI', cidade: 'ALEGRETE DO PIAUI' },
  { estado: 'RS', cidade: 'ALEGRIA' },
  { estado: 'MG', cidade: 'ALEM PARAIBA' },
  { estado: 'PA', cidade: 'ALENQUER' },
  { estado: 'RN', cidade: 'ALEXANDRIA' },
  { estado: 'GO', cidade: 'ALEXANIA' },
  { estado: 'MG', cidade: 'ALFENAS' },
  { estado: 'ES', cidade: 'ALFREDO CHAVES' },
  { estado: 'SP', cidade: 'ALFREDO MARCONDES' },
  { estado: 'MG', cidade: 'ALFREDO VASCONCELOS' },
  { estado: 'SC', cidade: 'ALFREDO WAGNER' },
  { estado: 'PB', cidade: 'ALGODAO DE JANDAIRA' },
  { estado: 'PB', cidade: 'ALHANDRA' },
  { estado: 'PE', cidade: 'ALIANCA' },
  { estado: 'TO', cidade: 'ALIANCA DO TOCANTINS' },
  { estado: 'BA', cidade: 'ALMADINA' },
  { estado: 'TO', cidade: 'ALMAS' },
  { estado: 'PA', cidade: 'ALMEIRIM' },
  { estado: 'MG', cidade: 'ALMENARA' },
  { estado: 'RN', cidade: 'ALMINO AFONSO' },
  { estado: 'PR', cidade: 'ALMIRANTE TAMANDARE' },
  { estado: 'RS', cidade: 'ALMIRANTE TAMANDARE DO SUL' },
  { estado: 'GO', cidade: 'ALOANDIA' },
  { estado: 'MG', cidade: 'ALPERCATA' },
  { estado: 'RS', cidade: 'ALPESTRE' },
  { estado: 'MG', cidade: 'ALPINOPOLIS' },
  { estado: 'MT', cidade: 'ALTA FLORESTA' },
  { estado: 'RO', cidade: 'ALTA FLORESTA D´OESTE' },
  { estado: 'SP', cidade: 'ALTAIR' },
  { estado: 'PA', cidade: 'ALTAMIRA' },
  { estado: 'MA', cidade: 'ALTAMIRA DO MARANHAO' },
  { estado: 'PR', cidade: 'ALTAMIRA DO PARANA' },
  { estado: 'CE', cidade: 'ALTANEIRA' },
  { estado: 'MG', cidade: 'ALTEROSA' },
  { estado: 'PE', cidade: 'ALTINHO' },
  { estado: 'SP', cidade: 'ALTINOPOLIS' },
  { estado: 'SP', cidade: 'ALTO ALEGRE' },
  { estado: 'RR', cidade: 'ALTO ALEGRE' },
  { estado: 'RS', cidade: 'ALTO ALEGRE' },
  { estado: 'MA', cidade: 'ALTO ALEGRE DO MARANHAO' },
  { estado: 'RO', cidade: 'ALTO ALEGRE DO PARECIS' },
  { estado: 'MA', cidade: 'ALTO ALEGRE DO PINDARE' },
  { estado: 'MT', cidade: 'ALTO ARAGUAIA' },
  { estado: 'SC', cidade: 'ALTO BELA VISTA' },
  { estado: 'MT', cidade: 'ALTO BOA VISTA' },
  { estado: 'MG', cidade: 'ALTO CAPARAO' },
  { estado: 'RN', cidade: 'ALTO DO RODRIGUES' },
  { estado: 'RS', cidade: 'ALTO FELIZ' },
  { estado: 'MT', cidade: 'ALTO GARCAS' },
  { estado: 'GO', cidade: 'ALTO HORIZONTE' },
  { estado: 'MG', cidade: 'ALTO JEQUITIBA' },
  { estado: 'PI', cidade: 'ALTO LONGA' },
  { estado: 'MT', cidade: 'ALTO PARAGUAI' },
  { estado: 'RO', cidade: 'ALTO PARAISO' },
  { estado: 'GO', cidade: 'ALTO PARAISO DE GOIAS' },
  { estado: 'PR', cidade: 'ALTO PARANA' },
  { estado: 'MA', cidade: 'ALTO PARNAIBA' },
  { estado: 'PR', cidade: 'ALTO PIQUIRI' },
  { estado: 'MG', cidade: 'ALTO RIO DOCE' },
  { estado: 'ES', cidade: 'ALTO RIO NOVO' },
  { estado: 'CE', cidade: 'ALTO SANTO' },
  { estado: 'MT', cidade: 'ALTO TAQUARI' },
  { estado: 'PR', cidade: 'ALTONIA' },
  { estado: 'PI', cidade: 'ALTOS' },
  { estado: 'SP', cidade: 'ALUMINIO' },
  { estado: 'AM', cidade: 'ALVARAES' },
  { estado: 'MG', cidade: 'ALVARENGA' },
  { estado: 'SP', cidade: 'ALVARES FLORENCE' },
  { estado: 'SP', cidade: 'ALVARES MACHADO' },
  { estado: 'SP', cidade: 'ALVARO DE CARVALHO' },
  { estado: 'SP', cidade: 'ALVINLANDIA' },
  { estado: 'MG', cidade: 'ALVINOPOLIS' },
  { estado: 'TO', cidade: 'ALVORADA' },
  { estado: 'RS', cidade: 'ALVORADA' },
  { estado: 'RO', cidade: 'ALVORADA D´OESTE' },
  { estado: 'MG', cidade: 'ALVORADA DE MINAS' },
  { estado: 'PI', cidade: 'ALVORADA DO GURGUEIA' },
  { estado: 'GO', cidade: 'ALVORADA DO NORTE' },
  { estado: 'PR', cidade: 'ALVORADA DO SUL' },
  { estado: 'RR', cidade: 'AMAJARI' },
  { estado: 'MS', cidade: 'AMAMBAI' },
  { estado: 'AP', cidade: 'AMAPA' },
  { estado: 'MA', cidade: 'AMAPA DO MARANHAO' },
  { estado: 'PR', cidade: 'AMAPORA' },
  { estado: 'PE', cidade: 'AMARAJI' },
  { estado: 'RS', cidade: 'AMARAL FERRADOR' },
  { estado: 'GO', cidade: 'AMARALINA' },
  { estado: 'PI', cidade: 'AMARANTE' },
  { estado: 'MA', cidade: 'AMARANTE DO MARANHAO' },
  { estado: 'BA', cidade: 'AMARGOSA' },
  { estado: 'AM', cidade: 'AMATURA' },
  { estado: 'BA', cidade: 'AMELIA RODRIGUES' },
  { estado: 'BA', cidade: 'AMERICA DOURADA' },
  { estado: 'SP', cidade: 'AMERICANA' },
  { estado: 'GO', cidade: 'AMERICANO DO BRASIL' },
  { estado: 'SP', cidade: 'AMERICO BRASILIENSE' },
  { estado: 'SP', cidade: 'AMERICO DE CAMPOS' },
  { estado: 'RS', cidade: 'AMETISTA DO SUL' },
  { estado: 'CE', cidade: 'AMONTADA' },
  { estado: 'GO', cidade: 'AMORINOPOLIS' },
  { estado: 'SP', cidade: 'AMPARO' },
  { estado: 'PB', cidade: 'AMPARO' },
  { estado: 'SE', cidade: 'AMPARO DE SAO FRANCISCO' },
  { estado: 'MG', cidade: 'AMPARO DO SERRA' },
  { estado: 'PR', cidade: 'AMPERE' },
  { estado: 'Al', cidade: 'ANADIA' },
  { estado: 'BA', cidade: 'ANAGE' },
  { estado: 'PR', cidade: 'ANAHY' },
  { estado: 'PA', cidade: 'ANAJAS' },
  { estado: 'MA', cidade: 'ANAJATUBA' },
  { estado: 'SP', cidade: 'ANALANDIA' },
  { estado: 'AM', cidade: 'ANAMA' },
  { estado: 'TO', cidade: 'ANANAS' },
  { estado: 'PA', cidade: 'ANANINDEUA' },
  { estado: 'GO', cidade: 'ANAPOLIS' },
  { estado: 'PA', cidade: 'ANAPU' },
  { estado: 'MA', cidade: 'ANAPURUS' },
  { estado: 'MS', cidade: 'ANASTACIO' },
  { estado: 'MS', cidade: 'ANAURILANDIA' },
  { estado: 'ES', cidade: 'ANCHIETA' },
  { estado: 'SC', cidade: 'ANCHIETA' },
  { estado: 'BA', cidade: 'ANDARAI' },
  { estado: 'PR', cidade: 'ANDIRA' },
  { estado: 'BA', cidade: 'ANDORINHA' },
  { estado: 'MG', cidade: 'ANDRADAS' },
  { estado: 'SP', cidade: 'ANDRADINA' },
  { estado: 'RS', cidade: 'ANDRE DA ROCHA' },
  { estado: 'MG', cidade: 'ANDRELANDIA' },
  { estado: 'SP', cidade: 'ANGATUBA' },
  { estado: 'MG', cidade: 'ANGELANDIA' },
  { estado: 'MS', cidade: 'ANGELICA' },
  { estado: 'PE', cidade: 'ANGELIM' },
  { estado: 'SC', cidade: 'ANGELINA' },
  { estado: 'BA', cidade: 'ANGICAL' },
  { estado: 'PI', cidade: 'ANGICAL DO PIAUI' },
  { estado: 'TO', cidade: 'ANGICO' },
  { estado: 'RN', cidade: 'ANGICOS' },
  { estado: 'RJ', cidade: 'ANGRA DO REIS' },
  { estado: 'BA', cidade: 'ANGUERA' },
  { estado: 'PR', cidade: 'ANGULO' },
  { estado: 'GO', cidade: 'ANHANGUERA' },
  { estado: 'SP', cidade: 'ANHEMBI' },
  { estado: 'SP', cidade: 'ANHUMAS' },
  { estado: 'GO', cidade: 'ANICUNS' },
  { estado: 'PI', cidade: 'ANISIO DE ABREU' },
  { estado: 'SC', cidade: 'ANITA GARIBALDI' },
  { estado: 'SC', cidade: 'ANITAPOLIS' },
  { estado: 'AM', cidade: 'ANORI' },
  { estado: 'RS', cidade: 'ANTA GORDA' },
  { estado: 'BA', cidade: 'ANTAS' },
  { estado: 'PR', cidade: 'ANTONINA' },
  { estado: 'CE', cidade: 'ANTONINA DO NORTE' },
  { estado: 'PI', cidade: 'ANTONIO ALMEIDA' },
  { estado: 'BA', cidade: 'ANTONIO CARDOSO' },
  { estado: 'MG', cidade: 'ANTONIO CARLOS' },
  { estado: 'SC', cidade: 'ANTONIO CARLOS' },
  { estado: 'MG', cidade: 'ANTONIO DIAS' },
  { estado: 'BA', cidade: 'ANTONIO GONCALVES' },
  { estado: 'MS', cidade: 'ANTONIO JOAO' },
  { estado: 'RN', cidade: 'ANTONIO MARTINS' },
  { estado: 'PR', cidade: 'ANTONIO OLINTO' },
  { estado: 'RS', cidade: 'ANTONIO PRADO' },
  { estado: 'MG', cidade: 'ANTONIO PRADO DE MINAS' },
  { estado: 'SP', cidade: 'APARECIDA' },
  { estado: 'PB', cidade: 'APARECIDA' },
  { estado: 'SP', cidade: 'APARECIDA D´OESTE' },
  { estado: 'GO', cidade: 'APARECIDA DE GOIANIA' },
  { estado: 'GO', cidade: 'APARECIDA DO RIO DOCE' },
  { estado: 'TO', cidade: 'APARECIDA DO RIO NEGRO' },
  { estado: 'MS', cidade: 'APARECIDA DO TABOADO' },
  { estado: 'RJ', cidade: 'APERIBE' },
  { estado: 'ES', cidade: 'APIACA' },
  { estado: 'MT', cidade: 'APIACAS' },
  { estado: 'SP', cidade: 'APIAI' },
  { estado: 'MA', cidade: 'APICUM-ACU' },
  { estado: 'SC', cidade: 'APIUNA' },
  { estado: 'RN', cidade: 'APODI' },
  { estado: 'BA', cidade: 'APORA' },
  { estado: 'GO', cidade: 'APORE' },
  { estado: 'BA', cidade: 'APUAREMA' },
  { estado: 'PR', cidade: 'APUCARANA' },
  { estado: 'AM', cidade: 'APUI' },
  { estado: 'CE', cidade: 'APUIARES' },
  { estado: 'SE', cidade: 'AQUIDABA' },
  { estado: 'MS', cidade: 'AQUIDAUANA' },
  { estado: 'CE', cidade: 'AQUIRAZ' },
  { estado: 'SC', cidade: 'ARABUTA' },
  { estado: 'PB', cidade: 'ARACAGI' },
  { estado: 'MG', cidade: 'ARACAI' },
  { estado: 'SE', cidade: 'ARACAJU' },
  { estado: 'SP', cidade: 'ARACARIGUAMA' },
  { estado: 'BA', cidade: 'ARACAS' },
  { estado: 'CE', cidade: 'ARACATI' },
  { estado: 'BA', cidade: 'ARACATU' },
  { estado: 'SP', cidade: 'ARACATUBA' },
  { estado: 'BA', cidade: 'ARACI' },
  { estado: 'MG', cidade: 'ARACITABA' },
  { estado: 'PE', cidade: 'ARACOIABA' },
  { estado: 'CE', cidade: 'ARACOIABA' },
  { estado: 'SP', cidade: 'ARACOIABA DA SERRA' },
  { estado: 'ES', cidade: 'ARACRUZ' },
  { estado: 'GO', cidade: 'ARACU' },
  { estado: 'MG', cidade: 'ARACUAI' },
  { estado: 'GO', cidade: 'ARAGARCAS' },
  { estado: 'GO', cidade: 'ARAGOIANIA' },
  { estado: 'TO', cidade: 'ARAGOMINAS' },
  { estado: 'TO', cidade: 'ARAGUACEMA' },
  { estado: 'TO', cidade: 'ARAGUACU' },
  { estado: 'MT', cidade: 'ARAGUAIANA' },
  { estado: 'TO', cidade: 'ARAGUAINA' },
  { estado: 'MT', cidade: 'ARAGUAINHA' },
  { estado: 'MA', cidade: 'ARAGUANA' },
  { estado: 'TO', cidade: 'ARAGUANA' },
  { estado: 'GO', cidade: 'ARAGUAPAZ' },
  { estado: 'MG', cidade: 'ARAGUARI' },
  { estado: 'TO', cidade: 'ARAGUATINS' },
  { estado: 'MA', cidade: 'ARAIOSES' },
  { estado: 'MS', cidade: 'ARAL MOREIRA' },
  { estado: 'BA', cidade: 'ARAMARI' },
  { estado: 'RS', cidade: 'ARAMBARE' },
  { estado: 'MA', cidade: 'ARAME' },
  { estado: 'SP', cidade: 'ARAMINA' },
  { estado: 'SP', cidade: 'ARANDU' },
  { estado: 'MG', cidade: 'ARANTINA' },
  { estado: 'SP', cidade: 'ARAPEI' },
  { estado: 'Al', cidade: 'ARAPIRACA' },
  { estado: 'TO', cidade: 'ARAPOEMA' },
  { estado: 'MG', cidade: 'ARAPONGA' },
  { estado: 'PR', cidade: 'ARAPONGAS' },
  { estado: 'MG', cidade: 'ARAPORA' },
  { estado: 'PR', cidade: 'ARAPOTI' },
  { estado: 'MG', cidade: 'ARAPUA' },
  { estado: 'PR', cidade: 'ARAPUA' },
  { estado: 'MT', cidade: 'ARAPUTANGA' },
  { estado: 'SC', cidade: 'ARAQUARI' },
  { estado: 'PB', cidade: 'ARARA' },
  { estado: 'SC', cidade: 'ARARANGUA' },
  { estado: 'SP', cidade: 'ARARAQUARA' },
  { estado: 'SP', cidade: 'ARARAS' },
  { estado: 'CE', cidade: 'ARARENDA' },
  { estado: 'MA', cidade: 'ARARI' },
  { estado: 'RS', cidade: 'ARARICA' },
  { estado: 'CE', cidade: 'ARARIPE' },
  { estado: 'PE', cidade: 'ARARIPINA' },
  { estado: 'RJ', cidade: 'ARARUAMA' },
  { estado: 'PB', cidade: 'ARARUNA' },
  { estado: 'PR', cidade: 'ARARUNA' },
  { estado: 'BA', cidade: 'ARATACA' },
  { estado: 'RS', cidade: 'ARATIBA' },
  { estado: 'CE', cidade: 'ARATUBA' },
  { estado: 'BA', cidade: 'ARATUIPE' },
  { estado: 'SE', cidade: 'ARAUA' },
  { estado: 'PR', cidade: 'ARAUCARIA' },
  { estado: 'MG', cidade: 'ARAUJOS' },
  { estado: 'MG', cidade: 'ARAXA' },
  { estado: 'MG', cidade: 'ARCEBURGO' },
  { estado: 'SP', cidade: 'ARCO-IRIS' },
  { estado: 'MG', cidade: 'ARCOS' },
  { estado: 'PE', cidade: 'ARCOVERDE' },
  { estado: 'MG', cidade: 'AREADO' },
  { estado: 'RJ', cidade: 'AREAL' },
  { estado: 'SP', cidade: 'AREALVA' },
  { estado: 'PB', cidade: 'AREIA' },
  { estado: 'SE', cidade: 'AREIA BRANCA' },
  { estado: 'RN', cidade: 'AREIA BRANCA' },
  { estado: 'PB', cidade: 'AREIA DE BARAUNAS' },
  { estado: 'PB', cidade: 'AREIAL' },
  { estado: 'SP', cidade: 'AREIAS' },
  { estado: 'SP', cidade: 'AREIOPOLIS' },
  { estado: 'MT', cidade: 'ARENAPOLIS' },
  { estado: 'GO', cidade: 'ARENOPOLIS' },
  { estado: 'RN', cidade: 'ARES' },
  { estado: 'MG', cidade: 'ARGIRITA' },
  { estado: 'MG', cidade: 'ARICANDUVA' },
  { estado: 'MG', cidade: 'ARINOS' },
  { estado: 'MT', cidade: 'ARIPUANA' },
  { estado: 'RO', cidade: 'ARIQUEMES' },
  { estado: 'SP', cidade: 'ARIRANHA' },
  { estado: 'PR', cidade: 'ARIRANHA DO IVAI' },
  { estado: 'RJ', cidade: 'ARMACAO DO BUZIOS' },
  { estado: 'SC', cidade: 'ARMAZEM' },
  { estado: 'CE', cidade: 'ARNEIROZ' },
  { estado: 'PI', cidade: 'AROAZES' },
  { estado: 'PB', cidade: 'AROEIRAS' },
  { estado: 'PI', cidade: 'AROEIRAS DO ITAIM' },
  { estado: 'PI', cidade: 'ARRAIAL' },
  { estado: 'RJ', cidade: 'ARRAIAL DO CABO' },
  { estado: 'TO', cidade: 'ARRAIAS' },
  { estado: 'RS', cidade: 'ARROIO DO MEIO' },
  { estado: 'RS', cidade: 'ARROIO DO PADRE' },
  { estado: 'RS', cidade: 'ARROIO DO RATOS' },
  { estado: 'RS', cidade: 'ARROIO DO SAL' },
  { estado: 'RS', cidade: 'ARROIO DO TIGRE' },
  { estado: 'RS', cidade: 'ARROIO GRANDE' },
  { estado: 'SC', cidade: 'ARROIO TRINTA' },
  { estado: 'SP', cidade: 'ARTUR NOGUEIRA' },
  { estado: 'GO', cidade: 'ARUANA' },
  { estado: 'SP', cidade: 'ARUJA' },
  { estado: 'SC', cidade: 'ARVOREDO' },
  { estado: 'RS', cidade: 'ARVOREZINHA' },
  { estado: 'SC', cidade: 'ASCURRA' },
  { estado: 'SP', cidade: 'ASPASIA' },
  { estado: 'PR', cidade: 'ASSAI' },
  { estado: 'CE', cidade: 'ASSARE' },
  { estado: 'SP', cidade: 'ASSIS' },
  { estado: 'AC', cidade: 'ASSIS BRASIL' },
  { estado: 'PR', cidade: 'ASSIS CHATEAUBRIAND' },
  { estado: 'PB', cidade: 'ASSUNCAO' },
  { estado: 'PI', cidade: 'ASSUNCAO DO PIAUI' },
  { estado: 'MG', cidade: 'ASTOLFO DUTRA' },
  { estado: 'PR', cidade: 'ASTORGA' },
  { estado: 'Al', cidade: 'ATALAIA' },
  { estado: 'PR', cidade: 'ATALAIA' },
  { estado: 'AM', cidade: 'ATALAIA DO NORTE' },
  { estado: 'SC', cidade: 'ATALANTA' },
  { estado: 'MG', cidade: 'ATALEIA' },
  { estado: 'SP', cidade: 'ATIBAIA' },
  { estado: 'ES', cidade: 'ATILIO VIVACQUA' },
  { estado: 'TO', cidade: 'AUGUSTINOPOLIS' },
  { estado: 'PA', cidade: 'AUGUSTO CORREA' },
  { estado: 'MG', cidade: 'AUGUSTO DE LIMA' },
  { estado: 'RS', cidade: 'AUGUSTO PESTANA' },
  { estado: 'RN', cidade: 'AUGUSTO SEVERO' },
  { estado: 'RS', cidade: 'AUREA' },
  { estado: 'BA', cidade: 'AURELINO LEAL' },
  { estado: 'SP', cidade: 'AURIFLAMA' },
  { estado: 'GO', cidade: 'AURILANDIA' },
  { estado: 'CE', cidade: 'AURORA' },
  { estado: 'SC', cidade: 'AURORA' },
  { estado: 'PA', cidade: 'AURORA DO PARA' },
  { estado: 'TO', cidade: 'AURORA DO TOCANTINS' },
  { estado: 'AM', cidade: 'AUTAZES' },
  { estado: 'SP', cidade: 'AVAI' },
  { estado: 'SP', cidade: 'AVANHANDAVA' },
  { estado: 'SP', cidade: 'AVARE' },
  { estado: 'PA', cidade: 'AVEIRO' },
  { estado: 'PI', cidade: 'AVELINO LOPES' },
  { estado: 'GO', cidade: 'AVELINOPOLIS' },
  { estado: 'MA', cidade: 'AXIXA' },
  { estado: 'TO', cidade: 'AXIXA DO TOCANTINS' },
  { estado: 'TO', cidade: 'BABACULANDIA' },
  { estado: 'MA', cidade: 'BACABAL' },
  { estado: 'MA', cidade: 'BACABEIRA' },
  { estado: 'MA', cidade: 'BACURI' },
  { estado: 'MA', cidade: 'BACURITUBA' },
  { estado: 'SP', cidade: 'BADY BASSITT' },
  { estado: 'MG', cidade: 'BAEPENDI' },
  { estado: 'RS', cidade: 'BAGE' },
  { estado: 'PA', cidade: 'BAGRE' },
  { estado: 'PB', cidade: 'BAIA DA TRAICAO' },
  { estado: 'RN', cidade: 'BAIA FORMOSA' },
  { estado: 'BA', cidade: 'BAIANOPOLIS' },
  { estado: 'PA', cidade: 'BAIAO' },
  { estado: 'BA', cidade: 'BAIXA GRANDE' },
  { estado: 'PI', cidade: 'BAIXA GRANDE DO RIBEIRO' },
  { estado: 'CE', cidade: 'BAIXIO' },
  { estado: 'ES', cidade: 'BAIXO GUANDU' },
  { estado: 'SP', cidade: 'BALBINOS' },
  { estado: 'MG', cidade: 'BALDIM' },
  { estado: 'GO', cidade: 'BALIZA' },
  { estado: 'SC', cidade: 'BALNEARIO ARROIO DO SILVA' },
  { estado: 'SC', cidade: 'BALNEARIO BARRA DO SUL' },
  { estado: 'SC', cidade: 'BALNEARIO CAMBORIU' },
  { estado: 'SC', cidade: 'BALNEARIO GAIVOTA' },
  { estado: 'RS', cidade: 'BALNEARIO PINHAL' },
  { estado: 'PR', cidade: 'BALSA NOVA' },
  { estado: 'SP', cidade: 'BALSAMO' },
  { estado: 'MA', cidade: 'BALSAS' },
  { estado: 'MG', cidade: 'BAMBUI' },
  { estado: 'CE', cidade: 'BANABUIU' },
  { estado: 'SP', cidade: 'BANANAL' },
  { estado: 'PB', cidade: 'BANANEIRAS' },
  { estado: 'MG', cidade: 'BANDEIRA' },
  { estado: 'MG', cidade: 'BANDEIRA DO SUL' },
  { estado: 'SC', cidade: 'BANDEIRANTE' },
  { estado: 'MS', cidade: 'BANDEIRANTES' },
  { estado: 'PR', cidade: 'BANDEIRANTES' },
  { estado: 'TO', cidade: 'BANDEIRANTES DO TOCANTINS' },
  { estado: 'PA', cidade: 'BANNACH' },
  { estado: 'BA', cidade: 'BANZAE' },
  { estado: 'RS', cidade: 'BARAO' },
  { estado: 'SP', cidade: 'BARAO DE ANTONINA' },
  { estado: 'MG', cidade: 'BARAO DE COCAIS' },
  { estado: 'RS', cidade: 'BARAO DE COTEGIPE' },
  { estado: 'MA', cidade: 'BARAO DE GRAJAU' },
  { estado: 'MT', cidade: 'BARAO DE MELGACO' },
  { estado: 'MG', cidade: 'BARAO DE MONTE ALTO' },
  { estado: 'RS', cidade: 'BARAO DO TRIUNFO' },
  { estado: 'PB', cidade: 'BARAUNA' },
  { estado: 'RN', cidade: 'BARAUNA' },
  { estado: 'MG', cidade: 'BARBACENA' },
  { estado: 'CE', cidade: 'BARBALHA' },
  { estado: 'SP', cidade: 'BARBOSA' },
  { estado: 'PR', cidade: 'BARBOSA FERRAZ' },
  { estado: 'PA', cidade: 'BARCARENA' },
  { estado: 'RN', cidade: 'BARCELONA' },
  { estado: 'AM', cidade: 'BARCELOS' },
  { estado: 'SP', cidade: 'BARIRI' },
  { estado: 'BA', cidade: 'BARRA' },
  { estado: 'SP', cidade: 'BARRA BONITA' },
  { estado: 'SC', cidade: 'BARRA BONITA' },
  { estado: 'PI', cidade: 'BARRA D ALCANTARA' },
  { estado: 'BA', cidade: 'BARRA DA ESTIVA' },
  { estado: 'PE', cidade: 'BARRA DE GUABIRABA' },
  { estado: 'PB', cidade: 'BARRA DE SANTA ROSA' },
  { estado: 'PB', cidade: 'BARRA DE SANTANA' },
  { estado: 'Al', cidade: 'BARRA DE SANTO ANTONIO' },
  { estado: 'ES', cidade: 'BARRA DE SAO FRANCISCO' },
  { estado: 'Al', cidade: 'BARRA DE SAO MIGUEL' },
  { estado: 'PB', cidade: 'BARRA DE SAO MIGUEL' },
  { estado: 'MT', cidade: 'BARRA DO BUGRES' },
  { estado: 'SP', cidade: 'BARRA DO CHAPEU' },
  { estado: 'BA', cidade: 'BARRA DO CHOCA' },
  { estado: 'SE', cidade: 'BARRA DO COQUEIROS' },
  { estado: 'MA', cidade: 'BARRA DO CORDA' },
  { estado: 'MT', cidade: 'BARRA DO GARCAS' },
  { estado: 'RS', cidade: 'BARRA DO GUARITA' },
  { estado: 'PR', cidade: 'BARRA DO JACARE' },
  { estado: 'BA', cidade: 'BARRA DO MENDES' },
  { estado: 'TO', cidade: 'BARRA DO OURO' },
  { estado: 'RJ', cidade: 'BARRA DO PIRAI' },
  { estado: 'RS', cidade: 'BARRA DO QUARAI' },
  { estado: 'RS', cidade: 'BARRA DO RIBEIRO' },
  { estado: 'RS', cidade: 'BARRA DO RIO AZUL' },
  { estado: 'BA', cidade: 'BARRA DO ROCHA' },
  { estado: 'SP', cidade: 'BARRA DO TURVO' },
  { estado: 'RS', cidade: 'BARRA FUNDA' },
  { estado: 'MG', cidade: 'BARRA LONGA' },
  { estado: 'RJ', cidade: 'BARRA MANSA' },
  { estado: 'SC', cidade: 'BARRA VELHA' },
  { estado: 'PR', cidade: 'BARRACAO' },
  { estado: 'RS', cidade: 'BARRACAO' },
  { estado: 'PI', cidade: 'BARRAS' },
  { estado: 'CE', cidade: 'BARREIRA' },
  { estado: 'BA', cidade: 'BARREIRAS' },
  { estado: 'PI', cidade: 'BARREIRAS DO PIAUI' },
  { estado: 'AM', cidade: 'BARREIRINHA' },
  { estado: 'MA', cidade: 'BARREIRINHAS' },
  { estado: 'PE', cidade: 'BARREIROS' },
  { estado: 'SP', cidade: 'BARRETOS' },
  { estado: 'SP', cidade: 'BARRINHA' },
  { estado: 'CE', cidade: 'BARRO' },
  { estado: 'BA', cidade: 'BARRO ALTO' },
  { estado: 'GO', cidade: 'BARRO ALTO' },
  { estado: 'PI', cidade: 'BARRO DURO' },
  { estado: 'BA', cidade: 'BARROCAS' },
  { estado: 'TO', cidade: 'BARROLANDIA' },
  { estado: 'CE', cidade: 'BARROQUINHA' },
  { estado: 'RS', cidade: 'BARROS CASSAL' },
  { estado: 'MG', cidade: 'BARROSO' },
  { estado: 'SP', cidade: 'BARUERI' },
  { estado: 'SP', cidade: 'BASTOS' },
  { estado: 'MS', cidade: 'BATAGUASSU' },
  { estado: 'MS', cidade: 'BATAIPORA' },
  { estado: 'Al', cidade: 'BATALHA' },
  { estado: 'PI', cidade: 'BATALHA' },
  { estado: 'SP', cidade: 'BATATAIS' },
  { estado: 'CE', cidade: 'BATURITE' },
  { estado: 'SP', cidade: 'BAURU' },
  { estado: 'PB', cidade: 'BAYEUX' },
  { estado: 'SP', cidade: 'BEBEDOURO' },
  { estado: 'CE', cidade: 'BEBERIBE' },
  { estado: 'CE', cidade: 'BELA CRUZ' },
  { estado: 'MS', cidade: 'BELA VISTA' },
  { estado: 'GO', cidade: 'BELA VISTA DE GOIAS' },
  { estado: 'MG', cidade: 'BELA VISTA DE MINAS' },
  { estado: 'PR', cidade: 'BELA VISTA DO CAROBA' },
  { estado: 'MA', cidade: 'BELA VISTA DO MARANHAO' },
  { estado: 'PR', cidade: 'BELA VISTA DO PARAISO' },
  { estado: 'PI', cidade: 'BELA VISTA DO PIAUI' },
  { estado: 'SC', cidade: 'BELA VISTA DO TOLDO' },
  { estado: 'MA', cidade: 'BELAGUA' },
  { estado: 'Al', cidade: 'BELEM' },
  { estado: 'PB', cidade: 'BELEM' },
  { estado: 'PA', cidade: 'BELEM' },
  { estado: 'PE', cidade: 'BELEM DE MARIA' },
  { estado: 'PE', cidade: 'BELEM DE SAO FRANCISCO' },
  { estado: 'PB', cidade: 'BELEM DO BREJO DO CRUZ' },
  { estado: 'PI', cidade: 'BELEM DO PIAUI' },
  { estado: 'RJ', cidade: 'BELFORD ROXO' },
  { estado: 'MG', cidade: 'BELMIRO BRAGA' },
  { estado: 'BA', cidade: 'BELMONTE' },
  { estado: 'SC', cidade: 'BELMONTE' },
  { estado: 'BA', cidade: 'BELO CAMPO' },
  { estado: 'MG', cidade: 'BELO HORIZONTE' },
  { estado: 'PE', cidade: 'BELO JARDIM' },
  { estado: 'Al', cidade: 'BELO MONTE' },
  { estado: 'MG', cidade: 'BELO ORIENTE' },
  { estado: 'MG', cidade: 'BELO VALE' },
  { estado: 'PA', cidade: 'BELTERRA' },
  { estado: 'PI', cidade: 'BENEDITINOS' },
  { estado: 'MA', cidade: 'BENEDITO LEITE' },
  { estado: 'SC', cidade: 'BENEDITO NOVO' },
  { estado: 'PA', cidade: 'BENEVIDES' },
  { estado: 'AM', cidade: 'BENJAMIN CONSTANT' },
  { estado: 'RS', cidade: 'BENJAMIN CONSTANT DO SUL' },
  { estado: 'SP', cidade: 'BENTO DE ABREU' },
  { estado: 'RN', cidade: 'BENTO FERNANDES' },
  { estado: 'RS', cidade: 'BENTO GONCALVES' },
  { estado: 'MA', cidade: 'BEQUIMAO' },
  { estado: 'MG', cidade: 'BERILO' },
  { estado: 'MG', cidade: 'BERIZAL' },
  { estado: 'PB', cidade: 'BERNARDINO BATISTA' },
  { estado: 'SP', cidade: 'BERNARDINO DE CAMPOS' },
  { estado: 'MA', cidade: 'BERNARDO DO MEARIM' },
  { estado: 'TO', cidade: 'BERNARDO SAYAO' },
  { estado: 'SP', cidade: 'BERTIOGA' },
  { estado: 'PI', cidade: 'BERTOLINIA' },
  { estado: 'MG', cidade: 'BERTOPOLIS' },
  { estado: 'AM', cidade: 'BERURI' },
  { estado: 'PE', cidade: 'BETANIA' },
  { estado: 'PI', cidade: 'BETANIA DO PIAUI' },
  { estado: 'MG', cidade: 'BETIM' },
  { estado: 'PE', cidade: 'BEZERROS' },
  { estado: 'MG', cidade: 'BIAS FORTES' },
  { estado: 'MG', cidade: 'BICAS' },
  { estado: 'SC', cidade: 'BIGUACU' },
  { estado: 'SP', cidade: 'BILAC' },
  { estado: 'MG', cidade: 'BIQUINHAS' },
  { estado: 'SP', cidade: 'BIRIGUI' },
  { estado: 'SP', cidade: 'BIRITIBA-MIRIM' },
  { estado: 'BA', cidade: 'BIRITINGA' },
  { estado: 'PR', cidade: 'BITURUNA' },
  { estado: 'SC', cidade: 'BLUMENAU' },
  { estado: 'ES', cidade: 'BOA ESPERANCA' },
  { estado: 'MG', cidade: 'BOA ESPERANCA' },
  { estado: 'PR', cidade: 'BOA ESPERANCA' },
  { estado: 'PR', cidade: 'BOA ESPERANCA DO IGUACU' },
  { estado: 'SP', cidade: 'BOA ESPERANCA DO SUL' },
  { estado: 'PI', cidade: 'BOA HORA' },
  { estado: 'BA', cidade: 'BOA NOVA' },
  { estado: 'PB', cidade: 'BOA VENTURA' },
  { estado: 'PR', cidade: 'BOA VENTURA DE SAO ROQUE' },
  { estado: 'CE', cidade: 'BOA VIAGEM' },
  { estado: 'PB', cidade: 'BOA VISTA' },
  { estado: 'RR', cidade: 'BOA VISTA' },
  { estado: 'PR', cidade: 'BOA VISTA DA APARECIDA' },
  { estado: 'RS', cidade: 'BOA VISTA DAS MISSOES' },
  { estado: 'RS', cidade: 'BOA VISTA DO BURICA' },
  { estado: 'RS', cidade: 'BOA VISTA DO CADEADO' },
  { estado: 'MA', cidade: 'BOA VISTA DO GURUPI' },
  { estado: 'RS', cidade: 'BOA VISTA DO INCRA' },
  { estado: 'AM', cidade: 'BOA VISTA DO RAMOS' },
  { estado: 'RS', cidade: 'BOA VISTA DO SUL' },
  { estado: 'BA', cidade: 'BOA VISTA DO TUPIM' },
  { estado: 'Al', cidade: 'BOCA DA MATA' },
  { estado: 'AM', cidade: 'BOCA DO ACRE' },
  { estado: 'SP', cidade: 'BOCAINA' },
  { estado: 'PI', cidade: 'BOCAINA' },
  { estado: 'MG', cidade: 'BOCAINA DE MINAS' },
  { estado: 'SC', cidade: 'BOCAINA DO SUL' },
  { estado: 'MG', cidade: 'BOCAIUVA' },
  { estado: 'PR', cidade: 'BOCAIUVA DO SUL' },
  { estado: 'RN', cidade: 'BODO' },
  { estado: 'PE', cidade: 'BODOCO' },
  { estado: 'MS', cidade: 'BODOQUENA' },
  { estado: 'SP', cidade: 'BOFETE' },
  { estado: 'SP', cidade: 'BOITUVA' },
  { estado: 'PE', cidade: 'BOM CONSELHO' },
  { estado: 'MG', cidade: 'BOM DESPACHO' },
  { estado: 'RJ', cidade: 'BOM JARDIM' },
  { estado: 'PE', cidade: 'BOM JARDIM' },
  { estado: 'MA', cidade: 'BOM JARDIM' },
  { estado: 'SC', cidade: 'BOM JARDIM DA SERRA' },
  { estado: 'GO', cidade: 'BOM JARDIM DE GOIAS' },
  { estado: 'MG', cidade: 'BOM JARDIM DE MINAS' },
  { estado: 'PB', cidade: 'BOM JESUS' },
  { estado: 'RN', cidade: 'BOM JESUS' },
  { estado: 'PI', cidade: 'BOM JESUS' },
  { estado: 'SC', cidade: 'BOM JESUS' },
  { estado: 'RS', cidade: 'BOM JESUS' },
  { estado: 'BA', cidade: 'BOM JESUS DA LAPA' },
  { estado: 'MG', cidade: 'BOM JESUS DA PENHA' },
  { estado: 'BA', cidade: 'BOM JESUS DA SERRA' },
  { estado: 'MA', cidade: 'BOM JESUS DAS SELVAS' },
  { estado: 'GO', cidade: 'BOM JESUS DE GOIAS' },
  { estado: 'MG', cidade: 'BOM JESUS DO AMPARO' },
  { estado: 'MT', cidade: 'BOM JESUS DO ARAGUAIA' },
  { estado: 'MG', cidade: 'BOM JESUS DO GALHO' },
  { estado: 'RJ', cidade: 'BOM JESUS DO ITABAPOANA' },
  { estado: 'ES', cidade: 'BOM JESUS DO NORTE' },
  { estado: 'SC', cidade: 'BOM JESUS DO OESTE' },
  { estado: 'SP', cidade: 'BOM JESUS DO PERDOES' },
  { estado: 'PR', cidade: 'BOM JESUS DO SUL' },
  { estado: 'PA', cidade: 'BOM JESUS DO TOCANTINS' },
  { estado: 'TO', cidade: 'BOM JESUS DO TOCANTINS' },
  { estado: 'MA', cidade: 'BOM LUGAR' },
  { estado: 'RS', cidade: 'BOM PRINCIPIO' },
  { estado: 'PI', cidade: 'BOM PRINCIPIO DO PIAUI' },
  { estado: 'RS', cidade: 'BOM PROGRESSO' },
  { estado: 'MG', cidade: 'BOM REPOUSO' },
  { estado: 'SC', cidade: 'BOM RETIRO' },
  { estado: 'RS', cidade: 'BOM RETIRO DO SUL' },
  { estado: 'MG', cidade: 'BOM SUCESSO' },
  { estado: 'PB', cidade: 'BOM SUCESSO' },
  { estado: 'PR', cidade: 'BOM SUCESSO' },
  { estado: 'SP', cidade: 'BOM SUCESSO DE ITARARE' },
  { estado: 'PR', cidade: 'BOM SUCESSO DO SUL' },
  { estado: 'SC', cidade: 'BOMBINHAS' },
  { estado: 'MG', cidade: 'BONFIM' },
  { estado: 'RR', cidade: 'BONFIM' },
  { estado: 'PI', cidade: 'BONFIM DO PIAUI' },
  { estado: 'GO', cidade: 'BONFINOPOLIS' },
  { estado: 'MG', cidade: 'BONFINOPOLIS DE MINAS' },
  { estado: 'BA', cidade: 'BONINAL' },
  { estado: 'BA', cidade: 'BONITO' },
  { estado: 'PE', cidade: 'BONITO' },
  { estado: 'PA', cidade: 'BONITO' },
  { estado: 'MS', cidade: 'BONITO' },
  { estado: 'MG', cidade: 'BONITO DE MINAS' },
  { estado: 'PB', cidade: 'BONITO DE SANTA FE' },
  { estado: 'GO', cidade: 'BONOPOLIS' },
  { estado: 'PB', cidade: 'BOQUEIRAO' },
  { estado: 'RS', cidade: 'BOQUEIRAO DO LEAO' },
  { estado: 'PI', cidade: 'BOQUEIRAO DO PIAUI' },
  { estado: 'SE', cidade: 'BOQUIM' },
  { estado: 'BA', cidade: 'BOQUIRA' },
  { estado: 'SP', cidade: 'BORA' },
  { estado: 'SP', cidade: 'BORACEIA' },
  { estado: 'AM', cidade: 'BORBA' },
  { estado: 'SP', cidade: 'BORBOREMA' },
  { estado: 'PB', cidade: 'BORBOREMA' },
  { estado: 'MG', cidade: 'BORDA DA MATA' },
  { estado: 'SP', cidade: 'BOREBI' },
  { estado: 'PR', cidade: 'BORRAZOPOLIS' },
  { estado: 'RS', cidade: 'BOSSOROCA' },
  { estado: 'MG', cidade: 'BOTELHOS' },
  { estado: 'SP', cidade: 'BOTUCATU' },
  { estado: 'MG', cidade: 'BOTUMIRIM' },
  { estado: 'BA', cidade: 'BOTUPORA' },
  { estado: 'SC', cidade: 'BOTUVERA' },
  { estado: 'RS', cidade: 'BOZANO' },
  { estado: 'SC', cidade: 'BRACO DO NORTE' },
  { estado: 'SC', cidade: 'BRACO DO TROMBUDO' },
  { estado: 'RS', cidade: 'BRAGA' },
  { estado: 'PA', cidade: 'BRAGANCA' },
  { estado: 'SP', cidade: 'BRAGANCA PAULISTA' },
  { estado: 'PR', cidade: 'BRAGANEY' },
  { estado: 'Al', cidade: 'BRANQUINHA' },
  { estado: 'MG', cidade: 'BRAS PIRES' },
  { estado: 'PA', cidade: 'BRASIL NOVO' },
  { estado: 'MS', cidade: 'BRASILANDIA' },
  { estado: 'MG', cidade: 'BRASILANDIA DE MINAS' },
  { estado: 'PR', cidade: 'BRASILANDIA DO SUL' },
  { estado: 'TO', cidade: 'BRASILANDIA DO TOCANTINS' },
  { estado: 'AC', cidade: 'BRASILEIA' },
  { estado: 'PI', cidade: 'BRASILEIRA' },
  { estado: 'DF', cidade: 'BRASILIA' },
  { estado: 'MG', cidade: 'BRASILIA DE MINAS' },
  { estado: 'MT', cidade: 'BRASNORTE' },
  { estado: 'MG', cidade: 'BRASOPOLIS' },
  { estado: 'SP', cidade: 'BRAUNA' },
  { estado: 'MG', cidade: 'BRAUNAS' },
  { estado: 'GO', cidade: 'BRAZABRANTES' },
  { estado: 'PE', cidade: 'BREJAO' },
  { estado: 'ES', cidade: 'BREJETUBA' },
  { estado: 'PE', cidade: 'BREJINHO' },
  { estado: 'RN', cidade: 'BREJINHO' },
  { estado: 'TO', cidade: 'BREJINHO DE NAZARE' },
  { estado: 'MA', cidade: 'BREJO' },
  { estado: 'SP', cidade: 'BREJO ALEGRE' },
  { estado: 'PE', cidade: 'BREJO DA MADRE DE DEUS' },
  { estado: 'MA', cidade: 'BREJO DE AREIA' },
  { estado: 'PB', cidade: 'BREJO DO CRUZ' },
  { estado: 'PI', cidade: 'BREJO DO PIAUI' },
  { estado: 'PB', cidade: 'BREJO DO SANTOS' },
  { estado: 'SE', cidade: 'BREJO GRANDE' },
  { estado: 'PA', cidade: 'BREJO GRANDE DO ARAGUAIA' },
  { estado: 'CE', cidade: 'BREJO SANTO' },
  { estado: 'BA', cidade: 'BREJOES' },
  { estado: 'BA', cidade: 'BREJOLANDIA' },
  { estado: 'PA', cidade: 'BREU BRANCO' },
  { estado: 'PA', cidade: 'BREVES' },
  { estado: 'GO', cidade: 'BRITANIA' },
  { estado: 'RS', cidade: 'BROCHIER' },
  { estado: 'SP', cidade: 'BRODOWSKI' },
  { estado: 'SP', cidade: 'BROTAS' },
  { estado: 'BA', cidade: 'BROTAS DE MACAUBAS' },
  { estado: 'MG', cidade: 'BRUMADINHO' },
  { estado: 'BA', cidade: 'BRUMADO' },
  { estado: 'SC', cidade: 'BRUNOPOLIS' },
  { estado: 'SC', cidade: 'BRUSQUE' },
  { estado: 'MG', cidade: 'BUENO BRANDAO' },
  { estado: 'MG', cidade: 'BUENOPOLIS' },
  { estado: 'PE', cidade: 'BUENOS AIRES' },
  { estado: 'BA', cidade: 'BUERAREMA' },
  { estado: 'MG', cidade: 'BUGRE' },
  { estado: 'PE', cidade: 'BUIQUE' },
  { estado: 'AC', cidade: 'BUJARI' },
  { estado: 'PA', cidade: 'BUJARU' },
  { estado: 'SP', cidade: 'BURI' },
  { estado: 'SP', cidade: 'BURITAMA' },
  { estado: 'MA', cidade: 'BURITI' },
  { estado: 'GO', cidade: 'BURITI ALEGRE' },
  { estado: 'MA', cidade: 'BURITI BRAVO' },
  { estado: 'GO', cidade: 'BURITI DE GOIAS' },
  { estado: 'PI', cidade: 'BURITI DO LOPES' },
  { estado: 'PI', cidade: 'BURITI DO MONTES' },
  { estado: 'TO', cidade: 'BURITI DO TOCANTINS' },
  { estado: 'MA', cidade: 'BURITICUPU' },
  { estado: 'GO', cidade: 'BURITINOPOLIS' },
  { estado: 'BA', cidade: 'BURITIRAMA' },
  { estado: 'MA', cidade: 'BURITIRANA' },
  { estado: 'MG', cidade: 'BURITIS' },
  { estado: 'RO', cidade: 'BURITIS' },
  { estado: 'SP', cidade: 'BURITIZAL' },
  { estado: 'MG', cidade: 'BURITIZEIRO' },
  { estado: 'RS', cidade: 'BUTIA' },
  { estado: 'AM', cidade: 'CAAPIRANGA' },
  { estado: 'PB', cidade: 'CAAPORA' },
  { estado: 'MS', cidade: 'CAARAPO' },
  { estado: 'BA', cidade: 'CAATIBA' },
  { estado: 'PB', cidade: 'CABACEIRAS' },
  { estado: 'BA', cidade: 'CABACEIRAS DO PARAGUACU' },
  { estado: 'MG', cidade: 'CABECEIRA GRANDE' },
  { estado: 'GO', cidade: 'CABECEIRAS' },
  { estado: 'PI', cidade: 'CABECEIRAS DO PIAUI' },
  { estado: 'PB', cidade: 'CABEDELO' },
  { estado: 'RO', cidade: 'CABIXI' },
  { estado: 'PE', cidade: 'CABO DE SANTO AGOSTINHO' },
  { estado: 'RJ', cidade: 'CABO FRIO' },
  { estado: 'MG', cidade: 'CABO VERDE' },
  { estado: 'SP', cidade: 'CABRALIA PAULISTA' },
  { estado: 'SP', cidade: 'CABREUVA' },
  { estado: 'PE', cidade: 'CABROBO' },
  { estado: 'SC', cidade: 'CACADOR' },
  { estado: 'SP', cidade: 'CACAPAVA' },
  { estado: 'RS', cidade: 'CACAPAVA DO SUL' },
  { estado: 'RO', cidade: 'CACAULANDIA' },
  { estado: 'RS', cidade: 'CACEQUI' },
  { estado: 'MT', cidade: 'CACERES' },
  { estado: 'BA', cidade: 'CACHOEIRA' },
  { estado: 'GO', cidade: 'CACHOEIRA ALTA' },
  { estado: 'MG', cidade: 'CACHOEIRA DA PRATA' },
  { estado: 'GO', cidade: 'CACHOEIRA DE GOIAS' },
  { estado: 'MG', cidade: 'CACHOEIRA DE MINAS' },
  { estado: 'MG', cidade: 'CACHOEIRA DE PAJEU' },
  { estado: 'PA', cidade: 'CACHOEIRA DO ARARI' },
  { estado: 'PB', cidade: 'CACHOEIRA DO INDIOS' },
  { estado: 'PA', cidade: 'CACHOEIRA DO PIRIA' },
  { estado: 'RS', cidade: 'CACHOEIRA DO SUL' },
  { estado: 'MG', cidade: 'CACHOEIRA DOURADA' },
  { estado: 'GO', cidade: 'CACHOEIRA DOURADA' },
  { estado: 'MA', cidade: 'CACHOEIRA GRANDE' },
  { estado: 'SP', cidade: 'CACHOEIRA PAULISTA' },
  { estado: 'RJ', cidade: 'CACHOEIRAS DE MACACU' },
  { estado: 'PE', cidade: 'CACHOEIRINHA' },
  { estado: 'TO', cidade: 'CACHOEIRINHA' },
  { estado: 'RS', cidade: 'CACHOEIRINHA' },
  { estado: 'ES', cidade: 'CACHOEIRO DE ITAPEMIRIM' },
  { estado: 'PB', cidade: 'CACIMBA DE AREIA' },
  { estado: 'PB', cidade: 'CACIMBA DE DENTRO' },
  { estado: 'PB', cidade: 'CACIMBAS' },
  { estado: 'Al', cidade: 'CACIMBINHAS' },
  { estado: 'RS', cidade: 'CACIQUE DOBLE' },
  { estado: 'RO', cidade: 'CACOAL' },
  { estado: 'SP', cidade: 'CACONDE' },
  { estado: 'GO', cidade: 'CACU' },
  { estado: 'BA', cidade: 'CACULE' },
  { estado: 'BA', cidade: 'CAEM' },
  { estado: 'MG', cidade: 'CAETANOPOLIS' },
  { estado: 'BA', cidade: 'CAETANOS' },
  { estado: 'MG', cidade: 'CAETE' },
  { estado: 'PE', cidade: 'CAETES' },
  { estado: 'BA', cidade: 'CAETITE' },
  { estado: 'BA', cidade: 'CAFARNAUM' },
  { estado: 'PR', cidade: 'CAFEARA' },
  { estado: 'SP', cidade: 'CAFELANDIA' },
  { estado: 'PR', cidade: 'CAFELANDIA' },
  { estado: 'PR', cidade: 'CAFEZAL DO SUL' },
  { estado: 'SP', cidade: 'CAIABU' },
  { estado: 'MG', cidade: 'CAIANA' },
  { estado: 'GO', cidade: 'CAIAPONIA' },
  { estado: 'RS', cidade: 'CAIBATE' },
  { estado: 'SC', cidade: 'CAIBI' },
  { estado: 'PB', cidade: 'CAICARA' },
  { estado: 'RS', cidade: 'CAICARA' },
  { estado: 'RN', cidade: 'CAICARA DO NORTE' },
  { estado: 'RN', cidade: 'CAICARA DO RIO DO VENTO' },
  { estado: 'RN', cidade: 'CAICO' },
  { estado: 'SP', cidade: 'CAIEIRAS' },
  { estado: 'BA', cidade: 'CAIRU' },
  { estado: 'SP', cidade: 'CAIUA' },
  { estado: 'SP', cidade: 'CAJAMAR' },
  { estado: 'MA', cidade: 'CAJAPIO' },
  { estado: 'MA', cidade: 'CAJARI' },
  { estado: 'SP', cidade: 'CAJATI' },
  { estado: 'PB', cidade: 'CAJAZEIRAS' },
  { estado: 'PI', cidade: 'CAJAZEIRAS DO PIAUI' },
  { estado: 'PB', cidade: 'CAJAZEIRINHAS' },
  { estado: 'SP', cidade: 'CAJOBI' },
  { estado: 'Al', cidade: 'CAJUEIRO' },
  { estado: 'PI', cidade: 'CAJUEIRO DA PRAIA' },
  { estado: 'MG', cidade: 'CAJURI' },
  { estado: 'SP', cidade: 'CAJURU' },
  { estado: 'PE', cidade: 'CALCADO' },
  { estado: 'AP', cidade: 'CALCOENE' },
  { estado: 'MG', cidade: 'CALDAS' },
  { estado: 'PB', cidade: 'CALDAS BRANDAO' },
  { estado: 'GO', cidade: 'CALDAS NOVAS' },
  { estado: 'GO', cidade: 'CALDAZINHA' },
  { estado: 'BA', cidade: 'CALDEIRAO GRANDE' },
  { estado: 'PI', cidade: 'CALDEIRAO GRANDE DO PIAUI' },
  { estado: 'PR', cidade: 'CALIFORNIA' },
  { estado: 'SC', cidade: 'CALMON' },
  { estado: 'PE', cidade: 'CALUMBI' },
  { estado: 'BA', cidade: 'CAMACAN' },
  { estado: 'BA', cidade: 'CAMACARI' },
  { estado: 'MG', cidade: 'CAMACHO' },
  { estado: 'PB', cidade: 'CAMALAU' },
  { estado: 'BA', cidade: 'CAMAMU' },
  { estado: 'MG', cidade: 'CAMANDUCAIA' },
  { estado: 'MS', cidade: 'CAMAPUA' },
  { estado: 'RS', cidade: 'CAMAQUA' },
  { estado: 'PE', cidade: 'CAMARAGIBE' },
  { estado: 'RS', cidade: 'CAMARGO' },
  { estado: 'PR', cidade: 'CAMBARA' },
  { estado: 'RS', cidade: 'CAMBARA DO SUL' },
  { estado: 'PR', cidade: 'CAMBE' },
  { estado: 'PR', cidade: 'CAMBIRA' },
  { estado: 'SC', cidade: 'CAMBORIU' },
  { estado: 'RJ', cidade: 'CAMBUCI' },
  { estado: 'MG', cidade: 'CAMBUI' },
  { estado: 'MG', cidade: 'CAMBUQUIRA' },
  { estado: 'PA', cidade: 'CAMETA' },
  { estado: 'CE', cidade: 'CAMOCIM' },
  { estado: 'PE', cidade: 'CAMOCIM DE SAO FELIX' },
  { estado: 'MG', cidade: 'CAMPANARIO' },
  { estado: 'MG', cidade: 'CAMPANHA' },
  { estado: 'MG', cidade: 'CAMPESTRE' },
  { estado: 'Al', cidade: 'CAMPESTRE' },
  { estado: 'RS', cidade: 'CAMPESTRE DA SERRA' },
  { estado: 'GO', cidade: 'CAMPESTRE DE GOIAS' },
  { estado: 'MA', cidade: 'CAMPESTRE DO MARANHAO' },
  { estado: 'PR', cidade: 'CAMPINA DA LAGOA' },
  { estado: 'RS', cidade: 'CAMPINA DAS MISSOES' },
  { estado: 'SP', cidade: 'CAMPINA DO MONTE ALEGRE' },
  { estado: 'PR', cidade: 'CAMPINA DO SIMAO' },
  { estado: 'PB', cidade: 'CAMPINA GRANDE' },
  { estado: 'PR', cidade: 'CAMPINA GRANDE DO SUL' },
  { estado: 'MG', cidade: 'CAMPINA VERDE' },
  { estado: 'GO', cidade: 'CAMPINACU' },
  { estado: 'MT', cidade: 'CAMPINAPOLIS' },
  { estado: 'SP', cidade: 'CAMPINAS' },
  { estado: 'PI', cidade: 'CAMPINAS DO PIAUI' },
  { estado: 'RS', cidade: 'CAMPINAS DO SUL' },
  { estado: 'GO', cidade: 'CAMPINORTE' },
  { estado: 'Al', cidade: 'CAMPO ALEGRE' },
  { estado: 'SC', cidade: 'CAMPO ALEGRE' },
  { estado: 'GO', cidade: 'CAMPO ALEGRE DE GOIAS' },
  { estado: 'BA', cidade: 'CAMPO ALEGRE DE LOURDES' },
  { estado: 'PI', cidade: 'CAMPO ALEGRE DO FIDALGO' },
  { estado: 'MG', cidade: 'CAMPO AZUL' },
  { estado: 'MG', cidade: 'CAMPO BELO' },
  { estado: 'SC', cidade: 'CAMPO BELO DO SUL' },
  { estado: 'RS', cidade: 'CAMPO BOM' },
  { estado: 'PR', cidade: 'CAMPO BONITO' },
  { estado: 'PB', cidade: 'CAMPO DE SANTANA' },
  { estado: 'SE', cidade: 'CAMPO DO BRITO' },
  { estado: 'MG', cidade: 'CAMPO DO MEIO' },
  { estado: 'PR', cidade: 'CAMPO DO TENENTE' },
  { estado: 'SC', cidade: 'CAMPO ERE' },
  { estado: 'MG', cidade: 'CAMPO FLORIDO' },
  { estado: 'BA', cidade: 'CAMPO FORMOSO' },
  { estado: 'Al', cidade: 'CAMPO GRANDE' },
  { estado: 'MS', cidade: 'CAMPO GRANDE' },
  { estado: 'PI', cidade: 'CAMPO GRANDE DO PIAUI' },
  { estado: 'PR', cidade: 'CAMPO LARGO' },
  { estado: 'PI', cidade: 'CAMPO LARGO DO PIAUI' },
  { estado: 'GO', cidade: 'CAMPO LIMPO DE GOIAS' },
  { estado: 'SP', cidade: 'CAMPO LIMPO PAULISTA' },
  { estado: 'PR', cidade: 'CAMPO MAGRO' },
  { estado: 'PI', cidade: 'CAMPO MAIOR' },
  { estado: 'PR', cidade: 'CAMPO MOURAO' },
  { estado: 'RS', cidade: 'CAMPO NOVO' },
  { estado: 'RO', cidade: 'CAMPO NOVO DE RONDONIA' },
  { estado: 'MT', cidade: 'CAMPO NOVO DO PARECIS' },
  { estado: 'RN', cidade: 'CAMPO REDONDO' },
  { estado: 'MT', cidade: 'CAMPO VERDE' },
  { estado: 'MG', cidade: 'CAMPOS ALTOS' },
  { estado: 'GO', cidade: 'CAMPOS BELOS' },
  { estado: 'RS', cidade: 'CAMPOS BORGES' },
  { estado: 'MT', cidade: 'CAMPOS DE JULIO' },
  { estado: 'RJ', cidade: 'CAMPOS DO GOYTACAZES' },
  { estado: 'SP', cidade: 'CAMPOS DO JORDAO' },
  { estado: 'MG', cidade: 'CAMPOS GERAIS' },
  { estado: 'TO', cidade: 'CAMPOS LINDOS' },
  { estado: 'SC', cidade: 'CAMPOS NOVOS' },
  { estado: 'SP', cidade: 'CAMPOS NOVOS PAULISTA' },
  { estado: 'CE', cidade: 'CAMPOS SALES' },
  { estado: 'GO', cidade: 'CAMPOS VERDES' },
  { estado: 'PE', cidade: 'CAMUTANGA' },
  { estado: 'MG', cidade: 'CANA VERDE' },
  { estado: 'MG', cidade: 'CANAA' },
  { estado: 'PA', cidade: 'CANAA DO CARAJAS' },
  { estado: 'MT', cidade: 'CANABRAVA DO NORTE' },
  { estado: 'SP', cidade: 'CANANEIA' },
  { estado: 'Al', cidade: 'CANAPI' },
  { estado: 'MG', cidade: 'CANAPOLIS' },
  { estado: 'BA', cidade: 'CANAPOLIS' },
  { estado: 'BA', cidade: 'CANARANA' },
  { estado: 'MT', cidade: 'CANARANA' },
  { estado: 'SP', cidade: 'CANAS' },
  { estado: 'PI', cidade: 'CANAVIEIRA' },
  { estado: 'BA', cidade: 'CANAVIEIRAS' },
  { estado: 'BA', cidade: 'CANDEAL' },
  { estado: 'MG', cidade: 'CANDEIAS' },
  { estado: 'BA', cidade: 'CANDEIAS' },
  { estado: 'RO', cidade: 'CANDEIAS DO JAMARI' },
  { estado: 'RS', cidade: 'CANDELARIA' },
  { estado: 'BA', cidade: 'CANDIBA' },
  { estado: 'PR', cidade: 'CANDIDO DE ABREU' },
  { estado: 'RS', cidade: 'CANDIDO GODOI' },
  { estado: 'MA', cidade: 'CANDIDO MENDES' },
  { estado: 'SP', cidade: 'CANDIDO MOTA' },
  { estado: 'SP', cidade: 'CANDIDO RODRIGUES' },
  { estado: 'BA', cidade: 'CANDIDO SALES' },
  { estado: 'RS', cidade: 'CANDIOTA' },
  { estado: 'PR', cidade: 'CANDOI' },
  { estado: 'RS', cidade: 'CANELA' },
  { estado: 'SC', cidade: 'CANELINHA' },
  { estado: 'RN', cidade: 'CANGUARETAMA' },
  { estado: 'RS', cidade: 'CANGUCU' },
  { estado: 'SE', cidade: 'CANHOBA' },
  { estado: 'PE', cidade: 'CANHOTINHO' },
  { estado: 'CE', cidade: 'CANINDE' },
  { estado: 'SE', cidade: 'CANINDE DE SAO FRANCISCO' },
  { estado: 'SP', cidade: 'CANITAR' },
  { estado: 'RS', cidade: 'CANOAS' },
  { estado: 'SC', cidade: 'CANOINHAS' },
  { estado: 'BA', cidade: 'CANSANCAO' },
  { estado: 'RR', cidade: 'CANTA' },
  { estado: 'RJ', cidade: 'CANTAGALO' },
  { estado: 'MG', cidade: 'CANTAGALO' },
  { estado: 'PR', cidade: 'CANTAGALO' },
  { estado: 'MA', cidade: 'CANTANHEDE' },
  { estado: 'PI', cidade: 'CANTO DO BURITI' },
  { estado: 'BA', cidade: 'CANUDOS' },
  { estado: 'RS', cidade: 'CANUDOS DO VALE' },
  { estado: 'AM', cidade: 'CANUTAMA' },
  { estado: 'PA', cidade: 'CAPANEMA' },
  { estado: 'PR', cidade: 'CAPANEMA' },
  { estado: 'SC', cidade: 'CAPAO ALTO' },
  { estado: 'SP', cidade: 'CAPAO BONITO' },
  { estado: 'RS', cidade: 'CAPAO BONITO DO SUL' },
  { estado: 'RS', cidade: 'CAPAO DA CANOA' },
  { estado: 'RS', cidade: 'CAPAO DO CIPO' },
  { estado: 'RS', cidade: 'CAPAO DO LEAO' },
  { estado: 'MG', cidade: 'CAPARAO' },
  { estado: 'SE', cidade: 'CAPELA' },
  { estado: 'Al', cidade: 'CAPELA' },
  { estado: 'RS', cidade: 'CAPELA DE SANTANA' },
  { estado: 'SP', cidade: 'CAPELA DO ALTO' },
  { estado: 'BA', cidade: 'CAPELA DO ALTO ALEGRE' },
  { estado: 'MG', cidade: 'CAPELA NOVA' },
  { estado: 'MG', cidade: 'CAPELINHA' },
  { estado: 'MG', cidade: 'CAPETINGA' },
  { estado: 'PB', cidade: 'CAPIM' },
  { estado: 'MG', cidade: 'CAPIM BRANCO' },
  { estado: 'BA', cidade: 'CAPIM GROSSO' },
  { estado: 'MG', cidade: 'CAPINOPOLIS' },
  { estado: 'SC', cidade: 'CAPINZAL' },
  { estado: 'MA', cidade: 'CAPINZAL DO NORTE' },
  { estado: 'CE', cidade: 'CAPISTRANO' },
  { estado: 'RS', cidade: 'CAPITAO' },
  { estado: 'MG', cidade: 'CAPITAO ANDRADE' },
  { estado: 'PI', cidade: 'CAPITAO DE CAMPOS' },
  { estado: 'MG', cidade: 'CAPITAO ENEAS' },
  { estado: 'PI', cidade: 'CAPITAO GERVASIO OLIVEIRA' },
  { estado: 'PR', cidade: 'CAPITAO LEONIDAS MARQUES' },
  { estado: 'PA', cidade: 'CAPITAO POCO' },
  { estado: 'MG', cidade: 'CAPITOLIO' },
  { estado: 'SP', cidade: 'CAPIVARI' },
  { estado: 'SC', cidade: 'CAPIVARI DE BAIXO' },
  { estado: 'RS', cidade: 'CAPIVARI DO SUL' },
  { estado: 'AC', cidade: 'CAPIXABA' },
  { estado: 'PE', cidade: 'CAPOEIRAS' },
  { estado: 'MG', cidade: 'CAPUTIRA' },
  { estado: 'RS', cidade: 'CARAA' },
  { estado: 'RR', cidade: 'CARACARAI' },
  { estado: 'PI', cidade: 'CARACOL' },
  { estado: 'MS', cidade: 'CARACOL' },
  { estado: 'SP', cidade: 'CARAGUATATUBA' },
  { estado: 'MG', cidade: 'CARAI' },
  { estado: 'BA', cidade: 'CARAIBAS' },
  { estado: 'PR', cidade: 'CARAMBEI' },
  { estado: 'MG', cidade: 'CARANAIBA' },
  { estado: 'MG', cidade: 'CARANDAI' },
  { estado: 'MG', cidade: 'CARANGOLA' },
  { estado: 'RJ', cidade: 'CARAPEBUS' },
  { estado: 'SP', cidade: 'CARAPICUIBA' },
  { estado: 'MG', cidade: 'CARATINGA' },
  { estado: 'AM', cidade: 'CARAUARI' },
  { estado: 'PB', cidade: 'CARAUBAS' },
  { estado: 'RN', cidade: 'CARAUBAS' },
  { estado: 'PI', cidade: 'CARAUBAS DO PIAUI' },
  { estado: 'BA', cidade: 'CARAVELAS' },
  { estado: 'RS', cidade: 'CARAZINHO' },
  { estado: 'MG', cidade: 'CARBONITA' },
  { estado: 'BA', cidade: 'CARDEAL DA SILVA' },
  { estado: 'SP', cidade: 'CARDOSO' },
  { estado: 'RJ', cidade: 'CARDOSO MOREIRA' },
  { estado: 'MG', cidade: 'CAREACU' },
  { estado: 'AM', cidade: 'CAREIRO' },
  { estado: 'AM', cidade: 'CAREIRO DA VARZEA' },
  { estado: 'ES', cidade: 'CARIACICA' },
  { estado: 'CE', cidade: 'CARIDADE' },
  { estado: 'PI', cidade: 'CARIDADE DO PIAUI' },
  { estado: 'BA', cidade: 'CARINHANHA' },
  { estado: 'SE', cidade: 'CARIRA' },
  { estado: 'CE', cidade: 'CARIRE' },
  { estado: 'TO', cidade: 'CARIRI DO TOCANTINS' },
  { estado: 'CE', cidade: 'CARIRIACU' },
  { estado: 'CE', cidade: 'CARIUS' },
  { estado: 'MT', cidade: 'CARLINDA' },
  { estado: 'PR', cidade: 'CARLOPOLIS' },
  { estado: 'RS', cidade: 'CARLOS BARBOSA' },
  { estado: 'MG', cidade: 'CARLOS CHAGAS' },
  { estado: 'RS', cidade: 'CARLOS GOMES' },
  { estado: 'MG', cidade: 'CARMESIA' },
  { estado: 'RJ', cidade: 'CARMO' },
  { estado: 'MG', cidade: 'CARMO DA CACHOEIRA' },
  { estado: 'MG', cidade: 'CARMO DA MATA' },
  { estado: 'MG', cidade: 'CARMO DE MINAS' },
  { estado: 'MG', cidade: 'CARMO DO CAJURU' },
  { estado: 'MG', cidade: 'CARMO DO PARANAIBA' },
  { estado: 'MG', cidade: 'CARMO DO RIO CLARO' },
  { estado: 'GO', cidade: 'CARMO DO RIO VERDE' },
  { estado: 'TO', cidade: 'CARMOLANDIA' },
  { estado: 'SE', cidade: 'CARMOPOLIS' },
  { estado: 'MG', cidade: 'CARMOPOLIS DE MINAS' },
  { estado: 'PE', cidade: 'CARNAIBA' },
  { estado: 'RN', cidade: 'CARNAUBA DO DANTAS' },
  { estado: 'RN', cidade: 'CARNAUBAIS' },
  { estado: 'CE', cidade: 'CARNAUBAL' },
  { estado: 'PE', cidade: 'CARNAUBEIRA DA PENHA' },
  { estado: 'MG', cidade: 'CARNEIRINHO' },
  { estado: 'Al', cidade: 'CARNEIROS' },
  { estado: 'RR', cidade: 'CAROEBE' },
  { estado: 'MA', cidade: 'CAROLINA' },
  { estado: 'PE', cidade: 'CARPINA' },
  { estado: 'MG', cidade: 'CARRANCAS' },
  { estado: 'PB', cidade: 'CARRAPATEIRA' },
  { estado: 'TO', cidade: 'CARRASCO BONITO' },
  { estado: 'PE', cidade: 'CARUARU' },
  { estado: 'MA', cidade: 'CARUTAPERA' },
  { estado: 'MG', cidade: 'CARVALHOPOLIS' },
  { estado: 'MG', cidade: 'CARVALHOS' },
  { estado: 'SP', cidade: 'CASA BRANCA' },
  { estado: 'MG', cidade: 'CASA GRANDE' },
  { estado: 'BA', cidade: 'CASA NOVA' },
  { estado: 'RS', cidade: 'CASCA' },
  { estado: 'MG', cidade: 'CASCALHO RICO' },
  { estado: 'CE', cidade: 'CASCAVEL' },
  { estado: 'PR', cidade: 'CASCAVEL' },
  { estado: 'TO', cidade: 'CASEARA' },
  { estado: 'RS', cidade: 'CASEIROS' },
  { estado: 'RJ', cidade: 'CASIMIRO DE ABREU' },
  { estado: 'PE', cidade: 'CASINHAS' },
  { estado: 'PB', cidade: 'CASSERENGUE' },
  { estado: 'MG', cidade: 'CASSIA' },
  { estado: 'SP', cidade: 'CASSIA DO COQUEIROS' },
  { estado: 'MS', cidade: 'CASSILANDIA' },
  { estado: 'PA', cidade: 'CASTANHAL' },
  { estado: 'MT', cidade: 'CASTANHEIRA' },
  { estado: 'RO', cidade: 'CASTANHEIRAS' },
  { estado: 'GO', cidade: 'CASTELANDIA' },
  { estado: 'ES', cidade: 'CASTELO' },
  { estado: 'PI', cidade: 'CASTELO DO PIAUI' },
  { estado: 'SP', cidade: 'CASTILHO' },
  { estado: 'PR', cidade: 'CASTRO' },
  { estado: 'BA', cidade: 'CASTRO ALVES' },
  { estado: 'MG', cidade: 'CATAGUASES' },
  { estado: 'GO', cidade: 'CATALAO' },
  { estado: 'SP', cidade: 'CATANDUVA' },
  { estado: 'PR', cidade: 'CATANDUVAS' },
  { estado: 'SC', cidade: 'CATANDUVAS' },
  { estado: 'CE', cidade: 'CATARINA' },
  { estado: 'MG', cidade: 'CATAS ALTAS' },
  { estado: 'MG', cidade: 'CATAS ALTAS DA NORUEGA' },
  { estado: 'PE', cidade: 'CATENDE' },
  { estado: 'SP', cidade: 'CATIGUA' },
  { estado: 'PB', cidade: 'CATINGUEIRA' },
  { estado: 'BA', cidade: 'CATOLANDIA' },
  { estado: 'PB', cidade: 'CATOLE DO ROCHA' },
  { estado: 'BA', cidade: 'CATU' },
  { estado: 'RS', cidade: 'CATUIPE' },
  { estado: 'MG', cidade: 'CATUJI' },
  { estado: 'CE', cidade: 'CATUNDA' },
  { estado: 'GO', cidade: 'CATURAI' },
  { estado: 'BA', cidade: 'CATURAMA' },
  { estado: 'PB', cidade: 'CATURITE' },
  { estado: 'MG', cidade: 'CATUTI' },
  { estado: 'CE', cidade: 'CAUCAIA' },
  { estado: 'GO', cidade: 'CAVALCANTE' },
  { estado: 'MG', cidade: 'CAXAMBU' },
  { estado: 'SC', cidade: 'CAXAMBU DO SUL' },
  { estado: 'MA', cidade: 'CAXIAS' },
  { estado: 'RS', cidade: 'CAXIAS DO SUL' },
  { estado: 'PI', cidade: 'CAXINGO' },
  { estado: 'RN', cidade: 'CEARA-MIRIM' },
  { estado: 'SP', cidade: 'CEDRAL' },
  { estado: 'MA', cidade: 'CEDRAL' },
  { estado: 'PE', cidade: 'CEDRO' },
  { estado: 'CE', cidade: 'CEDRO' },
  { estado: 'SE', cidade: 'CEDRO DE SAO JOAO' },
  { estado: 'MG', cidade: 'CEDRO DO ABAETE' },
  { estado: 'SC', cidade: 'CELSO RAMOS' },
  { estado: 'TO', cidade: 'CENTENARIO' },
  { estado: 'RS', cidade: 'CENTENARIO' },
  { estado: 'PR', cidade: 'CENTENARIO DO SUL' },
  { estado: 'BA', cidade: 'CENTRAL' },
  { estado: 'MG', cidade: 'CENTRAL DE MINAS' },
  { estado: 'MA', cidade: 'CENTRAL DO MARANHAO' },
  { estado: 'MG', cidade: 'CENTRALINA' },
  { estado: 'MA', cidade: 'CENTRO DO GUILHERME' },
  { estado: 'MA', cidade: 'CENTRO NOVO DO MARANHAO' },
  { estado: 'RO', cidade: 'CEREJEIRAS' },
  { estado: 'GO', cidade: 'CERES' },
  { estado: 'SP', cidade: 'CERQUEIRA CESAR' },
  { estado: 'SP', cidade: 'CERQUILHO' },
  { estado: 'RS', cidade: 'CERRITO' },
  { estado: 'PR', cidade: 'CERRO AZUL' },
  { estado: 'RS', cidade: 'CERRO BRANCO' },
  { estado: 'RN', cidade: 'CERRO CORA' },
  { estado: 'RS', cidade: 'CERRO GRANDE' },
  { estado: 'RS', cidade: 'CERRO GRANDE DO SUL' },
  { estado: 'RS', cidade: 'CERRO LARGO' },
  { estado: 'SC', cidade: 'CERRO NEGRO' },
  { estado: 'SP', cidade: 'CESARIO LANGE' },
  { estado: 'PR', cidade: 'CEU AZUL' },
  { estado: 'GO', cidade: 'CEZARINA' },
  { estado: 'PE', cidade: 'CHA DE ALEGRIA' },
  { estado: 'PE', cidade: 'CHA GRANDE' },
  { estado: 'Al', cidade: 'CHA PRETA' },
  { estado: 'MG', cidade: 'CHACARA' },
  { estado: 'MG', cidade: 'CHALE' },
  { estado: 'RS', cidade: 'CHAPADA' },
  { estado: 'TO', cidade: 'CHAPADA DA NATIVIDADE' },
  { estado: 'TO', cidade: 'CHAPADA DE AREIA' },
  { estado: 'MT', cidade: 'CHAPADA DO GUIMARAES' },
  { estado: 'MG', cidade: 'CHAPADA DO NORTE' },
  { estado: 'MG', cidade: 'CHAPADA GAUCHA' },
  { estado: 'GO', cidade: 'CHAPADAO DO CEU' },
  { estado: 'SC', cidade: 'CHAPADAO DO LAGEADO' },
  { estado: 'MS', cidade: 'CHAPADAO DO SUL' },
  { estado: 'MA', cidade: 'CHAPADINHA' },
  { estado: 'SC', cidade: 'CHAPECO' },
  { estado: 'SP', cidade: 'CHARQUEADA' },
  { estado: 'RS', cidade: 'CHARQUEADAS' },
  { estado: 'RS', cidade: 'CHARRUA' },
  { estado: 'CE', cidade: 'CHAVAL' },
  { estado: 'SP', cidade: 'CHAVANTES' },
  { estado: 'PA', cidade: 'CHAVES' },
  { estado: 'MG', cidade: 'CHIADOR' },
  { estado: 'RS', cidade: 'CHIAPETA' },
  { estado: 'PR', cidade: 'CHOPINZINHO' },
  { estado: 'CE', cidade: 'CHORO' },
  { estado: 'CE', cidade: 'CHOROZINHO' },
  { estado: 'BA', cidade: 'CHORROCHO' },
  { estado: 'RS', cidade: 'CHUI' },
  { estado: 'RO', cidade: 'CHUPINGUAIA' },
  { estado: 'RS', cidade: 'CHUVISCA' },
  { estado: 'PR', cidade: 'CIANORTE' },
  { estado: 'BA', cidade: 'CICERO DANTAS' },
  { estado: 'PR', cidade: ',CIDADE GAUCHA' },
  { estado: 'GO', cidade: ',CIDADE OCIDENTAL' },
  { estado: 'MA', cidade: 'CIDELANDIA' },
  { estado: 'RS', cidade: 'CIDREIRA' },
  { estado: 'BA', cidade: 'CIPO' },
  { estado: 'MG', cidade: 'CIPOTANEA' },
  { estado: 'RS', cidade: 'CIRIACO' },
  { estado: 'MG', cidade: 'CLARAVAL' },
  { estado: 'MG', cidade: 'CLARO DO POCOES' },
  { estado: 'MT', cidade: 'CLAUDIA' },
  { estado: 'MG', cidade: 'CLAUDIO' },
  { estado: 'SP', cidade: 'CLEMENTINA' },
  { estado: 'PR', cidade: 'CLEVELANDIA' },
  { estado: 'BA', cidade: 'COARACI' },
  { estado: 'AM', cidade: 'COARI' },
  { estado: 'PI', cidade: 'COCAL' },
  { estado: 'PI', cidade: 'COCAL DE TELHA' },
  { estado: 'PI', cidade: 'COCAL DO ALVES' },
  { estado: 'SC', cidade: 'COCAL DO SUL' },
  { estado: 'MT', cidade: 'COCALINHO' },
  { estado: 'GO', cidade: 'COCALZINHO DE GOIAS' },
  { estado: 'BA', cidade: 'COCOS' },
  { estado: 'AM', cidade: 'CODAJAS' },
  { estado: 'MA', cidade: 'CODO' },
  { estado: 'MA', cidade: 'COELHO NETO' },
  { estado: 'MG', cidade: 'COIMBRA, MG' },
  { estado: 'Al', cidade: 'COITE DO NOIA' },
  { estado: 'PI', cidade: 'COIVARAS' },
  { estado: 'PA', cidade: 'COLARES' },
  { estado: 'ES', cidade: 'COLATINA' },
  { estado: 'MT', cidade: 'COLIDER' },
  { estado: 'SP', cidade: 'COLINA' },
  { estado: 'MA', cidade: 'COLINAS' },
  { estado: 'RS', cidade: 'COLINAS' },
  { estado: 'GO', cidade: 'COLINAS DO SUL' },
  { estado: 'TO', cidade: 'COLINAS DO TOCANTINS' },
  { estado: 'TO', cidade: 'COLMEIA' },
  { estado: 'MT', cidade: 'COLNIZA' },
  { estado: 'SP', cidade: 'COLOMBIA' },
  { estado: 'PR', cidade: 'COLOMBO' },
  { estado: 'PI', cidade: 'COLONIA DO GURGUEIA' },
  { estado: 'PI', cidade: 'COLONIA DO PIAUI' },
  { estado: 'Al', cidade: 'COLONIA LEOPOLDINA' },
  { estado: 'PR', cidade: 'COLORADO' },
  { estado: 'RS', cidade: 'COLORADO' },
  { estado: 'RO', cidade: 'COLORADO DO OESTE' },
  { estado: 'MG', cidade: 'COLUNA' },
  { estado: 'TO', cidade: 'COMBINADO' },
  { estado: 'MG', cidade: 'COMENDADOR GOMES' },
  { estado: 'RJ', cidade: 'COMENDADOR LEVY GASPARIAN' },
  { estado: 'MG', cidade: 'COMERCINHO' },
  { estado: 'MT', cidade: 'COMODORO' },
  { estado: 'PB', cidade: 'CONCEICAO' },
  { estado: 'MG', cidade: 'CONCEICAO DA APARECIDA' },
  { estado: 'ES', cidade: 'CONCEICAO DA BARRA' },
  { estado: 'MG', cidade: 'CONCEICAO DA BARRA DE MINAS' },
  { estado: 'BA', cidade: 'CONCEICAO DA FEIRA' },
  { estado: 'MG', cidade: 'CONCEICAO DAS ALAGOAS' },
  { estado: 'MG', cidade: 'CONCEICAO DAS PEDRAS' },
  { estado: 'MG', cidade: 'CONCEICAO DE IPANEMA' },
  { estado: 'RJ', cidade: 'CONCEICAO DE MACABU' },
  { estado: 'BA', cidade: 'CONCEICAO DO ALMEIDA' },
  { estado: 'PA', cidade: 'CONCEICAO DO ARAGUAIA' },
  { estado: 'PI', cidade: 'CONCEICAO DO CANINDE' },
  { estado: 'ES', cidade: 'CONCEICAO DO CASTELO' },
  { estado: 'BA', cidade: 'CONCEICAO DO COITE' },
  { estado: 'BA', cidade: 'CONCEICAO DO JACUIPE' },
  { estado: 'MA', cidade: 'CONCEICAO DO LAGO-ACU' },
  { estado: 'MG', cidade: 'CONCEICAO DO MATO DENTRO' },
  { estado: 'MG', cidade: 'CONCEICAO DO OUROS' },
  { estado: 'MG', cidade: 'CONCEICAO DO PARA' },
  { estado: 'MG', cidade: 'CONCEICAO DO RIO VERDE' },
  { estado: 'TO', cidade: 'CONCEICAO DO TOCANTINS' },
  { estado: 'SP', cidade: 'CONCHAL' },
  { estado: 'SP', cidade: 'CONCHAS' },
  { estado: 'SC', cidade: 'CONCORDIA' },
  { estado: 'PA', cidade: 'CONCORDIA DO PARA' },
  { estado: 'PE', cidade: 'CONDADO' },
  { estado: 'PB', cidade: 'CONDADO' },
  { estado: 'BA', cidade: 'CONDE' },
  { estado: 'PB', cidade: 'CONDE' },
  { estado: 'BA', cidade: 'CONDEUBA' },
  { estado: 'RS', cidade: 'CONDOR' },
  { estado: 'MG', cidade: 'CONEGO MARINHO' },
  { estado: 'MG', cidade: 'CONFINS' },
  { estado: 'MT', cidade: 'CONFRESA' },
  { estado: 'PB', cidade: 'CONGO' },
  { estado: 'MG', cidade: 'CONGONHAL' },
  { estado: 'MG', cidade: 'CONGONHAS' },
  { estado: 'MG', cidade: 'CONGONHAS DO NORTE' },
  { estado: 'PR', cidade: 'CONGONHINHAS' },
  { estado: 'MG', cidade: 'CONQUISTA' },
  { estado: 'MT', cidade: 'CONQUISTA D´OESTE' },
  { estado: 'MG', cidade: 'CONSELHEIRO LAFAIETE' },
  { estado: 'PR', cidade: 'CONSELHEIRO MAIRINCK' },
  { estado: 'MG', cidade: 'CONSELHEIRO PENA' },
  { estado: 'MG', cidade: 'CONSOLACAO' },
  { estado: 'RS', cidade: 'CONSTANTINA' },
  { estado: 'MG', cidade: 'CONTAGEM' },
  { estado: 'PR', cidade: 'CONTENDA' },
  { estado: 'BA', cidade: 'CONTENDAS DO SINCORA' },
  { estado: 'MG', cidade: 'COQUEIRAL' },
  { estado: 'RS', cidade: 'COQUEIRO BAIXO' },
  { estado: 'Al', cidade: 'COQUEIRO SECO' },
  { estado: 'RS', cidade: 'COQUEIROS DO SUL' },
  { estado: 'MG', cidade: 'CORACAO DE JESUS' },
  { estado: 'BA', cidade: 'CORACAO DE MARIA' },
  { estado: 'PR', cidade: 'CORBELIA' },
  { estado: 'RJ', cidade: 'CORDEIRO' },
  { estado: 'SP', cidade: 'CORDEIROPOLIS' },
  { estado: 'BA', cidade: 'CORDEIROS' },
  { estado: 'SC', cidade: 'CORDILHEIRA ALTA' },
  { estado: 'MG', cidade: 'CORDISBURGO' },
  { estado: 'MG', cidade: 'CORDISLANDIA' },
  { estado: 'CE', cidade: 'COREAU' },
  { estado: 'PB', cidade: 'COREMAS' },
  { estado: 'MS', cidade: 'CORGUINHO' },
  { estado: 'BA', cidade: 'CORIBE' },
  { estado: 'MG', cidade: 'CORINTO, MG' },
  { estado: 'PR', cidade: 'CORNELIO PROCOPIO' },
  { estado: 'MG', cidade: 'COROACI' },
  { estado: 'SP', cidade: 'COROADOS' },
  { estado: 'MA', cidade: 'COROATA' },
  { estado: 'MG', cidade: 'COROMANDEL' },
  { estado: 'RS', cidade: 'CORONEL BARROS' },
  { estado: 'RS', cidade: 'CORONEL BICACO' },
  { estado: 'PR', cidade: 'CORONEL DOMINGOS SOARES' },
  { estado: 'RN', cidade: 'CORONEL EZEQUIEL' },
  { estado: 'MG', cidade: 'CORONEL FABRICIANO' },
  { estado: 'SC', cidade: 'CORONEL FREITAS' },
  { estado: 'RN', cidade: 'CORONEL JOAO PESSOA' },
  { estado: 'BA', cidade: 'CORONEL JOAO SA' },
  { estado: 'PI', cidade: 'CORONEL JOSE DIAS' },
  { estado: 'SP', cidade: 'CORONEL MACEDO' },
  { estado: 'SC', cidade: 'CORONEL MARTINS' },
  { estado: 'MG', cidade: 'CORONEL MURTA' },
  { estado: 'MG', cidade: 'CORONEL PACHECO' },
  { estado: 'RS', cidade: 'CORONEL PILAR' },
  { estado: 'MS', cidade: 'CORONEL SAPUCAIA' },
  { estado: 'PR', cidade: 'CORONEL VIVIDA' },
  { estado: 'MG', cidade: 'CORONEL XAVIER CHAVES' },
  { estado: 'MG', cidade: 'CORREGO DANTA' },
  { estado: 'MG', cidade: 'CORREGO DO BOM JESUS' },
  { estado: 'GO', cidade: 'CORREGO DO OURO' },
  { estado: 'MG', cidade: 'CORREGO FUNDO' },
  { estado: 'MG', cidade: 'CORREGO NOVO' },
  { estado: 'SC', cidade: 'CORREIA PINTO' },
  { estado: 'PI', cidade: 'CORRENTE' },
  { estado: 'PE', cidade: 'CORRENTES' },
  { estado: 'BA', cidade: 'CORRENTINA' },
  { estado: 'PE', cidade: 'CORTES' },
  { estado: 'MS', cidade: 'CORUMBA' },
  { estado: 'GO', cidade: 'CORUMBA DE GOIAS' },
  { estado: 'GO', cidade: 'CORUMBAIBA' },
  { estado: 'SP', cidade: 'CORUMBATAI' },
  { estado: 'PR', cidade: 'CORUMBATAI DO SUL' },
  { estado: 'RO', cidade: 'CORUMBIARA' },
  { estado: 'SC', cidade: 'CORUPA' },
  { estado: 'Al', cidade: 'CORURIPE' },
  { estado: 'SP', cidade: 'COSMOPOLIS' },
  { estado: 'SP', cidade: 'COSMORAMA' },
  { estado: 'RO', cidade: 'COSTA MARQUES' },
  { estado: 'MS', cidade: 'COSTA RICA' },
  { estado: 'BA', cidade: 'COTEGIPE' },
  { estado: 'SP', cidade: 'COTIA' },
  { estado: 'RS', cidade: 'COTIPORA' },
  { estado: 'MT', cidade: 'COTRIGUACU' },
  { estado: 'TO', cidade: 'COUTO DE MAGALHAES' },
  { estado: 'MG', cidade: 'COUTO DE MAGALHAES DE MINAS' },
  { estado: 'RS', cidade: 'COXILHA' },
  { estado: 'MS', cidade: 'COXIM' },
  { estado: 'PB', cidade: 'COXIXOLA' },
  { estado: 'Al', cidade: 'CRAIBAS' },
  { estado: 'CE', cidade: 'CRATEUS' },
  { estado: 'CE', cidade: 'CRATO' },
  { estado: 'SP', cidade: 'CRAVINHOS' },
  { estado: 'BA', cidade: 'CRAVOLANDIA' },
  { estado: 'SC', cidade: 'CRICIUMA' },
  { estado: 'MG', cidade: 'CRISOLITA' },
  { estado: 'BA', cidade: 'CRISOPOLIS' },
  { estado: 'RS', cidade: 'CRISSIUMAL' },
  { estado: 'MG', cidade: 'CRISTAIS' },
  { estado: 'SP', cidade: 'CRISTAIS PAULISTA' },
  { estado: 'RS', cidade: 'CRISTAL' },
  { estado: 'RS', cidade: 'CRISTAL DO SUL' },
  { estado: 'TO', cidade: 'CRISTALANDIA' },
  { estado: 'PI', cidade: 'CRISTALANDIA DO PIAUI' },
  { estado: 'MG', cidade: 'CRISTALIA' },
  { estado: 'GO', cidade: 'CRISTALINA' },
  { estado: 'MG', cidade: 'CRISTIANO OTONI' },
  { estado: 'GO', cidade: 'CRISTIANOPOLIS' },
  { estado: 'MG', cidade: 'CRISTINA' },
  { estado: 'SE', cidade: 'CRISTINAPOLIS' },
  { estado: 'PI', cidade: 'CRISTINO CASTRO' },
  { estado: 'BA', cidade: 'CRISTOPOLIS' },
  { estado: 'GO', cidade: 'CRIXAS' },
  { estado: 'TO', cidade: 'CRIXAS DO TOCANTINS' },
  { estado: 'CE', cidade: 'CROATA' },
  { estado: 'GO', cidade: 'CROMINIA' },
  { estado: 'MG', cidade: 'CRUCILANDIA' },
  { estado: 'CE', cidade: 'CRUZ' },
  { estado: 'RS', cidade: 'CRUZ ALTA' },
  { estado: 'BA', cidade: 'CRUZ DAS ALMAS' },
  { estado: 'PB', cidade: 'CRUZ DO ESPIRITO SANTO' },
  { estado: 'PR', cidade: 'CRUZ MACHADO' },
  { estado: 'SP', cidade: 'CRUZALIA' },
  { estado: 'RS', cidade: 'CRUZALTENSE' },
  { estado: 'SP', cidade: 'CRUZEIRO' },
  { estado: 'MG', cidade: 'CRUZEIRO DA FORTALEZA' },
  { estado: 'PR', cidade: 'CRUZEIRO DO IGUACU' },
  { estado: 'PR', cidade: 'CRUZEIRO DO OESTE' },
  { estado: 'AC', cidade: 'CRUZEIRO DO SUL' },
  { estado: 'PR', cidade: 'CRUZEIRO DO SUL' },
  { estado: 'RS', cidade: 'CRUZEIRO DO SUL' },
  { estado: 'RN', cidade: 'CRUZETA' },
  { estado: 'MG', cidade: 'CRUZILIA' },
  { estado: 'PR', cidade: 'CRUZMALTINA' },
  { estado: 'SP', cidade: 'CUBATAO' },
  { estado: 'PB', cidade: 'CUBATI' },
  { estado: 'MT', cidade: 'CUIABA' },
  { estado: 'PB', cidade: 'CUITE' },
  { estado: 'PB', cidade: 'CUITE DE MAMANGUAPE' },
  { estado: 'PB', cidade: 'CUITEGI' },
  { estado: 'RO', cidade: 'CUJUBIM' },
  { estado: 'GO', cidade: 'CUMARI' },
  { estado: 'PE', cidade: 'CUMARU' },
  { estado: 'PA', cidade: 'CUMARU DO NORTE' },
  { estado: 'SE', cidade: 'CUMBE' },
  { estado: 'SP', cidade: 'CUNHA' },
  { estado: 'SC', cidade: 'CUNHA PORA' },
  { estado: 'SC', cidade: 'CUNHATAI' },
  { estado: 'MG', cidade: 'CUPARAQUE' },
  { estado: 'PE', cidade: 'CUPIRA' },
  { estado: 'BA', cidade: 'CURACA' },
  { estado: 'PI', cidade: 'CURIMATA' },
  { estado: 'PA', cidade: 'CURIONOPOLIS' },
  { estado: 'PR', cidade: 'CURITIBA' },
  { estado: 'SC', cidade: 'CURITIBANOS' },
  { estado: 'PR', cidade: 'CURIUVA' },
  { estado: 'PI', cidade: 'CURRAIS' },
  { estado: 'RN', cidade: 'CURRAIS NOVOS' },
  { estado: 'PB', cidade: 'CURRAL DE CIMA' },
  { estado: 'MG', cidade: 'CURRAL DE DENTRO' },
  { estado: 'PI', cidade: 'CURRAL NOVO DO PIAUI' },
  { estado: 'PB', cidade: 'CURRAL VELHO' },
  { estado: 'PA', cidade: 'CURRALINHO' },
  { estado: 'PI', cidade: 'CURRALINHOS' },
  { estado: 'PA', cidade: 'CURUA' },
  { estado: 'PA', cidade: 'CURUCA' },
  { estado: 'MA', cidade: 'CURURUPU' },
  { estado: 'MG', cidade: 'CURVELO' },
  { estado: 'PE', cidade: 'CUSTODIA' },
  { estado: 'AP', cidade: 'CUTIAS' },
  { estado: 'MT', cidade: 'CUVERLANDIA' },
  { estado: 'GO', cidade: 'DAMIANOPOLIS' },
  { estado: 'PB', cidade: 'DAMIAO' },
  { estado: 'GO', cidade: 'DAMOLANDIA' },
  { estado: 'TO', cidade: 'DARCINOPOLIS' },
  { estado: 'BA', cidade: 'DARIO MEIRA' },
  { estado: 'MG', cidade: 'DATAS' },
  { estado: 'RS', cidade: 'DAVID CANABARRO' },
  { estado: 'MA', cidade: 'DAVINOPOLIS' },
  { estado: 'GO', cidade: 'DAVINOPOLIS' },
  { estado: 'MG', cidade: 'DELFIM MOREIRA' },
  { estado: 'MG', cidade: 'DELFINOPOLIS' },
  { estado: 'Al', cidade: 'DELMIRO GOUVEIA' },
  { estado: 'MG', cidade: 'DELTA' },
  { estado: 'PI', cidade: 'DEMERVAL LOBAO' },
  { estado: 'MT', cidade: 'DENISE' },
  { estado: 'MS', cidade: 'DEODAPOLIS' },
  { estado: 'CE', cidade: 'DEPUTADO IRAPUAN PINHEIRO' },
  { estado: 'RS', cidade: 'DERRUBADAS' },
  { estado: 'SP', cidade: 'DESCALVADO' },
  { estado: 'SC', cidade: 'DESCANSO' },
  { estado: 'MG', cidade: 'DESCOBERTO' },
  { estado: 'PB', cidade: 'DESTERRO' },
  { estado: 'MG', cidade: 'DESTERRO DE ENTRE RIOS' },
  { estado: 'MG', cidade: 'DESTERRO DO MELO' },
  { estado: 'RS', cidade: 'DEZESSEIS DE NOVEMBRO' },
  { estado: 'SP', cidade: 'DIADEMA' },
  { estado: 'PB', cidade: 'DIAMANTE' },
  { estado: 'PR', cidade: 'DIAMANTE D´OESTE' },
  { estado: 'PR', cidade: 'DIAMANTE DO NORTE' },
  { estado: 'PR', cidade: 'DIAMANTE DO SUL' },
  { estado: 'MG', cidade: 'DIAMANTINA' },
  { estado: 'MT', cidade: 'DIAMANTINO' },
  { estado: 'TO', cidade: 'DIANOPOLIS' },
  { estado: 'BA', cidade: 'DIAS D´AVILA' },
  { estado: 'RS', cidade: 'DILERMANDO DE AGUIAR' },
  { estado: 'MG', cidade: 'DIOGO DE VASCONCELOS' },
  { estado: 'MG', cidade: 'DIONISIO' },
  { estado: 'SC', cidade: 'DIONISIO CERQUEIRA' },
  { estado: 'GO', cidade: 'DIORAMA' },
  { estado: 'SP', cidade: 'DIRCE REIS' },
  { estado: 'PI', cidade: 'DIRCEU ARCOVERDE' },
  { estado: 'SE', cidade: 'DIVINA PASTORA' },
  { estado: 'MG', cidade: 'DIVINESIA' },
  { estado: 'MG', cidade: 'DIVINO' },
  { estado: 'MG', cidade: 'DIVINO DAS LARANJEIRAS' },
  { estado: 'ES', cidade: 'DIVINO DE SAO LOURENCO' },
  { estado: 'SP', cidade: 'DIVINOLANDIA' },
  { estado: 'MG', cidade: 'DIVINOLANDIA DE MINAS' },
  { estado: 'MG', cidade: 'DIVINOPOLIS' },
  { estado: 'GO', cidade: 'DIVINOPOLIS DE GOIAS' },
  { estado: 'TO', cidade: 'DIVINOPOLIS DO TOCANTINS' },
  { estado: 'MG', cidade: 'DIVISA ALEGRE' },
  { estado: 'MG', cidade: 'DIVISA NOVA' },
  { estado: 'MG', cidade: 'DIVISOPOLIS' },
  { estado: 'SP', cidade: 'DOBRADA' },
  { estado: 'SP', cidade: 'DOIS CORREGOS' },
  { estado: 'RS', cidade: 'DOIS IRMAOS' },
  { estado: 'RS', cidade: 'DOIS IRMAOS DAS MISSOES' },
  { estado: 'MS', cidade: 'DOIS IRMAOS DO BURITI' },
  { estado: 'TO', cidade: 'DOIS IRMAOS DO TOCANTINS' },
  { estado: 'RS', cidade: 'DOIS LAJEADOS' },
  { estado: 'Al', cidade: 'DOIS RIACHOS' },
  { estado: 'PR', cidade: 'DOIS VIZINHOS' },
  { estado: 'SP', cidade: 'DOLCINOPOLIS' },
  { estado: 'MT', cidade: 'DOM AQUINO' },
  { estado: 'BA', cidade: 'DOM BASILIO' },
  { estado: 'MG', cidade: 'DOM BOSCO' },
  { estado: 'MG', cidade: 'DOM CAVATI' },
  { estado: 'PA', cidade: 'DOM ELISEU' },
  { estado: 'PI', cidade: 'DOM EXPEDITO LOPES' },
  { estado: 'RS', cidade: 'DOM FELICIANO' },
  { estado: 'PI', cidade: 'DOM INOCENCIO' },
  { estado: 'MG', cidade: 'DOM JOAQUIM' },
  { estado: 'BA', cidade: 'DOM MACEDO COSTA' },
  { estado: 'RS', cidade: 'DOM PEDRITO' },
  { estado: 'MA', cidade: 'DOM PEDRO' },
  { estado: 'RS', cidade: 'DOM PEDRO DE ALCANTARA' },
  { estado: 'MG', cidade: 'DOM SILVERIO' },
  { estado: 'MG', cidade: 'DOM VICOSO' },
  { estado: 'ES', cidade: 'DOMINGOS MARTINS' },
  { estado: 'PI', cidade: 'DOMINGOS MOURAO' },
  { estado: 'SC', cidade: 'DONA EMMA' },
  { estado: 'MG', cidade: 'DONA EUSEBIA' },
  { estado: 'RS', cidade: 'DONA FRANCISCA' },
  { estado: 'PB', cidade: 'DONA INES' },
  { estado: 'MG', cidade: 'DORES DE CAMPOS' },
  { estado: 'MG', cidade: 'DORES DE GUANHAES' },
  { estado: 'MG', cidade: 'DORES DO INDAIA' },
  { estado: 'ES', cidade: 'DORES DO RIO PRETO' },
  { estado: 'MG', cidade: 'DORES DO TURVO' },
  { estado: 'MG', cidade: 'DORESOPOLIS' },
  { estado: 'PE', cidade: 'DORMENTES' },
  { estado: 'MS', cidade: 'DOURADINA' },
  { estado: 'PR', cidade: 'DOURADINA' },
  { estado: 'SP', cidade: 'DOURADO' },
  { estado: 'MG', cidade: 'DOURADOQUARA' },
  { estado: 'MS', cidade: 'DOURADOS' },
  { estado: 'PR', cidade: 'DOUTOR CAMARGO' },
  { estado: 'RS', cidade: 'DOUTOR MAURICIO CARDOSO' },
  { estado: 'SC', cidade: 'DOUTOR PEDRINHO' },
  { estado: 'RS', cidade: 'DOUTOR RICARDO' },
  { estado: 'RN', cidade: 'DOUTOR SEVERIANO' },
  { estado: 'PR', cidade: 'DOUTOR ULYSSES' },
  { estado: 'GO', cidade: 'DOVERLANDIA' },
  { estado: 'SP', cidade: 'DRACENA' },
  { estado: 'SP', cidade: 'DUARTINA' },
  { estado: 'RJ', cidade: 'DUAS BARRAS' },
  { estado: 'PB', cidade: 'DUAS ESTRADAS' },
  { estado: 'TO', cidade: 'DUERE' },
  { estado: 'SP', cidade: 'DUMONT' },
  { estado: 'MA', cidade: 'DUQUE BACELAR' },
  { estado: 'RJ', cidade: 'DUQUE DE CAXIAS' },
  { estado: 'MG', cidade: 'DURANDE' },
  { estado: 'SP', cidade: 'ECHAPORA' },
  { estado: 'ES', cidade: 'ECOPORANGA' },
  { estado: 'GO', cidade: 'EDEALINA' },
  { estado: 'GO', cidade: 'EDEIA' },
  { estado: 'AM', cidade: 'EIRUNEPE' },
  { estado: 'SP', cidade: 'ELDORADO' },
  { estado: 'MS', cidade: 'ELDORADO' },
  { estado: 'PA', cidade: 'ELDORADO DO CARAJAS' },
  { estado: 'RS', cidade: 'ELDORADO DO SUL' },
  { estado: 'PI', cidade: 'ELESBAO VELOSO' },
  { estado: 'SP', cidade: 'ELIAS FAUSTO' },
  { estado: 'PI', cidade: 'ELISEU MARTINS' },
  { estado: 'SP', cidade: 'ELISIARIO' },
  { estado: 'BA', cidade: 'ELISIO MEDRADO' },
  { estado: 'MG', cidade: 'ELOI MENDES' },
  { estado: 'PB', cidade: 'EMAS' },
  { estado: 'SP', cidade: 'EMBAUBA' },
  { estado: 'SP', cidade: 'EMBU' },
  { estado: 'SP', cidade: 'EMBU-GUACU' },
  { estado: 'SP', cidade: 'EMILIANOPOLIS' },
  { estado: 'RS', cidade: 'ENCANTADO' },
  { estado: 'RN', cidade: 'ENCANTO' },
  { estado: 'BA', cidade: 'ENCRUZILHADA' },
  { estado: 'RS', cidade: 'ENCRUZILHADA DO SUL' },
  { estado: 'PR', cidade: 'ENEAS MARQUES' },
  { estado: 'PR', cidade: 'ENGENHEIRO BELTRAO' },
  { estado: 'MG', cidade: 'ENGENHEIRO CALDAS' },
  { estado: 'SP', cidade: 'ENGENHEIRO COELHO' },
  { estado: 'MG', cidade: 'ENGENHEIRO NAVARRO' },
  { estado: 'RJ', cidade: 'ENGENHEIRO PAULO DE FRONTIN' },
  { estado: 'RS', cidade: 'ENGENHO VELHO' },
  { estado: 'MG', cidade: 'ENTRE FOLHAS' },
  { estado: 'BA', cidade: 'ENTRE RIOS' },
  { estado: 'SC', cidade: 'ENTRE RIOS' },
  { estado: 'MG', cidade: 'ENTRE RIOS DE MINAS' },
  { estado: 'PR', cidade: 'ENTRE RIOS DO OESTE' },
  { estado: 'RS', cidade: 'ENTRE RIOS DO SUL' },
  { estado: 'RS', cidade: 'ENTRE-IJUIS' },
  { estado: 'AM', cidade: 'ENVIRA' },
  { estado: 'AC', cidade: 'EPITACIOLANDIA' },
  { estado: 'RN', cidade: 'EQUADOR' },
  { estado: 'RS', cidade: 'EREBANGO' },
  { estado: 'RS', cidade: 'ERECHIM' },
  { estado: 'CE', cidade: 'ERERE' },
  { estado: 'BA', cidade: 'ERICO CARDOSO' },
  { estado: 'SC', cidade: 'ERMO' },
  { estado: 'RS', cidade: 'ERNESTINA' },
  { estado: 'RS', cidade: 'ERVAL GRANDE' },
  { estado: 'RS', cidade: 'ERVAL SECO' },
  { estado: 'SC', cidade: 'ERVAL VELHO' },
  { estado: 'MG', cidade: 'ERVALIA' },
  { estado: 'PE', cidade: 'ESCADA' },
  { estado: 'RS', cidade: 'ESMERALDA' },
  { estado: 'MG', cidade: 'ESMERALDAS' },
  { estado: 'MG', cidade: 'ESPERA FELIZ' },
  { estado: 'PB', cidade: 'ESPERANCA' },
  { estado: 'RS', cidade: 'ESPERANCA DO SUL' },
  { estado: 'PR', cidade: 'ESPERANCA NOVA' },
  { estado: 'PI', cidade: 'ESPERANTINA' },
  { estado: 'TO', cidade: 'ESPERANTINA' },
  { estado: 'MA', cidade: 'ESPERANTINOPOLIS' },
  { estado: 'PR', cidade: 'ESPIGAO ALTO DO IGUACU' },
  { estado: 'RO', cidade: 'ESPIGAO D´OESTE' },
  { estado: 'MG', cidade: 'ESPINOSA' },
  { estado: 'RN', cidade: 'ESPIRITO SANTO' },
  { estado: 'MG', cidade: 'ESPIRITO SANTO DO DOURADO' },
  { estado: 'SP', cidade: 'ESPIRITO SANTO DO PINHAL' },
  { estado: 'SP', cidade: 'ESPIRITO SANTO DO TURVO' },
  { estado: 'BA', cidade: 'ESPLANADA' },
  { estado: 'RS', cidade: 'ESPUMOSO' },
  { estado: 'RS', cidade: 'ESTACAO' },
  { estado: 'SE', cidade: 'ESTANCIA' },
  { estado: 'RS', cidade: 'ESTANCIA VELHA' },
  { estado: 'RS', cidade: 'ESTEIO' },
  { estado: 'MG', cidade: 'ESTIVA' },
  { estado: 'SP', cidade: 'ESTIVA GERBI' },
  { estado: 'MA', cidade: 'ESTREITO' },
  { estado: 'RS', cidade: 'ESTRELA' },
  { estado: 'SP', cidade: 'ESTRELA D´OESTE' },
  { estado: 'MG', cidade: 'ESTRELA DALVA' },
  { estado: 'Al', cidade: 'ESTRELA DE ALAGOAS' },
  { estado: 'MG', cidade: 'ESTRELA DO INDAIA' },
  { estado: 'SP', cidade: 'ESTRELA DO NORTE' },
  { estado: 'GO', cidade: 'ESTRELA DO NORTE' },
  { estado: 'MG', cidade: 'ESTRELA DO SUL' },
  { estado: 'RS', cidade: 'ESTRELA VELHA' },
  { estado: 'BA', cidade: 'EUCLIDES DA CUNHA' },
  { estado: 'SP', cidade: 'EUCLIDES DA CUNHA PAULISTA' },
  { estado: 'RS', cidade: 'EUGENIO DE CASTRO' },
  { estado: 'MG', cidade: 'EUGENOPOLIS' },
  { estado: 'BA', cidade: 'EUNAPOLIS' },
  { estado: 'CE', cidade: 'EUSEBIO' },
  { estado: 'MG', cidade: 'EWBANK DA CAMARA' },
  { estado: 'MG', cidade: 'EXTREMA' },
  { estado: 'RN', cidade: 'EXTREMOZ' },
  { estado: 'PE', cidade: 'EXU' },
  { estado: 'PB', cidade: 'FAGUNDES' },
  { estado: 'RS', cidade: 'FAGUNDES VARELA' },
  { estado: 'GO', cidade: 'FAINA' },
  { estado: 'MG', cidade: 'FAMA' },
  { estado: 'MG', cidade: 'FARIA LEMOS' },
  { estado: 'CE', cidade: 'FARIAS BRITO' },
  { estado: 'PA', cidade: 'FARO' },
  { estado: 'PR', cidade: 'FAROL' },
  { estado: 'RS', cidade: 'FARROUPILHA' },
  { estado: 'SP', cidade: 'FARTURA' },
  { estado: 'PI', cidade: 'FARTURA DO PIAUI' },
  { estado: 'BA', cidade: 'FATIMA' },
  { estado: 'TO', cidade: 'FATIMA' },
  { estado: 'MS', cidade: 'FATIMA DO SUL' },
  { estado: 'PR', cidade: 'FAXINAL' },
  { estado: 'SC', cidade: 'FAXINAL DO GUEDES' },
  { estado: 'RS', cidade: 'FAXINAL DO SOTURNO' },
  { estado: 'RS', cidade: 'FAXINALZINHO' },
  { estado: 'GO', cidade: 'FAZENDA NOVA' },
  { estado: 'PR', cidade: 'FAZENDA RIO GRANDE' },
  { estado: 'RS', cidade: 'FAZENDA VILANOVA' },
  { estado: 'AC', cidade: 'FEIJO' },
  { estado: 'BA', cidade: 'FEIRA DA MATA' },
  { estado: 'BA', cidade: 'FEIRA DE SANTANA' },
  { estado: 'Al', cidade: 'FEIRA GRANDE' },
  { estado: 'SE', cidade: 'FEIRA NOVA' },
  { estado: 'PE', cidade: 'FEIRA NOVA' },
  { estado: 'MA', cidade: 'FEIRA NOVA DO MARANHAO' },
  { estado: 'MG', cidade: 'FELICIO DO SANTOS' },
  { estado: 'RN', cidade: 'FELIPE GUERRA' },
  { estado: 'MG', cidade: 'FELISBURGO' },
  { estado: 'MG', cidade: 'FELIXLANDIA' },
  { estado: 'RS', cidade: 'FELIZ' },
  { estado: 'Al', cidade: 'FELIZ DESERTO' },
  { estado: 'MT', cidade: 'FELIZ NATAL' },
  { estado: 'PR', cidade: 'FENIX' },
  { estado: 'PR', cidade: 'FERNANDES PINHEIRO' },
  { estado: 'MG', cidade: 'FERNANDES TOURINHO' },
  { estado: 'PE', cidade: 'FERNANDO DE NORONHA' },
  { estado: 'MA', cidade: 'FERNANDO FALCAO' },
  { estado: 'RN', cidade: 'FERNANDO PEDROZA' },
  { estado: 'SP', cidade: 'FERNANDO PRESTES' },
  { estado: 'SP', cidade: 'FERNANDOPOLIS' },
  { estado: 'SP', cidade: 'FERNAO' },
  { estado: 'SP', cidade: 'FERRAZ DE VASCONCELOS' },
  { estado: 'AP', cidade: 'FERREIRA GOMES' },
  { estado: 'PE', cidade: 'FERREIROS' },
  { estado: 'MG', cidade: 'FERROS' },
  { estado: 'MG', cidade: 'FERVEDOURO' },
  { estado: 'PR', cidade: 'FIGUEIRA' },
  { estado: 'MS', cidade: 'FIGUEIRAO' },
  { estado: 'TO', cidade: 'FIGUEIROPOLIS' },
  { estado: 'MT', cidade: 'FIGUEIROPOLIS D´OESTE' },
  { estado: 'BA', cidade: 'FILADELFIA' },
  { estado: 'TO', cidade: 'FILADELFIA' },
  { estado: 'BA', cidade: 'FIRMINO ALVES' },
  { estado: 'GO', cidade: 'FIRMINOPOLIS' },
  { estado: 'Al', cidade: 'FLEXEIRAS' },
  { estado: 'PR', cidade: 'FLOR DA SERRA DO SUL' },
  { estado: 'SC', cidade: 'FLOR DO SERTAO' },
  { estado: 'SP', cidade: 'FLORA RICA' },
  { estado: 'PR', cidade: 'FLORAI' },
  { estado: 'RN', cidade: 'FLORANIA' },
  { estado: 'SP', cidade: 'FLOREAL' },
  { estado: 'PE', cidade: 'FLORES' },
  { estado: 'RS', cidade: 'FLORES DA CUNHA' },
  { estado: 'GO', cidade: 'FLORES DE GOIAS' },
  { estado: 'PI', cidade: 'FLORES DO PIAUI' },
  { estado: 'PE', cidade: 'FLORESTA' },
  { estado: 'PR', cidade: 'FLORESTA' },
  { estado: 'BA', cidade: 'FLORESTA AZUL' },
  { estado: 'PA', cidade: 'FLORESTA DO ARAGUAIA' },
  { estado: 'PI', cidade: 'FLORESTA DO PIAUI' },
  { estado: 'MG', cidade: 'FLORESTAL' },
  { estado: 'PR', cidade: 'FLORESTOPOLIS' },
  { estado: 'PI', cidade: 'FLORIANO' },
  { estado: 'RS', cidade: 'FLORIANO PEIXOTO' },
  { estado: 'SC', cidade: 'FLORIANOPOLIS' },
  { estado: 'PR', cidade: 'FLORIDA' },
  { estado: 'SP', cidade: 'FLORIDA PAULISTA' },
  { estado: 'SP', cidade: 'FLORINIA' },
  { estado: 'AM', cidade: 'FONTE BOA' },
  { estado: 'RS', cidade: 'FONTOURA XAVIER' },
  { estado: 'MG', cidade: 'FORMIGA' },
  { estado: 'RS', cidade: 'FORMIGUEIRO' },
  { estado: 'GO', cidade: 'FORMOSA' },
  { estado: 'MA', cidade: 'FORMOSA DA SERRA NEGRA' },
  { estado: 'PR', cidade: 'FORMOSA DO OESTE' },
  { estado: 'BA', cidade: 'FORMOSA DO RIO PRETO' },
  { estado: 'SC', cidade: 'FORMOSA DO SUL' },
  { estado: 'MG', cidade: 'FORMOSO' },
  { estado: 'GO', cidade: 'FORMOSO' },
  { estado: 'TO', cidade: 'FORMOSO DO ARAGUAIA' },
  { estado: 'RS', cidade: 'FORQUETINHA' },
  { estado: 'CE', cidade: 'FORQUILHA' },
  { estado: 'SC', cidade: 'FORQUILHINHA' },
  { estado: 'CE', cidade: 'FORTALEZA' },
  { estado: 'MG', cidade: 'FORTALEZA DE MINAS' },
  { estado: 'MA', cidade: 'FORTALEZA DO NOGUEIRAS' },
  { estado: 'TO', cidade: 'FORTALEZA DO TABOCAO' },
  { estado: 'RS', cidade: 'FORTALEZA DO VALOS' },
  { estado: 'CE', cidade: 'FORTIM' },
  { estado: 'MA', cidade: 'FORTUNA' },
  { estado: 'MG', cidade: 'FORTUNA DE MINAS' },
  { estado: 'PR', cidade: 'FOZ DO IGUACU' },
  { estado: 'PR', cidade: 'FOZ DO JORDAO' },
  { estado: 'SC', cidade: 'FRAIBURGO' },
  { estado: 'SP', cidade: 'FRANCA' },
  { estado: 'PI', cidade: 'FRANCINOPOLIS' },
  { estado: 'PR', cidade: 'FRANCISCO ALVES' },
  { estado: 'PI', cidade: 'FRANCISCO AYRES' },
  { estado: 'MG', cidade: 'FRANCISCO BADARO' },
  { estado: 'PR', cidade: 'FRANCISCO BELTRAO' },
  { estado: 'RN', cidade: 'FRANCISCO DANTAS' },
  { estado: 'MG', cidade: 'FRANCISCO DUMONT' },
  { estado: 'PI', cidade: 'FRANCISCO MACEDO' },
  { estado: 'SP', cidade: 'FRANCISCO MORATO' },
  { estado: 'MG', cidade: 'FRANCISCO SA' },
  { estado: 'PI', cidade: 'FRANCISCO SANTOS' },
  { estado: 'MG', cidade: 'FRANCISCOPOLIS' },
  { estado: 'SP', cidade: 'FRANCO DA ROCHA' },
  { estado: 'CE', cidade: 'FRECHEIRINHA' },
  { estado: 'RS', cidade: 'FREDERICO WESTPHALEN' },
  { estado: 'MG', cidade: 'FREI GASPAR' },
  { estado: 'MG', cidade: 'FREI INOCENCIO' },
  { estado: 'MG', cidade: 'FREI LAGONEGRO' },
  { estado: 'PB', cidade: 'FREI MARTINHO' },
  { estado: 'PE', cidade: 'FREI MIGUELINHO' },
  { estado: 'SE', cidade: 'FREI PAULO' },
  { estado: 'SC', cidade: 'FREI ROGERIO' },
  { estado: 'MG', cidade: 'FRONTEIRA' },
  { estado: 'MG', cidade: 'FRONTEIRA DO VALES' },
  { estado: 'PI', cidade: 'FRONTEIRAS' },
  { estado: 'MG', cidade: 'FRUTA DE LEITE' },
  { estado: 'MG', cidade: 'FRUTAL' },
  { estado: 'RN', cidade: 'FRUTUOSO GOMES' },
  { estado: 'ES', cidade: 'FUNDAO' },
  { estado: 'MG', cidade: 'FUNILANDIA' },
  { estado: 'SP', cidade: 'GABRIEL MONTEIRO' },
  { estado: 'PB', cidade: 'GADO BRAVO' },
  { estado: 'SP', cidade: 'GALIA' },
  { estado: 'MG', cidade: 'GALILEIA' },
  { estado: 'RN', cidade: 'GALINHOS' },
  { estado: 'SC', cidade: 'GALVAO' },
  { estado: 'PE', cidade: 'GAMELEIRA' },
  { estado: 'GO', cidade: 'GAMELEIRA DE GOIAS' },
  { estado: 'MG', cidade: 'GAMELEIRAS' },
  { estado: 'BA', cidade: 'GANDU' },
  { estado: 'PE', cidade: 'GARANHUNS' },
  { estado: 'SE', cidade: 'GARARU' },
  { estado: 'SP', cidade: 'GARCA' },
  { estado: 'RS', cidade: 'GARIBALDI' },
  { estado: 'SC', cidade: 'GAROPABA' },
  { estado: 'PA', cidade: 'GARRAFAO DO NORTE' },
  { estado: 'RS', cidade: 'GARRUCHOS' },
  { estado: 'SC', cidade: 'GARUVA' },
  { estado: 'SC', cidade: 'GASPAR' },
  { estado: 'SP', cidade: 'GASTAO VIDIGAL' },
  { estado: 'MT', cidade: 'GAUCHA DO NORTE' },
  { estado: 'RS', cidade: 'GAURAMA' },
  { estado: 'BA', cidade: 'GAVIAO' },
  { estado: 'SP', cidade: 'GAVIAO PEIXOTO' },
  { estado: 'PI', cidade: 'GEMINIANO' },
  { estado: 'RS', cidade: 'GENERAL CAMARA' },
  { estado: 'MT', cidade: 'GENERAL CARNEIRO' },
  { estado: 'PR', cidade: 'GENERAL CARNEIRO' },
  { estado: 'SE', cidade: 'GENERAL MAYNARD' },
  { estado: 'SP', cidade: 'GENERAL SALGADO' },
  { estado: 'CE', cidade: 'GENERAL SAMPAIO' },
  { estado: 'RS', cidade: 'GENTIL' },
  { estado: 'BA', cidade: 'GENTIO DO OURO' },
  { estado: 'SP', cidade: 'GETULINA' },
  { estado: 'RS', cidade: 'GETULIO VARGAS' },
  { estado: 'PI', cidade: 'GILBUES' },
  { estado: 'Al', cidade: 'GIRAU DO PONCIANO' },
  { estado: 'RS', cidade: 'GIRUA' },
  { estado: 'MG', cidade: 'GLAUCILANDIA' },
  { estado: 'SP', cidade: 'GLICERIO' },
  { estado: 'BA', cidade: 'GLORIA' },
  { estado: 'MT', cidade: 'GLORIA D´OESTE' },
  { estado: 'MS', cidade: 'GLORIA DE DOURADOS' },
  { estado: 'PE', cidade: 'GLORIA DO GOITA' },
  { estado: 'RS', cidade: 'GLORINHA' },
  { estado: 'MA', cidade: 'GODOFREDO VIANA' },
  { estado: 'PR', cidade: 'GODOY MOREIRA' },
  { estado: 'MG', cidade: 'GOIABEIRA' },
  { estado: 'MG', cidade: 'GOIANA' },
  { estado: 'PE', cidade: 'GOIANA' },
  { estado: 'GO', cidade: 'GOIANAPOLIS' },
  { estado: 'GO', cidade: 'GOIANDIRA' },
  { estado: 'GO', cidade: 'GOIANESIA' },
  { estado: 'PA', cidade: 'GOIANESIA DO PARA' },
  { estado: 'GO', cidade: 'GOIANIA' },
  { estado: 'RN', cidade: 'GOIANINHA' },
  { estado: 'GO', cidade: 'GOIANIRA' },
  { estado: 'TO', cidade: 'GOIANORTE' },
  { estado: 'GO', cidade: 'GOIAS' },
  { estado: 'TO', cidade: 'GOIATINS' },
  { estado: 'GO', cidade: 'GOIATUBA' },
  { estado: 'PR', cidade: 'GOIOERE' },
  { estado: 'PR', cidade: 'GOIOXIM' },
  { estado: 'MG', cidade: 'GONCALVES' },
  { estado: 'MA', cidade: 'GONCALVES DIAS' },
  { estado: 'BA', cidade: 'GONGOGI' },
  { estado: 'MG', cidade: 'GONZAGA' },
  { estado: 'MG', cidade: 'GOUVEIA' },
  { estado: 'GO', cidade: 'GOUVELANDIA' },
  { estado: 'MA', cidade: 'GOVERNADOR ARCHER' },
  { estado: 'SC', cidade: 'GOVERNADOR CELSO RAMOS' },
  { estado: 'RN', cidade: 'GOVERNADOR DIX-SEPT ROSADO' },
  { estado: 'MA', cidade: 'GOVERNADOR EDISON LOBAO' },
  { estado: 'MA', cidade: 'GOVERNADOR EUGENIO BARROS' },
  { estado: 'RO', cidade: 'GOVERNADOR JORGE TEIXEIRA' },
  { estado: 'ES', cidade: 'GOVERNADOR LINDENBERG' },
  { estado: 'BA', cidade: 'GOVERNADOR LOMANTO JUNIOR' },
  { estado: 'MA', cidade: 'GOVERNADOR LUIZ ROCHA' },
  { estado: 'BA', cidade: 'GOVERNADOR MANGABEIRA' },
  { estado: 'MA', cidade: 'GOVERNADOR NEWTON BELLO' },
  { estado: 'MA', cidade: 'GOVERNADOR NUNES FREIRE' },
  { estado: 'MG', cidade: 'GOVERNADOR VALADARES' },
  { estado: 'CE', cidade: 'GRACA' },
  { estado: 'MA', cidade: 'GRACA ARANHA' },
  { estado: 'SE', cidade: 'GRACHO CARDOSO' },
  { estado: 'MA', cidade: 'GRAJAU' },
  { estado: 'RS', cidade: 'GRAMADO' },
  { estado: 'RS', cidade: 'GRAMADO DO LOUREIROS' },
  { estado: 'RS', cidade: 'GRAMADO XAVIER' },
  { estado: 'PR', cidade: 'GRANDES RIOS' },
  { estado: 'PE', cidade: 'GRANITO' },
  { estado: 'CE', cidade: 'GRANJA' },
  { estado: 'CE', cidade: 'GRANJEIRO' },
  { estado: 'MG', cidade: 'GRAO MOGOL' },
  { estado: 'SC', cidade: 'GRAO PARA' },
  { estado: 'PE', cidade: 'GRAVATA' },
  { estado: 'RS', cidade: 'GRAVATAI' },
  { estado: 'SC', cidade: 'GRAVATAL' },
  { estado: 'CE', cidade: 'GROAIRAS' },
  { estado: 'RN', cidade: 'GROSSOS' },
  { estado: 'MG', cidade: 'GRUPIARA' },
  { estado: 'RS', cidade: 'GUABIJU' },
  { estado: 'SC', cidade: 'GUABIRUBA' },
  { estado: 'ES', cidade: 'GUACUI' },
  { estado: 'PI', cidade: 'GUADALUPE' },
  { estado: 'RS', cidade: 'GUAIBA' },
  { estado: 'SP', cidade: 'GUAICARA' },
  { estado: 'SP', cidade: 'GUAIMBE' },
  { estado: 'SP', cidade: 'GUAIRA' },
  { estado: 'PR', cidade: 'GUAIRA' },
  { estado: 'PR', cidade: 'GUAIRACA' },
  { estado: 'CE', cidade: 'GUAIUBA' },
  { estado: 'AM', cidade: 'GUAJARA' },
  { estado: 'RO', cidade: 'GUAJARA-MIRIM' },
  { estado: 'BA', cidade: 'GUAJERU' },
  { estado: 'RN', cidade: 'GUAMARE' },
  { estado: 'PR', cidade: 'GUAMIRANGA' },
  { estado: 'BA', cidade: 'GUANAMBI' },
  { estado: 'MG', cidade: 'GUANHAES' },
  { estado: 'MG', cidade: 'GUAPE' },
  { estado: 'SP', cidade: 'GUAPIACU' },
  { estado: 'SP', cidade: 'GUAPIARA' },
  { estado: 'RJ', cidade: 'GUAPIMIRIM' },
  { estado: 'PR', cidade: 'GUAPIRAMA' },
  { estado: 'GO', cidade: 'GUAPO' },
  { estado: 'RS', cidade: 'GUAPORE' },
  { estado: 'PR', cidade: 'GUAPOREMA' },
  { estado: 'SP', cidade: 'GUARA' },
  { estado: 'PB', cidade: 'GUARABIRA' },
  { estado: 'SP', cidade: 'GUARACAI' },
  { estado: 'SP', cidade: 'GUARACI' },
  { estado: 'PR', cidade: 'GUARACI' },
  { estado: 'MG', cidade: 'GUARACIABA' },
  { estado: 'SC', cidade: 'GUARACIABA' },
  { estado: 'CE', cidade: 'GUARACIABA DO NORTE' },
  { estado: 'MG', cidade: 'GUARACIAMA' },
  { estado: 'TO', cidade: 'GUARAI' },
  { estado: 'GO', cidade: 'GUARAITA' },
  { estado: 'CE', cidade: 'GUARAMIRANGA' },
  { estado: 'SC', cidade: 'GUARAMIRIM' },
  { estado: 'MG', cidade: 'GUARANESIA' },
  { estado: 'MG', cidade: 'GUARANI' },
  { estado: 'SP', cidade: 'GUARANI D´OESTE' },
  { estado: 'RS', cidade: 'GUARANI DAS MISSOES' },
  { estado: 'GO', cidade: 'GUARANI DE GOIAS' },
  { estado: 'PR', cidade: 'GUARANIACU' },
  { estado: 'SP', cidade: 'GUARANTA' },
  { estado: 'MT', cidade: 'GUARANTA DO NORTE' },
  { estado: 'ES', cidade: 'GUARAPARI' },
  { estado: 'PR', cidade: 'GUARAPUAVA' },
  { estado: 'PR', cidade: 'GUARAQUECABA' },
  { estado: 'MG', cidade: 'GUARARA' },
  { estado: 'SP', cidade: 'GUARARAPES' },
  { estado: 'SP', cidade: 'GUARAREMA' },
  { estado: 'BA', cidade: 'GUARATINGA' },
  { estado: 'SP', cidade: 'GUARATINGUETA' },
  { estado: 'PR', cidade: 'GUARATUBA' },
  { estado: 'MG', cidade: 'GUARDA-MOR' },
  { estado: 'SP', cidade: 'GUAREI' },
  { estado: 'SP', cidade: 'GUARIBA' },
  { estado: 'PI', cidade: 'GUARIBAS' },
  { estado: 'GO', cidade: 'GUARINOS' },
  { estado: 'SP', cidade: 'GUARUJA' },
  { estado: 'SC', cidade: 'GUARUJA DO SUL' },
  { estado: 'SP', cidade: 'GUARULHOS' },
  { estado: 'SC', cidade: 'GUATAMBU' },
  { estado: 'SP', cidade: 'GUATAPARA' },
  { estado: 'MG', cidade: 'GUAXUPE' },
  { estado: 'MS', cidade: 'GUIA LOPES DA LAGUNA' },
  { estado: 'MG', cidade: 'GUIDOVAL' },
  { estado: 'MA', cidade: 'GUIMARAES' },
  { estado: 'MG', cidade: 'GUIMARANIA' },
  { estado: 'MT', cidade: 'GUIRATINGA' },
  { estado: 'MG', cidade: 'GUIRICEMA' },
  { estado: 'MG', cidade: 'GURINHATA' },
  { estado: 'PB', cidade: 'GURINHEM' },
  { estado: 'PB', cidade: 'GURJAO' },
  { estado: 'PA', cidade: 'GURUPA' },
  { estado: 'TO', cidade: 'GURUPI' },
  { estado: 'SP', cidade: 'GUZOLANDIA' },
  { estado: 'RS', cidade: 'HARMONIA' },
  { estado: 'GO', cidade: 'HEITORAI' },
  { estado: 'MG', cidade: 'HELIODORA' },
  { estado: 'BA', cidade: 'HELIOPOLIS' },
  { estado: 'SP', cidade: 'HERCULANDIA' },
  { estado: 'RS', cidade: 'HERVAL' },
  { estado: 'SC', cidade: 'HERVAL D´OESTE' },
  { estado: 'RS', cidade: 'HERVEIRAS' },
  { estado: 'CE', cidade: 'HIDROLANDIA' },
  { estado: 'GO', cidade: 'HIDROLANDIA' },
  { estado: 'GO', cidade: 'HIDROLINA' },
  { estado: 'SP', cidade: 'HOLAMBRA' },
  { estado: 'PR', cidade: 'HONORIO SERPA' },
  { estado: 'CE', cidade: 'HORIZONTE' },
  { estado: 'RS', cidade: 'HORIZONTINA' },
  { estado: 'SP', cidade: 'HORTOLANDIA' },
  { estado: 'PI', cidade: 'HUGO NAPOLEAO' },
  { estado: 'RS', cidade: 'HULHA NEGRA' },
  { estado: 'AM', cidade: 'HUMAITA' },
  { estado: 'RS', cidade: 'HUMAITA' },
  { estado: 'MA', cidade: 'HUMBERTO DE CAMPOS' },
  { estado: 'SP', cidade: 'IACANGA' },
  { estado: 'GO', cidade: 'IACIARA' },
  { estado: 'SP', cidade: 'IACRI' },
  { estado: 'BA', cidade: 'IACU' },
  { estado: 'MG', cidade: 'IAPU' },
  { estado: 'SP', cidade: 'IARAS' },
  { estado: 'PE', cidade: 'IATI' },
  { estado: 'PR', cidade: 'IBAITI' },
  { estado: 'RS', cidade: 'IBARAMA' },
  { estado: 'CE', cidade: 'IBARETAMA' },
  { estado: 'SP', cidade: 'IBATE' },
  { estado: 'Al', cidade: 'IBATEGUARA' },
  { estado: 'ES', cidade: 'IBATIBA' },
  { estado: 'PR', cidade: 'IBEMA' },
  { estado: 'MG', cidade: 'IBERTIOGA' },
  { estado: 'MG', cidade: 'IBIA' },
  { estado: 'RS', cidade: 'IBIACA' },
  { estado: 'MG', cidade: 'IBIAI' },
  { estado: 'SC', cidade: 'IBIAM' },
  { estado: 'CE', cidade: 'IBIAPINA' },
  { estado: 'PB', cidade: 'IBIARA' },
  { estado: 'BA', cidade: 'IBIASSUCE' },
  { estado: 'BA', cidade: 'IBICARAI' },
  { estado: 'SC', cidade: 'IBICARE' },
  { estado: 'BA', cidade: 'IBICOARA' },
  { estado: 'BA', cidade: 'IBICUI' },
  { estado: 'CE', cidade: 'IBICUITINGA' },
  { estado: 'PE', cidade: 'IBIMIRIM' },
  { estado: 'BA', cidade: 'IBIPEBA' },
  { estado: 'BA', cidade: 'IBIPITANGA' },
  { estado: 'PR', cidade: 'IBIPORA' },
  { estado: 'BA', cidade: 'IBIQUERA' },
  { estado: 'SP', cidade: 'IBIRA' },
  { estado: 'MG', cidade: 'IBIRACATU' },
  { estado: 'MG', cidade: 'IBIRACI' },
  { estado: 'ES', cidade: 'IBIRACU' },
  { estado: 'RS', cidade: 'IBIRAIARAS' },
  { estado: 'PE', cidade: 'IBIRAJUBA' },
  { estado: 'SC', cidade: 'IBIRAMA' },
  { estado: 'BA', cidade: 'IBIRAPITANGA' },
  { estado: 'BA', cidade: 'IBIRAPUA' },
  { estado: 'RS', cidade: 'IBIRAPUITA' },
  { estado: 'SP', cidade: 'IBIRAREMA' },
  { estado: 'BA', cidade: 'IBIRATAIA' },
  { estado: 'MG', cidade: 'IBIRITE' },
  { estado: 'RS', cidade: 'IBIRUBA' },
  { estado: 'BA', cidade: 'IBITIARA' },
  { estado: 'SP', cidade: 'IBITINGA' },
  { estado: 'ES', cidade: 'IBITIRAMA' },
  { estado: 'BA', cidade: 'IBITITA' },
  { estado: 'MG', cidade: 'IBITIURA DE MINAS' },
  { estado: 'MG', cidade: 'IBITURUNA' },
  { estado: 'SP', cidade: 'IBIUNA' },
  { estado: 'BA', cidade: 'IBOTIRAMA' },
  { estado: 'CE', cidade: 'ICAPUI' },
  { estado: 'SC', cidade: 'ICARA' },
  { estado: 'MG', cidade: 'ICARAI DE MINAS' },
  { estado: 'PR', cidade: 'ICARAIMA' },
  { estado: 'MA', cidade: 'ICATU' },
  { estado: 'SP', cidade: 'ICEM' },
  { estado: 'BA', cidade: 'ICHU' },
  { estado: 'CE', cidade: 'ICO' },
  { estado: 'ES', cidade: 'ICONHA' },
  { estado: 'RN', cidade: 'IELMO MARINHO' },
  { estado: 'SP', cidade: 'IEPE' },
  { estado: 'Al', cidade: 'IGACI' },
  { estado: 'BA', cidade: 'IGAPORA' },
  { estado: 'SP', cidade: 'IGARACU DO TIETE' },
  { estado: 'PB', cidade: 'IGARACY' },
  { estado: 'SP', cidade: 'IGARAPAVA' },
  { estado: 'MG', cidade: 'IGARAPE' },
  { estado: 'MA', cidade: 'IGARAPE DO MEIO' },
  { estado: 'MA', cidade: 'IGARAPE GRANDE' },
  { estado: 'PA', cidade: 'IGARAPE-ACU' },
  { estado: 'PA', cidade: 'IGARAPE-MIRI' },
  { estado: 'PE', cidade: 'IGARASSU' },
  { estado: 'SP', cidade: 'IGARATA' },
  { estado: 'MG', cidade: 'IGARATINGA' },
  { estado: 'BA', cidade: 'IGRAPIUNA' },
  { estado: 'Al', cidade: 'IGREJA NOVA' },
  { estado: 'RS', cidade: 'IGREJINHA' },
  { estado: 'RJ', cidade: 'IGUABA GRANDE' },
  { estado: 'BA', cidade: 'IGUAI' },
  { estado: 'SP', cidade: 'IGUAPE' },
  { estado: 'PE', cidade: 'IGUARACI' },
  { estado: 'PR', cidade: 'IGUARACU' },
  { estado: 'MG', cidade: 'IGUATAMA' },
  { estado: 'MS', cidade: 'IGUATEMI' },
  { estado: 'CE', cidade: 'IGUATU' },
  { estado: 'PR', cidade: 'IGUATU' },
  { estado: 'MG', cidade: 'IJACI' },
  { estado: 'RS', cidade: 'IJUI' },
  { estado: 'SP', cidade: 'ILHA COMPRIDA' },
  { estado: 'SE', cidade: 'ILHA DAS FLORES' },
  { estado: 'PI', cidade: 'ILHA GRANDE' },
  { estado: 'SP', cidade: 'ILHA SOLTEIRA' },
  { estado: 'SP', cidade: 'ILHABELA' },
  { estado: 'BA', cidade: 'ILHEUS' },
  { estado: 'SC', cidade: 'ILHOTA' },
  { estado: 'MG', cidade: 'ILICINEA' },
  { estado: 'RS', cidade: 'ILOPOLIS' },
  { estado: 'PB', cidade: 'IMACULADA' },
  { estado: 'SC', cidade: 'IMARUI' },
  { estado: 'PR', cidade: 'IMBAU' },
  { estado: 'RS', cidade: 'IMBE' },
  { estado: 'MG', cidade: 'IMBE DE MINAS' },
  { estado: 'SC', cidade: 'IMBITUBA' },
  { estado: 'PR', cidade: 'IMBITUVA' },
  { estado: 'SC', cidade: 'IMBUIA' },
  { estado: 'RS', cidade: 'IMIGRANTE' },
  { estado: 'MA', cidade: 'IMPERATRIZ' },
  { estado: 'PR', cidade: 'INACIO MARTINS' },
  { estado: 'GO', cidade: 'INACIOLANDIA' },
  { estado: 'PE', cidade: 'INAJA' },
  { estado: 'PR', cidade: 'INAJA' },
  { estado: 'MG', cidade: 'INCONFIDENTES' },
  { estado: 'MG', cidade: 'INDAIABIRA' },
  { estado: 'SC', cidade: 'INDAIAL' },
  { estado: 'SP', cidade: 'INDAIATUBA' },
  { estado: 'CE', cidade: 'INDEPENDENCIA' },
  { estado: 'RS', cidade: 'INDEPENDENCIA' },
  { estado: 'SP', cidade: 'INDIANA' },
  { estado: 'MG', cidade: 'INDIANOPOLIS' },
  { estado: 'PR', cidade: 'INDIANOPOLIS' },
  { estado: 'SP', cidade: 'INDIAPORA' },
  { estado: 'GO', cidade: 'INDIARA' },
  { estado: 'SE', cidade: 'INDIAROBA' },
  { estado: 'MT', cidade: 'INDIAVAI' },
  { estado: 'PB', cidade: 'INGA' },
  { estado: 'MG', cidade: 'INGAI' },
  { estado: 'PE', cidade: 'INGAZEIRA' },
  { estado: 'RS', cidade: 'INHACORA' },
  { estado: 'BA', cidade: 'INHAMBUPE' },
  { estado: 'PA', cidade: 'INHANGAPI' },
  { estado: 'Al', cidade: 'INHAPI' },
  { estado: 'MG', cidade: 'INHAPIM' },
  { estado: 'MG', cidade: 'INHAUMA' },
  { estado: 'PI', cidade: 'INHUMA' },
  { estado: 'GO', cidade: 'INHUMAS' },
  { estado: 'MG', cidade: 'INIMUTABA' },
  { estado: 'MS', cidade: 'INOCENCIA' },
  { estado: 'SP', cidade: 'INUBIA PAULISTA' },
  { estado: 'SC', cidade: 'IOMERE' },
  { estado: 'MG', cidade: 'IPABA' },
  { estado: 'GO', cidade: 'IPAMERI' },
  { estado: 'MG', cidade: 'IPANEMA' },
  { estado: 'RN', cidade: 'IPANGUACU' },
  { estado: 'CE', cidade: 'IPAPORANGA' },
  { estado: 'MG', cidade: 'IPATINGA' },
  { estado: 'SP', cidade: 'IPAUCU' },
  { estado: 'CE', cidade: 'IPAUMIRIM' },
  { estado: 'RS', cidade: 'IPE' },
  { estado: 'BA', cidade: 'IPECAETA' },
  { estado: 'SP', cidade: 'IPERO' },
  { estado: 'SP', cidade: 'IPEUNA' },
  { estado: 'MG', cidade: 'IPIACU' },
  { estado: 'BA', cidade: 'IPIAU' },
  { estado: 'SP', cidade: 'IPIGUA' },
  { estado: 'BA', cidade: 'IPIRA' },
  { estado: 'SC', cidade: 'IPIRA' },
  { estado: 'PR', cidade: 'IPIRANGA' },
  { estado: 'GO', cidade: 'IPIRANGA DE GOIAS' },
  { estado: 'MT', cidade: 'IPIRANGA DO NORTE' },
  { estado: 'PI', cidade: 'IPIRANGA DO PIAUI' },
  { estado: 'RS', cidade: 'IPIRANGA DO SUL' },
  { estado: 'AM', cidade: 'IPIXUNA' },
  { estado: 'PA', cidade: 'IPIXUNA DO PARA' },
  { estado: 'PE', cidade: 'IPOJUCA' },
  { estado: 'GO', cidade: 'IPORA' },
  { estado: 'PR', cidade: 'IPORA' },
  { estado: 'SC', cidade: 'IPORA DO OESTE' },
  { estado: 'SP', cidade: 'IPORANGA' },
  { estado: 'CE', cidade: 'IPU' },
  { estado: 'SP', cidade: 'IPUA' },
  { estado: 'SC', cidade: 'IPUACU' },
  { estado: 'PE', cidade: 'IPUBI' },
  { estado: 'RN', cidade: 'IPUEIRA' },
  { estado: 'CE', cidade: 'IPUEIRAS' },
  { estado: 'TO', cidade: 'IPUEIRAS' },
  { estado: 'MG', cidade: 'IPUIUNA' },
  { estado: 'SC', cidade: 'IPUMIRIM' },
  { estado: 'BA', cidade: 'IPUPIARA' },
  { estado: 'CE', cidade: 'IRACEMA' },
  { estado: 'RR', cidade: 'IRACEMA' },
  { estado: 'PR', cidade: 'IRACEMA DO OESTE' },
  { estado: 'SP', cidade: 'IRACEMAPOLIS' },
  { estado: 'SC', cidade: 'IRACEMINHA' },
  { estado: 'RS', cidade: 'IRAI' },
  { estado: 'MG', cidade: 'IRAI DE MINAS' },
  { estado: 'BA', cidade: 'IRAJUBA' },
  { estado: 'BA', cidade: 'IRAMAIA' },
  { estado: 'AM', cidade: 'IRANDUBA' },
  { estado: 'SC', cidade: 'IRANI' },
  { estado: 'SP', cidade: 'IRAPUA' },
  { estado: 'SP', cidade: 'IRAPURU' },
  { estado: 'BA', cidade: 'IRAQUARA' },
  { estado: 'BA', cidade: 'IRARA' },
  { estado: 'PR', cidade: 'IRATI' },
  { estado: 'SC', cidade: 'IRATI' },
  { estado: 'CE', cidade: 'IRAUCUBA' },
  { estado: 'BA', cidade: 'IRECE' },
  { estado: 'PR', cidade: 'IRETAMA' },
  { estado: 'SC', cidade: 'IRINEOPOLIS' },
  { estado: 'PA', cidade: 'IRITUIA' },
  { estado: 'ES', cidade: 'IRUPI' },
  { estado: 'PI', cidade: 'ISAIAS COELHO' },
  { estado: 'GO', cidade: 'ISRAELANDIA' },
  { estado: 'SC', cidade: 'ITA' },
  { estado: 'RS', cidade: 'ITAARA' },
  { estado: 'SE', cidade: 'ITABAIANA' },
  { estado: 'PB', cidade: 'ITABAIANA' },
  { estado: 'SE', cidade: 'ITABAIANINHA' },
  { estado: 'BA', cidade: 'ITABELA' },
  { estado: 'SP', cidade: 'ITABERA' },
  { estado: 'BA', cidade: 'ITABERABA' },
  { estado: 'GO', cidade: 'ITABERAI' },
  { estado: 'SE', cidade: 'ITABI' },
  { estado: 'MG', cidade: 'ITABIRA' },
  { estado: 'MG', cidade: 'ITABIRINHA DE MANTENA' },
  { estado: 'MG', cidade: 'ITABIRITO' },
  { estado: 'RJ', cidade: 'ITABORAI' },
  { estado: 'BA', cidade: 'ITABUNA' },
  { estado: 'TO', cidade: 'ITACAJA' },
  { estado: 'MG', cidade: 'ITACAMBIRA' },
  { estado: 'MG', cidade: 'ITACARAMBI' },
  { estado: 'BA', cidade: 'ITACARE' },
  { estado: 'AM', cidade: 'ITACOATIARA' },
  { estado: 'PE', cidade: 'ITACURUBA' },
  { estado: 'RS', cidade: 'ITACURUBI' },
  { estado: 'BA', cidade: 'ITAETE' },
  { estado: 'BA', cidade: 'ITAGI' },
  { estado: 'BA', cidade: 'ITAGIBA' },
  { estado: 'BA', cidade: 'ITAGIMIRIM' },
  { estado: 'ES', cidade: 'ITAGUACU' },
  { estado: 'BA', cidade: 'ITAGUACU DA BAHIA' },
  { estado: 'RJ', cidade: 'ITAGUAI' },
  { estado: 'PR', cidade: 'ITAGUAJE' },
  { estado: 'MG', cidade: 'ITAGUARA' },
  { estado: 'GO', cidade: 'ITAGUARI' },
  { estado: 'GO', cidade: 'ITAGUARU' },
  { estado: 'TO', cidade: 'ITAGUATINS' },
  { estado: 'SP', cidade: 'ITAI' },
  { estado: 'PE', cidade: 'ITAIBA' },
  { estado: 'CE', cidade: 'ITAICABA' },
  { estado: 'PI', cidade: 'ITAINOPOLIS' },
  { estado: 'SC', cidade: 'ITAIOPOLIS' },
  { estado: 'MA', cidade: 'ITAIPAVA DO GRAJAU' },
  { estado: 'MG', cidade: 'ITAIPE' },
  { estado: 'PR', cidade: 'ITAIPULANDIA' },
  { estado: 'CE', cidade: 'ITAITINGA' },
  { estado: 'PA', cidade: 'ITAITUBA' },
  { estado: 'RN', cidade: 'ITAJA' },
  { estado: 'GO', cidade: 'ITAJA' },
  { estado: 'SC', cidade: 'ITAJAI' },
  { estado: 'SP', cidade: 'ITAJOBI' },
  { estado: 'SP', cidade: 'ITAJU' },
  { estado: 'BA', cidade: 'ITAJU DO COLONIA' },
  { estado: 'MG', cidade: 'ITAJUBA' },
  { estado: 'BA', cidade: 'ITAJUIPE' },
  { estado: 'RJ', cidade: 'ITALVA' },
  { estado: 'PE', cidade: 'ITAMARACA' },
  { estado: 'BA', cidade: 'ITAMARAJU' },
  { estado: 'MG', cidade: 'ITAMARANDIBA' },
  { estado: 'AM', cidade: 'ITAMARATI' },
  { estado: 'MG', cidade: 'ITAMARATI DE MINAS' },
  { estado: 'BA', cidade: 'ITAMARI' },
  { estado: 'MG', cidade: 'ITAMBACURI' },
  { estado: 'PR', cidade: 'ITAMBARACA' },
  { estado: 'BA', cidade: 'ITAMBE' },
  { estado: 'PE', cidade: 'ITAMBE' },
  { estado: 'PR', cidade: 'ITAMBE' },
  { estado: 'MG', cidade: 'ITAMBE DO MATO DENTRO' },
  { estado: 'MG', cidade: 'ITAMOGI' },
  { estado: 'MG', cidade: 'ITAMONTE' },
  { estado: 'BA', cidade: 'ITANAGRA' },
  { estado: 'SP', cidade: 'ITANHAEM' },
  { estado: 'MG', cidade: 'ITANHANDU' },
  { estado: 'MT', cidade: 'ITANHANGA' },
  { estado: 'BA', cidade: 'ITANHEM' },
  { estado: 'MG', cidade: 'ITANHOMI' },
  { estado: 'MG', cidade: 'ITAOBIM' },
  { estado: 'SP', cidade: 'ITAOCA' },
  { estado: 'RJ', cidade: 'ITAOCARA' },
  { estado: 'GO', cidade: 'ITAPACI' },
  { estado: 'CE', cidade: 'ITAPAGE' },
  { estado: 'MG', cidade: 'ITAPAGIPE' },
  { estado: 'BA', cidade: 'ITAPARICA' },
  { estado: 'BA', cidade: 'ITAPE' },
  { estado: 'BA', cidade: 'ITAPEBI' },
  { estado: 'MG', cidade: 'ITAPECERICA' },
  { estado: 'SP', cidade: 'ITAPECERICA DA SERRA' },
  { estado: 'MA', cidade: 'ITAPECURU MIRIM' },
  { estado: 'PR', cidade: 'ITAPEJARA D´OESTE' },
  { estado: 'SC', cidade: 'ITAPEMA' },
  { estado: 'ES', cidade: 'ITAPEMIRIM' },
  { estado: 'PR', cidade: 'ITAPERUCU' },
  { estado: 'RJ', cidade: 'ITAPERUNA' },
  { estado: 'PE', cidade: 'ITAPETIM' },
  { estado: 'BA', cidade: 'ITAPETINGA' },
  { estado: 'SP', cidade: 'ITAPETININGA' },
  { estado: 'SP', cidade: 'ITAPEVA' },
  { estado: 'MG', cidade: 'ITAPEVA' },
  { estado: 'SP', cidade: 'ITAPEVI' },
  { estado: 'BA', cidade: 'ITAPICURU' },
  { estado: 'CE', cidade: 'ITAPIPOCA' },
  { estado: 'SP', cidade: 'ITAPIRA' },
  { estado: 'AM', cidade: 'ITAPIRANGA' },
  { estado: 'SC', cidade: 'ITAPIRANGA' },
  { estado: 'GO', cidade: 'ITAPIRAPUA' },
  { estado: 'SP', cidade: 'ITAPIRAPUA PAULISTA' },
  { estado: 'TO', cidade: 'ITAPIRATINS' },
  { estado: 'PE', cidade: 'ITAPISSUMA' },
  { estado: 'BA', cidade: 'ITAPITANGA' },
  { estado: 'CE', cidade: 'ITAPIUNA' },
  { estado: 'SC', cidade: 'ITAPOA' },
  { estado: 'SP', cidade: 'ITAPOLIS' },
  { estado: 'MS', cidade: 'ITAPORA' },
  { estado: 'TO', cidade: 'ITAPORA DO TOCANTINS' },
  { estado: 'SP', cidade: 'ITAPORANGA' },
  { estado: 'PB', cidade: 'ITAPORANGA' },
  { estado: 'SE', cidade: 'ITAPORANGA D´AJUDA' },
  { estado: 'PB', cidade: 'ITAPOROROCA' },
  { estado: 'RO', cidade: 'ITAPUA DO OESTE' },
  { estado: 'RS', cidade: 'ITAPUCA' },
  { estado: 'SP', cidade: 'ITAPUI' },
  { estado: 'SP', cidade: 'ITAPURA' },
  { estado: 'GO', cidade: 'ITAPURANGA' },
  { estado: 'SP', cidade: 'ITAQUAQUECETUBA' },
  { estado: 'BA', cidade: 'ITAQUARA' },
  { estado: 'RS', cidade: 'ITAQUI' },
  { estado: 'MS', cidade: 'ITAQUIRAI' },
  { estado: 'PE', cidade: 'ITAQUITINGA' },
  { estado: 'ES', cidade: 'ITARANA' },
  { estado: 'BA', cidade: 'ITARANTIM' },
  { estado: 'SP', cidade: 'ITARARE' },
  { estado: 'CE', cidade: 'ITAREMA' },
  { estado: 'SP', cidade: 'ITARIRI' },
  { estado: 'GO', cidade: 'ITARUMA' },
  { estado: 'RS', cidade: 'ITATI' },
  { estado: 'RJ', cidade: 'ITATIAIA' },
  { estado: 'MG', cidade: 'ITATIAIUCU' },
  { estado: 'SP', cidade: 'ITATIBA' },
  { estado: 'RS', cidade: 'ITATIBA DO SUL' },
  { estado: 'BA', cidade: 'ITATIM' },
  { estado: 'SP', cidade: 'ITATINGA' },
  { estado: 'CE', cidade: 'ITATIRA' },
  { estado: 'PB', cidade: 'ITATUBA' },
  { estado: 'RN', cidade: 'ITAU' },
  { estado: 'MG', cidade: 'ITAU DE MINAS' },
  { estado: 'MT', cidade: 'ITAUBA' },
  { estado: 'AP', cidade: 'ITAUBAL' },
  { estado: 'GO', cidade: 'ITAUCU' },
  { estado: 'PI', cidade: 'ITAUEIRA' },
  { estado: 'MG', cidade: 'ITAUNA' },
  { estado: 'PR', cidade: 'ITAUNA DO SUL' },
  { estado: 'MG', cidade: 'ITAVERAVA' },
  { estado: 'MG', cidade: 'ITINGA' },
  { estado: 'MA', cidade: 'ITINGA DO MARANHAO' },
  { estado: 'MT', cidade: 'ITIQUIRA' },
  { estado: 'SP', cidade: 'ITIRAPINA' },
  { estado: 'SP', cidade: 'ITIRAPUA' },
  { estado: 'BA', cidade: 'ITIRUCU' },
  { estado: 'BA', cidade: 'ITIUBA' },
  { estado: 'SP', cidade: 'ITOBI' },
  { estado: 'BA', cidade: 'ITORORO' },
  { estado: 'SP', cidade: 'ITU' },
  { estado: 'BA', cidade: 'ITUACU' },
  { estado: 'BA', cidade: 'ITUBERA' },
  { estado: 'MG', cidade: 'ITUETA' },
  { estado: 'MG', cidade: 'ITUIUTABA' },
  { estado: 'GO', cidade: 'ITUMBIARA' },
  { estado: 'MG', cidade: 'ITUMIRIM' },
  { estado: 'SP', cidade: 'ITUPEVA' },
  { estado: 'PA', cidade: 'ITUPIRANGA' },
  { estado: 'SC', cidade: 'ITUPORANGA' },
  { estado: 'MG', cidade: 'ITURAMA' },
  { estado: 'MG', cidade: 'ITUTINGA' },
  { estado: 'SP', cidade: 'ITUVERAVA' },
  { estado: 'BA', cidade: 'IUIU' },
  { estado: 'ES', cidade: 'IUNA' },
  { estado: 'PR', cidade: 'IVAI' },
  { estado: 'PR', cidade: 'IVAIPORA' },
  { estado: 'PR', cidade: 'IVATE' },
  { estado: 'PR', cidade: 'IVATUBA' },
  { estado: 'MS', cidade: 'IVINHEMA' },
  { estado: 'GO', cidade: 'IVOLANDIA' },
  { estado: 'RS', cidade: 'IVORA' },
  { estado: 'RS', cidade: 'IVOTI' },
  { estado: 'PE', cidade: 'JABOATAO DO GUARARAPES' },
  { estado: 'SC', cidade: 'JABORA' },
  { estado: 'SP', cidade: 'JABORANDI' },
  { estado: 'BA', cidade: 'JABORANDI' },
  { estado: 'PR', cidade: 'JABOTI' },
  { estado: 'RS', cidade: 'JABOTICABA' },
  { estado: 'SP', cidade: 'JABOTICABAL' },
  { estado: 'MG', cidade: 'JABOTICATUBAS' },
  { estado: 'RN', cidade: 'JACANA' },
  { estado: 'BA', cidade: 'JACARACI' },
  { estado: 'PB', cidade: 'JACARAU' },
  { estado: 'Al', cidade: 'JACARE DO HOMENS' },
  { estado: 'PA', cidade: 'JACAREACANGA' },
  { estado: 'SP', cidade: 'JACAREI' },
  { estado: 'PR', cidade: 'JACAREZINHO' },
  { estado: 'SP', cidade: 'JACI' },
  { estado: 'MT', cidade: 'JACIARA' },
  { estado: 'MG', cidade: 'JACINTO' },
  { estado: 'SC', cidade: 'JACINTO MACHADO' },
  { estado: 'BA', cidade: 'JACOBINA' },
  { estado: 'PI', cidade: 'JACOBINA DO PIAUI' },
  { estado: 'MG', cidade: 'JACUI' },
  { estado: 'Al', cidade: 'JACUIPE' },
  { estado: 'RS', cidade: 'JACUIZINHO' },
  { estado: 'PA', cidade: 'JACUNDA' },
  { estado: 'SP', cidade: 'JACUPIRANGA' },
  { estado: 'MG', cidade: 'JACUTINGA' },
  { estado: 'RS', cidade: 'JACUTINGA' },
  { estado: 'PR', cidade: 'JAGUAPITA' },
  { estado: 'BA', cidade: 'JAGUAQUARA' },
  { estado: 'MG', cidade: 'JAGUARACU' },
  { estado: 'RS', cidade: 'JAGUARAO' },
  { estado: 'BA', cidade: 'JAGUARARI' },
  { estado: 'ES', cidade: 'JAGUARE' },
  { estado: 'CE', cidade: 'JAGUARETAMA' },
  { estado: 'RS', cidade: 'JAGUARI' },
  { estado: 'PR', cidade: 'JAGUARIAIVA' },
  { estado: 'CE', cidade: 'JAGUARIBARA' },
  { estado: 'CE', cidade: 'JAGUARIBE' },
  { estado: 'BA', cidade: 'JAGUARIPE' },
  { estado: 'SP', cidade: 'JAGUARIUNA' },
  { estado: 'CE', cidade: 'JAGUARUANA' },
  { estado: 'SC', cidade: 'JAGUARUNA' },
  { estado: 'MG', cidade: 'JAIBA' },
  { estado: 'PI', cidade: 'JAICOS' },
  { estado: 'SP', cidade: 'JALES' },
  { estado: 'SP', cidade: 'JAMBEIRO' },
  { estado: 'MG', cidade: 'JAMPRUCA' },
  { estado: 'MG', cidade: 'JANAUBA' },
  { estado: 'GO', cidade: 'JANDAIA' },
  { estado: 'PR', cidade: 'JANDAIA DO SUL' },
  { estado: 'BA', cidade: 'JANDAIRA' },
  { estado: 'RN', cidade: 'JANDAIRA' },
  { estado: 'SP', cidade: 'JANDIRA' },
  { estado: 'RN', cidade: 'JANDUIS' },
  { estado: 'MT', cidade: 'JANGADA' },
  { estado: 'PR', cidade: 'JANIOPOLIS' },
  { estado: 'MG', cidade: 'JANUARIA' },
  { estado: 'RN', cidade: 'JANUARIO CICCO' },
  { estado: 'MG', cidade: 'JAPARAIBA' },
  { estado: 'Al', cidade: 'JAPARATINGA' },
  { estado: 'SE', cidade: 'JAPARATUBA' },
  { estado: 'RJ', cidade: 'JAPERI' },
  { estado: 'RN', cidade: 'JAPI' },
  { estado: 'PR', cidade: 'JAPIRA' },
  { estado: 'SE', cidade: 'JAPOATA' },
  { estado: 'MG', cidade: 'JAPONVAR' },
  { estado: 'MS', cidade: 'JAPORA' },
  { estado: 'AM', cidade: 'JAPURA' },
  { estado: 'PR', cidade: 'JAPURA' },
  { estado: 'PE', cidade: 'JAQUEIRA' },
  { estado: 'RS', cidade: 'JAQUIRANA' },
  { estado: 'GO', cidade: 'JARAGUA' },
  { estado: 'SC', cidade: 'JARAGUA DO SUL' },
  { estado: 'MS', cidade: 'JARAGUARI' },
  { estado: 'Al', cidade: 'JARAMATAIA' },
  { estado: 'CE', cidade: 'JARDIM' },
  { estado: 'MS', cidade: 'JARDIM' },
  { estado: 'PR', cidade: 'JARDIM ALEGRE' },
  { estado: 'RN', cidade: 'JARDIM DE ANGICOS' },
  { estado: 'RN', cidade: 'JARDIM DE PIRANHAS' },
  { estado: 'PI', cidade: 'JARDIM DO MULATO' },
  { estado: 'RN', cidade: 'JARDIM DO SERIDO' },
  { estado: 'PR', cidade: 'JARDIM OLINDA' },
  { estado: 'SP', cidade: 'JARDINOPOLIS' },
  { estado: 'SC', cidade: 'JARDINOPOLIS' },
  { estado: 'RS', cidade: 'JARI' },
  { estado: 'SP', cidade: 'JARINU' },
  { estado: 'RO', cidade: 'JARU' },
  { estado: 'GO', cidade: 'JATAI' },
  { estado: 'PR', cidade: 'JATAIZINHO' },
  { estado: 'PE', cidade: 'JATAUBA' },
  { estado: 'MS', cidade: 'JATEI' },
  { estado: 'CE', cidade: 'JATI' },
  { estado: 'PE', cidade: 'JATOBA' },
  { estado: 'MA', cidade: 'JATOBA' },
  { estado: 'PI', cidade: 'JATOBA DO PIAUI' },
  { estado: 'SP', cidade: 'JAU' },
  { estado: 'TO', cidade: 'JAU DO TOCANTINS' },
  { estado: 'GO', cidade: 'JAUPACI' },
  { estado: 'MT', cidade: 'JAURU' },
  { estado: 'MG', cidade: 'JECEABA' },
  { estado: 'MG', cidade: 'JENIPAPO DE MINAS' },
  { estado: 'MA', cidade: 'JENIPAPO DO VIEIRAS' },
  { estado: 'MG', cidade: 'JEQUERI' },
  { estado: 'Al', cidade: 'JEQUIA DA PRAIA' },
  { estado: 'BA', cidade: 'JEQUIE' },
  { estado: 'MG', cidade: 'JEQUITAI' },
  { estado: 'MG', cidade: 'JEQUITIBA' },
  { estado: 'MG', cidade: 'JEQUITINHONHA' },
  { estado: 'BA', cidade: 'JEREMOABO' },
  { estado: 'PB', cidade: 'JERICO' },
  { estado: 'SP', cidade: 'JERIQUARA' },
  { estado: 'ES', cidade: 'JERONIMO MONTEIRO' },
  { estado: 'PI', cidade: 'JERUMENHA' },
  { estado: 'MG', cidade: 'JESUANIA' },
  { estado: 'PR', cidade: 'JESUITAS' },
  { estado: 'GO', cidade: 'JESUPOLIS' },
  { estado: 'CE', cidade: 'JIJOCA DE JERICOACOARA' },
  { estado: 'RO', cidade: 'JI-PARANA' },
  { estado: 'BA', cidade: 'JIQUIRICA' },
  { estado: 'BA', cidade: 'JITAUNA' },
  { estado: 'SC', cidade: 'JOACABA' },
  { estado: 'MG', cidade: 'JOAIMA' },
  { estado: 'MG', cidade: 'JOANESIA' },
  { estado: 'SP', cidade: 'JOANOPOLIS' },
  { estado: 'PE', cidade: 'JOAO ALFREDO' },
  { estado: 'RN', cidade: 'JOAO CAMARA' },
  { estado: 'PI', cidade: 'JOAO COSTA' },
  { estado: 'RN', cidade: 'JOAO DIAS' },
  { estado: 'BA', cidade: 'JOAO DOURADO' },
  { estado: 'MA', cidade: 'JOAO LISBOA' },
  { estado: 'MG', cidade: 'JOAO MONLEVADE' },
  { estado: 'ES', cidade: 'JOAO NEIVA' },
  { estado: 'PB', cidade: 'JOAO PESSOA' },
  { estado: 'MG', cidade: 'JOAO PINHEIRO' },
  { estado: 'SP', cidade: 'JOAO RAMALHO' },
  { estado: 'MG', cidade: 'JOAQUIM FELICIO' },
  { estado: 'Al', cidade: 'JOAQUIM GOMES' },
  { estado: 'PE', cidade: 'JOAQUIM NABUCO' },
  { estado: 'PI', cidade: 'JOAQUIM PIRES' },
  { estado: 'PR', cidade: 'JOAQUIM TAVORA' },
  { estado: 'PI', cidade: 'JOCA MARQUES' },
  { estado: 'RS', cidade: 'JOIA' },
  { estado: 'SC', cidade: 'JOINVILLE' },
  { estado: 'MG', cidade: 'JORDANIA' },
  { estado: 'AC', cidade: 'JORDAO' },
  { estado: 'SC', cidade: 'JOSE BOITEUX' },
  { estado: 'SP', cidade: 'JOSE BONIFACIO' },
  { estado: 'RN', cidade: 'JOSE DA PENHA' },
  { estado: 'PI', cidade: 'JOSE DE FREITAS' },
  { estado: 'MG', cidade: 'JOSE GONCALVES DE MINAS' },
  { estado: 'MG', cidade: 'JOSE RAYDAN' },
  { estado: 'MA', cidade: 'JOSELANDIA' },
  { estado: 'MG', cidade: 'JOSENOPOLIS' },
  { estado: 'GO', cidade: 'JOVIANIA' },
  { estado: 'MT', cidade: 'JUARA' },
  { estado: 'PB', cidade: 'JUAREZ TAVORA' },
  { estado: 'TO', cidade: 'JUARINA' },
  { estado: 'MG', cidade: 'JUATUBA' },
  { estado: 'PB', cidade: 'JUAZEIRINHO' },
  { estado: 'BA', cidade: 'JUAZEIRO' },
  { estado: 'CE', cidade: 'JUAZEIRO DO NORTE' },
  { estado: 'PI', cidade: 'JUAZEIRO DO PIAUI' },
  { estado: 'CE', cidade: 'JUCAS' },
  { estado: 'PE', cidade: 'JUCATI' },
  { estado: 'BA', cidade: 'JUCURUCU' },
  { estado: 'RN', cidade: 'JUCURUTU' },
  { estado: 'MT', cidade: 'JUINA' },
  { estado: 'MG', cidade: 'JUIZ DE FORA' },
  { estado: 'PI', cidade: 'JULIO BORGES' },
  { estado: 'RS', cidade: 'JULIO DE CASTILHOS' },
  { estado: 'SP', cidade: 'JULIO MESQUITA' },
  { estado: 'SP', cidade: 'JUMIRIM' },
  { estado: 'MA', cidade: 'JUNCO DO MARANHAO' },
  { estado: 'PB', cidade: 'JUNCO DO SERIDO' },
  { estado: 'Al', cidade: 'JUNDIA' },
  { estado: 'RN', cidade: 'JUNDIA' },
  { estado: 'SP', cidade: 'JUNDIAI' },
  { estado: 'PR', cidade: 'JUNDIAI DO SUL' },
  { estado: 'Al', cidade: 'JUNQUEIRO' },
  { estado: 'SP', cidade: 'JUNQUEIROPOLIS' },
  { estado: 'PE', cidade: 'JUPI' },
  { estado: 'SC', cidade: 'JUPIA' },
  { estado: 'SP', cidade: 'JUQUIA' },
  { estado: 'SP', cidade: 'JUQUITIBA' },
  { estado: 'MG', cidade: 'JURAMENTO' },
  { estado: 'PR', cidade: 'JURANDA' },
  { estado: 'PE', cidade: 'JUREMA' },
  { estado: 'PI', cidade: 'JUREMA' },
  { estado: 'PB', cidade: 'JURIPIRANGA' },
  { estado: 'PB', cidade: 'JURU' },
  { estado: 'AM', cidade: 'JURUA' },
  { estado: 'MG', cidade: 'JURUAIA' },
  { estado: 'MT', cidade: 'JURUENA' },
  { estado: 'PA', cidade: 'JURUTI' },
  { estado: 'MT', cidade: 'JUSCIMEIRA' },
  { estado: 'BA', cidade: 'JUSSARA' },
  { estado: 'GO', cidade: 'JUSSARA' },
  { estado: 'PR', cidade: 'JUSSARA' },
  { estado: 'BA', cidade: 'JUSSARI' },
  { estado: 'BA', cidade: 'JUSSIAPE' },
  { estado: 'AM', cidade: 'JUTAI' },
  { estado: 'MS', cidade: 'JUTI' },
  { estado: 'MG', cidade: 'JUVENILIA' },
  { estado: 'PR', cidade: 'KALORE' },
  { estado: 'AM', cidade: 'LABREA' },
  { estado: 'SC', cidade: 'LACERDOPOLIS' },
  { estado: 'MG', cidade: 'LADAINHA' },
  { estado: 'MS', cidade: 'LADARIO' },
  { estado: 'BA', cidade: 'LAFAIETE COUTINHO' },
  { estado: 'MG', cidade: 'LAGAMAR' },
  { estado: 'SE', cidade: 'LAGARTO' },
  { estado: 'SC', cidade: 'LAGES' },
  { estado: 'MA', cidade: 'LAGO DA PEDRA' },
  { estado: 'MA', cidade: 'LAGO DO JUNCO' },
  { estado: 'MA', cidade: 'LAGO DO RODRIGUES' },
  { estado: 'MA', cidade: 'LAGO VERDE' },
  { estado: 'PB', cidade: 'LAGOA' },
  { estado: 'PI', cidade: 'LAGOA ALEGRE' },
  { estado: 'RS', cidade: 'LAGOA BONITA DO SUL' },
  { estado: 'RN', cidade: 'LAGOA D´ANTA' },
  { estado: 'Al', cidade: 'LAGOA DA CANOA' },
  { estado: 'TO', cidade: 'LAGOA DA CONFUSAO' },
  { estado: 'MG', cidade: 'LAGOA DA PRATA' },
  { estado: 'PB', cidade: 'LAGOA DE DENTRO' },
  { estado: 'RN', cidade: 'LAGOA DE PEDRAS' },
  { estado: 'PI', cidade: 'LAGOA DE SAO FRANCISCO' },
  { estado: 'RN', cidade: 'LAGOA DE VELHOS' },
  { estado: 'PI', cidade: 'LAGOA DO BARRO DO PIAUI' },
  { estado: 'PE', cidade: 'LAGOA DO CARRO' },
  { estado: 'PE', cidade: 'LAGOA DO GATOS' },
  { estado: 'PE', cidade: 'LAGOA DO ITAENGA' },
  { estado: 'MA', cidade: 'LAGOA DO MATO' },
  { estado: 'PE', cidade: 'LAGOA DO OURO' },
  { estado: 'MG', cidade: 'LAGOA DO PATOS' },
  { estado: 'PI', cidade: 'LAGOA DO PIAUI' },
  { estado: 'PI', cidade: 'LAGOA DO SITIO' },
  { estado: 'TO', cidade: 'LAGOA DO TOCANTINS' },
  { estado: 'RS', cidade: 'LAGOA DO TRES CANTOS' },
  { estado: 'MG', cidade: 'LAGOA DOURADA' },
  { estado: 'MG', cidade: 'LAGOA FORMOSA' },
  { estado: 'MG', cidade: 'LAGOA GRANDE' },
  { estado: 'PE', cidade: 'LAGOA GRANDE' },
  { estado: 'MA', cidade: 'LAGOA GRANDE DO MARANHAO' },
  { estado: 'RN', cidade: 'LAGOA NOVA' },
  { estado: 'BA', cidade: 'LAGOA REAL' },
  { estado: 'RN', cidade: 'LAGOA SALGADA' },
  { estado: 'MG', cidade: 'LAGOA SANTA' },
  { estado: 'GO', cidade: 'LAGOA SANTA' },
  { estado: 'PB', cidade: 'LAGOA SECA' },
  { estado: 'RS', cidade: 'LAGOA VERMELHA' },
  { estado: 'RS', cidade: 'LAGOAO' },
  { estado: 'SP', cidade: 'LAGOINHA' },
  { estado: 'PI', cidade: 'LAGOINHA DO PIAUI' },
  { estado: 'SC', cidade: 'LAGUNA' },
  { estado: 'MS', cidade: 'LAGUNA CARAPA' },
  { estado: 'BA', cidade: 'LAJE' },
  { estado: 'RJ', cidade: 'LAJE DO MURIAE' },
  { estado: 'TO', cidade: 'LAJEADO' },
  { estado: 'RS', cidade: 'LAJEADO' },
  { estado: 'RS', cidade: 'LAJEADO DO BUGRE' },
  { estado: 'SC', cidade: 'LAJEADO GRANDE' },
  { estado: 'MA', cidade: 'LAJEADO NOVO' },
  { estado: 'BA', cidade: 'LAJEDAO' },
  { estado: 'BA', cidade: 'LAJEDINHO' },
  { estado: 'PE', cidade: 'LAJEDO' },
  { estado: 'BA', cidade: 'LAJEDO DO TABOCAL' },
  { estado: 'RN', cidade: 'LAJES' },
  { estado: 'RN', cidade: 'LAJES PINTADAS' },
  { estado: 'MG', cidade: 'LAJINHA' },
  { estado: 'BA', cidade: 'LAMARAO' },
  { estado: 'MG', cidade: 'LAMBARI' },
  { estado: 'MT', cidade: 'LAMBARI D´OESTE' },
  { estado: 'MG', cidade: 'LAMIM' },
  { estado: 'PI', cidade: 'LANDRI SALES' },
  { estado: 'PR', cidade: 'LAPA' },
  { estado: 'BA', cidade: 'LAPAO' },
  { estado: 'ES', cidade: 'LARANJA DA TERRA' },
  { estado: 'MG', cidade: 'LARANJAL' },
  { estado: 'PR', cidade: 'LARANJAL' },
  { estado: 'AP', cidade: 'LARANJAL DO JARI' },
  { estado: 'SP', cidade: 'LARANJAL PAULISTA' },
  { estado: 'SE', cidade: 'LARANJEIRAS' },
  { estado: 'PR', cidade: 'LARANJEIRAS DO SUL' },
  { estado: 'MG', cidade: 'LASSANCE' },
  { estado: 'PB', cidade: 'LASTRO' },
  { estado: 'SC', cidade: 'LAURENTINO' },
  { estado: 'BA', cidade: 'LAURO DE FREITAS' },
  { estado: 'SC', cidade: 'LAURO MULLER' },
  { estado: 'TO', cidade: 'LAVANDEIRA' },
  { estado: 'SP', cidade: 'LAVINIA' },
  { estado: 'MG', cidade: 'LAVRAS' },
  { estado: 'CE', cidade: 'LAVRAS DA MANGABEIRA' },
  { estado: 'RS', cidade: 'LAVRAS DO SUL' },
  { estado: 'SP', cidade: 'LAVRINHAS' },
  { estado: 'MG', cidade: 'LEANDRO FERREIRA' },
  { estado: 'SC', cidade: 'LEBON REGIS' },
  { estado: 'SP', cidade: 'LEME' },
  { estado: 'MG', cidade: 'LEME DO PRADO' },
  { estado: 'BA', cidade: 'LENCOIS' },
  { estado: 'SP', cidade: 'LENCOIS PAULISTA' },
  { estado: 'SC', cidade: 'LEOBERTO LEAL' },
  { estado: 'MG', cidade: 'LEOPOLDINA' },
  { estado: 'GO', cidade: 'LEOPOLDO DE BULHOES' },
  { estado: 'PR', cidade: 'LEOPOLIS' },
  { estado: 'RS', cidade: 'LIBERATO SALZANO' },
  { estado: 'MG', cidade: 'LIBERDADE' },
  { estado: 'BA', cidade: 'LICINIO DE ALMEIDA' },
  { estado: 'PR', cidade: 'LIDIANOPOLIS' },
  { estado: 'MA', cidade: 'LIMA CAMPOS' },
  { estado: 'MG', cidade: 'LIMA DUARTE' },
  { estado: 'SP', cidade: 'LIMEIRA' },
  { estado: 'MG', cidade: 'LIMEIRA DO OESTE' },
  { estado: 'PE', cidade: 'LIMOEIRO' },
  { estado: 'Al', cidade: 'LIMOEIRO DE ANADIA' },
  { estado: 'PA', cidade: 'LIMOEIRO DO AJURU' },
  { estado: 'CE', cidade: 'LIMOEIRO DO NORTE' },
  { estado: 'PR', cidade: 'LINDOESTE' },
  { estado: 'SP', cidade: 'LINDOIA' },
  { estado: 'SC', cidade: 'LINDOIA DO SUL' },
  { estado: 'RS', cidade: 'LINDOLFO COLLOR' },
  { estado: 'RS', cidade: 'LINHA NOVA' },
  { estado: 'ES', cidade: 'LINHARES' },
  { estado: 'SP', cidade: 'LINS' },
  { estado: 'PB', cidade: 'LIVRAMENTO' },
  { estado: 'BA', cidade: 'LIVRAMENTO DE NOSSA SENHORA' },
  { estado: 'TO', cidade: 'LIZARDA' },
  { estado: 'PR', cidade: 'LOANDA' },
  { estado: 'PR', cidade: 'LOBATO' },
  { estado: 'PB', cidade: 'LOGRADOURO' },
  { estado: 'PR', cidade: 'LONDRINA' },
  { estado: 'MG', cidade: 'LONTRA' },
  { estado: 'SC', cidade: 'LONTRAS' },
  { estado: 'SP', cidade: 'LORENA' },
  { estado: 'MA', cidade: 'LORETO' },
  { estado: 'SP', cidade: 'LOURDES' },
  { estado: 'SP', cidade: 'LOUVEIRA' },
  { estado: 'MT', cidade: 'LUCAS DO RIO VERDE' },
  { estado: 'SP', cidade: 'LUCELIA' },
  { estado: 'PB', cidade: 'LUCENA' },
  { estado: 'SP', cidade: 'LUCIANOPOLIS' },
  { estado: 'MT', cidade: 'LUCIARA' },
  { estado: 'RN', cidade: 'LUCRECIA' },
  { estado: 'SP', cidade: 'LUIS ANTONIO' },
  { estado: 'PI', cidade: 'LUIS CORREIA' },
  { estado: 'MA', cidade: 'LUIS DOMINGUES' },
  { estado: 'BA', cidade: 'LUIS EDUARDO MAGALHAES' },
  { estado: 'RN', cidade: 'LUIS GOMES' },
  { estado: 'MG', cidade: 'LUISBURGO' },
  { estado: 'MG', cidade: 'LUISLANDIA' },
  { estado: 'SC', cidade: 'LUIZ ALVES' },
  { estado: 'PR', cidade: 'LUIZIANA' },
  { estado: 'SP', cidade: 'LUIZIANIA' },
  { estado: 'MG', cidade: 'LUMINARIAS' },
  { estado: 'PR', cidade: 'LUNARDELLI' },
  { estado: 'SP', cidade: 'LUPERCIO' },
  { estado: 'PR', cidade: 'LUPIONOPOLIS' },
  { estado: 'SP', cidade: 'LUTECIA' },
  { estado: 'MG', cidade: 'LUZ' },
  { estado: 'SC', cidade: 'LUZERNA' },
  { estado: 'GO', cidade: 'LUZIANIA' },
  { estado: 'PI', cidade: 'LUZILANDIA' },
  { estado: 'TO', cidade: 'LUZINOPOLIS' },
  { estado: 'RJ', cidade: 'MACAE' },
  { estado: 'RN', cidade: 'MACAIBA' },
  { estado: 'BA', cidade: 'MACAJUBA' },
  { estado: 'RS', cidade: 'MACAMBARA' },
  { estado: 'SE', cidade: 'MACAMBIRA' },
  { estado: 'AP', cidade: 'MACAPA' },
  { estado: 'PE', cidade: 'MACAPARANA' },
  { estado: 'BA', cidade: 'MACARANI' },
  { estado: 'SP', cidade: 'MACATUBA' },
  { estado: 'RN', cidade: 'MACAU' },
  { estado: 'SP', cidade: 'MACAUBAL' },
  { estado: 'BA', cidade: 'MACAUBAS' },
  { estado: 'SP', cidade: 'MACEDONIA' },
  { estado: 'Al', cidade: 'MACEIO' },
  { estado: 'MG', cidade: 'MACHACALIS' },
  { estado: 'RS', cidade: 'MACHADINHO' },
  { estado: 'RO', cidade: 'MACHADINHO D´OESTE' },
  { estado: 'MG', cidade: 'MACHADO' },
  { estado: 'PE', cidade: 'MACHADOS' },
  { estado: 'SC', cidade: 'MACIEIRA' },
  { estado: 'RJ', cidade: 'MACUCO' },
  { estado: 'BA', cidade: 'MACURURE' },
  { estado: 'CE', cidade: 'MADALENA' },
  { estado: 'PI', cidade: 'MADEIRO' },
  { estado: 'BA', cidade: 'MADRE DE DEUS' },
  { estado: 'MG', cidade: 'MADRE DE DEUS DE MINAS' },
  { estado: 'PB', cidade: 'MAE D´AGUA' },
  { estado: 'PA', cidade: 'MAE DO RIO' },
  { estado: 'BA', cidade: 'MAETINGA' },
  { estado: 'SC', cidade: 'MAFRA' },
  { estado: 'PA', cidade: 'MAGALHAES BARATA' },
  { estado: 'MA', cidade: 'MAGALHAES DE ALMEIDA' },
  { estado: 'SP', cidade: 'MAGDA' },
  { estado: 'RJ', cidade: 'MAGE' },
  { estado: 'BA', cidade: 'MAIQUINIQUE' },
  { estado: 'BA', cidade: 'MAIRI' },
  { estado: 'SP', cidade: 'MAIRINQUE' },
  { estado: 'SP', cidade: 'MAIRIPORA' },
  { estado: 'GO', cidade: 'MAIRIPOTABA' },
  { estado: 'SC', cidade: 'MAJOR GERCINO' },
  { estado: 'Al', cidade: 'MAJOR ISIDORO' },
  { estado: 'RN', cidade: 'MAJOR SALES' },
  { estado: 'SC', cidade: 'MAJOR VIEIRA' },
  { estado: 'MG', cidade: 'MALACACHETA' },
  { estado: 'BA', cidade: 'MALHADA' },
  { estado: 'BA', cidade: 'MALHADA DE PEDRAS' },
  { estado: 'SE', cidade: 'MALHADA DO BOIS' },
  { estado: 'SE', cidade: 'MALHADOR' },
  { estado: 'PR', cidade: 'MALLET' },
  { estado: 'PB', cidade: 'MALTA' },
  { estado: 'PB', cidade: 'MAMANGUAPE' },
  { estado: 'GO', cidade: 'MAMBAI' },
  { estado: 'PR', cidade: 'MAMBORE' },
  { estado: 'MG', cidade: 'MAMONAS' },
  { estado: 'RS', cidade: 'MAMPITUBA' },
  { estado: 'AM', cidade: 'MANACAPURU' },
  { estado: 'PB', cidade: 'MANAIRA' },
  { estado: 'AM', cidade: 'MANAQUIRI' },
  { estado: 'PE', cidade: 'MANARI' },
  { estado: 'AM', cidade: 'MANAUS' },
  { estado: 'AC', cidade: 'MANCIO LIMA' },
  { estado: 'PR', cidade: 'MANDAGUACU' },
  { estado: 'PR', cidade: 'MANDAGUARI' },
  { estado: 'PR', cidade: 'MANDIRITUBA' },
  { estado: 'SP', cidade: 'MANDURI' },
  { estado: 'PR', cidade: 'MANFRINOPOLIS' },
  { estado: 'MG', cidade: 'MANGA' },
  { estado: 'RJ', cidade: 'MANGARATIBA' },
  { estado: 'PR', cidade: 'MANGUEIRINHA' },
  { estado: 'MG', cidade: 'MANHUACU' },
  { estado: 'MG', cidade: 'MANHUMIRIM' },
  { estado: 'AM', cidade: 'MANICORE' },
  { estado: 'PI', cidade: 'MANOEL EMIDIO' },
  { estado: 'PR', cidade: 'MANOEL RIBAS' },
  { estado: 'AC', cidade: 'MANOEL URBANO' },
  { estado: 'RS', cidade: 'MANOEL VIANA' },
  { estado: 'BA', cidade: 'MANOEL VITORINO' },
  { estado: 'BA', cidade: 'MANSIDAO' },
  { estado: 'MG', cidade: 'MANTENA' },
  { estado: 'ES', cidade: 'MANTENOPOLIS' },
  { estado: 'RS', cidade: 'MAQUINE' },
  { estado: 'MG', cidade: 'MAR DE ESPANHA' },
  { estado: 'Al', cidade: 'MAR VERMELHO' },
  { estado: 'GO', cidade: 'MARA ROSA' },
  { estado: 'AM', cidade: 'MARAA' },
  { estado: 'PA', cidade: 'MARABA' },
  { estado: 'SP', cidade: 'MARABA PAULISTA' },
  { estado: 'MA', cidade: 'MARACACUME' },
  { estado: 'SP', cidade: 'MARACAI' },
  { estado: 'SC', cidade: 'MARACAJA' },
  { estado: 'MS', cidade: 'MARACAJU' },
  { estado: 'PA', cidade: 'MARACANA' },
  { estado: 'CE', cidade: 'MARACANAU' },
  { estado: 'BA', cidade: 'MARACAS' },
  { estado: 'Al', cidade: 'MARAGOGI' },
  { estado: 'BA', cidade: 'MARAGOGIPE' },
  { estado: 'PE', cidade: 'MARAIAL' },
  { estado: 'MA', cidade: 'MARAJA DO SENA' },
  { estado: 'CE', cidade: 'MARANGUAPE' },
  { estado: 'MA', cidade: 'MARANHAOZINHO' },
  { estado: 'PA', cidade: 'MARAPANIM' },
  { estado: 'SP', cidade: 'MARAPOAMA' },
  { estado: 'RS', cidade: 'MARATA' },
  { estado: 'ES', cidade: 'MARATAIZES' },
  { estado: 'BA', cidade: 'MARAU' },
  { estado: 'RS', cidade: 'MARAU' },
  { estado: 'Al', cidade: 'MARAVILHA' },
  { estado: 'SC', cidade: 'MARAVILHA' },
  { estado: 'MG', cidade: 'MARAVILHAS' },
  { estado: 'PB', cidade: 'MARCACAO' },
  { estado: 'MT', cidade: 'MARCELANDIA' },
  { estado: 'RS', cidade: 'MARCELINO RAMOS' },
  { estado: 'RN', cidade: 'MARCELINO VIEIRA' },
  { estado: 'BA', cidade: 'MARCIONILIO SOUZA' },
  { estado: 'CE', cidade: 'MARCO' },
  { estado: 'PI', cidade: 'MARCOLANDIA' },
  { estado: 'PI', cidade: 'MARCOS PARENTE' },
  { estado: 'PR', cidade: 'MARECHAL CANDIDO RONDON' },
  { estado: 'Al', cidade: 'MARECHAL DEODORO' },
  { estado: 'ES', cidade: 'MARECHAL FLORIANO' },
  { estado: 'AC', cidade: 'MARECHAL THAUMATURGO' },
  { estado: 'SC', cidade: 'MAREMA' },
  { estado: 'PB', cidade: 'MARI' },
  { estado: 'MG', cidade: 'MARIA DA FE' },
  { estado: 'PR', cidade: 'MARIA HELENA' },
  { estado: 'PR', cidade: 'MARIALVA' },
  { estado: 'MG', cidade: 'MARIANA' },
  { estado: 'RS', cidade: 'MARIANA PIMENTEL' },
  { estado: 'RS', cidade: 'MARIANO MORO' },
  { estado: 'TO', cidade: 'MARIANOPOLIS DO TOCANTINS' },
  { estado: 'SP', cidade: 'MARIAPOLIS' },
  { estado: 'Al', cidade: 'MARIBONDO' },
  { estado: 'RJ', cidade: 'MARICA' },
  { estado: 'MG', cidade: 'MARILAC' },
  { estado: 'ES', cidade: 'MARILANDIA' },
  { estado: 'PR', cidade: 'MARILANDIA DO SUL' },
  { estado: 'PR', cidade: 'MARILENA' },
  { estado: 'SP', cidade: 'MARILIA' },
  { estado: 'PR', cidade: 'MARILUZ' },
  { estado: 'PR', cidade: 'MARINGA' },
  { estado: 'SP', cidade: 'MARINOPOLIS' },
  { estado: 'MG', cidade: 'MARIO CAMPOS' },
  { estado: 'PR', cidade: 'MARIOPOLIS' },
  { estado: 'PR', cidade: 'MARIPA' },
  { estado: 'MG', cidade: 'MARIPA DE MINAS' },
  { estado: 'PA', cidade: 'MARITUBA' },
  { estado: 'PB', cidade: 'MARIZOPOLIS' },
  { estado: 'MG', cidade: 'MARLIERIA' },
  { estado: 'PR', cidade: 'MARMELEIRO' },
  { estado: 'MG', cidade: 'MARMELOPOLIS' },
  { estado: 'RS', cidade: 'MARQUES DE SOUZA' },
  { estado: 'PR', cidade: 'MARQUINHO' },
  { estado: 'MG', cidade: 'MARTINHO CAMPOS' },
  { estado: 'CE', cidade: 'MARTINOPOLE' },
  { estado: 'SP', cidade: 'MARTINOPOLIS' },
  { estado: 'RN', cidade: 'MARTINS' },
  { estado: 'MG', cidade: 'MARTINS SOARES' },
  { estado: 'SE', cidade: 'MARUIM' },
  { estado: 'PR', cidade: 'MARUMBI' },
  { estado: 'GO', cidade: 'MARZAGAO' },
  { estado: 'BA', cidade: 'MASCOTE' },
  { estado: 'CE', cidade: 'MASSAPE' },
  { estado: 'PI', cidade: 'MASSAPE DO PIAUI' },
  { estado: 'PB', cidade: 'MASSARANDUBA' },
  { estado: 'SC', cidade: 'MASSARANDUBA' },
  { estado: 'RS', cidade: 'MATA' },
  { estado: 'BA', cidade: 'MATA DE SAO JOAO' },
  { estado: 'Al', cidade: 'MATA GRANDE' },
  { estado: 'MA', cidade: 'MATA ROMA' },
  { estado: 'MG', cidade: 'MATA VERDE' },
  { estado: 'SP', cidade: 'MATAO' },
  { estado: 'PB', cidade: 'MATARACA' },
  { estado: 'TO', cidade: 'MATEIROS' },
  { estado: 'PR', cidade: 'MATELANDIA' },
  { estado: 'MG', cidade: 'MATERLANDIA' },
  { estado: 'MG', cidade: 'MATEUS LEME' },
  { estado: 'MG', cidade: 'MATHIAS LOBATO' },
  { estado: 'MG', cidade: 'MATIAS BARBOSA' },
  { estado: 'MG', cidade: 'MATIAS CARDOSO' },
  { estado: 'PI', cidade: 'MATIAS OLIMPIO' },
  { estado: 'BA', cidade: 'MATINA' },
  { estado: 'MA', cidade: 'MATINHA' },
  { estado: 'PB', cidade: 'MATINHAS' },
  { estado: 'PR', cidade: 'MATINHOS' },
  { estado: 'MG', cidade: 'MATIPO' },
  { estado: 'RS', cidade: 'MATO CASTELHANO' },
  { estado: 'PB', cidade: 'MATO GROSSO' },
  { estado: 'RS', cidade: 'MATO LEITAO' },
  { estado: 'RS', cidade: 'MATO QUEIMADO' },
  { estado: 'PR', cidade: 'MATO RICO' },
  { estado: 'MG', cidade: 'MATO VERDE' },
  { estado: 'MA', cidade: 'MATOES' },
  { estado: 'MA', cidade: 'MATOES DO NORTE' },
  { estado: 'SC', cidade: 'MATOS COSTA' },
  { estado: 'MG', cidade: 'MATOZINHOS' },
  { estado: 'GO', cidade: 'MATRINCHA' },
  { estado: 'Al', cidade: 'MATRIZ DE CAMARAGIBE' },
  { estado: 'MT', cidade: 'MATUPA' },
  { estado: 'PB', cidade: 'MATUREIA' },
  { estado: 'MG', cidade: 'MATUTINA' },
  { estado: 'SP', cidade: 'MAUA' },
  { estado: 'PR', cidade: 'MAUA DA SERRA' },
  { estado: 'AM', cidade: 'MAUES' },
  { estado: 'GO', cidade: 'MAURILANDIA' },
  { estado: 'TO', cidade: 'MAURILANDIA DO TOCANTINS' },
  { estado: 'CE', cidade: 'MAURITI' },
  { estado: 'RN', cidade: 'MAXARANGUAPE' },
  { estado: 'RS', cidade: 'MAXIMILIANO DE ALMEIDA' },
  { estado: 'AP', cidade: 'MAZAGAO' },
  { estado: 'MG', cidade: 'MEDEIROS' },
  { estado: 'BA', cidade: 'MEDEIROS NETO' },
  { estado: 'PR', cidade: 'MEDIANEIRA' },
  { estado: 'PA', cidade: 'MEDICILANDIA' },
  { estado: 'MG', cidade: 'MEDINA' },
  { estado: 'SC', cidade: 'MELEIRO' },
  { estado: 'PA', cidade: 'MELGACO' },
  { estado: 'RJ', cidade: 'MENDES' },
  { estado: 'MG', cidade: 'MENDES PIMENTEL' },
  { estado: 'SP', cidade: 'MENDONCA' },
  { estado: 'PR', cidade: 'MERCEDES' },
  { estado: 'MG', cidade: 'MERCES' },
  { estado: 'SP', cidade: 'MERIDIANO' },
  { estado: 'CE', cidade: 'MERUOCA' },
  { estado: 'SP', cidade: 'MESOPOLIS' },
  { estado: 'RJ', cidade: 'MESQUITA' },
  { estado: 'MG', cidade: 'MESQUITA' },
  { estado: 'Al', cidade: 'MESSIAS' },
  { estado: 'RN', cidade: 'MESSIAS TARGINO' },
  { estado: 'PI', cidade: 'MIGUEL ALVES' },
  { estado: 'BA', cidade: 'MIGUEL CALMON' },
  { estado: 'PI', cidade: 'MIGUEL LEAO' },
  { estado: 'RJ', cidade: 'MIGUEL PEREIRA' },
  { estado: 'SP', cidade: 'MIGUELOPOLIS' },
  { estado: 'BA', cidade: 'MILAGRES' },
  { estado: 'CE', cidade: 'MILAGRES' },
  { estado: 'MA', cidade: 'MILAGRES DO MARANHAO' },
  { estado: 'CE', cidade: 'MILHA' },
  { estado: 'PI', cidade: 'MILTON BRANDAO' },
  { estado: 'GO', cidade: 'MIMOSO DE GOIAS' },
  { estado: 'ES', cidade: 'MIMOSO DO SUL' },
  { estado: 'GO', cidade: 'MINACU' },
  { estado: 'Al', cidade: 'MINADOR DO NEGRAO' },
  { estado: 'RS', cidade: 'MINAS DO LEAO' },
  { estado: 'MG', cidade: 'MINAS NOVAS' },
  { estado: 'MG', cidade: 'MINDURI' },
  { estado: 'GO', cidade: 'MINEIROS' },
  { estado: 'SP', cidade: 'MINEIROS DO TIETE' },
  { estado: 'RO', cidade: 'MINISTRO ANDREAZZA' },
  { estado: 'SP', cidade: 'MIRA ESTRELA' },
  { estado: 'MG', cidade: 'MIRABELA' },
  { estado: 'SP', cidade: 'MIRACATU' },
  { estado: 'RJ', cidade: 'MIRACEMA' },
  { estado: 'TO', cidade: 'MIRACEMA DO TOCANTINS' },
  { estado: 'MA', cidade: 'MIRADOR' },
  { estado: 'PR', cidade: 'MIRADOR' },
  { estado: 'MG', cidade: 'MIRADOURO' },
  { estado: 'RS', cidade: 'MIRAGUAI' },
  { estado: 'MG', cidade: 'MIRAI' },
  { estado: 'CE', cidade: 'MIRAIMA' },
  { estado: 'MS', cidade: 'MIRANDA' },
  { estado: 'MA', cidade: 'MIRANDA DO NORTE' },
  { estado: 'PE', cidade: 'MIRANDIBA' },
  { estado: 'SP', cidade: 'MIRANDOPOLIS' },
  { estado: 'BA', cidade: 'MIRANGABA' },
  { estado: 'TO', cidade: 'MIRANORTE' },
  { estado: 'BA', cidade: 'MIRANTE' },
  { estado: 'RO', cidade: 'MIRANTE DA SERRA' },
  { estado: 'SP', cidade: 'MIRANTE DO PARANAPANEMA' },
  { estado: 'PR', cidade: 'MIRASELVA' },
  { estado: 'SP', cidade: 'MIRASSOL' },
  { estado: 'MT', cidade: 'MIRASSOL D´OESTE' },
  { estado: 'SP', cidade: 'MIRASSOLANDIA' },
  { estado: 'MG', cidade: 'MIRAVANIA' },
  { estado: 'SC', cidade: 'MIRIM DOCE' },
  { estado: 'MA', cidade: 'MIRINZAL' },
  { estado: 'PR', cidade: 'MISSAL' },
  { estado: 'CE', cidade: 'MISSAO VELHA' },
  { estado: 'PA', cidade: 'MOCAJUBA' },
  { estado: 'SP', cidade: 'MOCOCA' },
  { estado: 'SC', cidade: 'MODELO' },
  { estado: 'MG', cidade: 'MOEDA' },
  { estado: 'MG', cidade: 'MOEMA' },
  { estado: 'PB', cidade: 'MOGEIRO' },
  { estado: 'SP', cidade: 'MOGI DAS CRUZES' },
  { estado: 'SP', cidade: 'MOGI-GUACU' },
  { estado: 'SP', cidade: 'MOGI-MIRIM' },
  { estado: 'GO', cidade: 'MOIPORA' },
  { estado: 'SE', cidade: 'MOITA BONITA' },
  { estado: 'PA', cidade: 'MOJU' },
  { estado: 'CE', cidade: 'MOMBACA' },
  { estado: 'SP', cidade: 'MOMBUCA' },
  { estado: 'MA', cidade: 'MONCAO' },
  { estado: 'SP', cidade: 'MONCOES' },
  { estado: 'SC', cidade: 'MONDAI' },
  { estado: 'SP', cidade: 'MONGAGUA' },
  { estado: 'MG', cidade: 'MONJOLOS' },
  { estado: 'PI', cidade: 'MONSENHOR GIL' },
  { estado: 'PI', cidade: 'MONSENHOR HIPOLITO' },
  { estado: 'MG', cidade: 'MONSENHOR PAULO' },
  { estado: 'CE', cidade: 'MONSENHOR TABOSA' },
  { estado: 'PB', cidade: 'MONTADAS' },
  { estado: 'MG', cidade: 'MONTALVANIA' },
  { estado: 'ES', cidade: 'MONTANHA' },
  { estado: 'RN', cidade: 'MONTANHAS' },
  { estado: 'RS', cidade: 'MONTAURI' },
  { estado: 'RN', cidade: 'MONTE ALEGRE' },
  { estado: 'PA', cidade: 'MONTE ALEGRE' },
  { estado: 'GO', cidade: 'MONTE ALEGRE DE GOIAS' },
  { estado: 'MG', cidade: 'MONTE ALEGRE DE MINAS' },
  { estado: 'SE', cidade: 'MONTE ALEGRE DE SERGIPE' },
  { estado: 'RS', cidade: 'MONTE ALEGRE DO CAMPOS' },
  { estado: 'PI', cidade: 'MONTE ALEGRE DO PIAUI' },
  { estado: 'SP', cidade: 'MONTE ALEGRE DO SUL' },
  { estado: 'SP', cidade: 'MONTE ALTO' },
  { estado: 'SP', cidade: 'MONTE APRAZIVEL' },
  { estado: 'MG', cidade: 'MONTE AZUL' },
  { estado: 'SP', cidade: 'MONTE AZUL PAULISTA' },
  { estado: 'MG', cidade: 'MONTE BELO' },
  { estado: 'RS', cidade: 'MONTE BELO DO SUL' },
  { estado: 'SC', cidade: 'MONTE CARLO' },
  { estado: 'MG', cidade: 'MONTE CARMELO' },
  { estado: 'SP', cidade: 'MONTE CASTELO' },
  { estado: 'SC', cidade: 'MONTE CASTELO' },
  { estado: 'RN', cidade: 'MONTE DAS GAMELEIRAS' },
  { estado: 'TO', cidade: 'MONTE DO CARMO' },
  { estado: 'MG', cidade: 'MONTE FORMOSO' },
  { estado: 'PB', cidade: 'MONTE HOREBE' },
  { estado: 'SP', cidade: 'MONTE MOR' },
  { estado: 'RO', cidade: 'MONTE NEGRO' },
  { estado: 'BA', cidade: 'MONTE SANTO' },
  { estado: 'MG', cidade: 'MONTE SANTO DE MINAS' },
  { estado: 'TO', cidade: 'MONTE SANTO DO TOCANTINS' },
  { estado: 'MG', cidade: 'MONTE SIAO' },
  { estado: 'PB', cidade: 'MONTEIRO' },
  { estado: 'SP', cidade: 'MONTEIRO LOBATO' },
  { estado: 'Al', cidade: 'MONTEIROPOLIS' },
  { estado: 'RS', cidade: 'MONTENEGRO' },
  { estado: 'MA', cidade: 'MONTES ALTOS' },
  { estado: 'MG', cidade: 'MONTES CLAROS' },
  { estado: 'GO', cidade: 'MONTES CLAROS DE GOIAS' },
  { estado: 'MG', cidade: 'MONTEZUMA' },
  { estado: 'GO', cidade: 'MONTIVIDIU' },
  { estado: 'GO', cidade: 'MONTIVIDIU DO NORTE' },
  { estado: 'CE', cidade: 'MORADA NOVA' },
  { estado: 'MG', cidade: 'MORADA NOVA DE MINAS' },
  { estado: 'CE', cidade: 'MORAUJO' },
  { estado: 'PE', cidade: 'MOREILANDIA' },
  { estado: 'PR', cidade: 'MOREIRA SALES' },
  { estado: 'PE', cidade: 'MORENO' },
  { estado: 'RS', cidade: 'MORMACO' },
  { estado: 'BA', cidade: 'MORPARA' },
  { estado: 'PR', cidade: 'MORRETES' },
  { estado: 'CE', cidade: 'MORRINHOS' },
  { estado: 'GO', cidade: 'MORRINHOS' },
  { estado: 'RS', cidade: 'MORRINHOS DO SUL' },
  { estado: 'SP', cidade: 'MORRO AGUDO' },
  { estado: 'GO', cidade: 'MORRO AGUDO DE GOIAS' },
  { estado: 'PI', cidade: 'MORRO CABECA NO TEMPO' },
  { estado: 'SC', cidade: 'MORRO DA FUMACA' },
  { estado: 'MG', cidade: 'MORRO DA GARCA' },
  { estado: 'BA', cidade: 'MORRO DO CHAPEU' },
  { estado: 'PI', cidade: 'MORRO DO CHAPEU DO PIAUI' },
  { estado: 'MG', cidade: 'MORRO DO PILAR' },
  { estado: 'SC', cidade: 'MORRO GRANDE' },
  { estado: 'RS', cidade: 'MORRO REDONDO' },
  { estado: 'RS', cidade: 'MORRO REUTER' },
  { estado: 'MA', cidade: 'MORROS' },
  { estado: 'BA', cidade: 'MORTUGABA' },
  { estado: 'SP', cidade: 'MORUNGABA' },
  { estado: 'GO', cidade: 'MOSSAMEDES' },
  { estado: 'RN', cidade: 'MOSSORO' },
  { estado: 'RS', cidade: 'MOSTARDAS' },
  { estado: 'SP', cidade: 'MOTUCA' },
  { estado: 'GO', cidade: 'MOZARLANDIA' },
  { estado: 'PA', cidade: 'MUANA' },
  { estado: 'RR', cidade: 'MUCAJAI' },
  { estado: 'CE', cidade: 'MUCAMBO' },
  { estado: 'BA', cidade: 'MUCUGE' },
  { estado: 'RS', cidade: 'MUCUM' },
  { estado: 'BA', cidade: 'MUCURI' },
  { estado: 'ES', cidade: 'MUCURICI' },
  { estado: 'RS', cidade: 'MUITOS CAPOES' },
  { estado: 'RS', cidade: 'MULITERNO' },
  { estado: 'PB', cidade: 'MULUNGU' },
  { estado: 'CE', cidade: 'MULUNGU' },
  { estado: 'BA', cidade: 'MULUNGU DO MORRO' },
  { estado: 'BA', cidade: 'MUNDO NOVO' },
  { estado: 'GO', cidade: 'MUNDO NOVO' },
  { estado: 'MS', cidade: 'MUNDO NOVO' },
  { estado: 'MG', cidade: 'MUNHOZ' },
  { estado: 'PR', cidade: 'MUNHOZ DE MELO' },
  { estado: 'BA', cidade: 'MUNIZ FERREIRA' },
  { estado: 'ES', cidade: 'MUNIZ FREIRE' },
  { estado: 'BA', cidade: 'MUQUEM DE SAO FRANCISCO' },
  { estado: 'ES', cidade: 'MUQUI' },
  { estado: 'MG', cidade: 'MURIAE' },
  { estado: 'SE', cidade: 'MURIBECA' },
  { estado: 'Al', cidade: 'MURICI' },
  { estado: 'PI', cidade: 'MURICI DO PORTELAS' },
  { estado: 'TO', cidade: 'MURICILANDIA' },
  { estado: 'BA', cidade: 'MURITIBA' },
  { estado: 'SP', cidade: 'MURUTINGA DO SUL' },
  { estado: 'BA', cidade: 'MUTUIPE' },
  { estado: 'MG', cidade: 'MUTUM' },
  { estado: 'GO', cidade: 'MUTUNOPOLIS' },
  { estado: 'MG', cidade: 'MUZAMBINHO' },
  { estado: 'MG', cidade: 'NACIP RAYDAN' },
  { estado: 'SP', cidade: 'NANTES' },
  { estado: 'MG', cidade: 'NANUQUE' },
  { estado: 'RS', cidade: 'NAO-ME-TOQUE' },
  { estado: 'MG', cidade: 'NAQUE' },
  { estado: 'SP', cidade: 'NARANDIBA' },
  { estado: 'RN', cidade: 'NATAL' },
  { estado: 'MG', cidade: 'NATALANDIA' },
  { estado: 'MG', cidade: 'NATERCIA' },
  { estado: 'RJ', cidade: 'NATIVIDADE' },
  { estado: 'TO', cidade: 'NATIVIDADE' },
  { estado: 'SP', cidade: 'NATIVIDADE DA SERRA' },
  { estado: 'PB', cidade: 'NATUBA' },
  { estado: 'SC', cidade: 'NAVEGANTES' },
  { estado: 'MS', cidade: 'NAVIRAI' },
  { estado: 'BA', cidade: 'NAZARE' },
  { estado: 'TO', cidade: 'NAZARE' },
  { estado: 'PE', cidade: 'NAZARE DA MATA' },
  { estado: 'PI', cidade: 'NAZARE DO PIAUI' },
  { estado: 'SP', cidade: 'NAZARE PAULISTA' },
  { estado: 'MG', cidade: 'NAZARENO' },
  { estado: 'PB', cidade: 'NAZAREZINHO' },
  { estado: 'GO', cidade: 'NAZARIO' },
  { estado: 'SE', cidade: 'NEOPOLIS' },
  { estado: 'MG', cidade: 'NEPOMUCENO' },
  { estado: 'GO', cidade: 'NEROPOLIS' },
  { estado: 'SP', cidade: 'NEVES PAULISTA' },
  { estado: 'AM', cidade: 'NHAMUNDA' },
  { estado: 'SP', cidade: 'NHANDEARA' },
  { estado: 'RS', cidade: 'NICOLAU VERGUEIRO' },
  { estado: 'BA', cidade: 'NILO PECANHA' },
  { estado: 'RJ', cidade: 'NILOPOLIS' },
  { estado: 'MA', cidade: 'NINA RODRIGUES' },
  { estado: 'MG', cidade: 'NINHEIRA' },
  { estado: 'MS', cidade: 'NIOAQUE' },
  { estado: 'SP', cidade: 'NIPOA' },
  { estado: 'GO', cidade: 'NIQUELANDIA' },
  { estado: 'RN', cidade: 'NISIA FLORESTA' },
  { estado: 'RJ', cidade: 'NITEROI' },
  { estado: 'MT', cidade: 'NOBRES' },
  { estado: 'RS', cidade: 'NONOAI' },
  { estado: 'BA', cidade: 'NORDESTINA' },
  { estado: 'RR', cidade: 'NORMANDIA' },
  { estado: 'MT', cidade: 'NORTELANDIA' },
  { estado: 'SE', cidade: 'NOSSA SENHORA APARECIDA' },
  { estado: 'SE', cidade: 'NOSSA SENHORA DA GLORIA' },
  { estado: 'SE', cidade: 'NOSSA SENHORA DAS DORES' },
  { estado: 'PR', cidade: 'NOSSA SENHORA DAS GRACAS' },
  { estado: 'SE', cidade: 'NOSSA SENHORA DE LOURDES' },
  { estado: 'PI', cidade: 'NOSSA SENHORA DE NAZARE' },
  { estado: 'MT', cidade: 'NOSSA SENHORA DO LIVRAMENTO' },
  { estado: 'PI', cidade: 'NOSSA SENHORA DO REMEDIOS' },
  { estado: 'SE', cidade: 'NOSSA SENHORA DO SOCORRO' },
  { estado: 'SP', cidade: 'NOVA ALIANCA' },
  { estado: 'PR', cidade: 'NOVA ALIANCA DO IVAI' },
  { estado: 'RS', cidade: 'NOVA ALVORADA' },
  { estado: 'MS', cidade: 'NOVA ALVORADA DO SUL' },
  { estado: 'GO', cidade: 'NOVA AMERICA' },
  { estado: 'PR', cidade: 'NOVA AMERICA DA COLINA' },
  { estado: 'MS', cidade: 'NOVA ANDRADINA' },
  { estado: 'RS', cidade: 'NOVA ARACA' },
  { estado: 'GO', cidade: 'NOVA AURORA' },
  { estado: 'PR', cidade: 'NOVA AURORA' },
  { estado: 'MT', cidade: 'NOVA BANDEIRANTES' },
  { estado: 'RS', cidade: 'NOVA BASSANO' },
  { estado: 'MG', cidade: 'NOVA BELEM' },
  { estado: 'RS', cidade: 'NOVA BOA VISTA' },
  { estado: 'MT', cidade: 'NOVA BRASILANDIA' },
  { estado: 'RO', cidade: 'NOVA BRASILANDIA D´OESTE' },
  { estado: 'RS', cidade: 'NOVA BRESCIA' },
  { estado: 'SP', cidade: 'NOVA CAMPINA' },
  { estado: 'BA', cidade: 'NOVA CANAA' },
  { estado: 'MT', cidade: 'NOVA CANAA DO NORTE' },
  { estado: 'SP', cidade: 'NOVA CANAA PAULISTA' },
  { estado: 'RS', cidade: 'NOVA CANDELARIA' },
  { estado: 'PR', cidade: 'NOVA CANTU' },
  { estado: 'SP', cidade: 'NOVA CASTILHO' },
  { estado: 'MA', cidade: 'NOVA COLINAS' },
  { estado: 'GO', cidade: 'NOVA CRIXAS' },
  { estado: 'RN', cidade: 'NOVA CRUZ' },
  { estado: 'MG', cidade: 'NOVA ERA' },
  { estado: 'SC', cidade: 'NOVA ERECHIM' },
  { estado: 'PR', cidade: 'NOVA ESPERANCA' },
  { estado: 'PA', cidade: 'NOVA ESPERANCA DO PIRIA' },
  { estado: 'PR', cidade: 'NOVA ESPERANCA DO SUDOESTE' },
  { estado: 'RS', cidade: 'NOVA ESPERANCA DO SUL' },
  { estado: 'SP', cidade: 'NOVA EUROPA' },
  { estado: 'BA', cidade: 'NOVA FATIMA' },
  { estado: 'PR', cidade: 'NOVA FATIMA' },
  { estado: 'PB', cidade: 'NOVA FLORESTA' },
  { estado: 'RJ', cidade: 'NOVA FRIBURGO' },
  { estado: 'GO', cidade: 'NOVA GLORIA' },
  { estado: 'SP', cidade: 'NOVA GRANADA' },
  { estado: 'MT', cidade: 'NOVA GUARITA' },
  { estado: 'SP', cidade: 'NOVA GUATAPORANGA' },
  { estado: 'RS', cidade: 'NOVA HARTZ' },
  { estado: 'BA', cidade: 'NOVA IBIA' },
  { estado: 'RJ', cidade: 'NOVA IGUACU' },
  { estado: 'GO', cidade: 'NOVA IGUACU DE GOIAS' },
  { estado: 'SP', cidade: 'NOVA INDEPENDENCIA' },
  { estado: 'MA', cidade: 'NOVA IORQUE' },
  { estado: 'PA', cidade: 'NOVA IPIXUNA' },
  { estado: 'SC', cidade: 'NOVA ITABERABA' },
  { estado: 'BA', cidade: 'NOVA ITARANA' },
  { estado: 'MT', cidade: 'NOVA LACERDA' },
  { estado: 'PR', cidade: 'NOVA LARANJEIRAS' },
  { estado: 'MG', cidade: 'NOVA LIMA' },
  { estado: 'PR', cidade: 'NOVA LONDRINA' },
  { estado: 'SP', cidade: 'NOVA LUZITANIA' },
  { estado: 'RO', cidade: 'NOVA MAMORE' },
  { estado: 'MT', cidade: 'NOVA MARILANDIA' },
  { estado: 'MT', cidade: 'NOVA MARINGA' },
  { estado: 'MG', cidade: 'NOVA MODICA' },
  { estado: 'MT', cidade: 'NOVA MONTE VERDE' },
  { estado: 'MT', cidade: 'NOVA MUTUM' },
  { estado: 'MT', cidade: 'NOVA NAZARE' },
  { estado: 'SP', cidade: 'NOVA ODESSA' },
  { estado: 'MT', cidade: 'NOVA OLIMPIA' },
  { estado: 'PR', cidade: 'NOVA OLIMPIA' },
  { estado: 'PB', cidade: 'NOVA OLINDA' },
  { estado: 'CE', cidade: 'NOVA OLINDA' },
  { estado: 'TO', cidade: 'NOVA OLINDA' },
  { estado: 'MA', cidade: 'NOVA OLINDA DO MARANHAO' },
  { estado: 'AM', cidade: 'NOVA OLINDA DO NORTE' },
  { estado: 'RS', cidade: 'NOVA PADUA' },
  { estado: 'RS', cidade: 'NOVA PALMA' },
  { estado: 'PB', cidade: 'NOVA PALMEIRA' },
  { estado: 'RS', cidade: 'NOVA PETROPOLIS' },
  { estado: 'MG', cidade: 'NOVA PONTE' },
  { estado: 'MG', cidade: 'NOVA PORTEIRINHA' },
  { estado: 'RS', cidade: 'NOVA PRATA' },
  { estado: 'PR', cidade: 'NOVA PRATA DO IGUACU' },
  { estado: 'RS', cidade: 'NOVA RAMADA' },
  { estado: 'BA', cidade: 'NOVA REDENCAO' },
  { estado: 'MG', cidade: 'NOVA RESENDE' },
  { estado: 'GO', cidade: 'NOVA ROMA' },
  { estado: 'RS', cidade: 'NOVA ROMA DO SUL' },
  { estado: 'TO', cidade: 'NOVA ROSALANDIA' },
  { estado: 'CE', cidade: 'NOVA RUSSAS' },
  { estado: 'PR', cidade: 'NOVA SANTA BARBARA' },
  { estado: 'MT', cidade: 'NOVA SANTA HELENA' },
  { estado: 'PI', cidade: 'NOVA SANTA RITA' },
  { estado: 'RS', cidade: 'NOVA SANTA RITA' },
  { estado: 'PR', cidade: 'NOVA SANTA ROSA' },
  { estado: 'MG', cidade: 'NOVA SERRANA' },
  { estado: 'BA', cidade: 'NOVA SOURE' },
  { estado: 'PR', cidade: 'NOVA TEBAS' },
  { estado: 'PA', cidade: 'NOVA TIMBOTEUA' },
  { estado: 'SC', cidade: 'NOVA TRENTO' },
  { estado: 'MT', cidade: 'NOVA UBIRATA' },
  { estado: 'MG', cidade: 'NOVA UNIAO' },
  { estado: 'RO', cidade: 'NOVA UNIAO' },
  { estado: 'ES', cidade: 'NOVA VENECIA' },
  { estado: 'GO', cidade: 'NOVA VENEZA' },
  { estado: 'SC', cidade: 'NOVA VENEZA' },
  { estado: 'BA', cidade: 'NOVA VICOSA' },
  { estado: 'MT', cidade: 'NOVA XAVANTINA' },
  { estado: 'SP', cidade: 'NOVAIS' },
  { estado: 'TO', cidade: 'NOVO ACORDO' },
  { estado: 'AM', cidade: 'NOVO AIRAO' },
  { estado: 'TO', cidade: 'NOVO ALEGRE' },
  { estado: 'AM', cidade: 'NOVO ARIPUANA' },
  { estado: 'RS', cidade: 'NOVO BARREIRO' },
  { estado: 'GO', cidade: 'NOVO BRASIL' },
  { estado: 'RS', cidade: 'NOVO CABRAIS' },
  { estado: 'MG', cidade: 'NOVO CRUZEIRO' },
  { estado: 'GO', cidade: 'NOVO GAMA' },
  { estado: 'RS', cidade: 'NOVO HAMBURGO' },
  { estado: 'SP', cidade: 'NOVO HORIZONTE' },
  { estado: 'BA', cidade: 'NOVO HORIZONTE' },
  { estado: 'SC', cidade: 'NOVO HORIZONTE' },
  { estado: 'MT', cidade: 'NOVO HORIZONTE DO NORTE' },
  { estado: 'RO', cidade: 'NOVO HORIZONTE DO OESTE' },
  { estado: 'MS', cidade: 'NOVO HORIZONTE DO SUL' },
  { estado: 'PR', cidade: 'NOVO ITACOLOMI' },
  { estado: 'TO', cidade: 'NOVO JARDIM' },
  { estado: 'Al', cidade: 'NOVO LINO' },
  { estado: 'RS', cidade: 'NOVO MACHADO' },
  { estado: 'MT', cidade: 'NOVO MUNDO' },
  { estado: 'CE', cidade: 'NOVO ORIENTE' },
  { estado: 'MG', cidade: 'NOVO ORIENTE DE MINAS' },
  { estado: 'PI', cidade: 'NOVO ORIENTE DO PIAUI' },
  { estado: 'GO', cidade: 'NOVO PLANALTO' },
  { estado: 'PA', cidade: 'NOVO PROGRESSO' },
  { estado: 'PA', cidade: 'NOVO REPARTIMENTO' },
  { estado: 'PI', cidade: 'NOVO SANTO ANTONIO' },
  { estado: 'MT', cidade: 'NOVO SANTO ANTONIO' },
  { estado: 'MT', cidade: 'NOVO SAO JOAQUIM' },
  { estado: 'RS', cidade: 'NOVO TIRADENTES' },
  { estado: 'BA', cidade: 'NOVO TRIUNFO' },
  { estado: 'RS', cidade: 'NOVO XINGU' },
  { estado: 'MG', cidade: 'NOVORIZONTE' },
  { estado: 'SP', cidade: 'NUPORANGA' },
  { estado: 'PA', cidade: 'OBIDOS' },
  { estado: 'CE', cidade: 'OCARA' },
  { estado: 'SP', cidade: 'OCAUCU' },
  { estado: 'PI', cidade: 'OEIRAS' },
  { estado: 'PA', cidade: 'OEIRAS DO PARA' },
  { estado: 'AP', cidade: 'OIAPOQUE' },
  { estado: 'MG', cidade: 'OLARIA' },
  { estado: 'SP', cidade: 'OLEO' },
  { estado: 'PI', cidade: 'OLHO D AGUA DO PIAUI' },
  { estado: 'PB', cidade: 'OLHO D´AGUA' },
  { estado: 'MA', cidade: 'OLHO D´AGUA DAS CUNHAS' },
  { estado: 'Al', cidade: 'OLHO D´AGUA DAS FLORES' },
  { estado: 'Al', cidade: 'OLHO D´AGUA DO CASADO' },
  { estado: 'Al', cidade: 'OLHO D´AGUA GRANDE' },
  { estado: 'RN', cidade: 'OLHO-D´AGUA DO BORGES' },
  { estado: 'MG', cidade: 'OLHOS-D AGUA' },
  { estado: 'SP', cidade: 'OLIMPIA' },
  { estado: 'MG', cidade: 'OLIMPIO NORONHA' },
  { estado: 'PE', cidade: 'OLINDA' },
  { estado: 'MA', cidade: 'OLINDA NOVA DO MARANHAO' },
  { estado: 'BA', cidade: 'OLINDINA' },
  { estado: 'PB', cidade: 'OLIVEDOS' },
  { estado: 'MG', cidade: 'OLIVEIRA' },
  { estado: 'TO', cidade: 'OLIVEIRA DE FATIMA' },
  { estado: 'BA', cidade: 'OLIVEIRA DO BREJINHOS' },
  { estado: 'MG', cidade: 'OLIVEIRA FORTES' },
  { estado: 'Al', cidade: 'OLIVENCA' },
  { estado: 'MG', cidade: 'ONCA DE PITANGUI' },
  { estado: 'SP', cidade: 'ONDA VERDE' },
  { estado: 'MG', cidade: 'ORATORIOS' },
  { estado: 'SP', cidade: 'ORIENTE' },
  { estado: 'SP', cidade: 'ORINDIUVA' },
  { estado: 'PA', cidade: 'ORIXIMINA' },
  { estado: 'MG', cidade: 'ORIZANIA' },
  { estado: 'GO', cidade: 'ORIZONA' },
  { estado: 'SP', cidade: 'ORLANDIA' },
  { estado: 'SC', cidade: 'ORLEANS' },
  { estado: 'PE', cidade: 'OROBO' },
  { estado: 'PE', cidade: 'OROCO' },
  { estado: 'CE', cidade: 'OROS' },
  { estado: 'PR', cidade: 'ORTIGUEIRA' },
  { estado: 'SP', cidade: 'OSASCO' },
  { estado: 'SP', cidade: 'OSCAR BRESSANE' },
  { estado: 'RS', cidade: 'OSORIO' },
  { estado: 'SP', cidade: 'OSVALDO CRUZ' },
  { estado: 'SC', cidade: 'OTACILIO COSTA' },
  { estado: 'PA', cidade: 'OUREM' },
  { estado: 'BA', cidade: 'OURICANGAS' },
  { estado: 'PE', cidade: 'OURICURI' },
  { estado: 'PA', cidade: 'OURILANDIA DO NORTE' },
  { estado: 'SP', cidade: 'OURINHOS' },
  { estado: 'PR', cidade: 'OURIZONA' },
  { estado: 'SC', cidade: 'OURO' },
  { estado: 'MG', cidade: 'OURO BRANCO' },
  { estado: 'Al', cidade: 'OURO BRANCO' },
  { estado: 'RN', cidade: 'OURO BRANCO' },
  { estado: 'MG', cidade: 'OURO FINO' },
  { estado: 'MG', cidade: 'OURO PRETO' },
  { estado: 'RO', cidade: 'OURO PRETO DO OESTE' },
  { estado: 'PB', cidade: 'OURO VELHO' },
  { estado: 'SP', cidade: 'OURO VERDE' },
  { estado: 'SC', cidade: 'OURO VERDE' },
  { estado: 'GO', cidade: 'OURO VERDE DE GOIAS' },
  { estado: 'MG', cidade: 'OURO VERDE DE MINAS' },
  { estado: 'PR', cidade: 'OURO VERDE DO OESTE' },
  { estado: 'SP', cidade: 'OUROESTE' },
  { estado: 'BA', cidade: 'OUROLANDIA' },
  { estado: 'GO', cidade: 'OUVIDOR' },
  { estado: 'SP', cidade: 'PACAEMBU' },
  { estado: 'PA', cidade: 'PACAJA' },
  { estado: 'CE', cidade: 'PACAJUS' },
  { estado: 'RR', cidade: 'PACARAIMA' },
  { estado: 'SE', cidade: 'PACATUBA' },
  { estado: 'CE', cidade: 'PACATUBA' },
  { estado: 'MA', cidade: 'PACO DO LUMIAR' },
  { estado: 'CE', cidade: 'PACOTI' },
  { estado: 'CE', cidade: 'PACUJA' },
  { estado: 'GO', cidade: 'PADRE BERNARDO' },
  { estado: 'MG', cidade: 'PADRE CARVALHO' },
  { estado: 'PI', cidade: 'PADRE MARCOS' },
  { estado: 'MG', cidade: 'PADRE PARAISO' },
  { estado: 'PI', cidade: 'PAES LANDIM' },
  { estado: 'MG', cidade: 'PAI PEDRO' },
  { estado: 'SC', cidade: 'PAIAL' },
  { estado: 'PR', cidade: 'PAICANDU' },
  { estado: 'RS', cidade: 'PAIM FILHO' },
  { estado: 'MG', cidade: 'PAINEIRAS' },
  { estado: 'SC', cidade: 'PAINEL' },
  { estado: 'MG', cidade: 'PAINS' },
  { estado: 'MG', cidade: 'PAIVA' },
  { estado: 'PI', cidade: 'PAJEU DO PIAUI' },
  { estado: 'SP', cidade: 'PALESTINA' },
  { estado: 'Al', cidade: 'PALESTINA' },
  { estado: 'GO', cidade: 'PALESTINA DE GOIAS' },
  { estado: 'PA', cidade: 'PALESTINA DO PARA' },
  { estado: 'CE', cidade: 'PALHANO' },
  { estado: 'SC', cidade: 'PALHOCA' },
  { estado: 'MG', cidade: 'PALMA' },
  { estado: 'SC', cidade: 'PALMA SOLA' },
  { estado: 'CE', cidade: 'PALMACIA' },
  { estado: 'PE', cidade: 'PALMARES' },
  { estado: 'RS', cidade: 'PALMARES DO SUL' },
  { estado: 'SP', cidade: 'PALMARES PAULISTA' },
  { estado: 'TO', cidade: 'PALMAS' },
  { estado: 'PR', cidade: 'PALMAS' },
  { estado: 'BA', cidade: 'PALMAS DE MONTE ALTO' },
  { estado: 'PR', cidade: 'PALMEIRA' },
  { estado: 'SC', cidade: 'PALMEIRA' },
  { estado: 'SP', cidade: 'PALMEIRA D´OESTE' },
  { estado: 'RS', cidade: 'PALMEIRA DAS MISSOES' },
  { estado: 'Al', cidade: 'PALMEIRA DO INDIOS' },
  { estado: 'PI', cidade: 'PALMEIRA DO PIAUI' },
  { estado: 'PI', cidade: 'PALMEIRAIS' },
  { estado: 'MA', cidade: 'PALMEIRANDIA' },
  { estado: 'TO', cidade: 'PALMEIRANTE' },
  { estado: 'BA', cidade: 'PALMEIRAS' },
  { estado: 'GO', cidade: 'PALMEIRAS DE GOIAS' },
  { estado: 'TO', cidade: 'PALMEIRAS DO TOCANTINS' },
  { estado: 'PE', cidade: 'PALMEIRINA' },
  { estado: 'TO', cidade: 'PALMEIROPOLIS' },
  { estado: 'GO', cidade: 'PALMELO' },
  { estado: 'GO', cidade: 'PALMINOPOLIS' },
  { estado: 'SP', cidade: 'PALMITAL' },
  { estado: 'PR', cidade: 'PALMITAL' },
  { estado: 'RS', cidade: 'PALMITINHO' },
  { estado: 'SC', cidade: 'PALMITOS' },
  { estado: 'MG', cidade: 'PALMOPOLIS' },
  { estado: 'PR', cidade: 'PALOTINA' },
  { estado: 'GO', cidade: 'PANAMA' },
  { estado: 'RS', cidade: 'PANAMBI' },
  { estado: 'ES', cidade: 'PANCAS' },
  { estado: 'PE', cidade: 'PANELAS' },
  { estado: 'SP', cidade: 'PANORAMA' },
  { estado: 'RS', cidade: 'PANTANO GRANDE' },
  { estado: 'Al', cidade: 'PAO DE ACUCAR' },
  { estado: 'MG', cidade: 'PAPAGAIOS' },
  { estado: 'SC', cidade: 'PAPANDUVA' },
  { estado: 'PI', cidade: 'PAQUETA' },
  { estado: 'MG', cidade: 'PARA DE MINAS' },
  { estado: 'RJ', cidade: 'PARACAMBI' },
  { estado: 'MG', cidade: 'PARACATU' },
  { estado: 'CE', cidade: 'PARACURU' },
  { estado: 'PA', cidade: 'PARAGOMINAS' },
  { estado: 'MG', cidade: 'PARAGUACU' },
  { estado: 'SP', cidade: 'PARAGUACU PAULISTA' },
  { estado: 'RS', cidade: 'PARAI' },
  { estado: 'RJ', cidade: 'PARAIBA DO SUL' },
  { estado: 'MA', cidade: 'PARAIBANO' },
  { estado: 'SP', cidade: 'PARAIBUNA' },
  { estado: 'CE', cidade: 'PARAIPABA' },
  { estado: 'SP', cidade: 'PARAISO' },
  { estado: 'SC', cidade: 'PARAISO' },
  { estado: 'PR', cidade: 'PARAISO DO NORTE' },
  { estado: 'RS', cidade: 'PARAISO DO SUL' },
  { estado: 'TO', cidade: 'PARAISO DO TOCANTINS' },
  { estado: 'MG', cidade: 'PARAISOPOLIS' },
  { estado: 'CE', cidade: 'PARAMBU' },
  { estado: 'BA', cidade: 'PARAMIRIM' },
  { estado: 'CE', cidade: 'PARAMOTI' },
  { estado: 'RN', cidade: 'PARANA' },
  { estado: 'TO', cidade: 'PARANA' },
  { estado: 'PR', cidade: 'PARANACITY' },
  { estado: 'PR', cidade: 'PARANAGUA' },
  { estado: 'MS', cidade: 'PARANAIBA' },
  { estado: 'GO', cidade: 'PARANAIGUARA' },
  { estado: 'MT', cidade: 'PARANAITA' },
  { estado: 'SP', cidade: 'PARANAPANEMA' },
  { estado: 'PR', cidade: 'PARANAPOEMA' },
  { estado: 'SP', cidade: 'PARANAPUA' },
  { estado: 'PE', cidade: 'PARANATAMA' },
  { estado: 'MT', cidade: 'PARANATINGA' },
  { estado: 'PR', cidade: 'PARANAVAI' },
  { estado: 'MS', cidade: 'PARANHOS' },
  { estado: 'MG', cidade: 'PARAOPEBA' },
  { estado: 'SP', cidade: 'PARAPUA' },
  { estado: 'PB', cidade: 'PARARI' },
  { estado: 'RJ', cidade: 'PARATI' },
  { estado: 'BA', cidade: 'PARATINGA' },
  { estado: 'RN', cidade: 'PARAU' },
  { estado: 'PA', cidade: 'PARAUAPEBAS' },
  { estado: 'GO', cidade: 'PARAUNA' },
  { estado: 'RN', cidade: 'PARAZINHO' },
  { estado: 'SP', cidade: 'PARDINHO' },
  { estado: 'RS', cidade: 'PARECI NOVO' },
  { estado: 'RO', cidade: 'PARECIS' },
  { estado: 'RN', cidade: 'PARELHAS' },
  { estado: 'Al', cidade: 'PARICONHA' },
  { estado: 'AM', cidade: 'PARINTINS' },
  { estado: 'BA', cidade: 'PARIPIRANGA' },
  { estado: 'Al', cidade: 'PARIPUEIRA' },
  { estado: 'SP', cidade: 'PARIQUERA-ACU' },
  { estado: 'SP', cidade: 'PARISI' },
  { estado: 'PI', cidade: 'PARNAGUA' },
  { estado: 'PI', cidade: 'PARNAIBA' },
  { estado: 'PE', cidade: 'PARNAMIRIM' },
  { estado: 'RN', cidade: 'PARNAMIRIM' },
  { estado: 'MA', cidade: 'PARNARAMA' },
  { estado: 'RS', cidade: 'PAROBE' },
  { estado: 'RN', cidade: 'PASSA E FICA' },
  { estado: 'MG', cidade: 'PASSA QUATRO' },
  { estado: 'RS', cidade: 'PASSA SETE' },
  { estado: 'MG', cidade: 'PASSA TEMPO' },
  { estado: 'MG', cidade: 'PASSA VINTE' },
  { estado: 'MG', cidade: 'PASSABEM' },
  { estado: 'PB', cidade: 'PASSAGEM' },
  { estado: 'RN', cidade: 'PASSAGEM' },
  { estado: 'MA', cidade: 'PASSAGEM FRANCA' },
  { estado: 'PI', cidade: 'PASSAGEM FRANCA DO PIAUI' },
  { estado: 'PE', cidade: 'PASSIRA' },
  { estado: 'Al', cidade: 'PASSO DE CAMARAGIBE' },
  { estado: 'SC', cidade: 'PASSO DE TORRES' },
  { estado: 'RS', cidade: 'PASSO DO SOBRADO' },
  { estado: 'RS', cidade: 'PASSO FUNDO' },
  { estado: 'MG', cidade: 'PASSOS' },
  { estado: 'SC', cidade: 'PASSOS MAIA' },
  { estado: 'MA', cidade: 'PASTOS BONS' },
  { estado: 'MG', cidade: 'PATIS' },
  { estado: 'PR', cidade: 'PATO BRAGADO' },
  { estado: 'PR', cidade: 'PATO BRANCO' },
  { estado: 'PB', cidade: 'PATOS' },
  { estado: 'MG', cidade: 'PATOS DE MINAS' },
  { estado: 'PI', cidade: 'PATOS DO PIAUI' },
  { estado: 'MG', cidade: 'PATROCINIO' },
  { estado: 'MG', cidade: 'PATROCINIO DO MURIAE' },
  { estado: 'SP', cidade: 'PATROCINIO PAULISTA' },
  { estado: 'RN', cidade: 'PATU' },
  { estado: 'RJ', cidade: 'PATY DO ALFERES' },
  { estado: 'BA', cidade: 'PAU BRASIL' },
  { estado: 'PA', cidade: 'PAU D´ARCO' },
  { estado: 'TO', cidade: 'PAU D´ARCO' },
  { estado: 'PI', cidade: 'PAU D´ARCO DO PIAUI' },
  { estado: 'RN', cidade: 'PAU DO FERROS' },
  { estado: 'PE', cidade: 'PAUDALHO' },
  { estado: 'AM', cidade: 'PAUINI' },
  { estado: 'MG', cidade: 'PAULA CANDIDO' },
  { estado: 'PR', cidade: 'PAULA FREITAS' },
  { estado: 'SP', cidade: 'PAULICEIA' },
  { estado: 'SP', cidade: 'PAULINIA' },
  { estado: 'MA', cidade: 'PAULINO NEVES' },
  { estado: 'PE', cidade: 'PAULISTA' },
  { estado: 'PB', cidade: 'PAULISTA' },
  { estado: 'PI', cidade: 'PAULISTANA' },
  { estado: 'SP', cidade: 'PAULISTANIA' },
  { estado: 'MG', cidade: 'PAULISTAS' },
  { estado: 'BA', cidade: 'PAULO AFONSO' },
  { estado: 'RS', cidade: 'PAULO BENTO' },
  { estado: 'SP', cidade: 'PAULO DE FARIA' },
  { estado: 'PR', cidade: 'PAULO FRONTIN' },
  { estado: 'Al', cidade: 'PAULO JACINTO' },
  { estado: 'SC', cidade: 'PAULO LOPES' },
  { estado: 'MA', cidade: 'PAULO RAMOS' },
  { estado: 'MG', cidade: 'PAVAO' },
  { estado: 'RS', cidade: 'PAVERAMA' },
  { estado: 'PI', cidade: 'PAVUSSU' },
  { estado: 'BA', cidade: 'PE DE SERRA' },
  { estado: 'PR', cidade: 'PEABIRU' },
  { estado: 'MG', cidade: 'PECANHA' },
  { estado: 'SP', cidade: 'PEDERNEIRAS' },
  { estado: 'PE', cidade: 'PEDRA' },
  { estado: 'MG', cidade: 'PEDRA AZUL' },
  { estado: 'SP', cidade: 'PEDRA BELA' },
  { estado: 'MG', cidade: 'PEDRA BONITA' },
  { estado: 'PB', cidade: 'PEDRA BRANCA' },
  { estado: 'CE', cidade: 'PEDRA BRANCA' },
  { estado: 'AP', cidade: 'PEDRA BRANCA DO AMAPARI' },
  { estado: 'MG', cidade: 'PEDRA DO ANTA' },
  { estado: 'MG', cidade: 'PEDRA DO INDAIA' },
  { estado: 'MG', cidade: 'PEDRA DOURADA' },
  { estado: 'RN', cidade: 'PEDRA GRANDE' },
  { estado: 'PB', cidade: 'PEDRA LAVRADA' },
  { estado: 'SE', cidade: 'PEDRA MOLE' },
  { estado: 'RN', cidade: 'PEDRA PRETA' },
  { estado: 'MT', cidade: 'PEDRA PRETA' },
  { estado: 'MG', cidade: 'PEDRALVA' },
  { estado: 'SP', cidade: 'PEDRANOPOLIS' },
  { estado: 'BA', cidade: 'PEDRAO' },
  { estado: 'RS', cidade: 'PEDRAS ALTAS' },
  { estado: 'PB', cidade: 'PEDRAS DE FOGO' },
  { estado: 'MG', cidade: 'PEDRAS DE MARIA DA CRUZ' },
  { estado: 'SC', cidade: 'PEDRAS GRANDES' },
  { estado: 'SP', cidade: 'PEDREGULHO' },
  { estado: 'SP', cidade: 'PEDREIRA' },
  { estado: 'MA', cidade: 'PEDREIRAS' },
  { estado: 'SE', cidade: 'PEDRINHAS' },
  { estado: 'SP', cidade: 'PEDRINHAS PAULISTA' },
  { estado: 'MG', cidade: 'PEDRINOPOLIS' },
  { estado: 'TO', cidade: 'PEDRO AFONSO' },
  { estado: 'BA', cidade: 'PEDRO ALEXANDRE' },
  { estado: 'RN', cidade: 'PEDRO AVELINO' },
  { estado: 'ES', cidade: 'PEDRO CANARIO' },
  { estado: 'SP', cidade: 'PEDRO DE TOLEDO' },
  { estado: 'MA', cidade: 'PEDRO DO ROSARIO' },
  { estado: 'MS', cidade: 'PEDRO GOMES' },
  { estado: 'PI', cidade: 'PEDRO II' },
  { estado: 'PI', cidade: 'PEDRO LAURENTINO' },
  { estado: 'MG', cidade: 'PEDRO LEOPOLDO' },
  { estado: 'RS', cidade: 'PEDRO OSORIO' },
  { estado: 'PB', cidade: 'PEDRO REGIS' },
  { estado: 'MG', cidade: 'PEDRO TEIXEIRA' },
  { estado: 'RN', cidade: 'PEDRO VELHO' },
  { estado: 'TO', cidade: 'PEIXE' },
  { estado: 'PA', cidade: 'PEIXE-BOI' },
  { estado: 'MT', cidade: 'PEIXOTO DE AZEVEDO' },
  { estado: 'RS', cidade: 'PEJUCARA' },
  { estado: 'RS', cidade: 'PELOTAS' },
  { estado: 'CE', cidade: 'PENAFORTE' },
  { estado: 'MA', cidade: 'PENALVA' },
  { estado: 'SP', cidade: 'PENAPOLIS' },
  { estado: 'RN', cidade: 'PENDENCIAS' },
  { estado: 'Al', cidade: 'PENEDO' },
  { estado: 'SC', cidade: 'PENHA' },
  { estado: 'CE', cidade: 'PENTECOSTE' },
  { estado: 'MG', cidade: 'PEQUERI' },
  { estado: 'MG', cidade: 'PEQUI' },
  { estado: 'TO', cidade: 'PEQUIZEIRO' },
  { estado: 'MG', cidade: 'PERDIGAO' },
  { estado: 'MG', cidade: 'PERDIZES' },
  { estado: 'MG', cidade: 'PERDOES' },
  { estado: 'SP', cidade: 'PEREIRA BARRETO' },
  { estado: 'SP', cidade: 'PEREIRAS' },
  { estado: 'CE', cidade: 'PEREIRO' },
  { estado: 'MA', cidade: 'PERI MIRIM' },
  { estado: 'MG', cidade: 'PERIQUITO' },
  { estado: 'SC', cidade: 'PERITIBA' },
  { estado: 'MA', cidade: 'PERITORO' },
  { estado: 'PR', cidade: 'PEROBAL' },
  { estado: 'PR', cidade: 'PEROLA' },
  { estado: 'PR', cidade: 'PEROLA D´OESTE' },
  { estado: 'GO', cidade: 'PEROLANDIA' },
  { estado: 'SP', cidade: 'PERUIBE' },
  { estado: 'MG', cidade: 'PESCADOR' },
  { estado: 'PE', cidade: 'PESQUEIRA' },
  { estado: 'PE', cidade: 'PETROLANDIA' },
  { estado: 'SC', cidade: 'PETROLANDIA' },
  { estado: 'PE', cidade: 'PETROLINA' },
  { estado: 'GO', cidade: 'PETROLINA DE GOIAS' },
  { estado: 'RJ', cidade: 'PETROPOLIS' },
  { estado: 'Al', cidade: 'PIACABUCU' },
  { estado: 'SP', cidade: 'PIACATU' },
  { estado: 'PB', cidade: 'PIANCO' },
  { estado: 'BA', cidade: 'PIATA' },
  { estado: 'MG', cidade: 'PIAU' },
  { estado: 'RS', cidade: 'PICADA CAFE' },
  { estado: 'PA', cidade: 'PICARRA' },
  { estado: 'SC', cidade: 'PICARRAS' },
  { estado: 'PI', cidade: 'PICOS' },
  { estado: 'PB', cidade: 'PICUI' },
  { estado: 'SP', cidade: 'PIEDADE' },
  { estado: 'MG', cidade: 'PIEDADE DE CARATINGA' },
  { estado: 'MG', cidade: 'PIEDADE DE PONTE NOVA' },
  { estado: 'MG', cidade: 'PIEDADE DO GERAIS' },
  { estado: 'MG', cidade: 'PIEDADE DO RIO GRANDE' },
  { estado: 'PR', cidade: 'PIEN' },
  { estado: 'BA', cidade: 'PILAO ARCADO' },
  { estado: 'Al', cidade: 'PILAR' },
  { estado: 'PB', cidade: 'PILAR' },
  { estado: 'GO', cidade: 'PILAR DE GOIAS' },
  { estado: 'SP', cidade: 'PILAR DO SUL' },
  { estado: 'PB', cidade: 'PILOES' },
  { estado: 'RN', cidade: 'PILOES' },
  { estado: 'PB', cidade: 'PILOEZINHOS' },
  { estado: 'MG', cidade: 'PIMENTA' },
  { estado: 'RO', cidade: 'PIMENTA BUENO' },
  { estado: 'PI', cidade: 'PIMENTEIRAS' },
  { estado: 'RO', cidade: 'PIMENTEIRAS DO OESTE' },
  { estado: 'BA', cidade: 'PINDAI' },
  { estado: 'SP', cidade: 'PINDAMONHANGABA' },
  { estado: 'MA', cidade: 'PINDARE MIRIM' },
  { estado: 'Al', cidade: 'PINDOBA' },
  { estado: 'BA', cidade: 'PINDOBACU' },
  { estado: 'SP', cidade: 'PINDORAMA' },
  { estado: 'TO', cidade: 'PINDORAMA DO TOCANTINS' },
  { estado: 'CE', cidade: 'PINDORETAMA' },
  { estado: 'MG', cidade: 'PINGO D AGUA' },
  { estado: 'PR', cidade: 'PINHAIS' },
  { estado: 'RS', cidade: 'PINHAL' },
  { estado: 'RS', cidade: 'PINHAL DA SERRA' },
  { estado: 'PR', cidade: 'PINHAL DE SAO BENTO' },
  { estado: 'RS', cidade: 'PINHAL GRANDE' },
  { estado: 'PR', cidade: 'PINHALAO' },
  { estado: 'SP', cidade: 'PINHALZINHO' },
  { estado: 'SC', cidade: 'PINHALZINHO' },
  { estado: 'SE', cidade: 'PINHAO' },
  { estado: 'PR', cidade: 'PINHAO' },
  { estado: 'RJ', cidade: 'PINHEIRAL' },
  { estado: 'RS', cidade: 'PINHEIRINHO DO VALE' },
  { estado: 'MA', cidade: 'PINHEIRO' },
  { estado: 'RS', cidade: 'PINHEIRO MACHADO' },
  { estado: 'SC', cidade: 'PINHEIRO PRETO' },
  { estado: 'ES', cidade: 'PINHEIROS' },
  { estado: 'BA', cidade: 'PINTADAS' },
  { estado: 'MG', cidade: 'PINTOPOLIS' },
  { estado: 'PI', cidade: 'PIO IX' },
  { estado: 'MA', cidade: 'PIO XII' },
  { estado: 'SP', cidade: 'PIQUEROBI' },
  { estado: 'CE', cidade: 'PIQUET CARNEIRO' },
  { estado: 'SP', cidade: 'PIQUETE' },
  { estado: 'SP', cidade: 'PIRACAIA' },
  { estado: 'GO', cidade: 'PIRACANJUBA' },
  { estado: 'MG', cidade: 'PIRACEMA' },
  { estado: 'SP', cidade: 'PIRACICABA' },
  { estado: 'PI', cidade: 'PIRACURUCA' },
  { estado: 'RJ', cidade: 'PIRAI' },
  { estado: 'BA', cidade: 'PIRAI DO NORTE' },
  { estado: 'PR', cidade: 'PIRAI DO SUL' },
  { estado: 'SP', cidade: 'PIRAJU' },
  { estado: 'MG', cidade: 'PIRAJUBA' },
  { estado: 'SP', cidade: 'PIRAJUI' },
  { estado: 'SE', cidade: 'PIRAMBU' },
  { estado: 'MG', cidade: 'PIRANGA' },
  { estado: 'SP', cidade: 'PIRANGI' },
  { estado: 'MG', cidade: 'PIRANGUCU' },
  { estado: 'MG', cidade: 'PIRANGUINHO' },
  { estado: 'Al', cidade: 'PIRANHAS' },
  { estado: 'GO', cidade: 'PIRANHAS' },
  { estado: 'MA', cidade: 'PIRAPEMAS' },
  { estado: 'MG', cidade: 'PIRAPETINGA' },
  { estado: 'RS', cidade: 'PIRAPO' },
  { estado: 'MG', cidade: 'PIRAPORA' },
  { estado: 'SP', cidade: 'PIRAPORA DO BOM JESUS' },
  { estado: 'SP', cidade: 'PIRAPOZINHO' },
  { estado: 'PR', cidade: 'PIRAQUARA' },
  { estado: 'TO', cidade: 'PIRAQUE' },
  { estado: 'SP', cidade: 'PIRASSUNUNGA' },
  { estado: 'RS', cidade: 'PIRATINI' },
  { estado: 'SP', cidade: 'PIRATININGA' },
  { estado: 'SC', cidade: 'PIRATUBA' },
  { estado: 'MG', cidade: 'PIRAUBA' },
  { estado: 'GO', cidade: 'PIRENOPOLIS' },
  { estado: 'GO', cidade: 'PIRES DO RIO' },
  { estado: 'CE', cidade: 'PIRES FERREIRA' },
  { estado: 'BA', cidade: 'PIRIPA' },
  { estado: 'PI', cidade: 'PIRIPIRI' },
  { estado: 'BA', cidade: 'PIRITIBA' },
  { estado: 'PB', cidade: 'PIRPIRITUBA' },
  { estado: 'PR', cidade: 'PITANGA' },
  { estado: 'SP', cidade: 'PITANGUEIRAS' },
  { estado: 'PR', cidade: 'PITANGUEIRAS' },
  { estado: 'MG', cidade: 'PITANGUI' },
  { estado: 'PB', cidade: 'PITIMBU' },
  { estado: 'TO', cidade: 'PIUM' },
  { estado: 'ES', cidade: 'PIUMA' },
  { estado: 'MG', cidade: 'PIUMHI' },
  { estado: 'PA', cidade: 'PLACAS' },
  { estado: 'AC', cidade: 'PLACIDO DE CASTRO' },
  { estado: 'GO', cidade: 'PLANALTINA' },
  { estado: 'PR', cidade: 'PLANALTINA DO PARANA' },
  { estado: 'BA', cidade: 'PLANALTINO' },
  { estado: 'SP', cidade: 'PLANALTO' },
  { estado: 'BA', cidade: 'PLANALTO' },
  { estado: 'PR', cidade: 'PLANALTO' },
  { estado: 'RS', cidade: 'PLANALTO' },
  { estado: 'SC', cidade: 'PLANALTO ALEGRE' },
  { estado: 'MT', cidade: 'PLANALTO DA SERRA' },
  { estado: 'MG', cidade: 'PLANURA' },
  { estado: 'SP', cidade: 'PLATINA' },
  { estado: 'SP', cidade: 'POA' },
  { estado: 'PE', cidade: 'POCAO' },
  { estado: 'MA', cidade: 'POCAO DE PEDRAS' },
  { estado: 'PB', cidade: 'POCINHOS' },
  { estado: 'RN', cidade: 'POCO BRANCO' },
  { estado: 'PB', cidade: 'POCO DANTAS' },
  { estado: 'RS', cidade: 'POCO DAS ANTAS' },
  { estado: 'Al', cidade: 'POCO DAS TRINCHEIRAS' },
  { estado: 'PB', cidade: 'POCO DE JOSE DE MOURA' },
  { estado: 'MG', cidade: 'POCO FUNDO' },
  { estado: 'SE', cidade: 'POCO REDONDO' },
  { estado: 'SE', cidade: 'POCO VERDE' },
  { estado: 'BA', cidade: 'POCOES' },
  { estado: 'MT', cidade: 'POCONE' },
  { estado: 'MG', cidade: 'POCOS DE CALDAS' },
  { estado: 'MG', cidade: 'POCRANE' },
  { estado: 'BA', cidade: 'POJUCA' },
  { estado: 'SP', cidade: 'POLONI' },
  { estado: 'PB', cidade: 'POMBAL' },
  { estado: 'PE', cidade: 'POMBOS' },
  { estado: 'SC', cidade: 'POMERODE' },
  { estado: 'SP', cidade: 'POMPEIA' },
  { estado: 'MG', cidade: 'POMPEU - MG' },
  { estado: 'SP', cidade: 'PONGAI' },
  { estado: 'PA', cidade: 'PONTA DE PEDRAS' },
  { estado: 'PR', cidade: 'PONTA GROSSA' },
  { estado: 'MS', cidade: 'PONTA PORA' },
  { estado: 'SP', cidade: 'PONTAL' },
  { estado: 'MT', cidade: 'PONTAL DO ARAGUAIA' },
  { estado: 'PR', cidade: 'PONTAL DO PARANA' },
  { estado: 'GO', cidade: 'PONTALINA' },
  { estado: 'SP', cidade: 'PONTALINDA' },
  { estado: 'RS', cidade: 'PONTAO' },
  { estado: 'SC', cidade: 'PONTE ALTA' },
  { estado: 'TO', cidade: 'PONTE ALTA DO BOM JESUS' },
  { estado: 'SC', cidade: 'PONTE ALTA DO NORTE' },
  { estado: 'TO', cidade: 'PONTE ALTA DO TOCANTINS' },
  { estado: 'MT', cidade: 'PONTE BRANCA' },
  { estado: 'MG', cidade: 'PONTE NOVA' },
  { estado: 'RS', cidade: 'PONTE PRETA' },
  { estado: 'SC', cidade: 'PONTE SERRADA' },
  { estado: 'MT', cidade: 'PONTES E LACERDA' },
  { estado: 'SP', cidade: 'PONTES GESTAL' },
  { estado: 'ES', cidade: 'PONTO BELO' },
  { estado: 'MG', cidade: 'PONTO CHIQUE' },
  { estado: 'MG', cidade: 'PONTO DO VOLANTES' },
  { estado: 'BA', cidade: 'PONTO NOVO' },
  { estado: 'SP', cidade: 'POPULINA' },
  { estado: 'CE', cidade: 'PORANGA' },
  { estado: 'SP', cidade: 'PORANGABA' },
  { estado: 'GO', cidade: 'PORANGATU' },
  { estado: 'RJ', cidade: 'PORCIUNCULA' },
  { estado: 'PR', cidade: 'PORECATU' },
  { estado: 'RN', cidade: 'PORTALEGRE' },
  { estado: 'RS', cidade: 'PORTAO' },
  { estado: 'GO', cidade: 'PORTEIRAO' },
  { estado: 'CE', cidade: 'PORTEIRAS' },
  { estado: 'MG', cidade: 'PORTEIRINHA' },
  { estado: 'PA', cidade: 'PORTEL' },
  { estado: 'GO', cidade: 'PORTELANDIA' },
  { estado: 'PI', cidade: 'PORTO' },
  { estado: 'AC', cidade: 'PORTO ACRE' },
  { estado: 'RS', cidade: 'PORTO ALEGRE' },
  { estado: 'MT', cidade: 'PORTO ALEGRE DO NORTE' },
  { estado: 'PI', cidade: 'PORTO ALEGRE DO PIAUI' },
  { estado: 'TO', cidade: 'PORTO ALEGRE DO TOCANTINS' },
  { estado: 'PR', cidade: 'PORTO AMAZONAS' },
  { estado: 'PR', cidade: 'PORTO BARREIRO' },
  { estado: 'SC', cidade: 'PORTO BELO' },
  { estado: 'Al', cidade: 'PORTO CALVO' },
  { estado: 'SE', cidade: 'PORTO DA FOLHA' },
  { estado: 'PA', cidade: 'PORTO DE MOZ' },
  { estado: 'Al', cidade: 'PORTO DE PEDRAS' },
  { estado: 'MT', cidade: 'PORTO DO GAUCHOS' },
  { estado: 'RN', cidade: 'PORTO DO MANGUE' },
  { estado: 'MT', cidade: 'PORTO ESPERIDIAO' },
  { estado: 'MT', cidade: 'PORTO ESTRELA' },
  { estado: 'SP', cidade: 'PORTO FELIZ' },
  { estado: 'SP', cidade: 'PORTO FERREIRA' },
  { estado: 'MG', cidade: 'PORTO FIRME' },
  { estado: 'MA', cidade: 'PORTO FRANCO' },
  { estado: 'AP', cidade: 'PORTO GRANDE' },
  { estado: 'RS', cidade: 'PORTO LUCENA' },
  { estado: 'RS', cidade: 'PORTO MAUA' },
  { estado: 'MS', cidade: 'PORTO MURTINHO' },
  { estado: 'TO', cidade: 'PORTO NACIONAL' },
  { estado: 'RJ', cidade: 'PORTO REAL' },
  { estado: 'Al', cidade: 'PORTO REAL DO COLEGIO' },
  { estado: 'PR', cidade: 'PORTO RICO' },
  { estado: 'MA', cidade: 'PORTO RICO DO MARANHAO' },
  { estado: 'BA', cidade: 'PORTO SEGURO' },
  { estado: 'SC', cidade: 'PORTO UNIAO' },
  { estado: 'RO', cidade: 'PORTO VELHO' },
  { estado: 'RS', cidade: 'PORTO VERA CRUZ' },
  { estado: 'PR', cidade: 'PORTO VITORIA' },
  { estado: 'AC', cidade: 'PORTO WALTER' },
  { estado: 'RS', cidade: 'PORTO XAVIER' },
  { estado: 'GO', cidade: 'POSSE' },
  { estado: 'MG', cidade: 'POTE' },
  { estado: 'CE', cidade: 'POTENGI' },
  { estado: 'SP', cidade: 'POTIM' },
  { estado: 'BA', cidade: 'POTIRAGUA' },
  { estado: 'SP', cidade: 'POTIRENDABA' },
  { estado: 'CE', cidade: 'POTIRETAMA' },
  { estado: 'MG', cidade: 'POUSO ALEGRE' },
  { estado: 'MG', cidade: 'POUSO ALTO' },
  { estado: 'RS', cidade: 'POUSO NOVO' },
  { estado: 'SC', cidade: 'POUSO REDONDO' },
  { estado: 'MT', cidade: 'POXOREO' },
  { estado: 'SP', cidade: 'PRACINHA' },
  { estado: 'AP', cidade: 'PRACUUBA' },
  { estado: 'BA', cidade: 'PRADO' },
  { estado: 'PR', cidade: 'PRADO FERREIRA' },
  { estado: 'SP', cidade: 'PRADOPOLIS' },
  { estado: 'MG', cidade: 'PRADOS' },
  { estado: 'SP', cidade: 'PRAIA GRANDE' },
  { estado: 'SC', cidade: 'PRAIA GRANDE' },
  { estado: 'TO', cidade: 'PRAIA NORTE' },
  { estado: 'PA', cidade: 'PRAINHA' },
  { estado: 'PR', cidade: 'PRANCHITA' },
  { estado: 'MG', cidade: 'PRATA' },
  { estado: 'PB', cidade: 'PRATA' },
  { estado: 'PI', cidade: 'PRATA DO PIAUI' },
  { estado: 'SP', cidade: 'PRATANIA' },
  { estado: 'MG', cidade: 'PRATAPOLIS' },
  { estado: 'MG', cidade: 'PRATINHA' },
  { estado: 'SP', cidade: 'PRESIDENTE ALVES' },
  { estado: 'SP', cidade: 'PRESIDENTE BERNARDES' },
  { estado: 'MG', cidade: 'PRESIDENTE BERNARDES' },
  { estado: 'PR', cidade: 'PRESIDENTE CASTELO BRANCO' },
  { estado: 'SC', cidade: 'PRESIDENTE CASTELO BRANCO' },
  { estado: 'BA', cidade: 'PRESIDENTE DUTRA' },
  { estado: 'MA', cidade: 'PRESIDENTE DUTRA' },
  { estado: 'SP', cidade: 'PRESIDENTE EPITACIO' },
  { estado: 'AM', cidade: 'PRESIDENTE FIGUEIREDO' },
  { estado: 'SC', cidade: 'PRESIDENTE GETULIO' },
  { estado: 'BA', cidade: 'PRESIDENTE JANIO QUADROS' },
  { estado: 'MG', cidade: 'PRESIDENTE JUSCELINO' },
  { estado: 'RN', cidade: 'PRESIDENTE JUSCELINO' },
  { estado: 'MA', cidade: 'PRESIDENTE JUSCELINO' },
  { estado: 'ES', cidade: 'PRESIDENTE KENNEDY' },
  { estado: 'TO', cidade: 'PRESIDENTE KENNEDY' },
  { estado: 'MG', cidade: 'PRESIDENTE KUBITSCHEK' },
  { estado: 'RS', cidade: 'PRESIDENTE LUCENA' },
  { estado: 'MA', cidade: 'PRESIDENTE MEDICI' },
  { estado: 'RO', cidade: 'PRESIDENTE MEDICI' },
  { estado: 'SC', cidade: 'PRESIDENTE NEREU' },
  { estado: 'MG', cidade: 'PRESIDENTE OLEGARIO' },
  { estado: 'SP', cidade: 'PRESIDENTE PRUDENTE' },
  { estado: 'MA', cidade: 'PRESIDENTE SARNEY' },
  { estado: 'BA', cidade: 'PRESIDENTE TANCREDO NEVES' },
  { estado: 'MA', cidade: 'PRESIDENTE VARGAS' },
  { estado: 'SP', cidade: 'PRESIDENTE VENCESLAU' },
  { estado: 'PE', cidade: 'PRIMAVERA' },
  { estado: 'PA', cidade: 'PRIMAVERA' },
  { estado: 'RO', cidade: 'PRIMAVERA DE RONDONIA' },
  { estado: 'MT', cidade: 'PRIMAVERA DO LESTE' },
  { estado: 'MA', cidade: 'PRIMEIRA CRUZ' },
  { estado: 'PR', cidade: 'PRIMEIRO DE MAIO' },
  { estado: 'SC', cidade: 'PRINCESA' },
  { estado: 'PB', cidade: 'PRINCESA ISABEL' },
  { estado: 'GO', cidade: 'PROFESSOR JAMIL' },
  { estado: 'RS', cidade: 'PROGRESSO' },
  { estado: 'SP', cidade: 'PROMISSAO' },
  { estado: 'SE', cidade: 'PROPRIA' },
  { estado: 'RS', cidade: 'PROTASIO ALVES' },
  { estado: 'MG', cidade: 'PRUDENTE DE MORAIS' },
  { estado: 'PR', cidade: 'PRUDENTOPOLIS' },
  { estado: 'TO', cidade: 'PUGMIL' },
  { estado: 'RN', cidade: 'PUREZA' },
  { estado: 'RS', cidade: 'PUTINGA' },
  { estado: 'PB', cidade: 'PUXINANA' },
  { estado: 'SP', cidade: 'QUADRA' },
  { estado: 'RS', cidade: 'QUARAI' },
  { estado: 'MG', cidade: 'QUARTEL GERAL' },
  { estado: 'PR', cidade: 'QUARTO CENTENARIO' },
  { estado: 'SP', cidade: 'QUATA' },
  { estado: 'PR', cidade: 'QUATIGUA' },
  { estado: 'PA', cidade: 'QUATIPURU' },
  { estado: 'RJ', cidade: 'QUATIS' },
  { estado: 'PR', cidade: 'QUATRO BARRAS' },
  { estado: 'RS', cidade: 'QUATRO IRMAOS' },
  { estado: 'PR', cidade: 'QUATRO PONTES' },
  { estado: 'Al', cidade: 'QUEBRANGULO' },
  { estado: 'PR', cidade: 'QUEDAS DO IGUACU' },
  { estado: 'PI', cidade: 'QUEIMADA NOVA' },
  { estado: 'BA', cidade: 'QUEIMADAS' },
  { estado: 'PB', cidade: 'QUEIMADAS' },
  { estado: 'RJ', cidade: 'QUEIMADOS' },
  { estado: 'SP', cidade: 'QUEIROZ' },
  { estado: 'SP', cidade: 'QUELUZ' },
  { estado: 'MG', cidade: 'QUELUZITO' },
  { estado: 'MT', cidade: 'QUERENCIA' },
  { estado: 'PR', cidade: 'QUERENCIA DO NORTE' },
  { estado: 'RS', cidade: 'QUEVEDOS' },
  { estado: 'BA', cidade: 'QUIJINGUE' },
  { estado: 'SC', cidade: 'QUILOMBO' },
  { estado: 'PR', cidade: 'QUINTA DO SOL' },
  { estado: 'SP', cidade: 'QUINTANA' },
  { estado: 'RS', cidade: 'QUINZE DE NOVEMBRO' },
  { estado: 'PE', cidade: 'QUIPAPA' },
  { estado: 'GO', cidade: 'QUIRINOPOLIS' },
  { estado: 'RJ', cidade: 'QUISSAMA' },
  { estado: 'PR', cidade: 'QUITANDINHA' },
  { estado: 'CE', cidade: 'QUITERIANOPOLIS' },
  { estado: 'PE', cidade: 'QUIXABA' },
  { estado: 'PB', cidade: 'QUIXABA' },
  { estado: 'BA', cidade: 'QUIXABEIRA' },
  { estado: 'CE', cidade: 'QUIXADA' },
  { estado: 'CE', cidade: 'QUIXELO' },
  { estado: 'CE', cidade: 'QUIXERAMOBIM' },
  { estado: 'CE', cidade: 'QUIXERE' },
  { estado: 'RN', cidade: 'RAFAEL FERNANDES' },
  { estado: 'RN', cidade: 'RAFAEL GODEIRO' },
  { estado: 'BA', cidade: 'RAFAEL JAMBEIRO' },
  { estado: 'SP', cidade: 'RAFARD' },
  { estado: 'PR', cidade: 'RAMILANDIA' },
  { estado: 'SP', cidade: 'RANCHARIA' },
  { estado: 'PR', cidade: 'RANCHO ALEGRE' },
  { estado: 'PR', cidade: 'RANCHO ALEGRE D´OESTE' },
  { estado: 'SC', cidade: 'RANCHO QUEIMADO' },
  { estado: 'MA', cidade: 'RAPOSA' },
  { estado: 'MG', cidade: 'RAPOSOS' },
  { estado: 'MG', cidade: 'RAUL SOARES' },
  { estado: 'PR', cidade: 'REALEZA' },
  { estado: 'PR', cidade: 'REBOUCAS' },
  { estado: 'PE', cidade: 'RECIFE' },
  { estado: 'MG', cidade: 'RECREIO' },
  { estado: 'TO', cidade: 'RECURSOLANDIA' },
  { estado: 'CE', cidade: 'REDENCAO' },
  { estado: 'PA', cidade: 'REDENCAO' },
  { estado: 'SP', cidade: 'REDENCAO DA SERRA' },
  { estado: 'PI', cidade: 'REDENCAO DO GURGUEIA' },
  { estado: 'RS', cidade: 'REDENTORA' },
  { estado: 'MG', cidade: 'REDUTO' },
  { estado: 'PI', cidade: 'REGENERACAO' },
  { estado: 'SP', cidade: 'REGENTE FEIJO' },
  { estado: 'SP', cidade: 'REGINOPOLIS' },
  { estado: 'SP', cidade: 'REGISTRO' },
  { estado: 'RS', cidade: 'RELVADO' },
  { estado: 'BA', cidade: 'REMANSO' },
  { estado: 'PB', cidade: 'REMIGIO' },
  { estado: 'PR', cidade: 'RENASCENCA' },
  { estado: 'CE', cidade: 'RERIUTABA' },
  { estado: 'RJ', cidade: 'RESENDE' },
  { estado: 'MG', cidade: 'RESENDE COSTA' },
  { estado: 'PR', cidade: 'RESERVA' },
  { estado: 'MT', cidade: 'RESERVA DO CABACAL' },
  { estado: 'PR', cidade: 'RESERVA DO IGUACU' },
  { estado: 'MG', cidade: 'RESPLENDOR' },
  { estado: 'MG', cidade: 'RESSAQUINHA' },
  { estado: 'SP', cidade: 'RESTINGA' },
  { estado: 'RS', cidade: 'RESTINGA SECA' },
  { estado: 'BA', cidade: 'RETIROLANDIA' },
  { estado: 'PB', cidade: 'RIACHAO' },
  { estado: 'MA', cidade: 'RIACHAO' },
  { estado: 'BA', cidade: 'RIACHAO DAS NEVES' },
  { estado: 'PB', cidade: 'RIACHAO DO BACAMARTE' },
  { estado: 'SE', cidade: 'RIACHAO DO DANTAS' },
  { estado: 'BA', cidade: 'RIACHAO DO JACUIPE' },
  { estado: 'PB', cidade: 'RIACHAO DO POCO' },
  { estado: 'MG', cidade: 'RIACHINHO' },
  { estado: 'TO', cidade: 'RIACHINHO' },
  { estado: 'RN', cidade: 'RIACHO DA CRUZ' },
  { estado: 'PE', cidade: 'RIACHO DAS ALMAS' },
  { estado: 'BA', cidade: 'RIACHO DE SANTANA' },
  { estado: 'RN', cidade: 'RIACHO DE SANTANA' },
  { estado: 'PB', cidade: 'RIACHO DE SANTO ANTONIO' },
  { estado: 'PB', cidade: 'RIACHO DO CAVALOS' },
  { estado: 'MG', cidade: 'RIACHO DO MACHADOS' },
  { estado: 'PI', cidade: 'RIACHO FRIO' },
  { estado: 'SE', cidade: 'RIACHUELO' },
  { estado: 'RN', cidade: 'RIACHUELO' },
  { estado: 'GO', cidade: 'RIALMA' },
  { estado: 'GO', cidade: 'RIANAPOLIS' },
  { estado: 'MA', cidade: 'RIBAMAR FIQUENE' },
  { estado: 'MS', cidade: 'RIBAS DO RIO PARDO' },
  { estado: 'SP', cidade: 'RIBEIRA' },
  { estado: 'BA', cidade: 'RIBEIRA DO AMPARO' },
  { estado: 'PI', cidade: 'RIBEIRA DO PIAUI' },
  { estado: 'BA', cidade: 'RIBEIRA DO POMBAL' },
  { estado: 'PE', cidade: 'RIBEIRAO' },
  { estado: 'SP', cidade: 'RIBEIRAO BONITO' },
  { estado: 'SP', cidade: 'RIBEIRAO BRANCO' },
  { estado: 'MT', cidade: 'RIBEIRAO CASCALHEIRA' },
  { estado: 'PR', cidade: 'RIBEIRAO CLARO' },
  { estado: 'SP', cidade: 'RIBEIRAO CORRENTE' },
  { estado: 'MG', cidade: 'RIBEIRAO DAS NEVES' },
  { estado: 'SP', cidade: 'RIBEIRAO DO INDIOS' },
  { estado: 'BA', cidade: 'RIBEIRAO DO LARGO' },
  { estado: 'PR', cidade: 'RIBEIRAO DO PINHAL' },
  { estado: 'SP', cidade: 'RIBEIRAO DO SUL' },
  { estado: 'SP', cidade: 'RIBEIRAO GRANDE' },
  { estado: 'SP', cidade: 'RIBEIRAO PIRES' },
  { estado: 'SP', cidade: 'RIBEIRAO PRETO' },
  { estado: 'MG', cidade: 'RIBEIRAO VERMELHO' },
  { estado: 'MT', cidade: 'RIBEIRAOZINHO' },
  { estado: 'PI', cidade: 'RIBEIRO GONCALVES' },
  { estado: 'SE', cidade: 'RIBEIROPOLIS' },
  { estado: 'SP', cidade: 'RIFAINA' },
  { estado: 'SP', cidade: 'RINCAO' },
  { estado: 'SP', cidade: 'RINOPOLIS' },
  { estado: 'MG', cidade: 'RIO ACIMA' },
  { estado: 'PR', cidade: 'RIO AZUL' },
  { estado: 'ES', cidade: 'RIO BANANAL' },
  { estado: 'PR', cidade: 'RIO BOM' },
  { estado: 'RJ', cidade: 'RIO BONITO' },
  { estado: 'PR', cidade: 'RIO BONITO DO IGUACU' },
  { estado: 'AC', cidade: 'RIO BRANCO' },
  { estado: 'MT', cidade: 'RIO BRANCO' },
  { estado: 'PR', cidade: 'RIO BRANCO DO IVAI' },
  { estado: 'PR', cidade: 'RIO BRANCO DO SUL' },
  { estado: 'MS', cidade: 'RIO BRILHANTE' },
  { estado: 'MG', cidade: 'RIO CASCA' },
  { estado: 'SP', cidade: 'RIO CLARO' },
  { estado: 'RJ', cidade: 'RIO CLARO' },
  { estado: 'RO', cidade: 'RIO CRESPO' },
  { estado: 'TO', cidade: 'RIO DA CONCEICAO' },
  { estado: 'SC', cidade: 'RIO DAS ANTAS' },
  { estado: 'RJ', cidade: 'RIO DAS FLORES' },
  { estado: 'RJ', cidade: 'RIO DAS OSTRAS' },
  { estado: 'SP', cidade: 'RIO DAS PEDRAS' },
  { estado: 'BA', cidade: 'RIO DE CONTAS' },
  { estado: 'RJ', cidade: 'RIO DE JANEIRO' },
  { estado: 'BA', cidade: 'RIO DO ANTONIO' },
  { estado: 'TO', cidade: 'RIO DO BOIS' },
  { estado: 'SC', cidade: 'RIO DO CAMPO' },
  { estado: 'SC', cidade: 'RIO DO CEDROS' },
  { estado: 'RN', cidade: 'RIO DO FOGO' },
  { estado: 'RS', cidade: 'RIO DO INDIOS' },
  { estado: 'SC', cidade: 'RIO DO OESTE' },
  { estado: 'BA', cidade: 'RIO DO PIRES' },
  { estado: 'MG', cidade: 'RIO DO PRADO' },
  { estado: 'SC', cidade: 'RIO DO SUL' },
  { estado: 'MG', cidade: 'RIO DOCE' },
  { estado: 'MG', cidade: 'RIO ESPERA' },
  { estado: 'PE', cidade: 'RIO FORMOSO' },
  { estado: 'SC', cidade: 'RIO FORTUNA' },
  { estado: 'RS', cidade: 'RIO GRANDE' },
  { estado: 'SP', cidade: 'RIO GRANDE DA SERRA' },
  { estado: 'PI', cidade: 'RIO GRANDE DO PIAUI' },
  { estado: 'Al', cidade: 'RIO LARGO' },
  { estado: 'MG', cidade: 'RIO MANSO' },
  { estado: 'PA', cidade: 'RIO MARIA' },
  { estado: 'SC', cidade: 'RIO NEGRINHO' },
  { estado: 'MS', cidade: 'RIO NEGRO' },
  { estado: 'PR', cidade: 'RIO NEGRO' },
  { estado: 'MG', cidade: 'RIO NOVO' },
  { estado: 'ES', cidade: 'RIO NOVO DO SUL' },
  { estado: 'MG', cidade: 'RIO PARANAIBA' },
  { estado: 'RS', cidade: 'RIO PARDO' },
  { estado: 'MG', cidade: 'RIO PARDO DE MINAS' },
  { estado: 'MG', cidade: 'RIO PIRACICABA' },
  { estado: 'MG', cidade: 'RIO POMBA' },
  { estado: 'MG', cidade: 'RIO PRETO' },
  { estado: 'AM', cidade: 'RIO PRETO DA EVA' },
  { estado: 'GO', cidade: 'RIO QUENTE' },
  { estado: 'BA', cidade: 'RIO REAL' },
  { estado: 'SC', cidade: 'RIO RUFINO' },
  { estado: 'TO', cidade: 'RIO SONO' },
  { estado: 'PB', cidade: 'RIO TINTO' },
  { estado: 'GO', cidade: 'RIO VERDE' },
  { estado: 'MS', cidade: 'RIO VERDE DE MATO GROSSO' },
  { estado: 'MG', cidade: 'RIO VERMELHO' },
  { estado: 'SP', cidade: 'RIOLANDIA' },
  { estado: 'RS', cidade: 'RIOZINHO' },
  { estado: 'SC', cidade: 'RIQUEZA' },
  { estado: 'MG', cidade: 'RITAPOLIS' },
  { estado: 'SP', cidade: 'RIVERSUL' },
  { estado: 'RS', cidade: 'ROCA SALES' },
  { estado: 'MS', cidade: 'ROCHEDO' },
  { estado: 'MG', cidade: 'ROCHEDO DE MINAS' },
  { estado: 'SC', cidade: 'RODEIO' },
  { estado: 'RS', cidade: 'RODEIO BONITO' },
  { estado: 'MG', cidade: 'RODEIRO' },
  { estado: 'BA', cidade: 'RODELAS' },
  { estado: 'RN', cidade: 'RODOLFO FERNANDES' },
  { estado: 'AC', cidade: 'RODRIGUES ALVES' },
  { estado: 'RS', cidade: 'ROLADOR' },
  { estado: 'PR', cidade: 'ROLANDIA' },
  { estado: 'RS', cidade: 'ROLANTE' },
  { estado: 'RO', cidade: 'ROLIM DE MOURA' },
  { estado: 'MG', cidade: 'ROMARIA' },
  { estado: 'SC', cidade: 'ROMELANDIA' },
  { estado: 'PR', cidade: 'RONCADOR' },
  { estado: 'RS', cidade: 'RONDA ALTA' },
  { estado: 'RS', cidade: 'RONDINHA' },
  { estado: 'MT', cidade: 'RONDOLANDIA' },
  { estado: 'PR', cidade: 'RONDON' },
  { estado: 'PA', cidade: 'RONDON DO PARA' },
  { estado: 'MT', cidade: 'RONDONOPOLIS' },
  { estado: 'RS', cidade: 'ROQUE GONZALES' },
  { estado: 'RR', cidade: 'RORAINOPOLIS' },
  { estado: 'SP', cidade: 'ROSANA' },
  { estado: 'MA', cidade: 'ROSARIO' },
  { estado: 'MG', cidade: 'ROSARIO DA LIMEIRA' },
  { estado: 'SE', cidade: 'ROSARIO DO CATETE' },
  { estado: 'PR', cidade: 'ROSARIO DO IVAI' },
  { estado: 'RS', cidade: 'ROSARIO DO SUL' },
  { estado: 'MT', cidade: 'ROSARIO OESTE' },
  { estado: 'SP', cidade: 'ROSEIRA' },
  { estado: 'Al', cidade: 'ROTEIRO' },
  { estado: 'MG', cidade: 'RUBELITA' },
  { estado: 'SP', cidade: 'RUBIACEA' },
  { estado: 'GO', cidade: 'RUBIATABA' },
  { estado: 'MG', cidade: 'RUBIM' },
  { estado: 'SP', cidade: 'RUBINEIA' },
  { estado: 'PA', cidade: 'RUROPOLIS' },
  { estado: 'CE', cidade: 'RUSSAS' },
  { estado: 'BA', cidade: 'RUY BARBOSA' },
  { estado: 'RN', cidade: 'RUY BARBOSA' },
  { estado: 'MG', cidade: 'SABARA' },
  { estado: 'PR', cidade: 'SABAUDIA' },
  { estado: 'SP', cidade: 'SABINO' },
  { estado: 'MG', cidade: 'SABINOPOLIS' },
  { estado: 'CE', cidade: 'SABOEIRO' },
  { estado: 'MG', cidade: 'SACRAMENTO' },
  { estado: 'RS', cidade: 'SAGRADA FAMILIA' },
  { estado: 'SP', cidade: 'SAGRES' },
  { estado: 'PE', cidade: 'SAIRE' },
  { estado: 'RS', cidade: 'SALDANHA MARINHO' },
  { estado: 'SP', cidade: 'SALES' },
  { estado: 'SP', cidade: 'SALES OLIVEIRA' },
  { estado: 'SP', cidade: 'SALESOPOLIS' },
  { estado: 'SC', cidade: 'SALETE' },
  { estado: 'PE', cidade: 'SALGADINHO' },
  { estado: 'PB', cidade: 'SALGADINHO' },
  { estado: 'SE', cidade: 'SALGADO' },
  { estado: 'PB', cidade: 'SALGADO DE SAO FELIX' },
  { estado: 'PR', cidade: 'SALGADO FILHO' },
  { estado: 'PE', cidade: 'SALGUEIRO' },
  { estado: 'MG', cidade: 'SALINAS' },
  { estado: 'BA', cidade: 'SALINAS DA MARGARIDA' },
  { estado: 'PA', cidade: 'SALINOPOLIS' },
  { estado: 'CE', cidade: 'SALITRE' },
  { estado: 'SP', cidade: 'SALMOURAO' },
  { estado: 'PE', cidade: 'SALOA' },
  { estado: 'SP', cidade: 'SALTINHO' },
  { estado: 'SC', cidade: 'SALTINHO' },
  { estado: 'SP', cidade: 'SALTO' },
  { estado: 'MG', cidade: 'SALTO DA DIVISA' },
  { estado: 'SP', cidade: 'SALTO DE PIRAPORA' },
  { estado: 'MT', cidade: 'SALTO DO CEU' },
  { estado: 'PR', cidade: 'SALTO DO ITARARE' },
  { estado: 'RS', cidade: 'SALTO DO JACUI' },
  { estado: 'PR', cidade: 'SALTO DO LONTRA' },
  { estado: 'SP', cidade: 'SALTO GRANDE' },
  { estado: 'SC', cidade: 'SALTO VELOSO' },
  { estado: 'BA', cidade: 'SALVADOR' },
  { estado: 'RS', cidade: 'SALVADOR DAS MISSOES' },
  { estado: 'RS', cidade: 'SALVADOR DO SUL' },
  { estado: 'PA', cidade: 'SALVATERRA' },
  { estado: 'MA', cidade: 'SAMBAIBA' },
  { estado: 'TO', cidade: 'SAMPAIO' },
  { estado: 'RS', cidade: 'SANANDUVA' },
  { estado: 'GO', cidade: 'SANCLERLANDIA' },
  { estado: 'TO', cidade: 'SANDOLANDIA' },
  { estado: 'SP', cidade: 'SANDOVALINA' },
  { estado: 'SC', cidade: 'SANGAO' },
  { estado: 'PE', cidade: 'SANHARO' },
  { estado: 'SP', cidade: 'SANTA ADELIA' },
  { estado: 'SP', cidade: 'SANTA ALBERTINA' },
  { estado: 'PR', cidade: 'SANTA AMELIA' },
  { estado: 'MG', cidade: 'SANTA BARBARA' },
  { estado: 'BA', cidade: 'SANTA BARBARA' },
  { estado: 'SP', cidade: 'SANTA BARBARA D´OESTE' },
  { estado: 'GO', cidade: 'SANTA BARBARA DE GOIAS' },
  { estado: 'MG', cidade: 'SANTA BARBARA DO LESTE' },
  { estado: 'MG', cidade: 'SANTA BARBARA DO MONTE VERDE' },
  { estado: 'PA', cidade: 'SANTA BARBARA DO PARA' },
  { estado: 'RS', cidade: 'SANTA BARBARA DO SUL' },
  { estado: 'MG', cidade: 'SANTA BARBARA DO TUGURIO' },
  { estado: 'SP', cidade: 'SANTA BRANCA' },
  { estado: 'BA', cidade: 'SANTA BRIGIDA' },
  { estado: 'MT', cidade: 'SANTA CARMEM' },
  { estado: 'PB', cidade: 'SANTA CECILIA' },
  { estado: 'SC', cidade: 'SANTA CECILIA' },
  { estado: 'PR', cidade: 'SANTA CECILIA DO PAVAO' },
  { estado: 'RS', cidade: 'SANTA CECILIA DO SUL' },
  { estado: 'SP', cidade: 'SANTA CLARA D´OESTE' },
  { estado: 'RS', cidade: 'SANTA CLARA DO SUL' },
  { estado: 'PE', cidade: 'SANTA CRUZ' },
  { estado: 'PB', cidade: 'SANTA CRUZ' },
  { estado: 'RN', cidade: 'SANTA CRUZ' },
  { estado: 'BA', cidade: 'SANTA CRUZ CABRALIA' },
  { estado: 'PE', cidade: 'SANTA CRUZ DA BAIXA VERDE' },
  { estado: 'SP', cidade: 'SANTA CRUZ DA CONCEICAO' },
  { estado: 'SP', cidade: 'SANTA CRUZ DA ESPERANCA' },
  { estado: 'BA', cidade: 'SANTA CRUZ DA VITORIA' },
  { estado: 'SP', cidade: 'SANTA CRUZ DAS PALMEIRAS' },
  { estado: 'GO', cidade: 'SANTA CRUZ DE GOIAS' },
  { estado: 'MG', cidade: 'SANTA CRUZ DE MINAS' },
  { estado: 'PR', cidade: 'SANTA CRUZ DE MONTE CASTELO' },
  { estado: 'MG', cidade: 'SANTA CRUZ DE SALINAS' },
  { estado: 'PA', cidade: 'SANTA CRUZ DO ARARI' },
  { estado: 'PE', cidade: 'SANTA CRUZ DO CAPIBARIBE' },
  { estado: 'MG', cidade: 'SANTA CRUZ DO ESCALVADO' },
  { estado: 'PI', cidade: 'SANTA CRUZ DO MILAGRES' },
  { estado: 'PI', cidade: 'SANTA CRUZ DO PIAUI' },
  { estado: 'SP', cidade: 'SANTA CRUZ DO RIO PARDO' },
  { estado: 'RS', cidade: 'SANTA CRUZ DO SUL' },
  { estado: 'MT', cidade: 'SANTA CRUZ DO XINGU' },
  { estado: 'MG', cidade: 'SANTA EFIGENIA DE MINAS' },
  { estado: 'SP', cidade: 'SANTA ERNESTINA' },
  { estado: 'PR', cidade: 'SANTA FE' },
  { estado: 'GO', cidade: 'SANTA FE DE GOIAS' },
  { estado: 'MG', cidade: 'SANTA FE DE MINAS' },
  { estado: 'TO', cidade: 'SANTA FE DO ARAGUAIA' },
  { estado: 'SP', cidade: 'SANTA FE DO SUL' },
  { estado: 'PE', cidade: 'SANTA FILOMENA' },
  { estado: 'PI', cidade: 'SANTA FILOMENA' },
  { estado: 'MA', cidade: 'SANTA FILOMENA DO MARANHAO' },
  { estado: 'SP', cidade: 'SANTA GERTRUDES' },
  { estado: 'PB', cidade: 'SANTA HELENA' },
  { estado: 'MA', cidade: 'SANTA HELENA' },
  { estado: 'PR', cidade: 'SANTA HELENA' },
  { estado: 'SC', cidade: 'SANTA HELENA' },
  { estado: 'GO', cidade: 'SANTA HELENA DE GOIAS' },
  { estado: 'MG', cidade: 'SANTA HELENA DE MINAS' },
  { estado: 'BA', cidade: 'SANTA INES' },
  { estado: 'PB', cidade: 'SANTA INES' },
  { estado: 'MA', cidade: 'SANTA INES' },
  { estado: 'PR', cidade: 'SANTA INES' },
  { estado: 'SP', cidade: 'SANTA ISABEL' },
  { estado: 'GO', cidade: 'SANTA ISABEL' },
  { estado: 'PR', cidade: 'SANTA ISABEL DO IVAI' },
  { estado: 'PA', cidade: 'SANTA ISABEL DO PARA' },
  { estado: 'AM', cidade: 'SANTA ISABEL DO RIO NEGRO' },
  { estado: 'PR', cidade: 'SANTA IZABEL DO OESTE' },
  { estado: 'MG', cidade: 'SANTA JULIANA' },
  { estado: 'ES', cidade: 'SANTA LEOPOLDINA' },
  { estado: 'SP', cidade: 'SANTA LUCIA' },
  { estado: 'PR', cidade: 'SANTA LUCIA' },
  { estado: 'PI', cidade: 'SANTA LUZ' },
  { estado: 'MG', cidade: 'SANTA LUZIA' },
  { estado: 'BA', cidade: 'SANTA LUZIA' },
  { estado: 'PB', cidade: 'SANTA LUZIA' },
  { estado: 'MA', cidade: 'SANTA LUZIA' },
  { estado: 'RO', cidade: 'SANTA LUZIA D´OESTE' },
  { estado: 'SE', cidade: 'SANTA LUZIA DO ITANHY' },
  { estado: 'Al', cidade: 'SANTA LUZIA DO NORTE' },
  { estado: 'PA', cidade: 'SANTA LUZIA DO PARA' },
  { estado: 'MA', cidade: 'SANTA LUZIA DO PARUA' },
  { estado: 'MG', cidade: 'SANTA MARGARIDA' },
  { estado: 'RS', cidade: 'SANTA MARGARIDA DO SUL' },
  { estado: 'RN', cidade: 'SANTA MARIA' },
  { estado: 'RS', cidade: 'SANTA MARIA' },
  { estado: 'PE', cidade: 'SANTA MARIA DA BOA VISTA' },
  { estado: 'SP', cidade: 'SANTA MARIA DA SERRA' },
  { estado: 'BA', cidade: 'SANTA MARIA DA VITORIA' },
  { estado: 'PA', cidade: 'SANTA MARIA DAS BARREIRAS' },
  { estado: 'MG', cidade: 'SANTA MARIA DE ITABIRA' },
  { estado: 'ES', cidade: 'SANTA MARIA DE JETIBA' },
  { estado: 'PE', cidade: 'SANTA MARIA DO CAMBUCA' },
  { estado: 'RS', cidade: 'SANTA MARIA DO HERVAL' },
  { estado: 'PR', cidade: 'SANTA MARIA DO OESTE' },
  { estado: 'PA', cidade: 'SANTA MARIA DO PARA' },
  { estado: 'MG', cidade: 'SANTA MARIA DO SALTO' },
  { estado: 'MG', cidade: 'SANTA MARIA DO SUACUI' },
  { estado: 'TO', cidade: 'SANTA MARIA DO TOCANTINS' },
  { estado: 'RJ', cidade: 'SANTA MARIA MADALENA' },
  { estado: 'PR', cidade: 'SANTA MARIANA' },
  { estado: 'SP', cidade: 'SANTA MERCEDES' },
  { estado: 'PR', cidade: 'SANTA MONICA' },
  { estado: 'CE', cidade: 'SANTA QUITERIA' },
  { estado: 'MA', cidade: 'SANTA QUITERIA DO MARANHAO' },
  { estado: 'PB', cidade: 'SANTA RITA' },
  { estado: 'MA', cidade: 'SANTA RITA' },
  { estado: 'SP', cidade: 'SANTA RITA D´OESTE' },
  { estado: 'MG', cidade: 'SANTA RITA DE CALDAS' },
  { estado: 'BA', cidade: 'SANTA RITA DE CASSIA' },
  { estado: 'MG', cidade: 'SANTA RITA DE IBITIPOCA' },
  { estado: 'MG', cidade: 'SANTA RITA DE JACUTINGA' },
  { estado: 'MG', cidade: 'SANTA RITA DE MINAS' },
  { estado: 'GO', cidade: 'SANTA RITA DO ARAGUAIA' },
  { estado: 'MG', cidade: 'SANTA RITA DO ITUETO' },
  { estado: 'GO', cidade: 'SANTA RITA DO NOVO DESTINO' },
  { estado: 'MS', cidade: 'SANTA RITA DO PARDO' },
  { estado: 'SP', cidade: 'SANTA RITA DO PASSA QUATRO' },
  { estado: 'MG', cidade: 'SANTA RITA DO SAPUCAI' },
  { estado: 'TO', cidade: 'SANTA RITA DO TOCANTINS' },
  { estado: 'MT', cidade: 'SANTA RITA DO TRIVELATO' },
  { estado: 'RS', cidade: 'SANTA ROSA' },
  { estado: 'MG', cidade: 'SANTA ROSA DA SERRA' },
  { estado: 'GO', cidade: 'SANTA ROSA DE GOIAS' },
  { estado: 'SE', cidade: 'SANTA ROSA DE LIMA' },
  { estado: 'SC', cidade: 'SANTA ROSA DE LIMA' },
  { estado: 'SP', cidade: 'SANTA ROSA DE VITERBO' },
  { estado: 'PI', cidade: 'SANTA ROSA DO PIAUI' },
  { estado: 'AC', cidade: 'SANTA ROSA DO PURUS' },
  { estado: 'SC', cidade: 'SANTA ROSA DO SUL' },
  { estado: 'TO', cidade: 'SANTA ROSA DO TOCANTINS' },
  { estado: 'SP', cidade: 'SANTA SALETE' },
  { estado: 'ES', cidade: 'SANTA TERESA' },
  { estado: 'BA', cidade: 'SANTA TERESINHA' },
  { estado: 'PB', cidade: 'SANTA TERESINHA' },
  { estado: 'RS', cidade: 'SANTA TEREZA' },
  { estado: 'GO', cidade: 'SANTA TEREZA DE GOIAS' },
  { estado: 'PR', cidade: 'SANTA TEREZA DO OESTE' },
  { estado: 'TO', cidade: 'SANTA TEREZA DO TOCANTINS' },
  { estado: 'PE', cidade: 'SANTA TEREZINHA' },
  { estado: 'MT', cidade: 'SANTA TEREZINHA' },
  { estado: 'SC', cidade: 'SANTA TEREZINHA' },
  { estado: 'GO', cidade: 'SANTA TEREZINHA DE GOIAS' },
  { estado: 'PR', cidade: 'SANTA TEREZINHA DE ITAIPU' },
  { estado: 'SC', cidade: 'SANTA TEREZINHA DO PROGRESSO' },
  { estado: 'TO', cidade: 'SANTA TEREZINHA DO TOCANTINS' },
  { estado: 'MG', cidade: 'SANTA VITORIA' },
  { estado: 'RS', cidade: 'SANTA VITORIA DO PALMAR' },
  { estado: 'BA', cidade: 'SANTALUZ' },
  { estado: 'BA', cidade: 'SANTANA' },
  { estado: 'AP', cidade: 'SANTANA' },
  { estado: 'RS', cidade: 'SANTANA DA BOA VISTA' },
  { estado: 'SP', cidade: 'SANTANA DA PONTE PENSA' },
  { estado: 'MG', cidade: 'SANTANA DA VARGEM' },
  { estado: 'MG', cidade: 'SANTANA DE CATAGUASES' },
  { estado: 'PB', cidade: 'SANTANA DE MANGUEIRA' },
  { estado: 'SP', cidade: 'SANTANA DE PARNAIBA' },
  { estado: 'MG', cidade: 'SANTANA DE PIRAPAMA' },
  { estado: 'CE', cidade: 'SANTANA DO ACARAU' },
  { estado: 'PA', cidade: 'SANTANA DO ARAGUAIA' },
  { estado: 'CE', cidade: 'SANTANA DO CARIRI' },
  { estado: 'MG', cidade: 'SANTANA DO DESERTO' },
  { estado: 'MG', cidade: 'SANTANA DO GARAMBEU' },
  { estado: 'PB', cidade: 'SANTANA DO GARROTES' },
  { estado: 'Al', cidade: 'SANTANA DO IPANEMA' },
  { estado: 'PR', cidade: 'SANTANA DO ITARARE' },
  { estado: 'MG', cidade: 'SANTANA DO JACARE' },
  { estado: 'RS', cidade: 'SANTANA DO LIVRAMENTO' },
  { estado: 'MG', cidade: 'SANTANA DO MANHUACU' },
  { estado: 'MA', cidade: 'SANTANA DO MARANHAO' },
  { estado: 'RN', cidade: 'SANTANA DO MATOS' },
  { estado: 'MG', cidade: 'SANTANA DO MONTES' },
  { estado: 'Al', cidade: 'SANTANA DO MUNDAU' },
  { estado: 'MG', cidade: 'SANTANA DO PARAISO' },
  { estado: 'PI', cidade: 'SANTANA DO PIAUI' },
  { estado: 'MG', cidade: 'SANTANA DO RIACHO' },
  { estado: 'SE', cidade: 'SANTANA DO SAO FRANCISCO' },
  { estado: 'RN', cidade: 'SANTANA DO SERIDO' },
  { estado: 'BA', cidade: 'SANTANOPOLIS' },
  { estado: 'PB', cidade: 'SANTAREM' },
  { estado: 'PA', cidade: 'SANTAREM' },
  { estado: 'PA', cidade: 'SANTAREM NOVO' },
  { estado: 'RS', cidade: 'SANTIAGO' },
  { estado: 'SC', cidade: 'SANTIAGO DO SUL' },
  { estado: 'MT', cidade: 'SANTO AFONSO' },
  { estado: 'BA', cidade: 'SANTO AMARO' },
  { estado: 'SC', cidade: 'SANTO AMARO DA IMPERATRIZ' },
  { estado: 'SE', cidade: 'SANTO AMARO DAS BROTAS' },
  { estado: 'MA', cidade: 'SANTO AMARO DO MARANHAO' },
  { estado: 'SP', cidade: 'SANTO ANASTACIO' },
  { estado: 'SP', cidade: 'SANTO ANDRE' },
  { estado: 'PB', cidade: 'SANTO ANDRE' },
  { estado: 'RS', cidade: 'SANTO ANGELO' },
  { estado: 'RN', cidade: 'SANTO ANTONIO' },
  { estado: 'SP', cidade: 'SANTO ANTONIO DA ALEGRIA' },
  { estado: 'GO', cidade: 'SANTO ANTONIO DA BARRA' },
  { estado: 'RS', cidade: 'SANTO ANTONIO DA PATRULHA' },
  { estado: 'PR', cidade: 'SANTO ANTONIO DA PLATINA' },
  { estado: 'RS', cidade: 'SANTO ANTONIO DAS MISSOES' },
  { estado: 'GO', cidade: 'SANTO ANTONIO DE GOIAS' },
  { estado: 'BA', cidade: 'SANTO ANTONIO DE JESUS' },
  { estado: 'PI', cidade: 'SANTO ANTONIO DE LISBOA' },
  { estado: 'RJ', cidade: 'SANTO ANTONIO DE PADUA' },
  { estado: 'SP', cidade: 'SANTO ANTONIO DE POSSE' },
  { estado: 'MG', cidade: 'SANTO ANTONIO DO AMPARO' },
  { estado: 'SP', cidade: 'SANTO ANTONIO DO ARACANGUA' },
  { estado: 'MG', cidade: 'SANTO ANTONIO DO AVENTUREIRO' },
  { estado: 'PR', cidade: 'SANTO ANTONIO DO CAIUA' },
  { estado: 'GO', cidade: 'SANTO ANTONIO DO DESCOBERTO' },
  { estado: 'MG', cidade: 'SANTO ANTONIO DO GRAMA' },
  { estado: 'AM', cidade: 'SANTO ANTONIO DO ICA' },
  { estado: 'MG', cidade: 'SANTO ANTONIO DO ITAMBE' },
  { estado: 'MG', cidade: 'SANTO ANTONIO DO JACINTO' },
  { estado: 'SP', cidade: 'SANTO ANTONIO DO JARDIM' },
  { estado: 'MT', cidade: 'SANTO ANTONIO DO LESTE' },
  { estado: 'MT', cidade: 'SANTO ANTONIO DO LEVERGER' },
  { estado: 'MA', cidade: 'SANTO ANTONIO DO LOPES' },
  { estado: 'PI', cidade: 'SANTO ANTONIO DO MILAGRES' },
  { estado: 'MG', cidade: 'SANTO ANTONIO DO MONTE' },
  { estado: 'RS', cidade: 'SANTO ANTONIO DO PALMA' },
  { estado: 'PR', cidade: 'SANTO ANTONIO DO PARAISO' },
  { estado: 'SP', cidade: 'SANTO ANTONIO DO PINHAL' },
  { estado: 'RS', cidade: 'SANTO ANTONIO DO PLANALTO' },
  { estado: 'MG', cidade: 'SANTO ANTONIO DO RETIRO' },
  { estado: 'MG', cidade: 'SANTO ANTONIO DO RIO ABAIXO' },
  { estado: 'PR', cidade: 'SANTO ANTONIO DO SUDOESTE' },
  { estado: 'PA', cidade: 'SANTO ANTONIO DO TAUA' },
  { estado: 'RS', cidade: 'SANTO AUGUSTO' },
  { estado: 'RS', cidade: 'SANTO CRISTO' },
  { estado: 'BA', cidade: 'SANTO ESTEVAO' },
  { estado: 'SP', cidade: 'SANTO EXPEDITO' },
  { estado: 'RS', cidade: 'SANTO EXPEDITO DO SUL' },
  { estado: 'MG', cidade: 'SANTO HIPOLITO' },
  { estado: 'PR', cidade: 'SANTO INACIO' },
  { estado: 'PI', cidade: 'SANTO INACIO DO PIAUI' },
  { estado: 'SP', cidade: 'SANTOPOLIS DO AGUAPEI' },
  { estado: 'SP', cidade: 'SANTOS' },
  { estado: 'MG', cidade: 'SANTOS DUMONT' },
  { estado: 'CE', cidade: 'SAO BENEDITO' },
  { estado: 'MA', cidade: 'SAO BENEDITO DO RIO PRETO' },
  { estado: 'PE', cidade: 'SAO BENEDITO DO SUL' },
  { estado: 'PB', cidade: 'SAO BENTO' },
  { estado: 'MA', cidade: 'SAO BENTO' },
  { estado: 'MG', cidade: 'SAO BENTO ABADE' },
  { estado: 'PB', cidade: 'SAO BENTO DE POMBAL' },
  { estado: 'RN', cidade: 'SAO BENTO DO NORTE' },
  { estado: 'SP', cidade: 'SAO BENTO DO SAPUCAI' },
  { estado: 'SC', cidade: 'SAO BENTO DO SUL' },
  { estado: 'TO', cidade: 'SAO BENTO DO TOCANTINS' },
  { estado: 'RN', cidade: 'SAO BENTO DO TRAIRI' },
  { estado: 'PE', cidade: 'SAO BENTO DO UNA' },
  { estado: 'SC', cidade: 'SAO BERNARDINO' },
  { estado: 'MA', cidade: 'SAO BERNARDO' },
  { estado: 'SP', cidade: 'SAO BERNARDO DO CAMPO' },
  { estado: 'SC', cidade: 'SAO BONIFACIO' },
  { estado: 'RS', cidade: 'SAO BORJA' },
  { estado: 'Al', cidade: 'SAO BRAS' },
  { estado: 'MG', cidade: 'SAO BRAS DO SUACUI' },
  { estado: 'PI', cidade: 'SAO BRAZ DO PIAUI' },
  { estado: 'PA', cidade: 'SAO CAETANO DE ODIVELAS' },
  { estado: 'SP', cidade: 'SAO CAETANO DO SUL' },
  { estado: 'PE', cidade: 'SAO CAITANO' },
  { estado: 'SP', cidade: 'SAO CARLOS' },
  { estado: 'SC', cidade: 'SAO CARLOS' },
  { estado: 'PR', cidade: 'SAO CARLOS DO IVAI' },
  { estado: 'SE', cidade: 'SAO CRISTOVAO' },
  { estado: 'SC', cidade: 'SAO CRISTOVAO DO SUL' },
  { estado: 'BA', cidade: 'SAO DESIDERIO' },
  { estado: 'BA', cidade: 'SAO DOMINGOS' },
  { estado: 'SE', cidade: 'SAO DOMINGOS' },
  { estado: 'GO', cidade: 'SAO DOMINGOS' },
  { estado: 'SC', cidade: 'SAO DOMINGOS' },
  { estado: 'MG', cidade: 'SAO DOMINGOS DAS DORES' },
  { estado: 'PB', cidade: 'SAO DOMINGOS DE POMBAL' },
  { estado: 'PA', cidade: 'SAO DOMINGOS DO ARAGUAIA' },
  { estado: 'MA', cidade: 'SAO DOMINGOS DO AZEITAO' },
  { estado: 'PA', cidade: 'SAO DOMINGOS DO CAPIM' },
  { estado: 'PB', cidade: 'SAO DOMINGOS DO CARIRI' },
  { estado: 'MA', cidade: 'SAO DOMINGOS DO MARANHAO' },
  { estado: 'ES', cidade: 'SAO DOMINGOS DO NORTE' },
  { estado: 'MG', cidade: 'SAO DOMINGOS DO PRATA' },
  { estado: 'RS', cidade: 'SAO DOMINGOS DO SUL' },
  { estado: 'BA', cidade: 'SAO FELIPE' },
  { estado: 'RO', cidade: 'SAO FELIPE D OESTE' },
  { estado: 'BA', cidade: 'SAO FELIX' },
  { estado: 'MA', cidade: 'SAO FELIX DE BALSAS' },
  { estado: 'MG', cidade: 'SAO FELIX DE MINAS' },
  { estado: 'MT', cidade: 'SAO FELIX DO ARAGUAIA' },
  { estado: 'BA', cidade: 'SAO FELIX DO CORIBE' },
  { estado: 'PI', cidade: 'SAO FELIX DO PIAUI' },
  { estado: 'TO', cidade: 'SAO FELIX DO TOCANTINS' },
  { estado: 'PA', cidade: 'SAO FELIX DO XINGU' },
  { estado: 'RN', cidade: 'SAO FERNANDO' },
  { estado: 'RJ', cidade: 'SAO FIDELIS' },
  { estado: 'SP', cidade: 'SAO FRANCISCO' },
  { estado: 'MG', cidade: 'SAO FRANCISCO' },
  { estado: 'SE', cidade: 'SAO FRANCISCO' },
  { estado: 'PB', cidade: 'SAO FRANCISCO' },
  { estado: 'RS', cidade: 'SAO FRANCISCO DE ASSIS' },
  { estado: 'PI', cidade: 'SAO FRANCISCO DE ASSIS DO PIAUI' },
  { estado: 'GO', cidade: 'SAO FRANCISCO DE GOIAS' },
  { estado: 'RJ', cidade: 'SAO FRANCISCO DE ITABAPOANA' },
  { estado: 'MG', cidade: 'SAO FRANCISCO DE PAULA' },
  { estado: 'RS', cidade: 'SAO FRANCISCO DE PAULA' },
  { estado: 'MG', cidade: 'SAO FRANCISCO DE SALES' },
  { estado: 'MA', cidade: 'SAO FRANCISCO DO BREJAO' },
  { estado: 'BA', cidade: 'SAO FRANCISCO DO CONDE' },
  { estado: 'MG', cidade: 'SAO FRANCISCO DO GLORIA' },
  { estado: 'RO', cidade: 'SAO FRANCISCO DO GUAPORE' },
  { estado: 'MA', cidade: 'SAO FRANCISCO DO MARANHAO' },
  { estado: 'RN', cidade: 'SAO FRANCISCO DO OESTE' },
  { estado: 'PA', cidade: 'SAO FRANCISCO DO PARA' },
  { estado: 'PI', cidade: 'SAO FRANCISCO DO PIAUI' },
  { estado: 'SC', cidade: 'SAO FRANCISCO DO SUL' },
  { estado: 'BA', cidade: 'SAO GABRIEL' },
  { estado: 'RS', cidade: 'SAO GABRIEL' },
  { estado: 'AM', cidade: 'SAO GABRIEL DA CACHOEIRA' },
  { estado: 'ES', cidade: 'SAO GABRIEL DA PALHA' },
  { estado: 'MS', cidade: 'SAO GABRIEL DO OESTE' },
  { estado: 'MG', cidade: 'SAO GERALDO' },
  { estado: 'MG', cidade: 'SAO GERALDO DA PIEDADE' },
  { estado: 'PA', cidade: 'SAO GERALDO DO ARAGUAIA' },
  { estado: 'MG', cidade: 'SAO GERALDO DO BAIXIO' },
  { estado: 'RJ', cidade: 'SAO GONCALO' },
  { estado: 'MG', cidade: 'SAO GONCALO DO ABAETE' },
  { estado: 'RN', cidade: 'SAO GONCALO DO AMARANTE' },
  { estado: 'CE', cidade: 'SAO GONCALO DO AMARANTE' },
  { estado: 'BA', cidade: 'SAO GONCALO DO CAMPOS' },
  { estado: 'PI', cidade: 'SAO GONCALO DO GURGUEIA' },
  { estado: 'MG', cidade: 'SAO GONCALO DO PARA' },
  { estado: 'PI', cidade: 'SAO GONCALO DO PIAUI' },
  { estado: 'MG', cidade: 'SAO GONCALO DO RIO ABAIXO' },
  { estado: 'MG', cidade: 'SAO GONCALO DO RIO PRETO' },
  { estado: 'MG', cidade: 'SAO GONCALO DO SAPUCAI' },
  { estado: 'MG', cidade: 'SAO GOTARDO' },
  { estado: 'RS', cidade: 'SAO JERONIMO' },
  { estado: 'PR', cidade: 'SAO JERONIMO DA SERRA' },
  { estado: 'PE', cidade: 'SAO JOAO' },
  { estado: 'PR', cidade: 'SAO JOAO' },
  { estado: 'MA', cidade: 'SAO JOAO BATISTA' },
  { estado: 'SC', cidade: 'SAO JOAO BATISTA' },
  { estado: 'MG', cidade: 'SAO JOAO BATISTA DO GLORIA' },
  { estado: 'GO', cidade: 'SAO JOAO D´ALIANCA' },
  { estado: 'RR', cidade: 'SAO JOAO DA BALIZA' },
  { estado: 'RJ', cidade: 'SAO JOAO DA BARRA' },
  { estado: 'SP', cidade: 'SAO JOAO DA BOA VISTA' },
  { estado: 'PI', cidade: 'SAO JOAO DA CANABRAVA' },
  { estado: 'PI', cidade: 'SAO JOAO DA FRONTEIRA' },
  { estado: 'MG', cidade: 'SAO JOAO DA LAGOA' },
  { estado: 'MG', cidade: 'SAO JOAO DA MATA' },
  { estado: 'GO', cidade: 'SAO JOAO DA PARAUNA' },
  { estado: 'PA', cidade: 'SAO JOAO DA PONTA' },
  { estado: 'MG', cidade: 'SAO JOAO DA PONTE' },
  { estado: 'PI', cidade: 'SAO JOAO DA SERRA' },
  { estado: 'RS', cidade: 'SAO JOAO DA URTIGA' },
  { estado: 'PI', cidade: 'SAO JOAO DA VARJOTA' },
  { estado: 'SP', cidade: 'SAO JOAO DAS DUAS PONTES' },
  { estado: 'MG', cidade: 'SAO JOAO DAS MISSOES' },
  { estado: 'SP', cidade: 'SAO JOAO DE IRACEMA' },
  { estado: 'RJ', cidade: 'SAO JOAO DE MERITI' },
  { estado: 'PA', cidade: 'SAO JOAO DE PIRABAS' },
  { estado: 'MG', cidade: 'SAO JOAO DEL REI' },
  { estado: 'PA', cidade: 'SAO JOAO DO ARAGUAIA' },
  { estado: 'PI', cidade: 'SAO JOAO DO ARRAIAL' },
  { estado: 'PR', cidade: 'SAO JOAO DO CAIUA' },
  { estado: 'PB', cidade: 'SAO JOAO DO CARIRI' },
  { estado: 'MA', cidade: 'SAO JOAO DO CARU' },
  { estado: 'SC', cidade: 'SAO JOAO DO ITAPERIU' },
  { estado: 'PR', cidade: 'SAO JOAO DO IVAI' },
  { estado: 'CE', cidade: 'SAO JOAO DO JAGUARIBE' },
  { estado: 'MG', cidade: 'SAO JOAO DO MANHUACU' },
  { estado: 'MG', cidade: 'SAO JOAO DO MANTENINHA' },
  { estado: 'SC', cidade: 'SAO JOAO DO OESTE' },
  { estado: 'MG', cidade: 'SAO JOAO DO ORIENTE' },
  { estado: 'MG', cidade: 'SAO JOAO DO PACUI' },
  { estado: 'MG', cidade: 'SAO JOAO DO PARAISO' },
  { estado: 'MA', cidade: 'SAO JOAO DO PARAISO' },
  { estado: 'MA', cidade: 'SAO JOAO DO PATOS' },
  { estado: 'SP', cidade: 'SAO JOAO DO PAU D´ALHO' },
  { estado: 'PI', cidade: 'SAO JOAO DO PIAUI' },
  { estado: 'RS', cidade: 'SAO JOAO DO POLESINE' },
  { estado: 'PB', cidade: 'SAO JOAO DO RIO DO PEIXE' },
  { estado: 'RN', cidade: 'SAO JOAO DO SABUGI' },
  { estado: 'MA', cidade: 'SAO JOAO DO SOTER' },
  { estado: 'SC', cidade: 'SAO JOAO DO SUL' },
  { estado: 'PB', cidade: 'SAO JOAO DO TIGRE' },
  { estado: 'PR', cidade: 'SAO JOAO DO TRIUNFO' },
  { estado: 'MG', cidade: 'SAO JOAO EVANGELISTA' },
  { estado: 'MG', cidade: 'SAO JOAO NEPOMUCENO' },
  { estado: 'SC', cidade: 'SAO JOAQUIM' },
  { estado: 'SP', cidade: 'SAO JOAQUIM DA BARRA' },
  { estado: 'MG', cidade: 'SAO JOAQUIM DE BICAS' },
  { estado: 'PE', cidade: 'SAO JOAQUIM DO MONTE' },
  { estado: 'RS', cidade: 'SAO JORGE' },
  { estado: 'PR', cidade: 'SAO JORGE D´OESTE' },
  { estado: 'PR', cidade: 'SAO JORGE DO IVAI' },
  { estado: 'PR', cidade: 'SAO JORGE DO PATROCINIO' },
  { estado: 'SC', cidade: 'SAO JOSE' },
  { estado: 'MG', cidade: 'SAO JOSE DA BARRA' },
  { estado: 'SP', cidade: 'SAO JOSE DA BELA VISTA' },
  { estado: 'PR', cidade: 'SAO JOSE DA BOA VISTA' },
  { estado: 'PE', cidade: 'SAO JOSE DA COROA GRANDE' },
  { estado: 'PB', cidade: 'SAO JOSE DA LAGOA TAPADA' },
  { estado: 'Al', cidade: 'SAO JOSE DA LAJE' },
  { estado: 'MG', cidade: 'SAO JOSE DA LAPA' },
  { estado: 'MG', cidade: 'SAO JOSE DA SAFIRA' },
  { estado: 'Al', cidade: 'SAO JOSE DA TAPERA' },
  { estado: 'MG', cidade: 'SAO JOSE DA VARGINHA' },
  { estado: 'BA', cidade: 'SAO JOSE DA VITORIA' },
  { estado: 'RS', cidade: 'SAO JOSE DAS MISSOES' },
  { estado: 'PR', cidade: 'SAO JOSE DAS PALMEIRAS' },
  { estado: 'PB', cidade: 'SAO JOSE DE CAIANA' },
  { estado: 'PB', cidade: 'SAO JOSE DE ESPINHARAS' },
  { estado: 'RN', cidade: 'SAO JOSE DE MIPIBU' },
  { estado: 'PB', cidade: 'SAO JOSE DE PIRANHAS' },
  { estado: 'PB', cidade: 'SAO JOSE DE PRINCESA' },
  { estado: 'MA', cidade: 'SAO JOSE DE RIBAMAR' },
  { estado: 'RJ', cidade: 'SAO JOSE DE UBA' },
  { estado: 'MG', cidade: 'SAO JOSE DO ALEGRE' },
  { estado: 'RS', cidade: 'SAO JOSE DO AUSENTES' },
  { estado: 'SP', cidade: 'SAO JOSE DO BARREIRO' },
  { estado: 'MA', cidade: 'SAO JOSE DO BASILIOS' },
  { estado: 'PE', cidade: 'SAO JOSE DO BELMONTE' },
  { estado: 'PB', cidade: 'SAO JOSE DO BONFIM' },
  { estado: 'PB', cidade: 'SAO JOSE DO BREJO DO CRUZ' },
  { estado: 'ES', cidade: 'SAO JOSE DO CALCADO' },
  { estado: 'RN', cidade: 'SAO JOSE DO CAMPESTRE' },
  { estado: 'SP', cidade: 'SAO JOSE DO CAMPOS' },
  { estado: 'SC', cidade: 'SAO JOSE DO CEDRO' },
  { estado: 'SC', cidade: 'SAO JOSE DO CERRITO' },
  { estado: 'PB', cidade: 'SAO JOSE DO CORDEIROS' },
  { estado: 'MG', cidade: 'SAO JOSE DO DIVINO' },
  { estado: 'PI', cidade: 'SAO JOSE DO DIVINO' },
  { estado: 'PE', cidade: 'SAO JOSE DO EGITO' },
  { estado: 'MG', cidade: 'SAO JOSE DO GOIABAL' },
  { estado: 'RS', cidade: 'SAO JOSE DO HERVAL' },
  { estado: 'RS', cidade: 'SAO JOSE DO HORTENCIO' },
  { estado: 'RS', cidade: 'SAO JOSE DO INHACORA' },
  { estado: 'BA', cidade: 'SAO JOSE DO JACUIPE' },
  { estado: 'MG', cidade: 'SAO JOSE DO JACURI' },
  { estado: 'MG', cidade: 'SAO JOSE DO MANTIMENTO' },
  { estado: 'RS', cidade: 'SAO JOSE DO NORTE' },
  { estado: 'RS', cidade: 'SAO JOSE DO OURO' },
  { estado: 'PI', cidade: 'SAO JOSE DO PEIXE' },
  { estado: 'PI', cidade: 'SAO JOSE DO PIAUI' },
  { estado: 'PR', cidade: 'SAO JOSE DO PINHAIS' },
  { estado: 'MT', cidade: 'SAO JOSE DO POVO' },
  { estado: 'MT', cidade: 'SAO JOSE DO QUATRO MARCOS' },
  { estado: 'PB', cidade: 'SAO JOSE DO RAMOS' },
  { estado: 'MT', cidade: 'SAO JOSE DO RIO CLARO' },
  { estado: 'SP', cidade: 'SAO JOSE DO RIO PARDO' },
  { estado: 'SP', cidade: 'SAO JOSE DO RIO PRETO' },
  { estado: 'PB', cidade: 'SAO JOSE DO SABUGI' },
  { estado: 'RN', cidade: 'SAO JOSE DO SERIDO' },
  { estado: 'RS', cidade: 'SAO JOSE DO SUL' },
  { estado: 'RJ', cidade: 'SAO JOSE DO VALE DO RIO PRETO' },
  { estado: 'MT', cidade: 'SAO JOSE DO XINGU' },
  { estado: 'PI', cidade: 'SAO JULIAO' },
  { estado: 'RS', cidade: 'SAO LEOPOLDO' },
  { estado: 'MG', cidade: 'SAO LOURENCO' },
  { estado: 'PE', cidade: 'SAO LOURENCO DA MATA' },
  { estado: 'SP', cidade: 'SAO LOURENCO DA SERRA' },
  { estado: 'SC', cidade: 'SAO LOURENCO DO OESTE' },
  { estado: 'PI', cidade: 'SAO LOURENCO DO PIAUI' },
  { estado: 'RS', cidade: 'SAO LOURENCO DO SUL' },
  { estado: 'SC', cidade: 'SAO LUDGERO' },
  { estado: 'MA', cidade: 'SAO LUIS' },
  { estado: 'GO', cidade: 'SAO LUIS DE MONTES BELOS' },
  { estado: 'CE', cidade: 'SAO LUIS DO CURU' },
  { estado: 'SP', cidade: 'SAO LUIS DO PARAITINGA' },
  { estado: 'PI', cidade: 'SAO LUIS DO PIAUI' },
  { estado: 'Al', cidade: 'SAO LUIS DO QUITUNDE' },
  { estado: 'MA', cidade: 'SAO LUIS GONZAGA DO MARANHAO' },
  { estado: 'RR', cidade: 'SAO LUIZ' },
  { estado: 'GO', cidade: 'SAO LUIZ DO NORTE' },
  { estado: 'RS', cidade: 'SAO LUIZ GONZAGA' },
  { estado: 'PB', cidade: 'SAO MAMEDE' },
  { estado: 'PR', cidade: 'SAO MANOEL DO PARANA' },
  { estado: 'SP', cidade: 'SAO MANUEL' },
  { estado: 'RS', cidade: 'SAO MARCOS' },
  { estado: 'SC', cidade: 'SAO MARTINHO' },
  { estado: 'RS', cidade: 'SAO MARTINHO' },
  { estado: 'RS', cidade: 'SAO MARTINHO DA SERRA' },
  { estado: 'ES', cidade: 'SAO MATEUS' },
  { estado: 'MA', cidade: 'SAO MATEUS DO MARANHAO' },
  { estado: 'PR', cidade: 'SAO MATEUS DO SUL' },
  { estado: 'RN', cidade: 'SAO MIGUEL' },
  { estado: 'SP', cidade: 'SAO MIGUEL ARCANJO' },
  { estado: 'SC', cidade: 'SAO MIGUEL D´OESTE' },
  { estado: 'PI', cidade: 'SAO MIGUEL DA BAIXA GRANDE' },
  { estado: 'SC', cidade: 'SAO MIGUEL DA BOA VISTA' },
  { estado: 'BA', cidade: 'SAO MIGUEL DAS MATAS' },
  { estado: 'RS', cidade: 'SAO MIGUEL DAS MISSOES' },
  { estado: 'PB', cidade: 'SAO MIGUEL DE TAIPU' },
  { estado: 'RN', cidade: 'SAO MIGUEL DE TOUROS' },
  { estado: 'SE', cidade: 'SAO MIGUEL DO ALEIXO' },
  { estado: 'MG', cidade: 'SAO MIGUEL DO ANTA' },
  { estado: 'GO', cidade: 'SAO MIGUEL DO ARAGUAIA' },
  { estado: 'Al', cidade: 'SAO MIGUEL DO CAMPOS' },
  { estado: 'PI', cidade: 'SAO MIGUEL DO FIDALGO' },
  { estado: 'PA', cidade: 'SAO MIGUEL DO GUAMA' },
  { estado: 'RO', cidade: 'SAO MIGUEL DO GUAPORE' },
  { estado: 'PR', cidade: 'SAO MIGUEL DO IGUACU' },
  { estado: 'Al', cidade: 'SAO MIGUEL DO MILAGRES' },
  { estado: 'GO', cidade: 'SAO MIGUEL DO PASSA QUATRO' },
  { estado: 'PI', cidade: 'SAO MIGUEL DO TAPUIO' },
  { estado: 'TO', cidade: 'SAO MIGUEL DO TOCANTINS' },
  { estado: 'RS', cidade: 'SAO NICOLAU' },
  { estado: 'GO', cidade: 'SAO PATRICIO' },
  { estado: 'SP', cidade: 'SAO PAULO' },
  { estado: 'RS', cidade: 'SAO PAULO DAS MISSOES' },
  { estado: 'AM', cidade: 'SAO PAULO DE OLIVENCA' },
  { estado: 'RN', cidade: 'SAO PAULO DO POTENGI' },
  { estado: 'SP', cidade: 'SAO PEDRO' },
  { estado: 'RN', cidade: 'SAO PEDRO' },
  { estado: 'MA', cidade: 'SAO PEDRO DA AGUA BRANCA' },
  { estado: 'RJ', cidade: 'SAO PEDRO DA ALDEIA' },
  { estado: 'MT', cidade: 'SAO PEDRO DA CIPA' },
  { estado: 'RS', cidade: 'SAO PEDRO DA SERRA' },
  { estado: 'MG', cidade: 'SAO PEDRO DA UNIAO' },
  { estado: 'RS', cidade: 'SAO PEDRO DAS MISSOES' },
  { estado: 'SC', cidade: 'SAO PEDRO DE ALCANTARA' },
  { estado: 'RS', cidade: 'SAO PEDRO DO BUTIA' },
  { estado: 'MA', cidade: 'SAO PEDRO DO CRENTES' },
  { estado: 'MG', cidade: 'SAO PEDRO DO FERROS' },
  { estado: 'PR', cidade: 'SAO PEDRO DO IGUACU' },
  { estado: 'PR', cidade: 'SAO PEDRO DO IVAI' },
  { estado: 'PR', cidade: 'SAO PEDRO DO PARANA' },
  { estado: 'PI', cidade: 'SAO PEDRO DO PIAUI' },
  { estado: 'MG', cidade: 'SAO PEDRO DO SUACUI' },
  { estado: 'RS', cidade: 'SAO PEDRO DO SUL' },
  { estado: 'SP', cidade: 'SAO PEDRO DO TURVO' },
  { estado: 'RN', cidade: 'SAO RAFAEL' },
  { estado: 'MA', cidade: 'SAO RAIMUNDO DAS MANGABEIRAS' },
  { estado: 'MA', cidade: 'SAO RAIMUNDO DO DOCA BEZERRA' },
  { estado: 'PI', cidade: 'SAO RAIMUNDO NONATO' },
  { estado: 'MA', cidade: 'SAO ROBERTO' },
  { estado: 'MG', cidade: 'SAO ROMAO' },
  { estado: 'SP', cidade: 'SAO ROQUE' },
  { estado: 'MG', cidade: 'SAO ROQUE DE MINAS' },
  { estado: 'ES', cidade: 'SAO ROQUE DO CANAA' },
  { estado: 'TO', cidade: 'SAO SALVADOR DO TOCANTINS' },
  { estado: 'SP', cidade: 'SAO SEBASTIAO' },
  { estado: 'Al', cidade: 'SAO SEBASTIAO' },
  { estado: 'PR', cidade: 'SAO SEBASTIAO DA AMOREIRA' },
  { estado: 'MG', cidade: 'SAO SEBASTIAO DA BELA VISTA' },
  { estado: 'PA', cidade: 'SAO SEBASTIAO DA BOA VISTA' },
  { estado: 'SP', cidade: 'SAO SEBASTIAO DA GRAMA' },
  { estado: 'MG', cidade: 'SAO SEBASTIAO DA VARGEM ALEGRE' },
  { estado: 'PB', cidade: 'SAO SEBASTIAO DE LAGOA DE ROCA' },
  { estado: 'RJ', cidade: 'SAO SEBASTIAO DO ALTO' },
  { estado: 'MG', cidade: 'SAO SEBASTIAO DO ANTA' },
  { estado: 'RS', cidade: 'SAO SEBASTIAO DO CAI' },
  { estado: 'MG', cidade: 'SAO SEBASTIAO DO MARANHAO' },
  { estado: 'MG', cidade: 'SAO SEBASTIAO DO OESTE' },
  { estado: 'MG', cidade: 'SAO SEBASTIAO DO PARAISO' },
  { estado: 'BA', cidade: 'SAO SEBASTIAO DO PASSE' },
  { estado: 'MG', cidade: 'SAO SEBASTIAO DO RIO PRETO' },
  { estado: 'MG', cidade: 'SAO SEBASTIAO DO RIO VERDE' },
  { estado: 'TO', cidade: 'SAO SEBASTIAO DO TOCANTINS' },
  { estado: 'AM', cidade: 'SAO SEBASTIAO DO UATUMA' },
  { estado: 'PB', cidade: 'SAO SEBASTIAO DO UMBUZEIRO' },
  { estado: 'RS', cidade: 'SAO SEPE' },
  { estado: 'SP', cidade: 'SAO SIMAO' },
  { estado: 'GO', cidade: 'SAO SIMAO' },
  { estado: 'MG', cidade: 'SAO THOME DAS LETRAS' },
  { estado: 'MG', cidade: 'SAO TIAGO' },
  { estado: 'MG', cidade: 'SAO TOMAS DE AQUINO' },
  { estado: 'RN', cidade: 'SAO TOME' },
  { estado: 'PR', cidade: 'SAO TOME' },
  { estado: 'RS', cidade: 'SAO VALENTIM' },
  { estado: 'RS', cidade: 'SAO VALENTIM DO SUL' },
  { estado: 'TO', cidade: 'SAO VALERIO DA NATIVIDADE' },
  { estado: 'RS', cidade: 'SAO VALERIO DO SUL' },
  { estado: 'RS', cidade: 'SAO VENDELINO' },
  { estado: 'SP', cidade: 'SAO VICENTE' },
  { estado: 'RN', cidade: 'SAO VICENTE' },
  { estado: 'MG', cidade: 'SAO VICENTE DE MINAS' },
  { estado: 'PB', cidade: 'SAO VICENTE DO SERIDO' },
  { estado: 'RS', cidade: 'SAO VICENTE DO SUL' },
  { estado: 'PE', cidade: 'SAO VICENTE FERRER' },
  { estado: 'MA', cidade: 'SAO VICENTE FERRER' },
  { estado: 'PB', cidade: 'SAPE' },
  { estado: 'BA', cidade: 'SAPEACU' },
  { estado: 'MT', cidade: 'SAPEZAL' },
  { estado: 'RS', cidade: 'SAPIRANGA' },
  { estado: 'PR', cidade: 'SAPOPEMA' },
  { estado: 'RJ', cidade: 'SAPUCAIA' },
  { estado: 'PA', cidade: 'SAPUCAIA' },
  { estado: 'RS', cidade: 'SAPUCAIA DO SUL' },
  { estado: 'MG', cidade: 'SAPUCAI-MIRIM' },
  { estado: 'RJ', cidade: 'SAQUAREMA' },
  { estado: 'PR', cidade: 'SARANDI' },
  { estado: 'RS', cidade: 'SARANDI' },
  { estado: 'SP', cidade: 'SARAPUI' },
  { estado: 'MG', cidade: 'SARDOA' },
  { estado: 'SP', cidade: 'SARUTAIA' },
  { estado: 'MG', cidade: 'SARZEDO' },
  { estado: 'BA', cidade: 'SATIRO DIAS' },
  { estado: 'Al', cidade: 'SATUBA' },
  { estado: 'MA', cidade: 'SATUBINHA' },
  { estado: 'BA', cidade: 'SAUBARA' },
  { estado: 'PR', cidade: 'SAUDADE DO IGUACU' },
  { estado: 'SC', cidade: 'SAUDADES' },
  { estado: 'BA', cidade: 'SAUDE' },
  { estado: 'SC', cidade: 'SCHROEDER' },
  { estado: 'BA', cidade: 'SEABRA' },
  { estado: 'SC', cidade: 'SEARA' },
  { estado: 'SP', cidade: 'SEBASTIANOPOLIS DO SUL' },
  { estado: 'PI', cidade: 'SEBASTIAO BARROS' },
  { estado: 'BA', cidade: 'SEBASTIAO LARANJEIRAS' },
  { estado: 'PI', cidade: 'SEBASTIAO LEAL' },
  { estado: 'RS', cidade: 'SEBERI' },
  { estado: 'RS', cidade: 'SEDE NOVA' },
  { estado: 'RS', cidade: 'SEGREDO' },
  { estado: 'RS', cidade: 'SELBACH' },
  { estado: 'MS', cidade: 'SELVIRIA' },
  { estado: 'MG', cidade: 'SEM PEIXE' },
  { estado: 'AC', cidade: 'SENA MADUREIRA' },
  { estado: 'MA', cidade: 'SENADOR ALEXANDRE COSTA' },
  { estado: 'MG', cidade: 'SENADOR AMARAL' },
  { estado: 'GO', cidade: 'SENADOR CANEDO' },
  { estado: 'MG', cidade: 'SENADOR CORTES' },
  { estado: 'RN', cidade: 'SENADOR ELOI DE SOUZA' },
  { estado: 'MG', cidade: 'SENADOR FIRMINO' },
  { estado: 'RN', cidade: 'SENADOR GEORGINO AVELINO' },
  { estado: 'AC', cidade: 'SENADOR GUIOMARD' },
  { estado: 'MG', cidade: 'SENADOR JOSE BENTO' },
  { estado: 'PA', cidade: 'SENADOR JOSE PORFIRIO' },
  { estado: 'MA', cidade: 'SENADOR LA ROCQUE' },
  { estado: 'MG', cidade: 'SENADOR MODESTINO GONCALVES' },
  { estado: 'CE', cidade: 'SENADOR POMPEU' },
  { estado: 'Al', cidade: 'SENADOR RUI PALMEIRA' },
  { estado: 'CE', cidade: 'SENADOR SA' },
  { estado: 'RS', cidade: 'SENADOR SALGADO FILHO' },
  { estado: 'PR', cidade: 'SENGES' },
  { estado: 'BA', cidade: 'SENHOR DO BONFIM' },
  { estado: 'MG', cidade: 'SENHORA DE OLIVEIRA' },
  { estado: 'MG', cidade: 'SENHORA DO PORTO' },
  { estado: 'MG', cidade: 'SENHORA DO REMEDIOS' },
  { estado: 'RS', cidade: 'SENTINELA DO SUL' },
  { estado: 'BA', cidade: 'SENTO SE' },
  { estado: 'RS', cidade: 'SERAFINA CORREA' },
  { estado: 'MG', cidade: 'SERICITA' },
  { estado: 'RO', cidade: 'SERINGUEIRAS' },
  { estado: 'RS', cidade: 'SERIO' },
  { estado: 'MG', cidade: 'SERITINGA' },
  { estado: 'RJ', cidade: 'SEROPEDICA' },
  { estado: 'ES', cidade: 'SERRA' },
  { estado: 'SC', cidade: 'SERRA ALTA' },
  { estado: 'SP', cidade: 'SERRA AZUL' },
  { estado: 'MG', cidade: 'SERRA AZUL DE MINAS' },
  { estado: 'PB', cidade: 'SERRA BRANCA' },
  { estado: 'PB', cidade: 'SERRA DA RAIZ' },
  { estado: 'MG', cidade: 'SERRA DA SAUDADE' },
  { estado: 'RN', cidade: 'SERRA DE SAO BENTO' },
  { estado: 'MG', cidade: 'SERRA DO AIMORES' },
  { estado: 'RN', cidade: 'SERRA DO MEL' },
  { estado: 'AP', cidade: 'SERRA DO NAVIO' },
  { estado: 'BA', cidade: 'SERRA DO RAMALHO' },
  { estado: 'MG', cidade: 'SERRA DO SALITRE' },
  { estado: 'BA', cidade: 'SERRA DOURADA' },
  { estado: 'PB', cidade: 'SERRA GRANDE' },
  { estado: 'SP', cidade: 'SERRA NEGRA' },
  { estado: 'RN', cidade: 'SERRA NEGRA DO NORTE' },
  { estado: 'MT', cidade: 'SERRA NOVA DOURADA' },
  { estado: 'BA', cidade: 'SERRA PRETA' },
  { estado: 'PB', cidade: 'SERRA REDONDA' },
  { estado: 'PE', cidade: 'SERRA TALHADA' },
  { estado: 'SP', cidade: 'SERRANA' },
  { estado: 'MG', cidade: 'SERRANIA' },
  { estado: 'MA', cidade: 'SERRANO DO MARANHAO' },
  { estado: 'GO', cidade: 'SERRANOPOLIS' },
  { estado: 'MG', cidade: 'SERRANOPOLIS DE MINAS' },
  { estado: 'PR', cidade: 'SERRANOPOLIS DO IGUACU' },
  { estado: 'MG', cidade: 'SERRANOS' },
  { estado: 'PB', cidade: 'SERRARIA' },
  { estado: 'BA', cidade: 'SERRINHA' },
  { estado: 'RN', cidade: 'SERRINHA' },
  { estado: 'RN', cidade: 'SERRINHA DO PINTOS' },
  { estado: 'PE', cidade: 'SERRITA' },
  { estado: 'MG', cidade: 'SERRO' },
  { estado: 'BA', cidade: 'SERROLANDIA' },
  { estado: 'PR', cidade: 'SERTANEJA' },
  { estado: 'PE', cidade: 'SERTANIA' },
  { estado: 'PR', cidade: 'SERTANOPOLIS' },
  { estado: 'RS', cidade: 'SERTAO' },
  { estado: 'RS', cidade: 'SERTAO SANTANA' },
  { estado: 'SP', cidade: 'SERTAOZINHO' },
  { estado: 'PB', cidade: 'SERTAOZINHO' },
  { estado: 'SP', cidade: 'SETE BARRAS' },
  { estado: 'RS', cidade: 'SETE DE SETEMBRO' },
  { estado: 'MG', cidade: 'SETE LAGOAS' },
  { estado: 'MS', cidade: 'SETE QUEDAS' },
  { estado: 'MG', cidade: 'SETUBINHA' },
  { estado: 'RS', cidade: 'SEVERIANO DE ALMEIDA' },
  { estado: 'RN', cidade: 'SEVERIANO MELO' },
  { estado: 'SP', cidade: 'SEVERINIA' },
  { estado: 'SC', cidade: 'SIDEROPOLIS' },
  { estado: 'MS', cidade: 'SIDROLANDIA' },
  { estado: 'PI', cidade: 'SIGEFREDO PACHECO' },
  { estado: 'RJ', cidade: 'SILVA JARDIM' },
  { estado: 'GO', cidade: 'SILVANIA' },
  { estado: 'TO', cidade: 'SILVANOPOLIS' },
  { estado: 'RS', cidade: 'SILVEIRA MARTINS' },
  { estado: 'MG', cidade: 'SILVEIRANIA' },
  { estado: 'SP', cidade: 'SILVEIRAS' },
  { estado: 'AM', cidade: 'SILVES' },
  { estado: 'MG', cidade: 'SILVIANOPOLIS' },
  { estado: 'SE', cidade: 'SIMAO DIAS' },
  { estado: 'MG', cidade: 'SIMAO PEREIRA' },
  { estado: 'PI', cidade: 'SIMOES' },
  { estado: 'BA', cidade: 'SIMOES FILHO' },
  { estado: 'GO', cidade: 'SIMOLANDIA' },
  { estado: 'MG', cidade: 'SIMONESIA' },
  { estado: 'PI', cidade: 'SIMPLICIO MENDES' },
  { estado: 'RS', cidade: 'SINIMBU' },
  { estado: 'MT', cidade: 'SINOP' },
  { estado: 'PR', cidade: 'SIQUEIRA CAMPOS' },
  { estado: 'PE', cidade: 'SIRINHAEM' },
  { estado: 'SE', cidade: 'SIRIRI' },
  { estado: 'GO', cidade: 'SITIO D´ABADIA' },
  { estado: 'BA', cidade: 'SITIO DO MATO' },
  { estado: 'BA', cidade: 'SITIO DO QUINTO' },
  { estado: 'RN', cidade: 'SITIO NOVO' },
  { estado: 'MA', cidade: 'SITIO NOVO' },
  { estado: 'TO', cidade: 'SITIO NOVO DO TOCANTINS' },
  { estado: 'BA', cidade: 'SOBRADINHO' },
  { estado: 'RS', cidade: 'SOBRADINHO' },
  { estado: 'PB', cidade: 'SOBRADO' },
  { estado: 'CE', cidade: 'SOBRAL' },
  { estado: 'MG', cidade: 'SOBRALIA' },
  { estado: 'SP', cidade: 'SOCORRO' },
  { estado: 'PI', cidade: 'SOCORRO DO PIAUI' },
  { estado: 'PB', cidade: 'SOLANEA' },
  { estado: 'PB', cidade: 'SOLEDADE' },
  { estado: 'RS', cidade: 'SOLEDADE' },
  { estado: 'MG', cidade: 'SOLEDADE DE MINAS' },
  { estado: 'PE', cidade: 'SOLIDAO' },
  { estado: 'CE', cidade: 'SOLONOPOLE' },
  { estado: 'SC', cidade: 'SOMBRIO' },
  { estado: 'MS', cidade: 'SONORA' },
  { estado: 'ES', cidade: 'SOORETAMA' },
  { estado: 'SP', cidade: 'SOROCABA' },
  { estado: 'MT', cidade: 'SORRISO' },
  { estado: 'PB', cidade: 'SOSSEGO' },
  { estado: 'PA', cidade: 'SOURE' },
  { estado: 'PB', cidade: 'SOUSA' },
  { estado: 'BA', cidade: 'SOUTO SOARES' },
  { estado: 'TO', cidade: 'SUCUPIRA' },
  { estado: 'MA', cidade: 'SUCUPIRA DO NORTE' },
  { estado: 'MA', cidade: 'SUCUPIRA DO RIACHAO' },
  { estado: 'SP', cidade: 'SUD MENNUCCI' },
  { estado: 'SC', cidade: 'SUL BRASIL' },
  { estado: 'PR', cidade: 'SULINA' },
  { estado: 'SP', cidade: 'SUMARE' },
  { estado: 'PB', cidade: 'SUME' },
  { estado: 'RJ', cidade: 'SUMIDOURO' },
  { estado: 'PE', cidade: 'SURUBIM' },
  { estado: 'PI', cidade: 'SUSSUAPARA' },
  { estado: 'SP', cidade: 'SUZANAPOLIS' },
  { estado: 'SP', cidade: 'SUZANO' },
  { estado: 'RS', cidade: 'TABAI' },
  { estado: 'MT', cidade: 'TABAPORA' },
  { estado: 'SP', cidade: 'TABAPUA' },
  { estado: 'SP', cidade: 'TABATINGA' },
  { estado: 'AM', cidade: 'TABATINGA' },
  { estado: 'PE', cidade: 'TABIRA' },
  { estado: 'SP', cidade: 'TABOAO DA SERRA' },
  { estado: 'BA', cidade: 'TABOCAS DO BREJO VELHO' },
  { estado: 'RN', cidade: 'TABOLEIRO GRANDE' },
  { estado: 'MG', cidade: 'TABULEIRO' },
  { estado: 'CE', cidade: 'TABULEIRO DO NORTE' },
  { estado: 'PE', cidade: 'TACAIMBO' },
  { estado: 'PE', cidade: 'TACARATU' },
  { estado: 'SP', cidade: 'TACIBA' },
  { estado: 'MS', cidade: 'TACURU' },
  { estado: 'SP', cidade: 'TAGUAI' },
  { estado: 'TO', cidade: 'TAGUATINGA' },
  { estado: 'SP', cidade: 'TAIACU' },
  { estado: 'PA', cidade: 'TAILANDIA' },
  { estado: 'SC', cidade: 'TAIO' },
  { estado: 'MG', cidade: 'TAIOBEIRAS' },
  { estado: 'TO', cidade: 'TAIPAS DO TOCANTINS' },
  { estado: 'RN', cidade: 'TAIPU' },
  { estado: 'SP', cidade: 'TAIUVA' },
  { estado: 'TO', cidade: 'TALISMA' },
  { estado: 'PE', cidade: 'TAMANDARE' },
  { estado: 'PR', cidade: 'TAMARANA' },
  { estado: 'SP', cidade: 'TAMBAU' },
  { estado: 'PR', cidade: 'TAMBOARA' },
  { estado: 'CE', cidade: 'TAMBORIL' },
  { estado: 'PI', cidade: 'TAMBORIL DO PIAUI' },
  { estado: 'SP', cidade: 'TANABI' },
  { estado: 'RN', cidade: 'TANGARA' },
  { estado: 'SC', cidade: 'TANGARA' },
  { estado: 'MT', cidade: 'TANGARA DA SERRA' },
  { estado: 'RJ', cidade: 'TANGUA' },
  { estado: 'BA', cidade: 'TANHACU' },
  { estado: 'Al', cidade: 'TANQUE D´ARCA' },
  { estado: 'PI', cidade: 'TANQUE DO PIAUI' },
  { estado: 'BA', cidade: 'TANQUE NOVO' },
  { estado: 'BA', cidade: 'TANQUINHO' },
  { estado: 'MG', cidade: 'TAPARUBA' },
  { estado: 'AM', cidade: 'TAPAUA' },
  { estado: 'PR', cidade: 'TAPEJARA' },
  { estado: 'RS', cidade: 'TAPEJARA' },
  { estado: 'RS', cidade: 'TAPERA' },
  { estado: 'BA', cidade: 'TAPEROA' },
  { estado: 'PB', cidade: 'TAPEROA' },
  { estado: 'RS', cidade: 'TAPES' },
  { estado: 'MG', cidade: 'TAPIRA' },
  { estado: 'PR', cidade: 'TAPIRA' },
  { estado: 'SP', cidade: 'TAPIRAI' },
  { estado: 'MG', cidade: 'TAPIRAI' },
  { estado: 'BA', cidade: 'TAPIRAMUTA' },
  { estado: 'SP', cidade: 'TAPIRATIBA' },
  { estado: 'MT', cidade: 'TAPURAH' },
  { estado: 'RS', cidade: 'TAQUARA' },
  { estado: 'MG', cidade: 'TAQUARACU DE MINAS' },
  { estado: 'SP', cidade: 'TAQUARAL' },
  { estado: 'GO', cidade: 'TAQUARAL DE GOIAS' },
  { estado: 'Al', cidade: 'TAQUARANA' },
  { estado: 'RS', cidade: 'TAQUARI' },
  { estado: 'SP', cidade: 'TAQUARITINGA' },
  { estado: 'PE', cidade: 'TAQUARITINGA DO NORTE' },
  { estado: 'SP', cidade: 'TAQUARITUBA' },
  { estado: 'SP', cidade: 'TAQUARIVAI' },
  { estado: 'RS', cidade: 'TAQUARUCU DO SUL' },
  { estado: 'MS', cidade: 'TAQUARUSSU' },
  { estado: 'SP', cidade: 'TARABAI' },
  { estado: 'AC', cidade: 'TARAUACA' },
  { estado: 'CE', cidade: 'TARRAFAS' },
  { estado: 'AP', cidade: 'TARTARUGALZINHO' },
  { estado: 'SP', cidade: 'TARUMA' },
  { estado: 'MG', cidade: 'TARUMIRIM' },
  { estado: 'MA', cidade: 'TASSO FRAGOSO' },
  { estado: 'SP', cidade: 'TATUI' },
  { estado: 'CE', cidade: 'TAUA' },
  { estado: 'SP', cidade: 'TAUBATE' },
  { estado: 'PB', cidade: 'TAVARES' },
  { estado: 'RS', cidade: 'TAVARES' },
  { estado: 'AM', cidade: 'TEFE' },
  { estado: 'PB', cidade: 'TEIXEIRA' },
  { estado: 'BA', cidade: 'TEIXEIRA DE FREITAS' },
  { estado: 'PR', cidade: 'TEIXEIRA SOARES' },
  { estado: 'MG', cidade: 'TEIXEIRAS' },
  { estado: 'RO', cidade: 'TEIXEIROPOLIS' },
  { estado: 'CE', cidade: 'TEJUCUOCA' },
  { estado: 'SP', cidade: 'TEJUPA' },
  { estado: 'PR', cidade: 'TELEMACO BORBA' },
  { estado: 'SE', cidade: 'TELHA' },
  { estado: 'RN', cidade: 'TENENTE ANANIAS' },
  { estado: 'RN', cidade: 'TENENTE LAURENTINO CRUZ' },
  { estado: 'RS', cidade: 'TENENTE PORTELA' },
  { estado: 'PB', cidade: 'TENORIO' },
  { estado: 'SP', cidade: 'TEODORO SAMPAIO' },
  { estado: 'BA', cidade: 'TEODORO SAMPAIO' },
  { estado: 'BA', cidade: 'TEOFILANDIA' },
  { estado: 'MG', cidade: 'TEOFILO OTONI' },
  { estado: 'BA', cidade: 'TEOLANDIA' },
  { estado: 'Al', cidade: 'TEOTONIO VILELA' },
  { estado: 'MS', cidade: 'TERENOS' },
  { estado: 'PI', cidade: 'TERESINA' },
  { estado: 'GO', cidade: 'TERESINA DE GOIAS' },
  { estado: 'RJ', cidade: 'TERESOPOLIS' },
  { estado: 'PE', cidade: 'TEREZINHA' },
  { estado: 'GO', cidade: 'TEREZOPOLIS DE GOIAS' },
  { estado: 'PA', cidade: 'TERRA ALTA' },
  { estado: 'PR', cidade: 'TERRA BOA' },
  { estado: 'RS', cidade: 'TERRA DE AREIA' },
  { estado: 'BA', cidade: 'TERRA NOVA' },
  { estado: 'PE', cidade: 'TERRA NOVA' },
  { estado: 'MT', cidade: 'TERRA NOVA DO NORTE' },
  { estado: 'PR', cidade: 'TERRA RICA' },
  { estado: 'SP', cidade: 'TERRA ROXA' },
  { estado: 'PR', cidade: 'TERRA ROXA' },
  { estado: 'PA', cidade: 'TERRA SANTA' },
  { estado: 'MT', cidade: 'TESOURO' },
  { estado: 'RS', cidade: 'TEUTONIA' },
  { estado: 'RO', cidade: 'THEOBROMA' },
  { estado: 'CE', cidade: 'TIANGUA' },
  { estado: 'PR', cidade: 'TIBAGI' },
  { estado: 'RN', cidade: 'TIBAU' },
  { estado: 'RN', cidade: 'TIBAU DO SUL' },
  { estado: 'SP', cidade: 'TIETE' },
  { estado: 'SC', cidade: 'TIGRINHOS' },
  { estado: 'SC', cidade: 'TIJUCAS' },
  { estado: 'PR', cidade: 'TIJUCAS DO SUL' },
  { estado: 'PE', cidade: 'TIMBAUBA' },
  { estado: 'RN', cidade: 'TIMBAUBA DO BATISTAS' },
  { estado: 'SC', cidade: 'TIMBE DO SUL' },
  { estado: 'MA', cidade: 'TIMBIRAS' },
  { estado: 'SC', cidade: 'TIMBO' },
  { estado: 'SC', cidade: 'TIMBO GRANDE' },
  { estado: 'SP', cidade: 'TIMBURI' },
  { estado: 'MA', cidade: 'TIMON' },
  { estado: 'MG', cidade: 'TIMOTEO' },
  { estado: 'RS', cidade: 'TIO HUGO' },
  { estado: 'MG', cidade: 'TIRADENTES' },
  { estado: 'RS', cidade: 'TIRADENTES DO SUL' },
  { estado: 'MG', cidade: 'TIROS' },
  { estado: 'SE', cidade: 'TOBIAS BARRETO' },
  { estado: 'TO', cidade: 'TOCANTINIA' },
  { estado: 'TO', cidade: 'TOCANTINOPOLIS' },
  { estado: 'MG', cidade: 'TOCANTINS' },
  { estado: 'MG', cidade: 'TOCOS DO MOGI' },
  { estado: 'MG', cidade: 'TOLEDO' },
  { estado: 'PR', cidade: 'TOLEDO' },
  { estado: 'SE', cidade: 'TOMAR DO GERU' },
  { estado: 'PR', cidade: 'TOMAZINA' },
  { estado: 'MG', cidade: 'TOMBOS' },
  { estado: 'PA', cidade: 'TOME-ACU' },
  { estado: 'AM', cidade: 'TONANTINS' },
  { estado: 'PE', cidade: 'TORITAMA' },
  { estado: 'MT', cidade: 'TORIXOREU' },
  { estado: 'RS', cidade: 'TOROPI' },
  { estado: 'SP', cidade: 'TORRE DE PEDRA' },
  { estado: 'RS', cidade: 'TORRES' },
  { estado: 'SP', cidade: 'TORRINHA' },
  { estado: 'RN', cidade: 'TOUROS' },
  { estado: 'SP', cidade: 'TRABIJU' },
  { estado: 'PA', cidade: 'TRACUATEUA' },
  { estado: 'PE', cidade: 'TRACUNHAEM' },
  { estado: 'Al', cidade: 'TRAIPU' },
  { estado: 'PA', cidade: 'TRAIRAO' },
  { estado: 'CE', cidade: 'TRAIRI' },
  { estado: 'RJ', cidade: 'TRAJANO DE MORAIS' },
  { estado: 'RS', cidade: 'TRAMANDAI' },
  { estado: 'RS', cidade: 'TRAVESSEIRO' },
  { estado: 'BA', cidade: 'TREMEDAL' },
  { estado: 'SP', cidade: 'TREMEMBE' },
  { estado: 'RS', cidade: 'TRES ARROIOS' },
  { estado: 'SC', cidade: 'TRES BARRAS' },
  { estado: 'PR', cidade: 'TRES BARRAS DO PARANA' },
  { estado: 'RS', cidade: 'TRES CACHOEIRAS' },
  { estado: 'MG', cidade: 'TRÊS CORACOES, MG' },
  { estado: 'RS', cidade: 'TRES COROAS' },
  { estado: 'RS', cidade: 'TRES DE MAIO' },
  { estado: 'RS', cidade: 'TRES FORQUILHAS' },
  { estado: 'SP', cidade: 'TRES FRONTEIRAS' },
  { estado: 'MS', cidade: 'TRES LAGOAS' },
  { estado: 'MG', cidade: 'TRÊS MARIAS, MG' },
  { estado: 'RS', cidade: 'TRES PALMEIRAS' },
  { estado: 'RS', cidade: 'TRES PASSOS' },
  { estado: 'MG', cidade: 'TRÊS PONTAS, MG' },
  { estado: 'GO', cidade: 'TRES RANCHOS' },
  { estado: 'RJ', cidade: 'TRES RIOS' },
  { estado: 'SC', cidade: 'TREVISO' },
  { estado: 'SC', cidade: 'TREZE DE MAIO' },
  { estado: 'SC', cidade: 'TREZE TILIAS' },
  { estado: 'PE', cidade: 'TRINDADE' },
  { estado: 'GO', cidade: 'TRINDADE' },
  { estado: 'RS', cidade: 'TRINDADE DO SUL' },
  { estado: 'PE', cidade: 'TRIUNFO' },
  { estado: 'PB', cidade: 'TRIUNFO' },
  { estado: 'RS', cidade: 'TRIUNFO' },
  { estado: 'RN', cidade: 'TRIUNFO POTIGUAR' },
  { estado: 'MA', cidade: 'TRIZIDELA DO VALE' },
  { estado: 'GO', cidade: 'TROMBAS' },
  { estado: 'SC', cidade: 'TROMBUDO CENTRAL' },
  { estado: 'SC', cidade: 'TUBARAO' },
  { estado: 'BA', cidade: 'TUCANO' },
  { estado: 'PA', cidade: 'TUCUMA' },
  { estado: 'RS', cidade: 'TUCUNDUVA' },
  { estado: 'PA', cidade: 'TUCURUI' },
  { estado: 'MA', cidade: 'TUFILANDIA' },
  { estado: 'SP', cidade: 'TUIUTI' },
  { estado: 'MG', cidade: 'TUMIRITINGA' },
  { estado: 'SC', cidade: 'TUNAPOLIS' },
  { estado: 'RS', cidade: 'TUNAS' },
  { estado: 'PR', cidade: 'TUNAS DO PARANA' },
  { estado: 'PR', cidade: 'TUNEIRAS DO OESTE' },
  { estado: 'MA', cidade: 'TUNTUM' },
  { estado: 'SP', cidade: 'TUPA' },
  { estado: 'MG', cidade: 'TUPACIGUARA' },
  { estado: 'PE', cidade: 'TUPANATINGA' },
  { estado: 'RS', cidade: 'TUPANCI DO SUL' },
  { estado: 'RS', cidade: 'TUPANCIRETA' },
  { estado: 'RS', cidade: 'TUPANDI' },
  { estado: 'RS', cidade: 'TUPARENDI' },
  { estado: 'PE', cidade: 'TUPARETAMA' },
  { estado: 'PR', cidade: 'TUPASSI' },
  { estado: 'SP', cidade: 'TUPI PAULISTA' },
  { estado: 'TO', cidade: 'TUPIRAMA' },
  { estado: 'TO', cidade: 'TUPIRATINS' },
  { estado: 'MA', cidade: 'TURIACU' },
  { estado: 'MA', cidade: 'TURILANDIA' },
  { estado: 'SP', cidade: 'TURIUBA' },
  { estado: 'MG', cidade: 'TURMALINA' },
  { estado: 'SP', cidade: 'TURMALINA' },
  { estado: 'RS', cidade: 'TURUCU' },
  { estado: 'CE', cidade: 'TURURU' },
  { estado: 'GO', cidade: 'TURVANIA' },
  { estado: 'GO', cidade: 'TURVELANDIA' },
  { estado: 'PR', cidade: 'TURVO' },
  { estado: 'SC', cidade: 'TURVO' },
  { estado: 'MG', cidade: 'TURVOLANDIA' },
  { estado: 'MA', cidade: 'TUTOIA' },
  { estado: 'AM', cidade: 'UARINI' },
  { estado: 'BA', cidade: 'UAUA' },
  { estado: 'MG', cidade: 'UBA (MG)' },
  { estado: 'MG', cidade: 'UBAI' },
  { estado: 'BA', cidade: 'UBAIRA' },
  { estado: 'BA', cidade: 'UBAITABA' },
  { estado: 'CE', cidade: 'UBAJARA' },
  { estado: 'MG', cidade: 'UBAPORANGA' },
  { estado: 'SP', cidade: 'UBARANA' },
  { estado: 'BA', cidade: 'UBATA' },
  { estado: 'SP', cidade: 'UBATUBA' },
  { estado: 'MG', cidade: 'UBERABA' },
  { estado: 'MG', cidade: 'UBERLANDIA' },
  { estado: 'SP', cidade: 'UBIRAJARA' },
  { estado: 'PR', cidade: 'UBIRATA' },
  { estado: 'RS', cidade: 'UBIRETAMA' },
  { estado: 'SP', cidade: 'UCHOA' },
  { estado: 'BA', cidade: 'UIBAI' },
  { estado: 'RR', cidade: 'UIRAMUTA' },
  { estado: 'GO', cidade: 'UIRAPURU' },
  { estado: 'PB', cidade: 'UIRAUNA' },
  { estado: 'PA', cidade: 'ULIANOPOLIS' },
  { estado: 'CE', cidade: 'UMARI' },
  { estado: 'RN', cidade: 'UMARIZAL' },
  { estado: 'SE', cidade: 'UMBAUBA' },
  { estado: 'BA', cidade: 'UMBURANAS' },
  { estado: 'MG', cidade: 'UMBURATIBA' },
  { estado: 'PB', cidade: 'UMBUZEIRO' },
  { estado: 'CE', cidade: 'UMIRIM' },
  { estado: 'PR', cidade: 'UMUARAMA' },
  { estado: 'BA', cidade: 'UNA' },
  { estado: 'MG', cidade: 'UNAI' },
  { estado: 'PI', cidade: 'UNIAO' },
  { estado: 'RS', cidade: 'UNIAO DA SERRA' },
  { estado: 'PR', cidade: 'UNIAO DA VITORIA' },
  { estado: 'MG', cidade: 'UNIAO DE MINAS' },
  { estado: 'SC', cidade: 'UNIAO DO OESTE' },
  { estado: 'Al', cidade: 'UNIAO DO PALMARES' },
  { estado: 'MT', cidade: 'UNIAO DO SUL' },
  { estado: 'SP', cidade: 'UNIAO PAULISTA' },
  { estado: 'PR', cidade: 'UNIFLOR' },
  { estado: 'RS', cidade: 'UNISTALDA' },
  { estado: 'RN', cidade: 'UPANEMA' },
  { estado: 'PR', cidade: 'URAI' },
  { estado: 'BA', cidade: 'URANDI' },
  { estado: 'SP', cidade: 'URANIA' },
  { estado: 'MA', cidade: 'URBANO SANTOS' },
  { estado: 'SP', cidade: 'URU' },
  { estado: 'GO', cidade: 'URUACU' },
  { estado: 'GO', cidade: 'URUANA' },
  { estado: 'MG', cidade: 'URUANA DE MINAS' },
  { estado: 'PA', cidade: 'URUARA' },
  { estado: 'SC', cidade: 'URUBICI' },
  { estado: 'CE', cidade: 'URUBURETAMA' },
  { estado: 'MG', cidade: 'URUCANIA' },
  { estado: 'AM', cidade: 'URUCARA' },
  { estado: 'BA', cidade: 'URUCUCA' },
  { estado: 'PI', cidade: 'URUCUI' },
  { estado: 'MG', cidade: 'URUCUIA' },
  { estado: 'AM', cidade: 'URUCURITUBA' },
  { estado: 'RS', cidade: 'URUGUAIANA' },
  { estado: 'CE', cidade: 'URUOCA' },
  { estado: 'RO', cidade: 'URUPA' },
  { estado: 'SC', cidade: 'URUPEMA' },
  { estado: 'SP', cidade: 'URUPES' },
  { estado: 'SC', cidade: 'URUSSANGA' },
  { estado: 'GO', cidade: 'URUTAI' },
  { estado: 'BA', cidade: 'UTINGA' },
  { estado: 'RS', cidade: 'VACARIA' },
  { estado: 'MT', cidade: 'VALE DE SAO DOMINGOS' },
  { estado: 'RO', cidade: 'VALE DO ANARI' },
  { estado: 'RO', cidade: 'VALE DO PARAISO' },
  { estado: 'RS', cidade: 'VALE DO SOL' },
  { estado: 'RS', cidade: 'VALE REAL' },
  { estado: 'RS', cidade: 'VALE VERDE' },
  { estado: 'RJ', cidade: 'VALENCA' },
  { estado: 'BA', cidade: 'VALENCA' },
  { estado: 'PI', cidade: 'VALENCA DO PIAUI' },
  { estado: 'BA', cidade: 'VALENTE' },
  { estado: 'SP', cidade: 'VALENTIM GENTIL' },
  { estado: 'SP', cidade: 'VALINHOS' },
  { estado: 'SP', cidade: 'VALPARAISO' },
  { estado: 'GO', cidade: 'VALPARAISO DE GOIAS' },
  { estado: 'RS', cidade: 'VANINI' },
  { estado: 'SC', cidade: 'VARGEAO' },
  { estado: 'SP', cidade: 'VARGEM' },
  { estado: 'SC', cidade: 'VARGEM' },
  { estado: 'MG', cidade: 'VARGEM ALEGRE' },
  { estado: 'ES', cidade: 'VARGEM ALTA' },
  { estado: 'MG', cidade: 'VARGEM BONITA' },
  { estado: 'SC', cidade: 'VARGEM BONITA' },
  { estado: 'MA', cidade: 'VARGEM GRANDE' },
  { estado: 'MG', cidade: 'VARGEM GRANDE DO RIO PARDO' },
  { estado: 'SP', cidade: 'VARGEM GRANDE DO SUL' },
  { estado: 'SP', cidade: 'VARGEM GRANDE PAULISTA' },
  { estado: 'MG', cidade: 'VARGINHA' },
  { estado: 'GO', cidade: 'VARJAO' },
  { estado: 'MG', cidade: 'VARJAO DE MINAS' },
  { estado: 'CE', cidade: 'VARJOTA' },
  { estado: 'RJ', cidade: 'VARRE-SAI' },
  { estado: 'PB', cidade: 'VARZEA' },
  { estado: 'RN', cidade: 'VARZEA' },
  { estado: 'CE', cidade: 'VARZEA ALEGRE' },
  { estado: 'PI', cidade: 'VARZEA BRANCA' },
  { estado: 'MG', cidade: 'VARZEA DA PALMA' },
  { estado: 'BA', cidade: 'VARZEA DA ROCA' },
  { estado: 'BA', cidade: 'VARZEA DO POCO' },
  { estado: 'PI', cidade: 'VARZEA GRANDE' },
  { estado: 'MT', cidade: 'VARZEA GRANDE' },
  { estado: 'BA', cidade: 'VARZEA NOVA' },
  { estado: 'SP', cidade: 'VARZEA PAULISTA' },
  { estado: 'BA', cidade: 'VARZEDO' },
  { estado: 'MG', cidade: 'VARZELANDIA' },
  { estado: 'RJ', cidade: 'VASSOURAS' },
  { estado: 'MG', cidade: 'VAZANTE' },
  { estado: 'RS', cidade: 'VENANCIO AIRES' },
  { estado: 'ES', cidade: 'VENDA NOVA DO IMIGRANTE' },
  { estado: 'RN', cidade: 'VENHA-VER' },
  { estado: 'PR', cidade: 'VENTANIA' },
  { estado: 'PE', cidade: 'VENTUROSA' },
  { estado: 'MT', cidade: 'VERA' },
  { estado: 'SP', cidade: 'VERA CRUZ' },
  { estado: 'BA', cidade: 'VERA CRUZ' },
  { estado: 'RN', cidade: 'VERA CRUZ' },
  { estado: 'RS', cidade: 'VERA CRUZ' },
  { estado: 'PR', cidade: 'VERA CRUZ DO OESTE' },
  { estado: 'PI', cidade: 'VERA MENDES' },
  { estado: 'RS', cidade: 'VERANOPOLIS' },
  { estado: 'PE', cidade: 'VERDEJANTE' },
  { estado: 'MG', cidade: 'VERDELANDIA' },
  { estado: 'PR', cidade: 'VERE' },
  { estado: 'BA', cidade: 'VEREDA' },
  { estado: 'MG', cidade: 'VEREDINHA' },
  { estado: 'MG', cidade: 'VERISSIMO' },
  { estado: 'MG', cidade: 'VERMELHO NOVO' },
  { estado: 'PE', cidade: 'VERTENTE DO LERIO' },
  { estado: 'PE', cidade: 'VERTENTES' },
  { estado: 'MG', cidade: 'VESPASIANO' },
  { estado: 'RS', cidade: 'VESPASIANO CORREA' },
  { estado: 'RS', cidade: 'VIADUTOS' },
  { estado: 'RS', cidade: 'VIAMAO' },
  { estado: 'ES', cidade: 'VIANA' },
  { estado: 'MA', cidade: 'VIANA' },
  { estado: 'GO', cidade: 'VIANOPOLIS' },
  { estado: 'PE', cidade: 'VICENCIA' },
  { estado: 'RS', cidade: 'VICENTE DUTRA' },
  { estado: 'MS', cidade: 'VICENTINA' },
  { estado: 'GO', cidade: 'VICENTINOPOLIS' },
  { estado: 'MG', cidade: 'VICOSA' },
  { estado: 'Al', cidade: 'VICOSA' },
  { estado: 'RN', cidade: 'VICOSA' },
  { estado: 'CE', cidade: 'VICOSA DO CEARA' },
  { estado: 'RS', cidade: 'VICTOR GRAEFF' },
  { estado: 'SC', cidade: 'VIDAL RAMOS' },
  { estado: 'SC', cidade: 'VIDEIRA' },
  { estado: 'MG', cidade: 'VIEIRAS' },
  { estado: 'PB', cidade: 'VIEIROPOLIS' },
  { estado: 'PA', cidade: 'VIGIA' },
  { estado: 'PR', cidade: 'VILA ALTA' },
  { estado: 'MT', cidade: 'VILA BELA DA SANTISSIMA TRINDADE' },
  { estado: 'GO', cidade: 'VILA BOA' },
  { estado: 'RN', cidade: 'VILA FLOR' },
  { estado: 'RS', cidade: 'VILA FLORES' },
  { estado: 'RS', cidade: 'VILA LANGARO' },
  { estado: 'RS', cidade: 'VILA MARIA' },
  { estado: 'MA', cidade: 'VILA NOVA DO MARTIRIOS' },
  { estado: 'PI', cidade: 'VILA NOVA DO PIAUI' },
  { estado: 'RS', cidade: 'VILA NOVA DO SUL' },
  { estado: 'ES', cidade: 'VILA PAVAO' },
  { estado: 'GO', cidade: 'VILA PROPICIO' },
  { estado: 'MT', cidade: 'VILA RICA' },
  { estado: 'ES', cidade: 'VILA VALERIO' },
  { estado: 'ES', cidade: 'VILA VELHA' },
  { estado: 'RO', cidade: 'VILHENA' },
  { estado: 'SP', cidade: 'VINHEDO' },
  { estado: 'SP', cidade: 'VIRADOURO' },
  { estado: 'MG', cidade: 'VIRGEM DA LAPA' },
  { estado: 'MG', cidade: 'VIRGINIA' },
  { estado: 'MG', cidade: 'VIRGINOPOLIS' },
  { estado: 'MG', cidade: 'VIRGOLANDIA' },
  { estado: 'PR', cidade: 'VIRMOND' },
  { estado: 'MG', cidade: 'VISCONDE DO RIO BRANCO' },
  { estado: 'PA', cidade: 'VISEU' },
  { estado: 'RS', cidade: 'VISTA ALEGRE' },
  { estado: 'SP', cidade: 'VISTA ALEGRE DO ALTO' },
  { estado: 'RS', cidade: 'VISTA ALEGRE DO PRATA' },
  { estado: 'RS', cidade: 'VISTA GAUCHA' },
  { estado: 'PB', cidade: 'VISTA SERRANA' },
  { estado: 'SC', cidade: 'VITOR MEIRELES' },
  { estado: 'ES', cidade: 'VITORIA' },
  { estado: 'SP', cidade: 'VITORIA BRASIL' },
  { estado: 'BA', cidade: 'VITORIA DA CONQUISTA' },
  { estado: 'RS', cidade: 'VITORIA DAS MISSOES' },
  { estado: 'PE', cidade: 'VITORIA DE SANTO ANTAO' },
  { estado: 'AP', cidade: 'VITORIA DO JARI' },
  { estado: 'MA', cidade: 'VITORIA DO MEARIM' },
  { estado: 'PA', cidade: 'VITORIA DO XINGU' },
  { estado: 'PR', cidade: 'VITORINO' },
  { estado: 'MA', cidade: 'VITORINO FREIRE' },
  { estado: 'MG', cidade: 'VOLTA GRANDE' },
  { estado: 'RJ', cidade: 'VOLTA REDONDA' },
  { estado: 'SP', cidade: 'VOTORANTIM' },
  { estado: 'SP', cidade: 'VOTUPORANGA' },
  { estado: 'BA', cidade: 'WAGNER' },
  { estado: 'PI', cidade: 'WALL FERRAZ' },
  { estado: 'TO', cidade: 'WANDERLANDIA' },
  { estado: 'BA', cidade: 'WANDERLEY' },
  { estado: 'MG', cidade: 'WENCESLAU BRAZ' },
  { estado: 'PR', cidade: 'WENCESLAU BRAZ' },
  { estado: 'BA', cidade: 'WENCESLAU GUIMARAES' },
  { estado: 'RS', cidade: 'WESTFALIA' },
  { estado: 'SC', cidade: 'WITMARSUM' },
  { estado: 'TO', cidade: 'XAMBIOA' },
  { estado: 'PR', cidade: 'XAMBRE' },
  { estado: 'RS', cidade: 'XANGRI-LA' },
  { estado: 'SC', cidade: 'XANXERE' },
  { estado: 'AC', cidade: 'XAPURI' },
  { estado: 'SC', cidade: 'XAVANTINA' },
  { estado: 'SC', cidade: 'XAXIM' },
  { estado: 'PE', cidade: 'XEXEU' },
  { estado: 'PA', cidade: 'XINGUARA' },
  { estado: 'BA', cidade: 'XIQUE-XIQUE' },
  { estado: 'PB', cidade: 'ZABELE' },
  { estado: 'SP', cidade: 'ZACARIAS' },
  { estado: 'MA', cidade: 'ZE DOCA' },
  { estado: 'SC', cidade: 'ZORTEA' },
];

export default cities;
