/* eslint-disable operator-linebreak */
import React, { useEffect, useMemo, useState } from 'react';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { IconButton } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { clearProposalStatus, proposalStatusList } from 'store/ducks/Proposal';
import { useAppDispatch, useAppSelector } from 'hooks/useAppStore';
import { textLabels } from 'utils/muidatatable';
import CustomBackDrop from 'components/CustomBackDrop';
import getColumns from './columnsData';

const List = () => {
  const dispatch = useAppDispatch();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [countText, setcountText] = useState<number | undefined>();
  const [searchAdvancedText, setsearchAdvancedText] = useState<string | null>('');

  // eslint-disable-next-line object-curly-newline
  const {
    proposal: { proposalList: ProposalReportsListState, type, status },
    // eslint-disable-next-line object-curly-newline
  } = useAppSelector((state) => {
    return state;
  });

  useEffect(() => {
    dispatch(proposalStatusList(''));
    dispatch(clearProposalStatus());
    // eslint-disable-next-line
  }, [refreshList]);

  useEffect(() => {
    if (status === 'idle') {
      setOpenBackdrop(true);
    } else if (status === 'loading' && type === 'proposal/adm/list/pending') {
      setOpenBackdrop(true);
    } else if (status === 'completed' && type === 'proposal/adm/list/fulfilled') {
      setOpenBackdrop(false);
    } else if (status === 'failed' && type === 'proposal/adm/list/rejected') {
      setOpenBackdrop(false);
    }
    // eslint-disable-next-line
  }, [status, type]);

  const columns: MUIDataTableColumn[] = useMemo(() => {
    return getColumns(ProposalReportsListState);
  }, [ProposalReportsListState]);

  const handleRefrashButton = () => {
    setRefreshList(!refreshList);
  };
  const options: MUIDataTableOptions = {
    filter: true,
    rowsPerPage: 10,
    customToolbar: () => {
      return (
        <IconButton onClick={handleRefrashButton}>
          <Refresh />
        </IconButton>
      );
    },
    selectableRows: 'none',
    sort: true,
    sortOrder: {
      name: 'Id_da_Proposta',
      direction: 'desc',
    },
    responsive: 'simple',
    page: 0,
    searchProps: {
      onKeyUp: (event) => {
        if (
          (event.code === 'Enter' || event.code === 'NumpadEnter') &&
          searchAdvancedText &&
          countText !== undefined &&
          countText >= 5
        ) {
          dispatch(clearProposalStatus());
          dispatch(proposalStatusList(searchAdvancedText));
        }
      },
    },
    onSearchChange: (searchText) => {
      setcountText(searchText?.length);
      setsearchAdvancedText(searchText);
    },
    searchPlaceholder: 'Nome, CPF ou Número da proposta para busca avançada.',
    textLabels,
    downloadOptions: { filename: 'Propostas.csv', separator: ';' },
  };

  return (
    <>
      <CustomBackDrop open={openBackdrop} />
      <MUIDataTable title="Propostas" data={ProposalReportsListState.document} columns={columns} options={options} />
    </>
  );
};

export default List;
