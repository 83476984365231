import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import iconZurich from '../../../assets/images/iconZurich.png';
import iconVileve from '../../../assets/images/logoVileve.png';
import iconCobap from '../../../assets/images/logoCobap.png';
import ZurichPackages from './ZurichPackages';
import useStyle from './styles';
import VilevePackages from './VilevePackages';
import CobapPackages from './CobapPackages';

const ProductSelection = () => {
  const classes = useStyle();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => {
    return (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  };
  return (
    <Grid container direction="column">
      <Grid item sx={{ marginBottom: '10px' }}>
        <Typography variant="h5" color="primary">
          <b>Instituição Parceira</b>
        </Typography>
      </Grid>
      <Grid item xs={12} spacing={4}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
            <Grid container>
              <Grid item>
                <Typography variant="h6" color="primary">
                  Vileve
                </Typography>
              </Grid>
              <Grid item>
                <img className={classes.iconsImageVileve} src={iconVileve} alt="Vileve" />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
              <VilevePackages />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
            <Grid container>
              <Grid item>
                <Typography variant="h6" color="primary">
                  Cobap
                </Typography>
              </Grid>
              <Grid item>
                <img className={classes.iconsImageCobap} src={iconCobap} alt="zurich" />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
              <CobapPackages />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
            <Grid container>
              <Grid item>
                <Typography variant="h6" color="primary">
                  Zurich Acidentes Pessoais
                </Typography>
              </Grid>
              <Grid item>
                <img className={classes.iconsImageZurich} src={iconZurich} alt="zurich" />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
              <ZurichPackages />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
export default ProductSelection;
