/* eslint-disable import/prefer-default-export */
import { typeDateFormat } from 'interfaces/Masks';

/**
 * Change a string to CPF format
 * @argument string
 * @returns string
 *
 */

export const maskCpf = (value: string): string => {
  let newValue = value;
  if (newValue) {
    newValue = newValue.replace(/\D/g, '');
    newValue = newValue.replace(/^(\d{3})/g, '$1.');
    newValue = newValue.replace(/(\d{3})(\d{3})/g, '$1.$2-');
    return newValue;
  }
  return '';
};

export const maskCnpj = (value: string): string => {
  if (value) {
    let formatedNum = '';
    formatedNum = value.replace(/\D/g, '');
    formatedNum = formatedNum.replace(/^(\d{2})(\d)/, '$1.$2');
    formatedNum = formatedNum.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    formatedNum = formatedNum.replace(/\.(\d{3})(\d)/, '.$1/$2');
    formatedNum = formatedNum.replace(/(\d{4})(\d)/, '$1-$2');
    formatedNum = formatedNum.replace(/(-\d{2})\d+?$/, '$1');
    return formatedNum;
  }
  return '';
};

/**
 * Change a string to cellphone number (nn) nnnnn-nnn format
 * @argument string
 * @returns string
 *
 */
export const maskCel = (value: string): string => {
  let newValue = value;
  if (newValue) {
    newValue = newValue.replace(/\D/g, '');
    newValue = newValue.replace(/^(\d{2})(\d)/g, '($1) $2');
    newValue = newValue.replace(/(\d)(\d{4})$/, '$1-$2');
    return newValue;
  }
  return '';
};

/**
 * Change a string to tell number (nn) nnnn-nnn format
 * @argument string
 * @returns string
 *
 */

export const maskTellPhone = (value: string): string => {
  let newValue = value;
  if (newValue) {
    // Remove tudo o que não é dígitopt-BR'
    // Coloca parênteses em volta dos dois primeiros dígitos
    newValue = newValue.replace(/^(\d{2})(\d)/g, '($1) $2');
    // Coloca hífen entre o quarto e o quinto dígitos
    newValue = newValue.replace(/(\d{4})(\d{4})$/, '$1-$2');
    return newValue;
  }

  return '';
};

/**
 * Return string with number(s)
 * @argument string
 * @returns string
 *
 */
export const maskNumber = (value: string): Number | void => {
  let newValue: string | Number = value;
  if (newValue) {
    newValue = newValue.replace(/\D/g, '');
    newValue = Number(newValue);
    return newValue;
  }
  return undefined;
};

export const maskAssociationCode = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{4})(\d)/, '$1.$2');
};

/**
 * Return string format R$ 000.000.000,00
 * Obs: The maximum is the millions
 * @argument string
 * @returns string
 *
 */
export const maskRealMoney = (value: string | number) => {
  const newValue = value.toString();
  let realFormated = newValue.replace(/\D/g, '');
  realFormated = realFormated.replace('R$', '');
  realFormated = `${(Number(realFormated) / 100).toFixed(2)}`;
  realFormated = realFormated.replace('.', ',');
  realFormated = realFormated.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  realFormated = realFormated.replace(/(\d)(\d{3}),/g, '$1.$2,');
  return `R$ ${realFormated}`;
};

export const maskDateTimeStamp = (date: string | Date, dateFormat: typeDateFormat = 'pt-BR'): string => {
  try {
    const plusDay = new Date(date);
    plusDay.setDate(plusDay.getDate());
    const newDate = new Intl.DateTimeFormat(dateFormat, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }).format(plusDay);
    return newDate;
  } catch (error) {
    return String(date);
  }
};

export const maskDate = (date: string | Date, dateFormat: typeDateFormat = 'pt-BR'): string => {
  try {
    const plusDay = new Date(date);
    plusDay.setDate(plusDay.getDate());
    const newDate = new Intl.DateTimeFormat(dateFormat, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(plusDay);
    return newDate;
  } catch (error) {
    return '';
  }
};

/**
 * Return string with format nnnnn-nnn
 * @argument string
 * @returns string
 *
 */
export const maskCep = (value: string): string => {
  let newValue = value;
  if (newValue) {
    newValue = newValue.replace(/\D/g, '');
    newValue = newValue.replace(/(\d{5})(\d{3})/g, '$1-$2');
    return newValue;
  }
  return '';
};

export const maskCardNumber = (value: string): string => {
  let newValue = value;
  newValue = newValue.replace(/\D/g, '');
  newValue = newValue.replace(/(\d{4})(\d)/, '$1-$2');
  newValue = newValue.replace(/(\d{4})-(\d{4})(\d)/, '$1-$2-$3');
  newValue = newValue.replace(/(\d{4})-(\d{4})-(\d{4})(\d)/, '$1-$2-$3-$4');
  return newValue;
};

export const maskAgence = (value: string): string => {
  let newValue = value;
  newValue = newValue.replace(/\D/g, '');
  newValue = newValue.replace(/(\d{4})(\d)/, '$1-$2');
  return newValue;
};

export const maskAccount = (value: string): string => {
  let newValue = value;
  newValue = newValue.toString().replace(/\D/g, '');
  newValue = [
    newValue.toString().substring(0, newValue.length - 1),
    newValue.toString().substring(newValue.length - 1),
  ].join('-');
  return newValue;
};

// export const masBeneficio = (value: string): string => {
//   // 111111111-1
//   // let newValue = value;
//   // newValue = newValue.replace(/\D/g, '');
//   // newValue = newValue.replace(/(\d{4})(\d)/, '$1-$2');
//   // return newValue;
// };
