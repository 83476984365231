import * as Yup from 'yup';
import { cobapFederation } from 'interfaces/Cobap';

const validationSchema: Yup.SchemaOf<Partial<cobapFederation['document'][0]>> = Yup.object({
  id: Yup.number(),
  nome: Yup.string()
    .matches(/[a-zzáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]\s+/g, 'Por favor, insira um nome válido')
    .max(255, 'Quantidade de caracteres excedida')
    .required('Campo obrigatório')
    .trim(),
  // prettier-ignore
  cnpj: Yup.string()
    .min(18, 'Celular inválido')
    .max(18, 'Celular inválido')
    .required('Campo obrigatório')
    .trim(),
  sigla: Yup.string().required('Campo obrigatório').trim(),
  cep: Yup.string().required('Campo obrigatório').trim(),
  // prettier-ignore
  estado: Yup.string().required('Campo obrigatório')
    .min(2, 'UF inválido')
    .max(2, 'UF inválido')
    .trim(),
  cidade: Yup.string().required('Campo obrigatório').trim(),
  bairro: Yup.string().required('Campo obrigatório').trim(),
  endereco: Yup.string().required('Campo obrigatório').trim(),
  emails: Yup.string().trim(),
  // prettier-ignore
  telefones: Yup.string().min(14, 'CNPJ inválido')
    .max(14, 'CNPJ inválido')
    .required('Campo obrigatório')
    .trim(),
  responsavel: Yup.string()
    .matches(/[a-zzáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]\s+/g, 'Por favor, insira um nome válido')
    .max(255, 'Quantidade de caracteres excedida')
    .required('Campo obrigatório')
    .trim(),
  // prettier-ignore
  cpf: Yup.string()
    .min(14, 'CPF inválido')
    .max(14, 'CPF inválido')
    .required('Campo obrigatório')
    .trim(),
  nascimento: Yup.date()
    .typeError('Data inválida')
    .max(new Date(), 'Datas futuras não são validas')
    .required('Campo obrigatório'),
  email: Yup.string().email('Email inválido').required('Campo obrigatório').trim(),
  // prettier-ignore
  celular: Yup.string()
    .min(15, 'Celular inválido')
    .max(15, 'Celular inválido')
    .required('Campo obrigatório')
    .trim(),
  Key_Associacao: Yup.string().trim(),
});

export default validationSchema;
