export const billingDate = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
];

export const inssEspecieOptions = [
  { codigoInssEspecie: 1 },
  { codigoInssEspecie: 2 },
  { codigoInssEspecie: 3 },
  { codigoInssEspecie: 4 },
  { codigoInssEspecie: 5 },
  { codigoInssEspecie: 6 },
  { codigoInssEspecie: 7 },
  { codigoInssEspecie: 8 },
  { codigoInssEspecie: 19 },
  { codigoInssEspecie: 20 },
  { codigoInssEspecie: 21 },
  { codigoInssEspecie: 22 },
  { codigoInssEspecie: 23 },
  { codigoInssEspecie: 24 },
  { codigoInssEspecie: 26 },
  { codigoInssEspecie: 27 },
  { codigoInssEspecie: 28 },
  { codigoInssEspecie: 29 },
  { codigoInssEspecie: 32 },
  { codigoInssEspecie: 33 },
  { codigoInssEspecie: 34 },
  { codigoInssEspecie: 37 },
  { codigoInssEspecie: 38 },
  { codigoInssEspecie: 41 },
  { codigoInssEspecie: 42 },
  { codigoInssEspecie: 43 },
  { codigoInssEspecie: 44 },
  { codigoInssEspecie: 45 },
  { codigoInssEspecie: 46 },
  { codigoInssEspecie: 49 },
  { codigoInssEspecie: 51 },
  { codigoInssEspecie: 52 },
  { codigoInssEspecie: 54 },
  { codigoInssEspecie: 55 },
  { codigoInssEspecie: 57 },
  { codigoInssEspecie: 58 },
  { codigoInssEspecie: 59 },
  { codigoInssEspecie: 78 },
  { codigoInssEspecie: 81 },
  { codigoInssEspecie: 82 },
  { codigoInssEspecie: 83 },
  { codigoInssEspecie: 84 },
  { codigoInssEspecie: 92 },
  { codigoInssEspecie: 93 },
];
