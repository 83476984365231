import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const width = '260px';
const height = '160px';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      width,
      height,
    },
    img: {
      width,
      height,
    },
    imgUpload: {
      maxHeight: height,
      maxWidth: width,
    },
    input: { display: 'none' },
    button: { margin: 10 },
    positionBtnUpload: {
      bottom: theme.spacing(8),
      right: theme.spacing(-21),
    },
  };
});

export default useStyles;
