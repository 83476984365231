/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  proposal,
  statusProposal,
  filesProposal,
  responseResendContract,
  resendContract,
  newProposalResponse,
  newProposalParams,
  responseValidation,
  responseValidationCelular,
} from 'interfaces/Proposal';
import { reduxTemplate } from 'interfaces/redux';
import { vileveAssitenciaValidadorCpf } from 'interfaces/externalApi';
import {
  getProposalStatusList,
  getProposalHistory,
  getProposalFiles,
  postProposalResendContract,
  postnewProposal,
  getValidateCpf,
  getValidateCelular,
  getValidadorCpf,
} from './service';

export const proposalStatusList = createAsyncThunk<proposal, string, { rejectValue: reduxTemplate }>(
  'proposal/adm/list',
  async (searchAdvanced, { rejectWithValue }) => {
    try {
      const data = await getProposalStatusList(searchAdvanced);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const proposalHistory = createAsyncThunk<statusProposal, number, { rejectValue: reduxTemplate }>(
  '/proposal/adm/status/id',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getProposalHistory(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const proposalFiles = createAsyncThunk<filesProposal, number, { rejectValue: reduxTemplate }>(
  '/proposal/adm/files/id',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getProposalFiles(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const resendContractType = createAsyncThunk<
  responseResendContract,
  resendContract,
  { rejectValue: reduxTemplate }
>('/proposal/resendContract', async (resendContractParams, { rejectWithValue }) => {
  try {
    const data = await postProposalResendContract(resendContractParams);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const newProposal = createAsyncThunk<newProposalResponse, newProposalParams, { rejectValue: reduxTemplate }>(
  '/proposal/onboarding',
  async (newProposalPayload, { rejectWithValue }) => {
    try {
      const data = await postnewProposal(newProposalPayload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const validateCPF = createAsyncThunk<responseValidation, string, { rejectValue: reduxTemplate }>(
  'proposal/cpfvalidate/cpf',
  async (cpf, { rejectWithValue }) => {
    try {
      const data = await getValidateCpf(cpf);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const validateCelular = createAsyncThunk<responseValidationCelular, string, { rejectValue: reduxTemplate }>(
  'proposal/celularvalidate/celular',
  async (celular, { rejectWithValue }) => {
    try {
      const data = await getValidateCelular(celular);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const validadorCpf = createAsyncThunk<vileveAssitenciaValidadorCpf, string, { rejectValue: reduxTemplate }>(
  'proposal/cpf/validador',
  async (cpf, { rejectWithValue }) => {
    try {
      const data = await getValidadorCpf(cpf);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface IProposalReports extends reduxTemplate {
  proposalList: proposal;
  proposalHistory?: statusProposal['document'];
  proposalFiles?: filesProposal['document'];
  newProposal?: newProposalResponse;
  validateCPF?: responseValidation;
  validateCelular?: responseValidationCelular;
  validadorCpf?: vileveAssitenciaValidadorCpf;
}

const initialState: IProposalReports = {
  status: 'idle',
  message: '',
  type: '',
  proposalList: {
    status: '',
    message: '',
    document: [],
  },
  validateCPF: {
    status: '',
    message: '',
    document: [],
  },
  validateCelular: {
    status: '',
    message: '',
    document: [],
  },
};

export const ProposalStatusReducer = createSlice({
  name: 'ProposalStatus',
  initialState,
  reducers: {
    clearProposalStatus: (state) => {
      state.status = 'idle';
      state.message = '';
      state.type = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(proposalStatusList.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(proposalStatusList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.type = action.type;
        state.message = action.payload.message;
        state.proposalList = action.payload;
      })
      .addCase(proposalStatusList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(proposalHistory.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(proposalHistory.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.proposalHistory = action.payload?.document;
        state.type = action.type;
      })
      .addCase(proposalHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(proposalFiles.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(proposalFiles.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.proposalFiles = action.payload?.document;
        state.type = action.type;
      })
      .addCase(proposalFiles.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(resendContractType.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(resendContractType.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.type = action.type;
      })
      .addCase(resendContractType.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(newProposal.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(newProposal.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.type = action.type;
      })
      .addCase(newProposal.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(validateCPF.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(validateCPF.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.validateCPF = action.payload;
      })
      .addCase(validateCPF.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(validateCelular.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(validateCelular.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.validateCelular = action.payload;
      })
      .addCase(validateCelular.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(validadorCpf.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(validadorCpf.fulfilled, (state, action) => {
        state.status = 'completed';
        state.validadorCpf = action.payload;
      })
      .addCase(validadorCpf.rejected, (state) => {
        state.status = 'failed';
        state.validadorCpf = { isValidCpf: false };
      });
  },
});
export const { clearProposalStatus } = ProposalStatusReducer.actions;
export default ProposalStatusReducer.reducer;
