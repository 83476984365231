import React from 'react';
import PageBody from 'components/PageBody';
import { ParamsRoutes } from 'interfaces/RouterParams';
import { ViewList, AssignmentOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppStore';
import { proposal } from 'interfaces/Proposal';
import Form from './Form';

const Federation = () => {
  const { id } = useParams<ParamsRoutes>();
  let idCasting: number = 0;

  if (id) {
    idCasting = parseInt(id, 10);
  }

  const proposalById = useAppSelector((state): proposal['document'][0] | undefined => {
    const getFederationById = state.proposal.proposalList.document.find((doc) => {
      return doc.Id_da_Proposta === idCasting;
    });
    return getFederationById;
  });
  const breadcrumb = [
    {
      href: '/proposal/list',
      label: 'Lista de Propostas',
      icon: ViewList,
    },
    {
      href: `/proposal/${id}`,
      label: 'Dados da Proposta',
      icon: AssignmentOutlined,
    },
  ];

  return (
    <PageBody breadCrumb={breadcrumb}>
      <Form proposalParams={proposalById} create={!id} />
    </PageBody>
  );
};

export default Federation;
