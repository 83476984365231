const validateBenefitNumber = (string: string) => {
  const { length } = string;
  let sum = 0;
  let currentWeight = 2;
  // eslint-disable-next-line no-plusplus
  for (let i = length - 2; i >= 0; i--) {
    const element = parseInt(string[i], 10);
    sum += element * currentWeight;
    currentWeight = currentWeight === 9 ? 2 : currentWeight + 1;
  }
  sum = (sum * 10) % 11;
  const digit = String(sum === 10 ? 0 : sum);
  return digit === string.at(-1);
};

export default validateBenefitNumber;
