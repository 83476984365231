import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    tableRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    tableCell: { width: '100%' },
    cardLayout: { heightMax: '350px' },
    contractButton: { margin: '20px 0 10px 0', display: 'flex', justifyContent: 'center' },
    tdTable: { textAlign: 'center' },
    iconsImageZurich: { height: '35px' },
    iconsImageVileve: { height: '25px', marginLeft: '10px' },
    iconsImageCobap: { height: '25px', marginLeft: '10px' },
  };
});

export default useStyles;
