import { Article, Dashboard } from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export interface ISections {
  subheader: string;
  section: string;
  open: boolean;
  isVisible: boolean;
  href?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  subsections?: {
    partners: {
      title: string;
      href?: string;
      icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
      };
      isVisible: boolean;
      open: boolean;
      itens: {
        title: string;
        href?: string;
        icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
          muiName: string;
        };
        isVisible: boolean;
        anchorEl?: null;
        menus?: {
          title: string;
          href?: string;
          isVisible: boolean;
          isNested?: boolean;
          nesteds?: {
            title: string;
            href: string;
          }[];
        }[];
      }[];
    }[];
  }[];
}

const sections: ISections[] = [
  {
    subheader: 'Dashboard',
    section: 'dashboard',
    href: '/dashboard',
    open: true,
    icon: Dashboard,
    isVisible: false,
  },
  {
    subheader: 'Propostas',
    section: 'proposta',
    href: '/proposal/list',
    open: true,
    icon: Article,
    isVisible: false,
  },
  {
    subheader: 'Produtos',
    section: 'product',
    open: true,
    isVisible: false,
    // subsections: [
    //   {
    //     partners: [
    //       {
    //         title: 'Vileve',
    //         icon: Article,
    //         open: true,
    //         isVisible: false,
    //         itens: [
    //           {
    //             title: 'Parceiros',
    //             icon: Article,
    //             anchorEl: null,
    //             isVisible: false,
    //             menus: [
    //               {
    //                 title: 'Parceiro Teste',
    //                 href: '/cobap/list/association',
    //                 isVisible: false,
    //               },
    //               {
    //                 title: 'Federação',
    //                 href: '/dashboardAssociation',
    //                 isVisible: false,
    //               },
    //               {
    //                 title: 'teste aninhamento',
    //                 isNested: true,
    //                 isVisible: false,
    //                 nesteds: [
    //                   {
    //                     title: 'dentro do aninhamento',
    //                     href: '/signin',
    //                   },
    //                   {
    //                     title: 'dentro do aninhamento',
    //                     href: '/signin',
    //                   },
    //                 ],
    //               },
    //             ],
    //           },
    //         ],
    //       },
    //     ],
    //   },
    // ],
  },
];

export default sections;
