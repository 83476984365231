import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Typography,
  IconButton,
  Modal,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper,
  Avatar,
  Link,
  Grid,
  Divider,
  SvgIconTypeMap,
} from '@mui/material';
import { FilePresent, OpenInNew, AccountBox, Article, FlipToBack, Handshake, Signpost } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useAppDispatch, useAppSelector } from 'hooks/useAppStore';
import { proposalFiles } from 'store/ducks/Proposal';
import PaperBackDrop from 'components/PaperBackDrop';
import { blue, green, lightGreen } from '@mui/material/colors';
import useStyles from './styles';

interface Props {
  open: boolean;
  handleModalFiles: () => void;
  proposal: number | undefined;
}

const FileListLinks = ({ open, handleModalFiles, proposal }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isLoadingFiles, setisLoadingFiles] = useState(true);

  const {
    proposalFiles: proposalFilesRedux,
    status,
    type,
  } = useAppSelector((state) => {
    return state.proposal;
  });

  useEffect(() => {
    if (status === 'loading' && type === '/proposal/adm/files/id/pending') {
      setisLoadingFiles(true);
    } else if (status === 'completed' && type === '/proposal/adm/files/id/fulfilled') {
      setisLoadingFiles(false);
    } else if (status === 'failed' && type === '/proposal/adm/files/id/rejected') {
      setisLoadingFiles(false);
    }
  }, [status, type]);

  useEffect(() => {
    if (proposal && open) {
      dispatch(proposalFiles(proposal));
    }
  }, [open, dispatch, proposal]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleModalFiles}
        aria-labelledby="modal-history-proposal"
        aria-describedby="modal-history-description"
      >
        {
          <Box className={classes.bodyContent}>
            {isLoadingFiles ? (
              <Box sx={{ minWidth: '375px' }}>
                <PaperBackDrop />
              </Box>
            ) : (
              <Paper className={classes.paperContent}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={12} lg={4}>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item>
                        <Chip
                          label={
                            // eslint-disable-next-line
                            <Typography
                              variant="h5"
                              sx={{
                                color: (t) => {
                                  return t.palette.primary.main;
                                },
                                fontWeight: 500,
                              }}
                            >
                              {/* eslint-disable-next-line */}
                              Proposta {proposal}
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <List sx={{ minWidth: '40vh', bgcolor: 'background.paper' }}>
                      {proposalFilesRedux?.map(({ stipoarquivoid, uri, formato }) => {
                        let Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
                          muiName: string;
                        };
                        let label = '';
                        let color = '';
                        switch (stipoarquivoid) {
                          case 1:
                            Icon = AccountBox;
                            label = 'Selfie';
                            color = lightGreen['800'];
                            break;
                          case 2:
                            Icon = Signpost;
                            label = 'Comprovante de Endereço';
                            color = blue['900'];
                            break;
                          case 3:
                            Icon = Article;
                            label = 'Documento de Identificação - Frente';
                            color = green['800'];
                            break;
                          case 4:
                            Icon = FlipToBack;
                            label = 'Documento de Identificação - Verso';
                            color = lightGreen['900'];
                            break;
                          case 5:
                            Icon = Handshake;
                            label = 'Contrato Social - Inserção';
                            color = blue['900'];
                            break;
                          case 6:
                            Icon = Handshake;
                            label = 'Contrato Social - Atualização';
                            color = blue['800'];
                            break;
                          default:
                            Icon = FilePresent;
                            break;
                        }
                        if (stipoarquivoid === 2 && formato === 'pdf') {
                          Icon = Handshake;
                          label = 'Contrato';
                          color = blue.A700;
                        }
                        return (
                          <>
                            <ListItem key={stipoarquivoid}>
                              <ListItemAvatar>
                                <Avatar sx={{ bgcolor: color }}>
                                  <Icon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={label} sx={{ color }} />
                              <IconButton
                                aria-label="link document"
                                component={Link}
                                color="primary"
                                href={`https://integracaovileveassist.vileve.com.br/public/storage/${uri}`}
                                underline="always"
                                target="_blank"
                                rel="noopener"
                              >
                                <OpenInNew />
                              </IconButton>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </>
                        );
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Box>
        }
      </Modal>
    </div>
  );
};

export default FileListLinks;
