import React, { useState } from 'react';
import { AddPhotoAlternate } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { toBase64 } from 'utils/string/toBase64';
import noImage from 'assets/images/no-image.png';
import { isValidateMaxSizeFile } from 'utils/files';
import { useSnackbar } from 'notistack';
import useStyles from './styles';

interface Props {
  name: string;
  sizeLimitMB: number;
  getBase64?: (base64: string, file: File) => void;
  base64?: string;
}

const Image = ({ name, getBase64, base64 = '', sizeLimitMB }: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [base64Content, setBase64Content] = useState<string>(base64);
  const handleBase64 = (urlBase64: string, file: File) => {
    if (getBase64) {
      getBase64(urlBase64, file);
    }
  };
  const handleUploadClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget) {
      if (event.currentTarget.files) {
        const file = event.currentTarget.files[0];
        const urlBase64 = await toBase64(file);
        if (!isValidateMaxSizeFile(file, sizeLimitMB)) {
          enqueueSnackbar(`O limite para esse campo é: ${sizeLimitMB}MB`, { variant: 'warning' });
          return;
        }
        if (typeof urlBase64 === 'string') {
          setBase64Content(urlBase64);
          handleBase64(urlBase64, file);
        }
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.img}>
        <img
          id={`${name}/img`}
          src={!base64Content ? noImage : base64Content}
          alt="Imagem carregada"
          className={classes.imgUpload}
        />

        <input
          name={name}
          accept="image/png, image/jpeg"
          className={classes.input}
          id={`${name}/input`}
          multiple
          type="file"
          onChange={handleUploadClick}
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={`${name}/input`}>
        <Fab
          component="span"
          sx={{
            backgroundColor: (theme) => {
              return theme.palette.primary.main;
            },
            color: 'white',
            width: '50px',
            height: '50px',
          }}
          className={(classes.button, classes.positionBtnUpload)}
        >
          <AddPhotoAlternate />
        </Fab>
      </label>
    </div>
  );
};

export default Image;

Image.defaultProps = { getBase64: undefined, base64: '' };
